import React, { useCallback, useState } from 'react';
import {
  ReferenceArrayField,
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  RowClickFunction,
  Record,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { useForm, useField } from 'react-final-form';
import moment from 'moment';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import RequestEditDialog from '../Request/standalone/RequestEditDialog';

import { isReadonly } from '../../../utils/node';
import { Rights } from '../../../authProvider/permissions';

import { GQLMinoStatus } from '../../../types/mino/schema';
import { SMinoUser, SMinoRequest } from '../../../types/mino/nodes';
import { ClassName } from '../../../types/styles';
import config from '../../../config';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    numOrDateCell: {
      textAlign: 'center',
      flexDirection: 'row',
    },
  }),
);

type UserRequestsProps = {
  record?: SMinoUser;
  permissions?: Rights[];
  className?: ClassName;
};

const UserRequests: React.FC<UserRequestsProps> = ({
  className,
  ...raProps
}: UserRequestsProps) => {
  const classes = useStyles();
  const { record: user, permissions } = raProps;
  const form = useForm();
  const formRequestsField = useField('requests');

  const translate = useTranslate();

  const readOnly =
    isReadonly(user) || !permissions?.includes(Rights.MINO_WRITE);

  const [editRequest, setEditRequest] = useState<SMinoRequest | null>(null);
  const handleCloseEditDialog = useCallback(() => {
    setEditRequest(null);
  }, [setEditRequest]);

  const handleRowClick = useCallback<RowClickFunction>(
    (id, basePath, request) => {
      setEditRequest(request as SMinoRequest);
      return '';
    },
    [setEditRequest],
  );

  if (!user) {
    return null;
  }

  if (!formRequestsField) {
    form.registerField(
      'requests',
      () => {},
      {},
      { data: user?.requests || [] },
    );
  }

  return (
    <>
      {!readOnly && (
        <RequestEditDialog
          {...raProps}
          request={editRequest}
          onClose={handleCloseEditDialog}
        />
      )}
      <ReferenceArrayField
        {...raProps}
        record={
          { requests: formRequestsField.input.value } as unknown as Record
        }
        reference="minoRequest"
        source="requests"
        addLabel={false}
        fullWidth
        filter={{ status: GQLMinoStatus.PUBLISHED }} // #199 Hide archived requests
      >
        <Datagrid rowClick={readOnly ? undefined : handleRowClick}>
          <FunctionField
            sortable={false}
            source="createdAt"
            render={(record?: Record): React.ReactNode => {
              const { createdAt } = (record as SMinoRequest) || {};
              return moment(createdAt).format('DD/MM/YYYY');
            }}
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <FunctionField
            sortable={false}
            source="wfStatus"
            render={(record?: Record): string => {
              const rq = record as SMinoRequest;
              return `${
                rq?.wfStatus ? translate(`enums.wfStatus.${rq.wfStatus}`) : ''
              }`;
            }}
          />
          <TextField sortable={false} source="provider" />
          <TextField sortable={false} source="streetAddress" />
          <TextField sortable={false} source="postalCode" />
          <TextField sortable={false} source="addressLevel2" />
          <FunctionField
            sortable={false}
            source="dateBail"
            render={(record?: Record): React.ReactNode => {
              const { dateBail } = (record as SMinoRequest) || {};
              return moment(dateBail, config.awsDateFormat).format(
                'DD/MM/YYYY',
              );
            }}
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <EditButton component="button" to={undefined} />
        </Datagrid>
      </ReferenceArrayField>
    </>
  );
};

export default UserRequests;
