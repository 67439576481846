import React, { useState, useCallback, useRef } from 'react';
import {
  useAuthenticated,
  useDataProvider,
  UpdateResult,
  DataProvider,
} from 'ra-core';

import csv from 'papaparse';
import moment, { Moment } from 'moment';

import { progressPreparedPromisesBatch } from '../../../lib/promises';

import { MinoUser, SMinoUser } from '../../../types/mino/nodes';

import GenericImport, {
  GenericFieldsInfos,
  GenericCsvData,
  GenericDataToImport,
  GenericErrorData,
  checkAndUpdateFieldsData,
} from './genericImport';
import { getUsersCP } from '../../../utils/mino/management';
import { DateTuple, isDateIncluded } from '../../../utils/dates';
import { GQLMinoContractEdge } from '../../../types/mino/schema';

import config from '../../../config';
import { CustomMinoProvider } from '../../../dataProvider/dataProvider';
import { MM } from '../../../aws/s3-utils-management';

export const key = 'minoManagementImportUsersOrganisation';

const fields = [
  'sncfCP',
  'effectDate',
  'sncfSA',
  'sncfBranche',
  'sncfDivision',
  'sncfRG',
  'sncfEtablissement',
] as const;
type Field = (typeof fields)[number];

function isOnContractMandatory(dbUser?: MinoUser): boolean {
  if (typeof dbUser === 'undefined') return true;
  const allContractsDates: DateTuple[] = [];
  dbUser.contracts?.edges.forEach(({ node: contract }: GQLMinoContractEdge) => {
    allContractsDates.push([
      moment(contract.sncfContractBeginDate),
      contract.sncfContractEndDate
        ? moment(contract.sncfContractEndDate)
        : undefined,
    ]);
  });
  return isDateIncluded(
    moment(config.minoManagementMonthIso).endOf('month').endOf('day'),
    allContractsDates,
  );
}

const fieldsInfos: GenericFieldsInfos<Field> = {
  sncfCP: {
    csvHeader: 'IMMATRICULATION',
    isMandatory: true,
    isRowKey: true,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  effectDate: {
    csvHeader: "DATE D'EFFET",
    isMandatory: true,
    isDate: true,
    dateCsvFormat: 'YYYYMMDD',
    translateFieldPath: 'import.fields',
  },
  sncfSA: {
    csvHeader: 'SA',
    isMandatory: isOnContractMandatory,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  sncfBranche: {
    csvHeader: 'BRANCHE',
    isMandatory: isOnContractMandatory,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  sncfDivision: {
    csvHeader: 'DIVISION',
    isMandatory: isOnContractMandatory,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  sncfRG: {
    csvHeader: 'RG',
    isMandatory: isOnContractMandatory,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  sncfEtablissement: {
    csvHeader: 'ETABLISSEMENT',
    isMandatory: isOnContractMandatory,
    isDbData: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
};

type CsvUser = GenericCsvData<Field>;

type UserToImport = GenericDataToImport<Field> & {
  user: MinoUser;
};

type ErrorUser = GenericErrorData<Field>;

type DbUsers = {
  [sncfCP: string]: MinoUser;
};

type DbUsersControl = {
  [sncfCP: string]: MinoUser | undefined;
};

const ImportUsersOrganisation: React.FC = () => {
  useAuthenticated();
  const dataProvider = useDataProvider<DataProvider, CustomMinoProvider>();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<null | number>(null);
  const usersCP = useRef<ReturnType<typeof getUsersCP>>(new Map());
  const csvValidUsersSet = useRef(new Set<string>());
  const [usersToImport, setUsersToImport] = useState<UserToImport[]>();
  const [errorUsers, setErrorUsers] = useState<ErrorUser[]>();

  const retrieveDbUsers = useCallback(() => {
    return dataProvider
      .getListAll('minoUser')
      .then(({ data: users, flattenedData: flattenedUsers }) => {
        usersCP.current = getUsersCP(users);
        return {
          dbUsers: flattenedUsers.reduce<DbUsers>((acc, user) => {
            if (user.sncfCP) {
              acc[user.sncfCP] = user;
            }
            return acc;
          }, {}),
          dbUsersControl: users.reduce<DbUsersControl>((acc, user) => {
            if (user.sncfCP) {
              acc[user.sncfCP] = user;
            }
            return acc;
          }, {}),
        };
      });
  }, [dataProvider]);

  // Match db and import contracts
  const checkUsers = useCallback(
    ({
      dbUsers,
      dbUsersControl,
      csvUsers,
    }: {
      dbUsers: DbUsers;
      dbUsersControl: DbUsersControl;
      csvUsers: CsvUser[];
    }) => {
      const importUsersTemp: UserToImport[] = [];
      const errorUsersTemp: ErrorUser[] = [];
      csvUsers.forEach(csvUser => {
        const updatedFields: Field[] = [];
        let dbUser: MinoUser | undefined;

        const { csvDataToImport: csvUserToImport, errors } =
          checkAndUpdateFieldsData<Field>(
            fieldsInfos,
            csvUser,
            csvUser.sncfCP ? dbUsersControl[csvUser.sncfCP] : undefined,
          );

        // Special knownledge: month must be equal to currentMonth
        let csvMonthDate: Moment | undefined;
        if (csvUserToImport.effectDate) {
          csvMonthDate = moment(`${csvUserToImport.effectDate}`);
          if (
            !csvMonthDate.isSame(moment(config.minoManagementMonthIso), 'month')
          ) {
            errors.push({
              field: 'effectDate',
              message: `Le mois ne correspond pas au mois importé [${moment(
                config.minoManagementMonthIso,
              ).format('YYYY/MM')}]`,
            });
          } else {
            csvUserToImport.effectDate = csvMonthDate.format(
              config.awsDateFormat,
            );
          }
        }

        if (csvUserToImport.sncfCP) {
          csvUserToImport.sncfCP = `${csvUserToImport.sncfCP}`;
          // Special knownledge: sncfCP must be in DB
          if (!Object.keys(dbUsers).includes(csvUserToImport.sncfCP)) {
            errors.push({
              field: 'sncfCP',
              message:
                "Inexistant dans la base de donnée (Du moins, cet agent n'a aucune demande en cours)",
            });
          }
          // Special knownledge: sncfCP must be in export 2b
          if (!usersCP.current.has(csvUserToImport.sncfCP)) {
            errors.push({
              field: 'sncfCP',
              message: "Ne correspond pas à un agent de l'export 2b",
            });
          }

          if (!errors.some(({ field }) => field === 'sncfCP')) {
            const user = dbUsers[csvUserToImport.sncfCP];
            dbUser = user;

            (Object.keys(fieldsInfos) as Field[])
              .filter(
                field =>
                  fieldsInfos[field].isDbData && !fieldsInfos[field].isRowKey,
              )
              .forEach(field => {
                const userField = field as keyof typeof user;
                if (user[userField] !== csvUserToImport[field]) {
                  updatedFields.push(field);
                }
              });
          }
        }

        if (dbUser && errors.length === 0) {
          csvValidUsersSet.current.add(`${csvUserToImport.sncfCP}`);
          importUsersTemp.push({
            ...csvUserToImport,
            user: dbUser,
            updatedFields,
          });
        } else {
          errorUsersTemp.push({
            ...csvUserToImport,
            updatedFields,
            errors,
          });
        }
      });

      setUsersToImport(importUsersTemp);
      setErrorUsers(errorUsersTemp);
    },
    [],
  );

  // Set importContracts
  const onCompleteCSVImport = useCallback(
    (csvResult: csv.ParseResult<CsvUser>): Promise<void> => {
      usersCP.current.clear();
      csvValidUsersSet.current.clear();
      return retrieveDbUsers().then(
        ({
          dbUsers,
          dbUsersControl,
        }: {
          dbUsers: DbUsers;
          dbUsersControl: DbUsersControl;
        }) => {
          checkUsers({
            dbUsers,
            dbUsersControl,
            csvUsers: csvResult.data,
          });
        },
      );
    },
    [retrieveDbUsers, checkUsers],
  );

  const importUsersToDB = useCallback(() => {
    if (usersToImport) {
      setLoading(true);

      const preparedPromises: Array<
        [(...args: any) => Promise<UpdateResult>, any[]]
      > = usersToImport.map(userToImport => {
        const { user, ...userRest } = userToImport;
        const clearedUser: SMinoUser = (
          Object.keys(userRest) as Field[]
        ).reduce<any>((acc, field) => {
          if (fieldsInfos[field] && fieldsInfos[field].isDbData) {
            acc[field] = userRest[field];
          }
          return acc;
        }, {});
        return [
          dataProvider.update,
          [
            'minoUser',
            {
              id: user.id,
              data: {
                ...clearedUser,
                ifMutation: {
                  sncfImportDate: moment().format(config.awsDateFormat),
                },
                version: user.version,
              },
              previousData: user,
            },
          ],
        ];
      });

      setProgress(0);
      return progressPreparedPromisesBatch(preparedPromises, p => {
        setProgress(p);
      }).then(() => {
        setLoading(false);
        setProgress(null);
      });
    }
    return Promise.resolve();
  }, [usersToImport, dataProvider]);

  return (
    <GenericImport
      pageKey={key}
      s3FileName="Organisations\.csv"
      s3Source={`npf-padh/PADH\\.AGT\\.N\\.CPT\\.M\\.${MM}\\d{2}\\.\\d{4}\\.zip$`}
      fieldsInfos={fieldsInfos}
      loading={loading}
      setLoading={setLoading}
      progress={progress}
      onCompleteCSVImport={onCompleteCSVImport}
      users={[Array.from(usersCP.current.keys()), csvValidUsersSet.current]}
      dataToImport={usersToImport}
      errorsData={errorUsers}
      importDataToDB={importUsersToDB}
    />
  );
};

export default ImportUsersOrganisation;
