import React, { useCallback } from 'react';
// import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import classnames from 'clsx';
import { Link } from '@material-ui/core';

import { getS3DownloadUrl } from '../../aws/s3-utils';

import sanitizeRestProps from './sanitizeRestProps';
import { FieldProps, InjectedFieldProps } from './types';

const useStyles = makeStyles(
  {
    root: { display: 'inline-block' },
  },
  { name: 'CustomFileField' },
);

interface Props extends FieldProps, InjectedFieldProps {
  src?: string;
  title: string;
  target?: string;
  classes?: object;
}

const FileField: React.FC<Props> = (props: Props) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    record,
    source,
    title,
    src,
    target,
    ...rest
  } = props;
  const classes = useStyles(props);

  const handleClickOpen = useCallback(async () => {
    if (!record) return;

    let link: string;
    if (record.rawFile) {
      link = URL.createObjectURL(record.rawFile);
    } else if (record.key) {
      link = await getS3DownloadUrl(record.key);
    } else {
      return;
    }
    window.open(link);
  }, [record]);

  return (
    <div
      className={classnames(classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <Link
        component="button"
        type="button"
        variant="body2"
        onClick={handleClickOpen}
      >
        {record?.title ?? title}
      </Link>
    </div>
  );
};

export default FileField;
