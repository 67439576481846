import React from 'react';
import { Create, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { useTranslate, ReduxState } from 'ra-core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import RequestFormBaseInputs from './RequestFormBaseInputs';
import ContainerForward from '../../structure/ContainerForward';

const RequestTitle: React.FC = () => {
  const translate = useTranslate();
  return <span>{translate('resources.htRequest.create')}</span>;
};

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
    },
  }),
);

type RequestCreateToolbarProps = {
  submitting?: boolean;
  basePath?: string;
};
const RequestCreateToolbar: React.FC<RequestCreateToolbarProps> = ({
  submitting,
  ...props
}: RequestCreateToolbarProps) => {
  const { basePath } = props;
  const classes = useToolbarStyles();
  const translate = useTranslate();

  return (
    <Toolbar {...props} className={classes.root}>
      <ContainerForward>
        <SaveButton
          label="ra.action.save"
          redirect="list"
          submitOnEnter={false}
          disabled={submitting}
        />
        <>
          {!!basePath && (
            <Button
              component={RouterLink}
              to={basePath ?? ''}
              className={classes.cancelButton}
            >
              {translate('ra.action.cancel')}
            </Button>
          )}
        </>
      </ContainerForward>
    </Toolbar>
  );
};

type RequestCreateProps = {};

const RequestCreate: React.FC<RequestCreateProps> = (
  props: RequestCreateProps,
) => {
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );
  return (
    <Create {...props} title={<RequestTitle />}>
      <SimpleForm
        saving={submitting}
        toolbar={<RequestCreateToolbar submitting={submitting} />}
        // warnWhenUnsavedChanges
        submitOnEnter={false}
      >
        <RequestFormBaseInputs create />
      </SimpleForm>
    </Create>
  );
};

export default RequestCreate;
