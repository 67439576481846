import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  EditButton,
  BulkDeleteButton,
  BulkDeleteButtonProps,
  Record,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { ListProps } from 'ra-core/esm/controller/useListController';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Rights } from '../../../authProvider/permissions';

import { HtResidence } from '../../../types/ht/nodes';
import residencesExporter from '../../../exporters/ht/residence';

const ResidenceBulkActionButtons: React.FC<BulkDeleteButtonProps> = props => {
  return <BulkDeleteButton {...props} undoable={false} />;
};

const useStyles = makeStyles(() =>
  createStyles({
    numOrDateCell: {
      textAlign: 'center',
      flexDirection: 'row',
    },
  }),
);

const ResidenceList: React.FC<ListProps> = (props: ListProps) => {
  const { permissions } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const permExport = permissions?.includes(Rights.EXPORT);
  return (
    <List
      key={permExport} // Weird but necessary for export button to showup ...
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      title={translate('resources.htResidence.list')}
      bulkActionButtons={<ResidenceBulkActionButtons />}
      exporter={permExport ? residencesExporter : false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="streetAddress" />
        <NumberField
          source="postalCode"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <TextField source="addressLevel2" />
        <TextField source="code" />
        <NumberField
          source="distance"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <FunctionField
          source="filiere"
          render={(record?: Record): string =>
            `${(record as HtResidence)?.filiere?.join(', ')}`
          }
        />
        <NumberField
          source="priority"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <FunctionField
          label={translate('resources.htResidence.fields.longitude')}
          render={(record?: Record): string =>
            `${(record as HtResidence)?.coordinates?.[0]}`
          }
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <FunctionField
          label={translate('resources.htResidence.fields.latitude')}
          render={(record?: Record): string =>
            `${(record as HtResidence)?.coordinates?.[1]}`
          }
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ResidenceList;
