import React from 'react';
import { useGetMany } from 'ra-core';
import { LinearProgress, FileInput } from 'react-admin';
import _compact from 'lodash/compact';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import FileField from '../CustomFields/FileField';

import { nodeEntryIsSimplifiedConnectionMany } from '../../types/connections';
import { ID, Asset } from '../../types/nodes';
import withInputContainer from './withInputContainer';

type FormattedInput = {
  title: string;
};
export type FormattedFile = FormattedInput & {
  rawFile: File;
};
export type FormattedAsset = FormattedInput & {
  key: string;
  assetId: string;
};

/* FileInputFormated because:
    - format IN can be parsed data
    - parse IN can be formatted data */
export type FileInputFormatted = FormattedFile | FormattedAsset;
type FileInputToFormat = string | File | FileInputFormatted;

/** Can not have been parsed due to no modifications of the file => ID */
export type AssetData = ID | FileInputFormatted;
/** Can not have been parsed due to no modifications of the files => ID[] */
export type AssetsData = ID[] | FileInputFormatted[];
export function assetsDataIsAssetIds(
  assetsData: AssetsData,
): assetsData is ID[] {
  return typeof assetsData[0] === 'string';
}
export function assetDataIsFile(
  assetData: AssetData,
): assetData is FormattedFile {
  return (assetData as FormattedFile).rawFile instanceof File;
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    fullWidthLoader: {
      width: '100%',
    },
  }),
);

function formatFiles(assets: Asset[]): any {
  const formatFile = (file: FileInputToFormat): FileInputFormatted | null => {
    // `file` is an asset id
    if (typeof file === 'string') {
      const asset = assets.find(({ id }) => id === file);
      if (!asset || !asset.key) return null;

      return {
        key: asset.key,
        title: asset.filename ?? '', // For display
        assetId: file, // For keeping track
      };
    }

    // `file` is a File
    if (file instanceof File) {
      return {
        title: file.name, // For display
        rawFile: file, // For keeping track
      };
    }

    // `file` is already formatted
    return file;
  };

  return (
    files: FileInputToFormat | FileInputToFormat[],
  ): FileInputFormatted | FileInputFormatted[] | null => {
    if (!files) return null;
    return Array.isArray(files)
      ? _compact(files.map(formatFile))
      : formatFile(files);
  };
}

type AssetInputProps = {
  record?: any;
  resource?: string;
  assetResource: string;
  source: string;
  multiple?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
};

const AssetInput: React.FC<AssetInputProps> = ({
  record,
  disabled,
  assetResource,
  ...fileInputProps
}: AssetInputProps) => {
  const classes = useStyles();
  const { source, fullWidth, className = '' } = fileInputProps;
  const sourceData = record[source];
  const ids = nodeEntryIsSimplifiedConnectionMany(sourceData) ? sourceData : [];
  const { data, error, loaded } = useGetMany(assetResource, ids);

  if (!loaded || error) {
    return (
      <LinearProgress
        className={clsx(className, { [classes.fullWidthLoader]: fullWidth })}
      />
    );
  }
  const assets = data.filter(a => a) as Asset[]; // some assets could have been deleted

  return (
    <FileInput
      format={formatFiles(assets)} // Data to input (initialize)
      parse={formatFiles(assets)} // Input to data (submit)
      options={{ disabled, noClick: disabled }}
      {...fileInputProps}
    >
      <FileField title="title" target="_blank" />
    </FileInput>
  );
};

export default withInputContainer(AssetInput);
