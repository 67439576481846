import _filter from 'lodash/filter';
import moment from 'moment';
import { Record } from 'react-admin';

import { HtNode, HtRequest, nodeIsRequest } from '../../types/ht/nodes';

// type Filters = { [K in keyof Partial<Request>]: Request[K] };
type Filters = any;

function filterRequests(requests: HtRequest[], filters: Filters): HtRequest[] {
  const remainingFilters: Filters = {};
  let filteredRequests: HtRequest[] = requests;
  Object.keys(filters).forEach(filterKey => {
    const filterValue = filters[filterKey];
    switch (filterKey) {
      case 'createdAt':
        filteredRequests = filteredRequests.filter(
          ({ createdAt }) =>
            createdAt && moment(createdAt).isSameOrAfter(filterValue),
        );
        break;
      case 'sncfCP':
      case 'givenName':
      case 'familyName':
        filteredRequests = filteredRequests.filter(request => {
          const requestKeyValue = request[filterKey];
          return requestKeyValue
            ? new RegExp(filterValue, 'i').test(requestKeyValue)
            : false;
        });
        break;
      case 'nbDays':
        filteredRequests = filteredRequests.filter(
          ({ nbDays }) => nbDays <= +filterValue,
        );
        break;
      case 'nbPropositions':
        filteredRequests = filteredRequests.filter(
          ({ nbPropositions }) => nbPropositions >= +filterValue,
        );
        break;
      case 'nbRefusedPropositions':
        filteredRequests = filteredRequests.filter(
          ({ nbRefusedPropositions }) => nbRefusedPropositions >= +filterValue,
        );
        break;
      default:
        remainingFilters[filterKey] = filterValue;
    }
  });
  return _filter(filteredRequests, remainingFilters);
}

const isRequestArray = (a: Record[]): a is HtRequest[] =>
  a.length ? nodeIsRequest(a[0]) : false;

function filterNodes(data: Record[], filters: Filters): Record[] {
  if (!data.length || !Object.keys(filters).length) return data;
  if (isRequestArray(data)) {
    return filterRequests(data, filters);
  }
  return _filter(data, filters);
}

export default filterNodes;
