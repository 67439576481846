import _castArray from 'lodash/castArray';
import _uniq from 'lodash/uniq';

import { awsAppSyncCredentials } from '../aws/config';

import { Rights, roleRights, Roles } from './permissions';
import { winModal, winFeatures } from './win-utils';
import { logout as padhLogout } from './phileog-login';

let logouting: Promise<any> | null = null;

export default {
  // called when the user attempts to log in
  login: async (): Promise<any> => {
    console.info('auth login');
    localStorage.removeItem('padhCredentials');
    await winModal(`#/oauth`, 'padhLogin', winFeatures(960, 780));
    return awsAppSyncCredentials.setCredentials().catch(() => {});
  },
  // called when the user clicks on the logout button
  logout: async (): Promise<void> => {
    console.info('auth logout');
    if (!logouting) logouting = padhLogout();
    await logouting;
    logouting = null;
    awsAppSyncCredentials.deleteCredentials();
    // return Promise.resolve(/* redirect: string */);
  },
  // called when the API returns an error
  // On reject, calls logout
  checkError: (...args: any[]): Promise<void> => {
    // FIXME - refresh ?
    console.info('auth checkError');
    console.info(args);
    return Promise.resolve();
  },
  // called when the user navigates to a new location
  // On reject, calls logout
  checkAuth: async (): Promise<any> => {
    console.info('auth checkAuth');
    const hasCreds = awsAppSyncCredentials.credentials;
    try {
      // console.log(hasCreds ? 'Getting' : 'Setting');
      await (hasCreds
        ? awsAppSyncCredentials.getCredentials()
        : awsAppSyncCredentials.setCredentials());
    } catch (e) {
      console.log(e);
      return Promise.reject(/* { redirectTo: '/login' } */);
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async (): Promise<Rights[]> => {
    console.info('auth getPermissions');
    const hasCreds = awsAppSyncCredentials.credentials;
    try {
      await (hasCreds
        ? awsAppSyncCredentials.getCredentials()
        : awsAppSyncCredentials.setCredentials());
    } catch (e) {
      return [];
    }
    const creds = awsAppSyncCredentials.padhCreds;
    if (!creds) return [];
    const { roles = [] }: { roles: Roles[] } = creds;
    if (!roles || roles.length === 0) return [];

    if (creds.sub === 'PJQS11641') {
      roles.push(Roles.SUPER_ADMIN);
    }
    const permissions = _castArray(roles).reduce((acc: Rights[], role) => {
      return _uniq(acc.concat(roleRights[role]));
    }, []);
    return permissions;
  },
};
