import React, { useState, useCallback, useRef } from 'react';
import {
  useAuthenticated,
  useDataProvider,
  UpdateResult,
  CreateResult,
  DataProvider,
} from 'ra-core';

import csv from 'papaparse';
import moment, { Moment } from 'moment';

import { progressPreparedPromisesBatch } from '../../../lib/promises';

import { SMinoContract } from '../../../types/mino/nodes';
import { GQLMinoSncfContractType } from '../../../types/mino/schema';
import { enhanceQueryResult } from '../../../dataProvider/mino/flattener';

import GenericImport, {
  GenericFieldsInfos,
  DateOrder,
  GenericCsvData,
  GenericDataToImport,
  GenericErrorData,
  checkAndUpdateFieldsData,
} from './genericImport';
import { DateTuple, areDatesOverlappingInMonth } from '../../../utils/dates';
import config from '../../../config';
import { getUsersCP } from '../../../utils/mino/management';
import { CustomMinoProvider } from '../../../dataProvider/dataProvider';
import { ResourceKey } from '../../../dataProvider/mino/resourcesKeys';
import { MM } from '../../../aws/s3-utils-management';

export const key = 'minoManagementImportContracts';

const fields = [
  'sncfCP',
  'sncfContractType',
  'sncfContractBeginDate',
  'sncfContractEndDate',
] as const;
type Field = (typeof fields)[number];

const fieldsInfos: GenericFieldsInfos<Field> = {
  sncfCP: {
    csvHeader: 'IMMATRICULATION',
    isMandatory: true,
    isRowKey: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  sncfContractType: {
    csvHeader: 'TYPE DE CONTRAT',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoContract.fields',
  },
  sncfContractBeginDate: {
    csvHeader: 'DATE DE DEBUT DU CONTRAT',
    isMandatory: true,
    isRowKey: true,
    isDate: true,
    dateCsvFormat: 'YYYYMMDD',
    dateOrder: { [DateOrder.BEFORE]: 'sncfContractEndDate' },
    isDbData: true,
    translateFieldPath: 'resources.minoContract.fields',
  },
  sncfContractEndDate: {
    csvHeader: 'DATE DE FIN DU CONTRAT',
    isDate: true,
    dateCsvFormat: 'YYYYMMDD',
    dateOrder: { [DateOrder.BEFORE]: 'sncfContractBeginDate' },
    isDbData: true,
    translateFieldPath: 'resources.minoContract.fields',
  },
};

type CsvContract = GenericCsvData<Field>;

const contractTypes: (keyof typeof GQLMinoSncfContractType)[] = ['DD', 'DI'];

type ContractToImport = GenericDataToImport<Field> & {
  dbContract?: SMinoContract;
  user: SMinoContract['user'];
};

type ErrorContract = GenericErrorData<Field>;

type DbUsers = {
  [sncfCP: string]: SMinoContract['user'];
};
type DbContracts = {
  [k: string]: SMinoContract[];
};

const ImportContracts: React.FC = () => {
  useAuthenticated();
  const dataProvider = useDataProvider<DataProvider, CustomMinoProvider>();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<null | number>(null);
  const usersCP = useRef<ReturnType<typeof getUsersCP>>(new Map());
  const csvValidUsersSet = useRef(new Set<string>());
  const [contractsToImport, setContractsToImport] =
    useState<ContractToImport[]>();
  const [errorContracts, setErrorContracts] = useState<ErrorContract[]>();

  const retrieveDbContracts = useCallback(() => {
    return dataProvider.getListAll('minoUser').then(({ data: users }) => {
      const dbContracts: DbContracts = {};
      users.forEach(({ id, sncfCP, contracts }) => {
        if (sncfCP) {
          contracts?.edges.forEach(({ node: contract }) => {
            const sContract = enhanceQueryResult<
              typeof contract,
              SMinoContract
            >(contract);
            sContract.user = id; // For connection not to be considered an updated data
            if (dbContracts[sncfCP]) {
              dbContracts[sncfCP].push(sContract);
            } else {
              dbContracts[sncfCP] = [sContract];
            }
          });
        }
      });
      usersCP.current = getUsersCP(users);
      return { dbContracts };
    });
  }, [dataProvider]);

  // Match db and import contracts
  const checkContracts = useCallback(
    ({
      dbContracts,
      csvContracts,
    }: {
      dbContracts: DbContracts;
      csvContracts: CsvContract[];
    }) => {
      const importContractsTemp: ContractToImport[] = [];
      const errorContractsTemp: ErrorContract[] = [];

      csvContracts.forEach(csvContractRaw => {
        const updatedFields: Field[] = [];
        let dbContract: SMinoContract | undefined;
        let userId;
        let isNew = true;

        const { csvDataToImport: csvContractToImport, errors } =
          checkAndUpdateFieldsData<Field>(fieldsInfos, csvContractRaw);

        if (csvContractToImport.sncfCP) {
          csvContractToImport.sncfCP = `${csvContractToImport.sncfCP}`;

          // Special knownledge: sncfCP must be in DB
          if (!usersCP.current.has(csvContractToImport.sncfCP)) {
            errors.push({
              field: 'sncfCP',
              message: "Ne correspond pas à un agent de l'export 2b",
            });
          } else {
            userId = usersCP.current.get(csvContractToImport.sncfCP);
          }
        }
        // Special knownledge: contractType must be of specified types
        if (
          !contractTypes.includes(
            csvContractToImport.sncfContractType as keyof typeof GQLMinoSncfContractType,
          )
        ) {
          errors.push({
            field: 'sncfContractType',
            message: `Ne correspond pas à un type de contrat autorisé (${contractTypes.join(
              ', ',
            )})`,
          });
        }

        const dbContractMatch =
          csvContractToImport.sncfCP &&
          dbContracts[csvContractToImport.sncfCP]?.find(contract => {
            return (
              contract.sncfContractBeginDate ===
              csvContractToImport.sncfContractBeginDate
            );
          });
        if (dbContractMatch) {
          dbContract = dbContractMatch;
          isNew = false;
          (Object.keys(fieldsInfos) as Field[])
            .filter(
              field =>
                fieldsInfos[field].isDbData && !fieldsInfos[field].isRowKey,
            )
            .forEach(field => {
              const contractField = field as keyof typeof dbContractMatch;
              if (
                dbContractMatch[contractField] !== csvContractToImport[field]
              ) {
                updatedFields.push(field);
              }
            });
        }

        // Special knownledge: contracts must not overlap on the currentMonth
        if (csvContractToImport.sncfContractBeginDate) {
          if (csvContractToImport.sncfCP) {
            const allContractsDates: DateTuple[] = [];
            dbContracts[csvContractToImport.sncfCP]?.forEach(contract => {
              const { sncfContractBeginDate, sncfContractEndDate } = contract;
              let start = moment(sncfContractBeginDate);
              let end: Moment | undefined = sncfContractEndDate
                ? moment(sncfContractEndDate)
                : undefined;
              if (dbContract && dbContract.id === contract.id) {
                start = moment(
                  `${csvContractToImport.sncfContractBeginDate}`,
                  fieldsInfos.sncfContractBeginDate.dateCsvFormat,
                );
                end = csvContractToImport.sncfContractEndDate
                  ? moment(
                      `${csvContractToImport.sncfContractEndDate}`,
                      fieldsInfos.sncfContractEndDate.dateCsvFormat,
                    )
                  : undefined;
              }
              allContractsDates.push([start, end]);
            });
            if (
              areDatesOverlappingInMonth(
                allContractsDates,
                moment(config.minoManagementMonthIso),
              )
            ) {
              errors.push({
                field: 'sncfContractBeginDate',
                message: `Plusieurs contrats se chevauchent sur le mois ${moment(
                  config.minoManagementMonthIso,
                ).format('MM/YYYY')}`,
              });
              errors.push({
                field: 'sncfContractEndDate',
                message: `Plusieurs contrats se chevauchent sur le mois ${moment(
                  config.minoManagementMonthIso,
                ).format('MM/YYYY')}`,
              });
            }
          }
        }

        if (userId && errors.length === 0) {
          csvValidUsersSet.current.add(`${csvContractRaw.sncfCP}`);
          importContractsTemp.push({
            ...csvContractToImport,
            dbContract,
            user: userId, // Needed for connection upon db creation
            updatedFields,
            isNew,
          });
        } else {
          errorContractsTemp.push({
            ...csvContractToImport,
            updatedFields,
            errors,
          });
        }
      });

      setContractsToImport(importContractsTemp);
      setErrorContracts(errorContractsTemp);
    },
    [],
  );

  // Set importContracts
  const onCompleteCSVImport = useCallback(
    (csvResult: csv.ParseResult<CsvContract>): Promise<void> => {
      usersCP.current.clear();
      csvValidUsersSet.current.clear();
      return retrieveDbContracts().then(
        ({ dbContracts }: { dbContracts: DbContracts }) => {
          checkContracts({
            dbContracts,
            csvContracts: csvResult.data,
          });
        },
      );
    },
    [retrieveDbContracts, checkContracts],
  );

  const importContractsToDB = useCallback(() => {
    if (contractsToImport) {
      setLoading(true);

      const preparedPromises: Array<
        [(...args: any) => Promise<UpdateResult | CreateResult>, any[]]
      > = contractsToImport.map(contract => {
        const { dbContract, user, ...contractRest } = contract;
        const clearedContract: SMinoContract = (
          Object.keys(contractRest) as Field[]
        ).reduce<any>((acc, field) => {
          if (fieldsInfos[field] && fieldsInfos[field].isDbData) {
            acc[field] = contractRest[field];
          }
          return acc;
        }, {});
        clearedContract.user = user;
        if (dbContract) {
          return [
            dataProvider.update,
            [
              'minoContract',
              {
                id: dbContract.id,
                data: { ...clearedContract, version: dbContract.version },
                previousData: dbContract,
              },
            ],
          ];
        }
        return [
          dataProvider.create,
          [
            'minoContract',
            {
              data: clearedContract,
            },
          ],
        ];
      });

      setProgress(0);
      return progressPreparedPromisesBatch(preparedPromises, p => {
        setProgress(p);
      }).then(() => {
        setLoading(false);
        setProgress(null);
      });
    }
    return Promise.resolve();
  }, [contractsToImport, dataProvider]);

  return (
    <GenericImport
      pageKey={key}
      s3FileName="Contrats\.csv"
      s3Source={`npf-padh/PADH\\.AGT\\.N\\.CPT\\.M\\.${MM}\\d{2}\\.\\d{4}\\.zip$`}
      fieldsInfos={fieldsInfos}
      loading={loading}
      setLoading={setLoading}
      progress={progress}
      onCompleteCSVImport={onCompleteCSVImport}
      users={[Array.from(usersCP.current.keys()), csvValidUsersSet.current]}
      dataToImport={contractsToImport}
      errorsData={errorContracts}
      importDataToDB={importContractsToDB}
    />
  );
};

export default ImportContracts;
