import React from 'react';
import { ClassName } from '../../types/styles';

export type ContainerForwardProps = React.PropsWithChildren<{
  containerClassName?: ClassName;
}>;
const ContainerForward: React.FC<ContainerForwardProps> = ({
  children,
  containerClassName,
  ...props
}: ContainerForwardProps) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return <div className={containerClassName}>{childrenWithProps}</div>;
};

export default ContainerForward;
