import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ClassName } from '../../types/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% + ${theme.spacing(0.5) * 2}px);`,
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-0.5),
      alignItems: 'center',
      '& > *': {
        flexGrow: 1,
        flexBasis: '0%',
        margin: theme.spacing(0.5),
      },
    },
  }),
);

export type FormRowProps = {
  children: React.ReactNode;
  className?: ClassName;
};
const FormRow: React.FC<FormRowProps> = ({
  children,
  className = '',
  ...props
}: FormRowProps) => {
  const classes = useStyles();
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
  return (
    <div className={clsx(classes.root, className)}>{childrenWithProps}</div>
  );
};

export default FormRow;
