import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ArrayInput,
  DateInput,
  SelectInput,
  FormDataConsumer,
  SimpleFormIterator,
  Button,
} from 'react-admin';
import { required, useTranslate } from 'ra-core';
import { useForm, useFormState } from 'react-final-form';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import TextInput from '../../../CustomInputs/TextInput';

import FormSection from '../../../structure/FormSection';
import FormRow from '../../../structure/FormRow';

import {
  motifRefusChoices,
  residenceCodeChoices,
  wfStatusChoices,
} from '../../../../utils/mino/enums';

import AssetInput from '../../../CustomInputs/AssetInput';
import { getAssetResource } from '../../../../dataProvider/resourcesInfos';
import resourcesInfos from '../../../../dataProvider/mino/resourcesInfos';
import { SMinoRequest } from '../../../../types/mino/nodes';
import { DeepPartial } from '../../../../types/utils';
import {
  GQLMinoMinoStatus,
  GQLMinoRequest,
} from '../../../../types/mino/schema';
import BooleanCheckboxInput from '../../../CustomInputs/BooleanCheckboxInput';
import TooltipRGPD from '../../../Tooltip/TooltipRGPD';
import { useMakeDocx } from '../../../../exporters/mino/docx';
import { visualize } from '../../../../lib/download';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    formSection: {
      display: 'flex',
      flexFlow: 'row wrap',

      '& $field': {
        flexGrow: 1,
        flexBasis: '34%', // More that 1/3
      },
    },
    field: {},
    conventionButton: {
      flexBasis: 'auto',
      flexGrow: 0,
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(0.5),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    basicRow: {
      marginLeft: theme.spacing(1),
    },
    postalCode: {
      flexGrow: 0.5,
    },
    residenceCode: {
      maxWidth: '33%',
    },
  }),
);

type RequestEditFormInputsProps = {
  record: GQLMinoRequest;
  disabled: boolean;
};

const RequestEditFormInputs: React.FC<RequestEditFormInputsProps> = ({
  ...inputProps
}: RequestEditFormInputsProps) => {
  const { record: request } = inputProps;
  const classes = useStyles();

  const resource = 'minoRequest';

  const translate = useTranslate();

  const form = useForm();
  const { values: formStateValues } = useFormState();
  const requestValues = formStateValues as DeepPartial<GQLMinoRequest>;

  // Reset `motifRefus` if status is not REFUSEE
  useEffect(() => {
    if (
      requestValues.wfStatus !== GQLMinoMinoStatus.REFUSEE &&
      requestValues.motifRefus
    ) {
      form.change(
        'motifRefus',
        // Force `null` only if db value is set to something relevant
        request?.wfStatus && request.wfStatus !== null ? null : undefined,
      );
    }
  }, [form, request, requestValues]);

  const makeDocx = useMakeDocx();

  const handleDocx = useCallback(() => {
    const action = async () => {
      const doc = await makeDocx();
      if (doc) visualize(doc);
    };
    action();
  }, [makeDocx]);

  const assetResource = getAssetResource(resourcesInfos);

  const defaultReceipts = useMemo<boolean[]>(() => [false, false, false], []);

  return (
    <>
      <FormRow className={classes.basicRow}>
        <SelectInput
          {...inputProps}
          source="wfStatus"
          choices={wfStatusChoices}
        />
        <FormDataConsumer>
          {({
            formData,
            ...rest
          }: {
            formData: DeepPartial<SMinoRequest>;
          }): React.ReactNode => {
            if (formData.wfStatus === GQLMinoMinoStatus.REFUSEE) {
              return (
                <SelectInput
                  {...inputProps}
                  source="motifRefus"
                  choices={motifRefusChoices}
                  fullWidth
                  validate={required()}
                  {...rest}
                />
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <BooleanCheckboxInput {...inputProps} source="wfNotify" />
      </FormRow>
      <FormRow className={classes.basicRow}>
        <Button
          className={classes.conventionButton}
          onClick={handleDocx}
          color="default"
          variant="contained"
        >
          <>
            <VisibilityIcon className={classes.leftIcon} /> Visualiser la
            convention
          </>
        </Button>
        <div />
      </FormRow>
      <FormSection
        title={translate(`resources.${resource}.sections.accomodationInfo`)}
        className={classes.formSection}
      >
        <FormRow>
          <TextInput {...inputProps} source="provider" fullWidth />
          <DateInput {...inputProps} source="dateBail" fullWidth />
          <TextInput {...inputProps} source="streetAddress" fullWidth />
        </FormRow>
        <FormRow>
          <TextInput
            {...inputProps}
            source="postalCode"
            className={classes.postalCode}
          />
          <TextInput {...inputProps} source="addressLevel2" />
        </FormRow>
        <FormRow>
          <SelectInput
            {...inputProps}
            source="residenceCode"
            className={classes.residenceCode}
            choices={residenceCodeChoices}
          />
        </FormRow>
      </FormSection>
      <FormSection
        title={translate(`resources.${resource}.sections.additionalInfo`)}
        className={classes.formSection}
      >
        <FormRow>
          <TooltipRGPD>
            <TextInput
              source="comment"
              multiline
              minRows={5}
              maxRows={10}
              {...inputProps}
              fullWidth
            />
          </TooltipRGPD>
        </FormRow>
        <FormRow>
          <ArrayInput
            {...inputProps}
            source="receipts"
            defaultValue={defaultReceipts}
            label="Quittances"
          >
            <SimpleFormIterator disableRemove disableReordering disableAdd>
              <FormDataConsumer>
                {args => {
                  const { getSource } = args;
                  const source = getSource?.('').slice(0, -1); // remove final dot (improve ?)
                  const id = source?.match(/\[(\d*)]$/)?.[1] || 0; // source === 'receipts[0]'
                  if (!source) return null;
                  return (
                    <BooleanCheckboxInput
                      source={source}
                      label={id && `Trimestre ${+id + 1}`}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormRow>
        {assetResource && (
          <FormRow>
            <AssetInput
              {...inputProps}
              source="attachments"
              multiple
              assetResource={assetResource}
              fullWidth
            />
          </FormRow>
        )}
      </FormSection>
    </>
  );
};

export default RequestEditFormInputs;
