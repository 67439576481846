import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import { Record } from 'react-admin';

import { NodeBase, NodeInput } from '../types/nodes';

/* eslint-disable import/prefer-default-export */

/**
 * Extract key/values from data that are different than previousData
 */
export function extractUpdateDiff<T extends Record = NodeBase>(
  data: T,
  previousData: T,
): NodeInput {
  return _reduce<T, NodeInput>(
    data,
    (acc, value, key) => {
      const prevValue = previousData[key as keyof NodeBase];
      if (
        typeof value !== 'undefined' &&
        !_isEqual(value, prevValue) &&
        !(_isNil(value) && _isNil(prevValue))
      ) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
}
