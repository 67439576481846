import React, { useMemo, useCallback, useState } from 'react';
import {
  List,
  Datagrid,
  DatagridBody,
  DatagridRow,
  TextField,
  NumberField,
  DateField,
  FunctionField,
  EditButton,
  Filter,
  NumberInput,
  DateInput,
  SelectInput,
  BulkDeleteButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  BulkDeleteButtonProps,
  DatagridRowProps,
} from 'react-admin';
import {
  useTranslate,
  useListContext,
  useDataProvider,
  Exporter,
  Record,
} from 'ra-core';
import { ListProps } from 'ra-core/esm/controller/useListController';
import { Backdrop, LinearProgress, useTheme } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Color from 'color';
import clsx from 'clsx';

import IconCloudDownload from '@material-ui/icons/CloudDownload';

import TextInput from '../../CustomInputs/TextInput';

import { Rights } from '../../../authProvider/permissions';

import requestsExporter, {
  requestsFullExporter,
} from '../../../exporters/ht/request';

import { isWfNextDone } from '../../../utils/ht/request';
import { wfStatusChoices } from '../../../utils/ht/enums';

import { HtRequest } from '../../../types/ht/nodes';
import { Style } from '../../../types/styles';

const RequestBulkActionButtons: React.FC<BulkDeleteButtonProps> = props => {
  return <BulkDeleteButton {...props} undoable={false} />;
};

const useActionsStyles = makeStyles<Theme>(theme =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.text.contrastText,
    },
    linearProgress: {
      width: '20vw',
      boxShadow: theme.shadows[4],
    },
  }),
);

const Actions: React.FC<any> = (props: any) => {
  const classes = useActionsStyles();
  const { className, exporter, filters, maxResults, permissions, ...rest } =
    props;
  const permExport = permissions?.includes(Rights.EXPORT);

  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [progress, setProgress] = useState<number | null>(null);

  const tickCallback = (n: number) => {
    setProgress(n);
  };

  const requestsFullExporterCb = useCallback(() => {
    if (permExport) {
      setProgress(0);
      requestsFullExporter(translate, dataProvider, tickCallback).finally(
        () => {
          setProgress(null);
        },
      );
    }
  }, [permExport, translate, dataProvider]);

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    // hasCreate,
    basePath,
    // selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <>
      <Backdrop className={classes.backdrop} open={progress !== null}>
        {progress !== null && (
          <LinearProgress
            // Usefull when archived users are being loaded (no way of knowing progress of it)
            variant={progress !== 1 ? 'determinate' : 'indeterminate'}
            value={progress * 100}
            className={classes.linearProgress}
          />
        )}
      </Backdrop>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0 || !permExport}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
        <Button
          disabled={total === 0 || !permExport}
          onClick={requestsFullExporterCb}
          label="Exporter tout"
        >
          <IconCloudDownload />
        </Button>
      </TopToolbar>
    </>
  );
};

const RequestFilter: React.FC = props => {
  return (
    <Filter {...props}>
      <TextInput source="sncfCP" alwaysOn />
      <TextInput source="givenName" />
      <TextInput source="familyName" alwaysOn />
      <DateInput source="createdAt" locales="fr-FR" />
      <NumberInput source="nbDays" />
      <SelectInput source="wfStatus" choices={wfStatusChoices} alwaysOn />
      <NumberInput source="nbPropositions" />
      <NumberInput source="nbRefusedPropositions" />
    </Filter>
  );
};

const requestRowStyle =
  (theme: Theme) =>
  (record: Record, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.secondary.light).alpha(0.1).toString(),
    };
  };

const useRowStyles = makeStyles(() =>
  createStyles({
    done: {
      '&, & > *': {
        opacity: 0.7,
      },
    },
  }),
);

type RequestDatagridRowProps = React.PropsWithChildren<{
  record?: HtRequest;
}>;
const RequestDatagridRow: React.FC<RequestDatagridRowProps> = (
  props: RequestDatagridRowProps,
) => {
  const { record: request } = props;
  const classes = useRowStyles();

  return (
    <DatagridRow
      {...props}
      classes={
        {
          rowCell: clsx({
            [classes.done]: isWfNextDone(request),
          }),
        } as DatagridRowProps['classes']
      }
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    numOrDateCell: {
      textAlign: 'center',
      flexDirection: 'row',
    },
  }),
);

const RequestDatagridBody: React.FC = props => (
  <DatagridBody {...props} row={<RequestDatagridRow />} />
);

const RequestList: React.FC<ListProps> = (props: ListProps) => {
  const { permissions } = props;
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();

  const requestExporterWithTranslate: Exporter = useMemo(
    () => requestsExporter(translate),
    [translate],
  );

  const permExport = permissions?.includes(Rights.EXPORT);
  const permEdit = permissions?.includes(Rights.HT_WRITE);

  return (
    <List
      {...props}
      filters={<RequestFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title={translate('resources.htRequest.list')}
      bulkActionButtons={permEdit ? <RequestBulkActionButtons /> : false}
      exporter={permExport ? requestExporterWithTranslate : false}
      actions={<Actions permissions={permissions} />}
    >
      <Datagrid
        rowClick="edit"
        body={<RequestDatagridBody />}
        rowStyle={requestRowStyle(theme)}
      >
        <TextField source="sncfCP" />
        <TextField source="givenName" />
        <TextField source="familyName" />
        <DateField
          source="createdAt"
          locales="fr-FR"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <FunctionField
          source="nbDays"
          render={(record?: Record): React.ReactNode => {
            const { nbDays } = (record as HtRequest) || {};
            if (nbDays === Infinity) return '';
            return nbDays <= 0 ? (
              <span style={{ color: 'red' }}>{nbDays}</span>
            ) : (
              <span>{nbDays}</span>
            );
          }}
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <FunctionField
          source="wfStatus"
          render={(record?: Record): string => {
            const rq = record as HtRequest;
            return `${
              rq?.wfStatus ? translate(`enums.wfStatus.${rq.wfStatus}`) : ''
            }`;
          }}
        />
        <NumberField
          source="nbPropositions"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <NumberField
          source="nbRefusedPropositions"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <EditButton label={permEdit ? 'ra.action.edit' : 'ra.action.show'} />
      </Datagrid>
    </List>
  );
};

export default RequestList;
