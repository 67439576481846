export enum Status {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum TransactionState {
  BEGIN = 'BEGIN',
  COMMIT = 'COMMIT',
  ROLLBACK = 'ROLLBACK',
}

export enum WFNext {
  VALIDATED = 'VALIDATED',
  UPDATED = 'UPDATED',
  DONE = 'DONE',
}

export enum WFStatus {
  A_VALIDER = 'A_VALIDER',
  EN_INSTRUCTION = 'EN_INSTRUCTION',
  STANDBY = 'STANDBY',
  VALIDEE_PARME = 'VALIDEE_PARME',
  REFUSEE_PARME = 'REFUSEE_PARME',
  VALIDEE = 'VALIDEE',
  ATTENTE_ORIENTATION = 'ATTENTE_ORIENTATION',
  REFUSEE = 'REFUSEE',
  TRANSMIS_PARTENAIRE = 'TRANSMIS_PARTENAIRE',
  TRANSMIS_ALS = 'TRANSMIS_ALS',
  PROPOSITION_EN_COURS = 'PROPOSITION_EN_COURS',
  PROPOSITION_ALS = 'PROPOSITION_ALS',
  PROPOSITION_ACCEPTEE = 'PROPOSITION_ACCEPTEE',
  BAIL_SIGNE = 'BAIL_SIGNE',
  RENONCEE = 'RENONCEE',
  ANNULEE = 'ANNULEE',
}

export enum Nationality {
  FR = 'FR',
  EU = 'EU',
  NON_EU = 'NON_EU',
}

export enum Decision {
  ACCEPTATION = 'ACCEPTATION',
  REFUS = 'REFUS',
}

export enum Financement {
  PLAI = 'PLAI',
  PLUS = 'PLUS',
  PLS = 'PLS',
  PLI = 'PLI',
  LIBRE = 'LIBRE',
}

// Situation maritale
export enum Marital {
  SINGLE = 'SINGLE',
  REGISTERED = 'REGISTERED',
  PACSED = 'PACSED',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum HonorificPrefix {
  MR = 'MR',
  MME = 'MME',
}

// Motif de la demande
export enum Reason {
  ALTERNANT = 'ALTERNANT',
  DECOHABITATION = 'DECOHABITATION',
  FORMATION = 'FORMATION',
  MOBILITE = 'MOBILITE',
  NOUVEL_EMBAUCHE = 'NOUVEL_EMBAUCHE',
  SANS_LOGEMENT = 'SANS_LOGEMENT',
  SEPARATION = 'SEPARATION',
  URGENCE_SOCIALE = 'URGENCE_SOCIALE',
}

/* /!\ Manuel /!\
 * enum absent du schéma graphql */
export enum HTMotifRefus {
  GALO = 'GALO',
  DOC_NON_RECUS = 'DOC_NON_RECUS',
  PLUS_SALARIE = 'PLUS_SALARIE',
  AYANT_DROIT = 'AYANT_DROIT',
}

export enum SNCFEmployeeType {
  /** Agent CDD */
  CDD = 'CDD',
  /** Agent CDI */
  CDI = 'CDI',
  // /** Prestataire externe */
  EXT = 'EXT',
  // /** Filiale */
  INT = 'INT',
}

export enum SNCFStatus {
  /** AG agent contractuelle relevant de la CPAM */
  AG = 'AG',
  /** Cadre dirigeant */
  CD = 'CD',
  /** CP agent au cadre permanent relevant de la CPR */
  CP = 'CP',
  /** Cadre supérieur */
  CS = 'CS',
  /** CC */
  CC = 'CC',
  CM = 'CM',
  MD = 'MD',
}

/** See translation in [frenchMessages.ts](../../i18n/frenchMessages.ts) */
export enum SNCFCompany {
  'SNCF' = 'SNCF', // 001
  'SNCF RESEAU' = 'SNCF RESEAU',
  'SNCF VOYAGEURS' = 'SNCF VOYAGEURS',
  'SNCF GARES & CONNEXIONS' = 'SNCF GARES & CONNEXIONS',
  'FRET SNCF' = 'FRET SNCF',
  'SNCF Optimservices' = 'SNCF Optimservices', // 008
}

export enum Provider {
  PARME = 'PARME',
}

export enum Typology {
  STUDIO = 'STUDIO',
  T1 = 'T1',
  T2 = 'T2',
}
