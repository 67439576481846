import { ID } from './ht/base';
import { ReplaceKeys, OptionalExtends } from './utils';

export type Connection<Node = any> = {
  edges: Edge<Node>[];
  pageInfo: PageInfo;
};
export type Edge<Node = any> = {
  node: Node;
  cursor?: ID;
  __typename?: string;
};
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: boolean;
  /** When paginating backwards, are there more items? */
  hasPreviousPage: boolean;
  /** When paginating backwards, the cursor to continue */
  startCursor?: string;
  /** When paginating forwards, the cursor to continue */
  endCursor?: string;
};

/**
 * Make connections optional
 * (Because when using FragmentBase connections are not returned)
 */
export type OptionalConnections<T> = OptionalExtends<T, Connection>;

/* ********************
 * Simplified Nodes (without connections)
 */
export type SimplifiedConnectionMany = ID[];
export type SimplifyNodeConnectionMany<T, K extends keyof T> = ReplaceKeys<
  T,
  K,
  SimplifiedConnectionMany
>;
export type SimplifiedConnectionOne = ID;
export type SimplifyNodeConnectionOne<T, K extends keyof T> = ReplaceKeys<
  T,
  K,
  SimplifiedConnectionOne
>;

/* *******************
 * Other type guards
 */
export function nodeEntryIsSimplifiedConnectionMany(
  nodeEntry: unknown,
): nodeEntry is SimplifiedConnectionMany {
  return (
    typeof nodeEntry !== 'undefined' &&
    Array.isArray(nodeEntry as SimplifiedConnectionMany) &&
    ((nodeEntry as SimplifiedConnectionMany).length === 0 ||
      typeof (nodeEntry as SimplifiedConnectionMany)[0] === 'string')
  );
}

export type SimplifiedConnection =
  | undefined
  | SimplifiedConnectionOne
  | SimplifiedConnectionMany;

/**
 * Connection Input
 */
export type ConnectionInput = {
  /**
   * Add connections to ID[]
   */
  connect?: ID[];
  /**
   * Remove connections to ID[]
   */
  disconnect?: ID[];
};
