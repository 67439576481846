import {
  DataProvider,
  GetListResult,
  GetListParams,
  GetOneResult,
  GetManyResult,
  CrudGetListAction,
  CrudGetOneAction,
  CrudGetManyAction,
  Identifier,
  Record,
} from 'ra-core';
import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
  ObservableQuery,
  OperationVariables,
} from '@apollo/client';
import { eventChannel, END, EventChannel } from 'redux-saga';
import Observable from 'zen-observable';
import gql from 'graphql-tag';

import { isGetListAction, isGetOneAction, isGetManyAction } from '../saga';

import { CacheUpdateResult, updateStore } from '../../graphql/ht/cacheUtils';
import { UpdateFragmentsBase } from '../../graphql/ht/fragments';
import { QueryAllNode } from '../../graphql/ht/queries';
import { HtNodeUpdate } from '../../graphql/ht/mutations';
import { CustomHTProvider } from '../dataProvider';

type ObservableTuple = [
  ObservableQuery<any, OperationVariables>,
  Promise<ApolloQueryResult<QueryAllNode>>,
];

const withObserver = <T extends DataProvider>(
  dataProvider: T,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  observables: { htResidence: ObservableTuple; htRequest: ObservableTuple },
): T & Pick<CustomHTProvider, 'getObserver'> => {
  const [obsHtResidence, obsHtResidenceInit] = observables.htResidence;
  const [obsHtRequest, obsHtRequestInit] = observables.htRequest;

  // create a set of observers who will register to obsUpdate
  // see inspiration https://github.com/apollographql/apollo-client/blob/master/src/core/ObservableQuery.ts#L515
  const subObservers = new Set<ZenObservable.Observer<any>>();

  const obsUpdate = new Observable(observer => {
    subObservers.add(observer);
    return (): void => {
      subObservers.delete(observer);
    };
  });

  // Setup subscription
  const SUB_QUERY = gql`
    subscription {
      subscribeToNode {
        ...UpdateFragments
      }
    }
    ${UpdateFragmentsBase('all')}
  `;
  const obsSubscription = apolloClient.subscribe({
    query: SUB_QUERY,
    // fetchPolicy: 'network-only',
  });
  obsSubscription.subscribe({
    next: ({
      data: { subscribeToNode: nodeUpdate },
    }: {
      data: { subscribeToNode: HtNodeUpdate };
    }) => {
      updateStore(nodeUpdate, apolloClient).then(updateResult => {
        // CHECK - should GetManys be updated first ?
        requestAnimationFrame(() => {
          subObservers.forEach(o => {
            if (!o.next) return;
            o.next(updateResult);
          });
        });
      });
      // Note: no need to obsRequest.updateQuery(), it is done by Apollo
    },
    error: (...args: any[]) => {
      console.warn(args);
    },
  });

  return {
    ...dataProvider,
    getObserver: (
      resource: string,
      params: CrudGetListAction | CrudGetOneAction | CrudGetManyAction,
    ): EventChannel<GetListResult | GetOneResult | GetManyResult> =>
      eventChannel(emit => {
        const { type } = params;
        if (isGetListAction(params)) {
          const { payload } = params;
          const init: Promise<ApolloQueryResult<QueryAllNode>> | undefined = (
            {
              htRequest: obsHtRequestInit,
              htResidence: obsHtResidenceInit,
            } as { [k: string]: any }
          )[resource];
          if (!init) {
            // We don't support more than htRequest and htResidence
            emit(END);
            return (): void => {};
          }
          const observable: ObservableQuery<any, any> = (
            {
              htRequest: obsHtRequest,
              htResidence: obsHtResidence,
            } as { [k: string]: any }
          )[resource];
          let isReady = false;
          const sub = init.then(() =>
            observable.subscribe({
              next() {
                if (!isReady) {
                  // apollo passes an update directly onSubscribe - ignore it
                  isReady = true;
                  return;
                }
                // ignore data, refetch the list (or the one) and flatten from api
                dataProvider
                  .getList(resource, payload as GetListParams)
                  .then(
                    (
                      newData:
                        | GetListResult<Record>
                        | GetOneResult<Record>
                        | GetManyResult<Record>
                        | END,
                    ) => {
                      // this is GET_LIST emit
                      emit(newData);
                    },
                  );
              },
              error(err: any) {
                emit(err); // new Error(err) ??
              },
              complete() {
                emit(END);
              },
            }),
          );
          return (): void => {
            sub.then(subscription => {
              subscription.unsubscribe();
            });
          };
        }
        if (isGetOneAction(params)) {
          const { payload } = params;
          const sub = obsUpdate.subscribe({
            next(data: CacheUpdateResult) {
              if (data[resource]?.[payload.id]) {
                // that's me - emit get_one !
                dataProvider
                  .getOne(resource, payload)
                  .then(
                    (
                      newData:
                        | GetListResult<Record>
                        | GetOneResult<Record>
                        | GetManyResult<Record>
                        | END,
                    ) => {
                      // GET_ONE emit
                      emit(newData);
                    },
                  );
              }
            },
            error(err: any) {
              emit(err); // new Error(err) ??
            },
            complete() {
              emit(END);
            },
          });
          return (): void => {
            sub.unsubscribe();
          };
        }
        if (isGetManyAction(params)) {
          const { payload } = params;
          const sub = obsUpdate.subscribe({
            next(data: CacheUpdateResult) {
              const ids: Identifier[] = Object.keys(data[resource] ?? {});
              if (payload.ids.some(id => ids.includes(id))) {
                // that's one of mine - emit get_many !
                dataProvider
                  .getMany(resource, payload)
                  .then(
                    (
                      newData:
                        | GetListResult<Record>
                        | GetOneResult<Record>
                        | GetManyResult<Record>
                        | END,
                    ) => {
                      // GET_MANY emit
                      emit(newData);
                    },
                  );
              }
            },
            error(err: any) {
              emit(err); // new Error(err) ??
            },
            complete() {
              emit(END);
            },
          });
          return (): void => {
            sub.unsubscribe();
          };
        }
        console.warn(`${type} not supported yet`);
        emit(END);
        return (): void => {};
      }),
  };
};

export default withObserver;
