import { DocumentNode } from 'graphql';

import { GenericResourceKey } from './resourcesKeys';

export type GenericResourceInfos<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
> = {
  typename: string;
  /** Used in apollo client's cache: dataIdFromObject */
  dataId: string;
  /** ONLY ONE of the resourcesInfos CAN BE asset */
  isAsset?: boolean;
  fragment: {
    name: string;
    full: DocumentNode;
    base: DocumentNode;
  };
  query: {
    name: string;
    allList: DocumentNode;
  };
  mutation: {
    transactionnal: boolean;
    dataInputGQLType: string;
    createName: string;
    updateName: string;
    deleteName?: string;
  };
  connections?: GenericResourceConnectionInfo<ResourceKey>[]; // use map ?
};

export type GenericResourceConnectionInfo<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
> = {
  resource: ResourceKey;
  key: string;
  multiple: boolean;
};

export type GenericResourcesInfos<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
  ResourceInfos = GenericResourceInfos<ResourceKey>,
> = {
  [k in ResourceKey]: ResourceInfos;
};

type GenericResourceInfosWithConnections<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
> = Exclude<GenericResourceInfos<ResourceKey>, 'connections'> &
  Pick<Required<GenericResourceInfos<ResourceKey>>, 'connections'>;

export function resourceInfosHasConnections<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
>(
  resourceInfos: GenericResourceInfos<ResourceKey>,
): resourceInfos is GenericResourceInfosWithConnections<ResourceKey> {
  return (
    !!resourceInfos.connections && Array.isArray(resourceInfos.connections)
  );
}

export function getAssetResource<ResourceKey extends GenericResourceKey>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
): ResourceKey | undefined {
  return (Object.keys(resourcesInfos) as ResourceKey[]).find(
    resource => resourcesInfos[resource].isAsset ?? false,
  );
}

export type AssetResourceKeyConnections<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
> = Map<ResourceKey, string>;
export function getAssetResourceKeyConnections<
  ResourceKey extends GenericResourceKey,
>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
): AssetResourceKeyConnections<ResourceKey> {
  const assetResourceKeyConnections = new Map<ResourceKey, string>();
  const assetResource = getAssetResource(resourcesInfos);
  if (!assetResource) {
    return assetResourceKeyConnections;
  }
  (Object.keys(resourcesInfos) as ResourceKey[]).forEach(resource => {
    const { connections } = resourcesInfos[resource];
    connections?.forEach(connection => {
      if (connection.resource === assetResource) {
        assetResourceKeyConnections.set(resource, connection.key);
      }
    });
  });
  return assetResourceKeyConnections;
}
