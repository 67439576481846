import gql from 'graphql-tag';

export const NodeFragment = gql`
  fragment NodeFields on Node {
    __typename
    id
    createdAt
    updatedAt
    version
    status
  }
`;

export const NodeUpdateInfoFields = gql`
  fragment NodeUpdateInfoFields on NodeUpdateInfo {
    __typename
    mutation
    connect {
      field
      id
    }
    disconnect {
      field
      id
    }
  }
`;

export const HistoryFields = gql`
  fragment HistoryFields on History {
    identity
    wfStatus
    action
    details
  }
`;

export const LogEventFields = gql`
  fragment LogEventFields on LogEvent {
    id
    ingestionTime
    timestamp
    message {
      ...HistoryFields
    }
  }
  ${HistoryFields}
`;
