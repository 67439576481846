import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    tooltipComment: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.warning.main,
      maxWidth: 500,
      padding: theme.spacing(4),
      boxShadow: theme.shadows[4],
    },
    tooltipTitle: {
      color: theme.palette.warning.main,
      textAlign: 'center',
    },
  }),
);

type TooltipRGPDProps = React.PropsWithChildren<any>;

const TooltipRGPD: React.FC<TooltipRGPDProps> = ({
  children,
}: TooltipRGPDProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="left"
      classes={{ tooltip: classes.tooltipComment }}
      arrow
      interactive
      title={
        <>
          <Typography variant="h2" className={classes.tooltipTitle}>
            Attention !
          </Typography>
          <Typography>
            Cette zone est exclusivement réservée pour le suivi des dossiers et
            ne doit contenir :<br />
            Que des informations datées avec les initiales de l’utilisateur
            ayant ajouté le commentaire
          </Typography>
          <Typography>
            Que des informations neutres, pertinentes et objectives
          </Typography>
          <Typography>
            Que le strict nécessaire s’agissant d’infractions, condamnations ou
            mesure de sûreté en rapport avec la demande, Aucune information
            inadéquate, non pertinente ou excessive
          </Typography>
          <Typography>
            Aucune donnée personnelle en particulier s’agissant de données
            relatives à la santé ou à la vie sexuelle
          </Typography>
          <Typography>
            Aucune information pouvant porter atteinte à l’image
          </Typography>
          <Typography>
            Aucune information pouvant empêcher un demandeur de bénéficier d’une
            prestation à laquelle il peut prétendre
          </Typography>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipRGPD;
