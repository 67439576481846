export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  HT_VIEWER = 'HT_VIEWER',
  HT_EDITOR = 'HT_EDITOR',
  MINO_VIEWER = 'MINO_VIEWER',
  MINO_EDITOR = 'MINO_EDITOR',
}

export enum Rights {
  HT_READ = 'HT_READ', // Can view HT
  HT_WRITE = 'HT_WRITE', // Can save / delete HT
  RESIDENCES = 'RESIDENCES', // Can view residences
  MINO_READ = 'MINO_READ', // Can view mino
  MINO_WRITE = 'MINO_WRITE', // Can save mino
  MINO_DELETE = 'MINO_DELETE', // Can delete mino
  HISTORY = 'HISTORY', // Can view simplified history
  HISTORY_ALL = 'HISTORY_ALL', // Can view full history (names + hiddens) + export it
  EXPORT = 'EXPORT', // Can export data (from resources)
  // EDIT_DONE = 'EDIT_DONE', // Can edit resources which have the status "DONE"
  EDIT_RESTRICTED = 'EDIT_RESTRICTED', // Can access and edit restricted inputs in forms
}

const superAdminRights: Rights[] = [Rights.MINO_DELETE];

const adminRights: Rights[] = (Object.keys(Rights) as (keyof typeof Rights)[])
  .filter(
    k => typeof Rights[k] === 'string' && !superAdminRights.includes(Rights[k]),
  )
  .map(k => Rights[k]);

export const roleRights = {
  [Roles.SUPER_ADMIN]: adminRights.concat(superAdminRights),
  [Roles.ADMIN]: adminRights,
  [Roles.HT_VIEWER]: [Rights.HT_READ, Rights.HISTORY],
  [Roles.HT_EDITOR]: [
    Rights.HT_READ,
    Rights.HT_WRITE,
    Rights.HISTORY,
    Rights.EXPORT,
  ],
  [Roles.MINO_VIEWER]: [Rights.MINO_READ, Rights.HISTORY],
  [Roles.MINO_EDITOR]: [
    Rights.MINO_READ,
    Rights.MINO_WRITE,
    Rights.HISTORY,
    Rights.EXPORT,
  ],
};
