import React from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormDataConsumer,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useTranslate, ReduxState } from 'ra-core';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useFormState } from 'react-final-form';

import RequestFormBaseInputs from './RequestFormBaseInputs';
import RequestPropositions from './RequestPropositions';
import History from './History/History';

import ContainerForward from '../../structure/ContainerForward';
import FormSection from '../../structure/FormSection';

import { HtRequest } from '../../../types/ht/nodes';
import {
  isReadonly,
  isLocked,
  isLongLocked,
  getRollbackMessage,
  isWfNextValidated,
} from '../../../utils/ht/request';
import { ID } from '../../../types/ht/base';
import { Rights } from '../../../authProvider/permissions';
import { DeepPartial } from '../../../types/utils';

type RequestTitleProps = {
  record?: HtRequest;
  locked?: boolean;
};

const RequestTitle: React.FC<RequestTitleProps> = ({
  record: request,
  locked = false,
}: RequestTitleProps) => {
  const translate = useTranslate();
  return (
    <>
      <span>
        {translate('resources.htRequest.name', { smart_count: 1 })}{' '}
        {request ? `${request.givenName} ${request.familyName}` : ''}
      </span>
      {locked && (
        <>
          &nbsp;<span>{translate('transaction.locked')}</span>
        </>
      )}
    </>
  );
};

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    secondaryButton: {
      margin: theme.spacing(0, 1),
    },
  }),
);

const nonDuplicatedFields = [
  'id',
  'createdAt',
  'updatedAt',
  'version',
  'status',
  'transaction',
  // 'gdpr',
  // 'sncfCP',
  // 'honorificPrefix',
  // 'email',
  // 'givenName',
  // 'familyName',
  // 'tel',
  // 'bday',
  // 'nationality',
  // 'maritalStatus',
  // 'fiscalRevenue',
  // 'salary',
  // 'sncfCompany',
  // 'sncfPostalCode',
  // 'sncfContractBeginDate',
  // 'sncfStatut',
  // 'sncfEmployeeType',
  // 'htOccupants',
  // 'htMotif',
  // 'htGarant',
  'htLocations',
  'htStartDate',
  'htEndDate',
  // 'htAttribution',
  // 'htTypology',
  // 'comment',
  'motifRefus',
  'origin',
  'propositions',
  // 'attachments',
  // Workflow
  'wfStatus', // Default will be set by the create form
  'wfNotify',
  'wfNext',
  // Calculated
  'nbPropositions',
  'nbRefusedPropositions',
  'nbDays',
  // GQL
  '__typename',
];

type RequestEditToolbarProps = {
  record?: HtRequest;
  submitting?: boolean;
  readOnly?: boolean;
  basePath?: string;
};
const RequestEditToolbar: React.FC<RequestEditToolbarProps> = ({
  submitting = false,
  readOnly = false,
  ...props
}: RequestEditToolbarProps) => {
  const { record: request, basePath } = props;
  const classes = useToolbarStyles();
  const translate = useTranslate();

  const { values: formStateValues } = useFormState();
  const requestValues = formStateValues as DeepPartial<HtRequest>;

  return (
    <Toolbar {...props} className={classes.root}>
      <ContainerForward>
        <SaveButton
          label="ra.action.save"
          redirect="list"
          submitOnEnter={false}
          disabled={readOnly || submitting}
        />
        <>
          {!!basePath && (
            <Button
              component={RouterLink}
              to={basePath ?? ''}
              className={classes.secondaryButton}
            >
              {translate('ra.action.cancel')}
            </Button>
          )}
        </>
      </ContainerForward>
      {request?.wfStatus !== 'BAIL_SIGNE' &&
        requestValues.wfStatus === 'BAIL_SIGNE' && (
          <Typography color="error">
            Le statut BAIL_SIGNE a été sélectionné mais n&apos;a pas encore été
            validé. Veuillez enregistrer la demande
          </Typography>
        )}
      <ContainerForward>
        <>
          {!!basePath && !!request && (
            <Button
              component={RouterLink}
              to={{
                pathname: `${basePath}/create`,
                state: { record: _omit(request, nonDuplicatedFields) },
              }}
              className={classes.secondaryButton}
              variant="outlined"
              color="primary"
            >
              {translate('action.duplicate')}
            </Button>
          )}
        </>
        <DeleteButton
          label="ra.action.delete"
          redirect="list"
          disabled={readOnly || submitting}
          undoable={false}
          confirmTitle={translate('message.confirm_delete_title', {
            name: translate(`resources.htRequest.name`, 1).toLocaleLowerCase(),
          })}
        />
      </ContainerForward>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editMain: {
      position: 'relative',
    },
    propositionsWrapper: {
      marginTop: theme.spacing(4),
    },
  }),
);

type RequestEditProps = {
  resource?: string;
  id?: ID;
  permissions?: Rights[];
};

const RequestEdit: React.FC<RequestEditProps> = (props: RequestEditProps) => {
  const { resource, id, permissions } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const request = useSelector<ReduxState, HtRequest>(state =>
    _get(state, `admin.resources.${resource}.data.${id}`, {}),
  );

  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );
  const validated = isWfNextValidated(request);
  const readOnly = isReadonly(request);
  const locked = isLocked(request);
  const longLocked = isLongLocked(request);
  const rollbackMessage = getRollbackMessage(request);

  const permEdit = permissions?.includes(Rights.HT_WRITE);
  const showPropositions = !validated;

  return (
    <>
      {longLocked && (
        <Alert severity="warning">{translate('transaction.longLocked')}</Alert>
      )}
      {rollbackMessage && (
        <Alert severity="warning">
          {translate('transaction.rollbacked', { message: rollbackMessage })}
        </Alert>
      )}
      <Edit
        {...props}
        title={<RequestTitle locked={locked} />}
        undoable={false}
        aside={<History />}
        classes={{ main: classes.editMain }}
      >
        <SimpleForm
          // warnWhenUnsavedChanges
          saving={submitting}
          toolbar={
            permEdit ? (
              <RequestEditToolbar submitting={submitting} readOnly={readOnly} />
            ) : (
              <></> // No buttons
            )
          }
          submitOnEnter={false}
        >
          <RequestFormBaseInputs permissions={permissions} />
          <FormDataConsumer>
            {({ formData, ...rest }: { formData: any }): React.ReactNode =>
              (showPropositions || !!formData.propositions.length) && (
                <FormSection
                  {...rest}
                  title={translate('resources.htProposition.name', 2)}
                  className={classes.propositionsWrapper}
                >
                  <RequestPropositions
                    permissions={permissions}
                    canCreate={showPropositions}
                  />
                </FormSection>
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default RequestEdit;
