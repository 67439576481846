import React, { useCallback, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import {
  useAuthenticated,
  useTranslate,
  useDataProvider,
  useNotify,
} from 'ra-core';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import ManagementIcon from '@material-ui/icons/ImportExport';

import { key as importContractsKey } from './ImportContracts';
import { key as importUsersOrganisationKey } from './ImportUsersOrganisation';
import { key as importPaymentsKey } from './ImportPayments';

import {
  exportUsersCP,
  exportData,
  exportEvs,
  exportPayments,
} from '../../../utils/mino/management';

export const key = 'minoManagement';
export const Icon = ManagementIcon;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    managementList: {
      '&>li': {
        minHeight: 40,
        margin: theme.spacing(1, 0),
      },
    },
    text: {
      margin: 0,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.text.contrastText,
    },
  }),
);

const MinoManagement: React.FC = () => {
  useAuthenticated();
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const handleExportUsersS3 = useCallback(() => {
    setLoading(true);
    exportUsersCP(dataProvider, translate).then(() => {
      setLoading(false);
      notify(translate('export.done'), 'info');
    });
  }, [dataProvider, translate, notify]);
  const handleExportUsers = useCallback(() => {
    setLoading(true);
    exportUsersCP(dataProvider, translate, { local: true }).then(() => {
      setLoading(false);
    });
  }, [dataProvider, translate]);

  const handleExportData = useCallback(() => {
    setLoading(true);
    exportData(dataProvider, translate)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setError(e);
      });
  }, [dataProvider, translate]);

  const handleExportEvs = useCallback(() => {
    setLoading(true);
    exportEvs(dataProvider, translate)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setError(e);
      });
  }, [dataProvider, translate]);

  const handleExportPayments = useCallback(() => {
    setLoading(true);
    exportPayments(dataProvider, translate).then(() => {
      setLoading(false);
    });
  }, [dataProvider, translate]);

  useEffect(() => {
    if (error) {
      // Error boundaries does not work on asynchonous tasks
      throw error;
    }
  }, [error]);

  return (
    <>
      <Title title={translate(`${key}.title`)} />
      <Backdrop className={classes.backdrop} open={loading}>
        {loading && <CircularProgress color="inherit" />}
      </Backdrop>
      <Card>
        <CardContent>
          {/* TODO: i18n export/import */}
          <ul className={classes.managementList}>
            <li>
              <Typography component="span" className={classes.text}>
                2a. Export des Agents
              </Typography>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleExportUsersS3}
              >
                Cliquer ici pour exporter les agents
              </Button>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleExportUsers}
              >
                (sur votre ordinateur)
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                2b. Import de Contrats
              </Typography>
              <Button
                component={RouterLink}
                to={`/${importContractsKey}`}
                variant="outlined"
                className={classes.button}
              >
                Cliquer ici pour importer des contrats
              </Button>
              <Button
                component={RouterLink}
                to={`/${importContractsKey}/local`}
                variant="outlined"
                className={classes.button}
              >
                (depuis votre ordinateur)
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                2c. Import d&apos;Organisations
              </Typography>
              <Button
                component={RouterLink}
                to={`/${importUsersOrganisationKey}`}
                variant="outlined"
                className={classes.button}
              >
                Cliquer ici pour importer des organisations
              </Button>
              <Button
                component={RouterLink}
                to={`/${importUsersOrganisationKey}/local`}
                variant="outlined"
                className={classes.button}
              >
                (depuis votre ordinateur)
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                4. Export des Données
              </Typography>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleExportData}
              >
                Cliquer ici pour exporter les données
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                5. Export EVS
              </Typography>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleExportEvs}
              >
                Cliquer ici pour exporter EVS
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                6b. Import de Transactions
              </Typography>
              <Button
                component={RouterLink}
                to={`/${importPaymentsKey}`}
                variant="outlined"
                className={classes.button}
              >
                Cliquer ici pour importer des transactions
              </Button>
              <Button
                component={RouterLink}
                to={`/${importPaymentsKey}/local`}
                variant="outlined"
                className={classes.button}
              >
                (depuis votre ordinateur)
              </Button>
            </li>
            <li>
              <Typography component="span" className={classes.text}>
                6c. Exporter toutes les Transactions
              </Typography>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleExportPayments}
              >
                Cliquer ici pour exporter les transactions
              </Button>
            </li>
          </ul>
        </CardContent>
      </Card>
    </>
  );
};

export default MinoManagement;
