import moment, { Moment } from 'moment';

export type DateTuple = [Moment, Moment | undefined];

/**
 * @param dates array of dates tuple
 * @return *SORTED* array of dates tuple
 */
function sortDates(dates: DateTuple[]): DateTuple[] {
  return dates.sort(([aStart], [bStart]) => {
    return aStart.isSameOrBefore(bStart) ? -1 : 1;
  });
}

/**
 * @param dates *SORTED* array of date tuple
 */
function mergeDates(dates: DateTuple[]): DateTuple[] {
  return dates.reduce<DateTuple[]>((acc, [start, end]) => {
    if (acc.length > 0) {
      let [, groupEnd] = acc[acc.length - 1];
      // Once groupEnd is undefined, merge is over
      if (!groupEnd) {
        return acc;
      }

      // Groups will merge because start is before end
      if (start.isSameOrBefore(groupEnd)) {
        if (!end || end.isSameOrAfter(groupEnd)) {
          groupEnd = end;
        }
        return acc;
      }
    }
    acc.push([start, end]);
    return acc;
  }, []);
}

/** Nb days (total) that might not have been in rent during the month */
export const FULL_MONTH_MARGIN_DEFAULT = 0;

/**
 * @param dates *SORTED* array of date tuple
 * @param prevDiff Accumuled diff
 */
function areDatesFullMonthRecursive(
  dates: DateTuple[],
  {
    monthStart,
    monthEnd,
    monthMargin,
  }: { monthStart: Moment; monthEnd: Moment; monthMargin: number },
  prevValues: { prevDiff?: number; prevEnd?: Moment } = {},
): boolean {
  const { prevDiff = 0, prevEnd = monthStart } = prevValues;
  let newDiff = prevDiff;
  let newEnd = prevEnd;
  const [[rawStart, rawEnd], ...nextDates] = dates;
  let start = rawStart;
  const end = rawEnd && rawEnd.add(1, 'day').startOf('day'); // End is the begining of the day after last day

  if (start.isAfter(monthEnd)) {
    // If the start date if after the monthEnd, replace it by the monthEnd because we don't care about what happens after
    // => Makes diff from "previous end" to "end of month" (instead of to "following start")
    start = monthEnd;
  }

  // No `end` means we care only about the `start`
  // `end` before `prevEnd` means we don't care about these dates
  if (!end || end.isSameOrAfter(prevEnd)) {
    // Add the difference between previous end and new start (only if start if after previous end)
    if (start.isAfter(prevEnd)) {
      newDiff += start.diff(prevEnd, 'days');
    }
    // If end is after the end of the month, we have our answer
    if (!end || end.isSameOrAfter(monthEnd)) {
      return newDiff <= monthMargin;
    }
    // If this is the last date tuple we have our answer
    if (nextDates.length === 0) {
      newDiff += monthEnd.diff(end, 'days');
      return newDiff <= monthMargin;
    }
    newEnd = end;
  } else if (nextDates.length === 0) {
    // If this is the last date tuple we have our answer
    newDiff += monthEnd.diff(prevEnd, 'days');
    return newDiff <= monthMargin;
  }

  // Let's recognize a lost cause (stop recursion early)
  if (newDiff > monthMargin) {
    return false;
  }
  return areDatesFullMonthRecursive(
    nextDates,
    { monthStart, monthEnd, monthMargin },
    {
      prevDiff: newDiff,
      prevEnd: newEnd,
    },
  );
}

/**
 * @param dates Array of date tuple
 * @param month A Moment object pointing to any date within the searched month
 * @param monthMargin Nb days (total) that might not have been in rent during the month
 */
export function areDatesFullMonth(
  dates: DateTuple[],
  month: Moment,
  monthMargin = FULL_MONTH_MARGIN_DEFAULT,
): ReturnType<typeof areDatesFullMonthRecursive> {
  if (dates.length < 1) return false;
  const monthStart = moment(month).startOf('month').startOf('day');
  const monthEnd = moment(month).endOf('month').add(1, 'day').startOf('day'); // End is the begining of the day after last day
  const sortedDates = sortDates(dates);
  const mergedDates = mergeDates(sortedDates);
  return areDatesFullMonthRecursive(mergedDates, {
    monthStart,
    monthEnd,
    monthMargin,
  });
}

export function areDatesOverlappingInMonth(
  dates: DateTuple[],
  month: Moment,
): boolean {
  if (dates.length < 1) return false;
  const monthStart = moment(month).startOf('month').startOf('day');
  const monthEnd = moment(month).endOf('month').startOf('day');

  const sortedDates = sortDates(dates);

  let overlappingStart: Moment | undefined;
  let overlappingEnd: Moment | undefined;
  let isOverlappingInMonth = false;

  for (let index = 0; index < sortedDates.length; index += 1) {
    const [start, end] = sortedDates[index];

    // If not active during month, ignore it
    if (start.isAfter(monthEnd) || (end && end.isBefore(monthStart))) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!overlappingStart) {
      // Initialize overlapping dates
      overlappingStart = start;
      overlappingEnd = end;
    } else if (!overlappingEnd || start.isBefore(overlappingEnd)) {
      isOverlappingInMonth = true;
      break;
    } else {
      // Replace by further overlapping dates before looping
      overlappingStart = start;
      overlappingEnd = end;
    }
  }

  return isOverlappingInMonth;
}

export function isDateIncluded(date: Moment, dates: DateTuple[]): boolean {
  if (dates.length < 1) return false;
  return dates.some(
    ([start, end]) =>
      start.startOf('day').isSameOrBefore(date) &&
      (!end || end.endOf('day').isSameOrAfter(date)),
  );
}
