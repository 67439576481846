import { GenericResourceKey } from '../resourcesKeys';

export const resourcesKeys = [
  'minoUser',
  'minoRequest',
  'minoContract',
  'minoPayment',
  'minoRent',
  'minoAsset',
] as const;
export type ResourceKey = GenericResourceKey<(typeof resourcesKeys)[number]>;

export function resourceIsValid(resource: string): resource is ResourceKey {
  return (
    !!resource &&
    typeof resource === 'string' &&
    resourcesKeys.includes(resource as ResourceKey)
  );
}
