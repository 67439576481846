import _isObjectLike from 'lodash/isObjectLike';
import _cloneDeep from 'lodash/cloneDeep';

import {
  SimplifiedConnectionMany,
  SimplifiedConnectionOne,
  Connection,
} from '../types/connections';
import { NodeBase } from '../types/nodes';

export function clearDeepTypenames<T extends object>(
  rawNode: T,
  root = true,
): T {
  const node = root ? _cloneDeep(rawNode) : rawNode;
  if (!_isObjectLike(node)) return node;
  Object.keys(node).forEach(key => {
    if (!root && key === '__typename') {
      delete (node as any)[key];
    } else {
      clearDeepTypenames((node as any)[key], false);
    }
  });
  return node;
}

export function flattenConnectionMany<Node extends NodeBase>(
  connectionEntry?: Connection<Node>,
): SimplifiedConnectionMany {
  return connectionEntry?.edges.map(({ node: { id: nodeId } }) => nodeId) ?? [];
}

export function flattenConnectionOne<Node extends NodeBase>(
  connectionEntry?: Connection<Node>,
): SimplifiedConnectionOne {
  return (
    (connectionEntry?.edges[0] ? connectionEntry?.edges[0].node.id : '') ?? ''
  );
}
