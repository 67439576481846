import {
  DataProvider,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
} from 'ra-core';
import _partition from 'lodash/partition';

import { uploadS3 } from '../aws/s3-utils';
import { AssetResourceKeyConnections } from './resourcesInfos';
import {
  FormattedFile,
  FileInputFormatted,
  AssetsData,
  assetsDataIsAssetIds,
  assetDataIsFile,
} from '../components/CustomInputs/AssetInput';
import { ID, NodeBase, Asset } from '../types/nodes';

/** Upload file to S3 and get it's ID */
function uploadFile(
  file: File,
  dataProvider: DataProvider,
  assetResource: string,
): Promise<CreateResult & { data: Asset }> {
  return uploadS3(file)
    .then(({ key, upload }) =>
      upload.then(() => ({
        filename: file.name,
        size: file.size,
        type: file.type,
        key,
      })),
    )
    .then(assetInput => {
      return dataProvider.create(assetResource, {
        data: assetInput,
      }) as Promise<CreateResult & { data: Asset }>;
    });
}

/**
 * For resources that can have `asset`s,
 * on `create` and `update`,
 * 1) upload new files to S3 and get their affected ID
 * 2) replace param[assetKey] with the new IDs list
 */
const withFileUpload = <T extends DataProvider>(
  dataProvider: T,
  assetResource: string,
  assetResourceKeyConnections: AssetResourceKeyConnections,
): T => ({
  ...dataProvider,
  create: (resource: string, params: CreateParams): Promise<CreateResult> => {
    const { data } = params as {
      id: ID;
      data: NodeBase & { [k: string]: AssetsData };
    };
    const assetKey = assetResourceKeyConnections.get(resource);
    if (!assetKey) {
      return dataProvider.create(resource, params);
    }
    let assetsData = data[assetKey] as AssetsData;
    if (!assetsData) {
      return dataProvider.create(resource, params);
    }

    if (!Array.isArray(assetsData)) {
      assetsData = [assetsData];
    }

    let newFiles: File[] = [];
    // Should never be IDs
    if (!assetsDataIsAssetIds(assetsData)) {
      const newFormattedFiles = assetsData.filter(assetDataIsFile);
      newFiles = newFormattedFiles.map(({ rawFile }) => rawFile);
    }

    return Promise.all(
      newFiles.map(file => uploadFile(file, dataProvider, assetResource)),
    ).then(createdAssetsResults => {
      const createdAssetsIDs = createdAssetsResults.map(
        ({ data: { id } }) => id,
      );

      return dataProvider.create(resource, {
        ...params,
        data: {
          ...data,
          [assetKey]: createdAssetsIDs,
        },
      });
    });
  },
  update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    const { data } = params as {
      id: ID;
      data: NodeBase & { [k: string]: AssetsData };
    };
    const assetKey = assetResourceKeyConnections.get(resource);
    if (!assetKey) {
      return dataProvider.update(resource, params);
    }
    let assetsData = data[assetKey] as AssetsData;
    if (!assetsData) {
      return dataProvider.update(resource, params);
    }

    if (!Array.isArray(assetsData)) {
      assetsData = [assetsData];
    }

    let newFiles: File[] = [];
    let formerAssetsIds: ID[] = [];
    if (assetsDataIsAssetIds(assetsData)) {
      formerAssetsIds = assetsData;
    } else {
      const [newFormattedFiles, formerFormattedAssets] = _partition<
        FileInputFormatted,
        FormattedFile
      >(assetsData, assetDataIsFile);
      newFiles = newFormattedFiles.map(({ rawFile }) => rawFile);
      formerAssetsIds = formerFormattedAssets.map(({ assetId }) => assetId);
    }

    return Promise.all(
      newFiles.map(file => uploadFile(file, dataProvider, assetResource)),
    ).then(createdAssetsResults => {
      const createdAssetsIDs = createdAssetsResults.map(
        ({ data: { id } }) => id,
      );

      return dataProvider.update(resource, {
        ...params,
        data: {
          ...data,
          [assetKey]: [...formerAssetsIds, ...createdAssetsIDs],
        },
      });
    });
  },
});

export default withFileUpload;
