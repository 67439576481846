import React from 'react';
import { Typography } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ClassName } from '../../types/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      padding: theme.spacing(3, 1, 1),

      borderRadius: theme.shape.borderRadius,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.specials.sncfLightGrey,

      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    title: {
      display: 'inline-block',
      position: 'absolute',
      top: theme.spacing(-2),
      left: theme.spacing(1),
      padding: theme.spacing(0, 4),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.specials.sncfDarkGrey,
    },
  }),
);

export type FormSectionProps = {
  children: React.ReactNode;
  title?: string;
  className?: ClassName;
};
const FormSection: React.FC<FormSectionProps> = ({
  children,
  title = undefined,
  className = '',
  ...props
}: FormSectionProps) => {
  const classes = useStyles();
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
  return (
    <fieldset className={clsx(classes.root, className)}>
      {title && (
        <Typography variant="h3" component="legend" className={classes.title}>
          {title}
        </Typography>
      )}
      {childrenWithProps}
    </fieldset>
  );
};

export default FormSection;
