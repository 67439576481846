import React, { useCallback, useState } from 'react';
import {
  ReferenceArrayField,
  Datagrid,
  DateField,
  TextField,
  RowClickFunction,
  Record,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';

import PropositionCreateButton from '../Proposition/standalone/PropositionCreateButton';
import PropositionDeleteButton from '../Proposition/standalone/PropositionDeleteButton';
import PropositionEditDialog from '../Proposition/standalone/PropositionEditDialog';

import { isPropositionsReadonly } from '../../../utils/ht/request';

import {
  HtRequest,
  nodeIsRequest,
  HtProposition,
} from '../../../types/ht/nodes';
import { ClassName } from '../../../types/styles';
import { ID } from '../../../types/ht/base';
import { Rights } from '../../../authProvider/permissions';

type RequestPropositionsProps = {
  record?: HtRequest;
  permissions?: Rights[];
  canCreate?: boolean;
  className?: ClassName;
};

const RequestPropositions: React.FC<RequestPropositionsProps> = ({
  canCreate = true,
  className,
  ...raProps
}: RequestPropositionsProps) => {
  const { record: request, permissions } = raProps;
  const form = useForm();
  const field = useField('propositions');

  const readOnly =
    isPropositionsReadonly(request) || !permissions?.includes(Rights.HT_WRITE);

  const handleCreatedProposition = useCallback(
    createdId => {
      form.change('propositions', [...field.input.value, createdId]);
    },
    [form, field],
  );

  const handleDeleteProposition = useCallback(
    deletedId => {
      form.change(
        'propositions',
        field.input.value.filter((id: ID) => id !== deletedId),
      );
    },
    [form, field],
  );

  const [editProposition, setEditProposition] = useState<HtProposition | null>(
    null,
  );
  const handleCloseEditDialog = useCallback(() => {
    setEditProposition(null);
  }, [setEditProposition]);

  const handleRowClick = useCallback<RowClickFunction>(
    (id, basePath, proposition) => {
      setEditProposition(proposition as HtProposition);
      return '';
    },
    [setEditProposition],
  );

  if (!request || !nodeIsRequest(request)) {
    return null;
  }

  if (!field) {
    form.registerField(
      'propositions',
      () => {},
      {},
      { data: request?.propositions || [] },
    );
  }

  return (
    <>
      {!readOnly && (
        <>
          {canCreate && (
            <PropositionCreateButton
              {...raProps}
              onCreate={handleCreatedProposition}
            />
          )}
          <PropositionEditDialog
            {...raProps}
            proposition={editProposition}
            onClose={handleCloseEditDialog}
          />
        </>
      )}
      <ReferenceArrayField
        {...raProps}
        record={{ propositions: field.input.value } as unknown as Record}
        reference="htProposition"
        source="propositions"
        addLabel={false}
        fullWidth
      >
        <Datagrid rowClick={readOnly ? undefined : handleRowClick}>
          <DateField sortable={false} source="provDate" />
          <TextField sortable={false} source="provider" />
          <TextField sortable={false} source="addressLevel2" />
          <DateField sortable={false} source="dateDecision" />
          <TextField sortable={false} source="decision" />
          <TextField sortable={false} source="motifRefus" />
          <DateField sortable={false} source="dateBail" />
          {!readOnly && (
            <PropositionDeleteButton onDelete={handleDeleteProposition} />
          )}
        </Datagrid>
      </ReferenceArrayField>
    </>
  );
};

export default RequestPropositions;
