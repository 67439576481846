import { mapEnum } from '../enumsManip';

import {
  GQLMinoHonorificPrefix,
  GQLMinoSncfEmployeeType,
  GQLMinoMinoStatus,
} from '../../types/mino/schema';

export const honorificPrefixChoices = mapEnum(
  GQLMinoHonorificPrefix,
  'honorificPrefix',
);
export const sncfEmployeeTypeChoices = mapEnum(
  GQLMinoSncfEmployeeType,
  'sncfEmployeeType',
  ['INT', 'EXT'],
);
export const wfStatusChoices = mapEnum(GQLMinoMinoStatus, 'wfStatus');

export enum MinoMotifRefus {
  LOCATION_NON_ELIGIBLE = 'LOCATION_NON_ELIGIBLE',
  A_DEJA_EU = 'A_DEJA_EU',
  PREAVIS_BAILLEUR = 'PREAVIS_BAILLEUR',
  PREAVIS_SNCF = 'PREAVIS_SNCF',
  BAIL_JUSTIFICATIF_NON_TRANSMIS = 'BAIL_JUSTIFICATIF_NON_TRANSMIS',
  DOUBLON = 'DOUBLON',
}
export const motifRefusChoices = mapEnum(MinoMotifRefus, 'minoMotifRefus');

enum MinoResidenceCode {
  ETUDIANTE = 'ETUDIANTE',
  PARME = 'PARME',
  AUTRE = 'AUTRE',
}
export const residenceCodeChoices = mapEnum(MinoResidenceCode, 'residenceCode');
