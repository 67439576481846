import { Record } from 'react-admin';

export type ID = string;

export type NodeBase = Record & {
  __typename: string;
  id: ID;
  createdAt: string;
  updatedAt: string;
  version: number;
};

// TODO: Find a better type
export type NodeInput = Partial<NodeBase> & { [k: string]: any };

export enum TransactionState {
  BEGIN = 'BEGIN',
  COMMIT = 'COMMIT',
  ROLLBACK = 'ROLLBACK',
}

export type Transaction = {
  begin?: string;
  commit?: string;
  rollback?: string;
  state?: keyof typeof TransactionState;
  message?: string; // only on rollback
};

export type TransactionalNode = {
  transaction?: Transaction;
};

/* **********
 * Assets
 */
export interface Asset extends NodeBase {
  /** File name */
  filename?: string;
  /** File size */
  size?: number;
  /** Mime type */
  type?: string;
  /** S3 Key (suffix only) */
  key?: string;
}
