import { DataProviderProxy, Exporter, Record } from 'ra-core';
import { downloadCSV } from 'react-admin';
import * as jsonExport from 'jsonexport/dist';
import _get from 'lodash/get';
import _range from 'lodash/range';

import config from '../../config';

import { HtResidence } from '../../types/ht/nodes';
import { CustomHTProvider } from '../../dataProvider/dataProvider';

const residencesExporter: Exporter = (
  records: Record[],
  fetchRelatedRecords: (
    data: any,
    field: string,
    resource: string,
  ) => Promise<any>,
  dataProvider: DataProviderProxy,
  resource?: string,
): void | Promise<void> => {
  (dataProvider as CustomHTProvider)
    .getListAll('htResidence')
    .then(({ data: residences }: { data: HtResidence[] }) => {
      const residencesForExport: any = residences.map(residence => {
        const {
          __typename,
          id,
          status,
          createdAt,
          version,
          coordinates,
          filiere,
          ...residenceRest
        } = residence;
        const residenceForExport: { [k: string]: any } = residenceRest;
        _range(5).forEach(index => {
          residenceForExport[`filiere${index + 1}`] = _get(filiere, index, '');
        });
        return residenceForExport;
      });

      jsonExport(
        residencesForExport,
        {
          ...config.exportedCSVconfig,
          // order fields in the export
          headers: [
            'updatedAt',
            'provider',
            'code',
            'name',
            'streetAddress',
            'postalCode',
            'addressLevel2',
            'distance',
            'filiere1',
            'filiere2',
            'filiere3',
            'filiere4',
            'filiere5',
            'priority',
          ],
        },
        (err: any, csv: any) => {
          if (err) console.error(err);
          downloadCSV(`\ufeff${csv}`, 'residences');
        },
      );
    });
};

export default residencesExporter;
