import React, { useState, useCallback, useRef } from 'react';
import { useDataProvider } from 'ra-core';
import clsx from 'clsx';
import Color from 'color';
import InfiniteScroll from 'react-infinite-scroller';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Backdrop,
  Button,
  CircularProgress,
} from '@material-ui/core';

import HistoryEntry from './HistoryEntry';

import { SMinoUser } from '../../../../types/mino/nodes';
import { GQLMinoLogConnection } from '../../../../types/mino/schema';
import { CustomMinoProvider } from '../../../../dataProvider/dataProvider';

const NB_PER_PAGE = 1000;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      position: 'sticky',
      top: theme.spacing(2),
      maxHeight: `calc(100vh - ${theme.spacing(2) * 2}px)`,
      marginLeft: theme.spacing(2),
      zIndex: theme.zIndex.appBar - 1,
    },
    card: {
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['background-color', 'box-shadow']),
    },
    titleCard: {
      position: 'relative',
      display: 'block',
      height: '100%',
      zIndex: theme.zIndex.appBar - 1,
      borderLeft: `0px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['border-left']),
    },
    titleContent: {
      position: 'sticky',
      top: 0,
      padding: theme.spacing(8, 0),
    },
    title: {
      // textOrientation: 'upright',
      writingMode: 'vertical-rl',
    },
    titleClose: {
      position: 'absolute',
      top: theme.spacing(2),
      left: 0,
      width: '100%',
      display: 'flex',
      margin: 0,
      justifyContent: 'center',
    },
    drawer: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      right: 0,
      padding: 0,
      zIndex: theme.zIndex.appBar - 2,
      boxShadow: theme.shadows[0],
      backgroundColor: 'inherit',
      borderRadius: theme.shape.borderRadius,
      opacity: 0,

      transition: theme.transitions.create([
        'opacity',
        'paddingRight',
        'width',
        'box-shadow',
      ]),
    },
    drawerContent: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    drawerContentWrapper: {
      minHeight: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    open: {
      zIndex: theme.zIndex.drawer,

      '& $card': {
        backgroundColor: theme.palette.specials.sncfLightGrey,
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& $titleCard': {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
      '& $drawer': {
        width: 500,
        opacity: 1,
        paddingRight: 50,
        boxShadow: theme.shadows[4],
      },
    },
    loadMore: {
      marginTop: theme.spacing(2),
      alignSelf: 'center',
    },
    loadMoreProgress: {
      padding: theme.spacing(1, 5),
    },
    loadingWrapper: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    backdrop: {
      backgroundColor: Color(theme.palette.background.paper)
        .alpha(0.1)
        .toString(),
      position: 'absolute',
      zIndex: theme.zIndex.appBar - 3,
    },
  }),
);

type HistoryProps = {
  record?: SMinoUser;
  resource?: string;
};

const History: React.FC<HistoryProps> = ({
  record: request,
  resource,
}: HistoryProps) => {
  const classes = useStyles();

  const dataProvider = useDataProvider<CustomMinoProvider>();

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<undefined | GQLMinoLogConnection>();
  const [hasMore, setHasMore] = useState<undefined | boolean>();
  const loadHistory = useCallback(() => {
    if (resource && request) {
      setLoading(true);
      dataProvider
        .getHistory(resource, { id: request.id, first: NB_PER_PAGE })
        .then(({ data }: { data: GQLMinoLogConnection }) => {
          setLoading(false);
          setHasMore(true);
          setHistory({ ...data, edges: data.edges.reverse() });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [resource, request, dataProvider]);
  const loadMore = useCallback(() => {
    if (resource && request && history) {
      setLoading(true);
      dataProvider
        .getHistory(resource, {
          id: request.id,
          first: NB_PER_PAGE,
          after: history.pageInfo.endCursor,
        })
        .then(({ data }: { data: GQLMinoLogConnection }) => {
          setLoading(false);
          if (history.pageInfo.endCursor === data.pageInfo.endCursor) {
            setHasMore(false);
          } else {
            setHistory({
              ...data,
              edges: [...history.edges, ...data.edges.reverse()],
            });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [resource, request, dataProvider, history]);
  const scrollParentRef = useRef(null);
  const ISGetScrollParent = useCallback(() => {
    return scrollParentRef.current;
  }, [scrollParentRef]);

  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (request) {
      if (!open && !history) {
        loadHistory();
      }
      setOpen(!open);
    }
  }, [request, open, history, loadHistory]);

  let historyChildren: React.ReactNodeArray = [];

  if (history) {
    historyChildren = history.edges
      .sort(({ node: nodeA }, { node: nodeB }) => {
        if (nodeA.timestamp < nodeB.timestamp) return 1;
        return -1;
      })
      .map(({ node }) => <HistoryEntry key={node.id} logEvent={node} />);
  }

  return (
    <>
      <div className={clsx(classes.root, { [classes.open]: open })}>
        <Card className={classes.card}>
          <CardHeader
            onClick={handleClick}
            title="Historique"
            titleTypographyProps={{ variant: 'h5', className: classes.title }}
            action={open ? <span className="sncf-icons-close" /> : null}
            classes={{
              root: classes.titleCard,
              content: classes.titleContent,
              action: classes.titleClose,
            }}
          />
          <CardContent className={classes.drawer}>
            <div className={classes.drawerContent} ref={scrollParentRef}>
              {historyChildren.length === 0 && loading ? (
                <div className={classes.loadingWrapper} key="history-loading">
                  <LinearProgress />
                </div>
              ) : (
                <div className={classes.drawerContentWrapper}>
                  {open && hasMore && (
                    <Button
                      variant={loading ? 'text' : 'outlined'}
                      classes={{
                        root: classes.loadMore,
                        label: clsx({
                          [classes.loadMoreProgress]: loading,
                        }),
                      }}
                      onClick={loadMore}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress disableShrink size={14} />
                      ) : (
                        'Charger plus'
                      )}
                    </Button>
                  )}
                  {open && historyChildren && (
                    <InfiniteScroll
                      loadMore={loadMore}
                      hasMore={hasMore && !loading}
                      useWindow={false}
                      getScrollParent={ISGetScrollParent}
                      isReverse
                    >
                      {historyChildren}
                    </InfiniteScroll>
                  )}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleClick}
      />
    </>
  );
};

export default History;
