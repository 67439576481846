import moment from 'moment';

const config = {
  awsDateFormat: 'YYYY-MM-DD',
  textFieldMaxLength: 10000, // 10kb
  ht: {
    propositions: {
      max: 5,
    },
    locations: {
      max: 5,
    },
  },
  mino: {
    requests: {
      max: 5,
    },
  },
  exportedCSVconfig: {
    rowDelimiter: ';',
    booleanTrueString: 'Oui',
    booleanFalseString: 'Non',
    typeHandlers: {
      /* (value: any, index: any, parent: any) */
      // Array: (value: any, index: any, parent: any): any => {
      //   console.log('Array', { value, index, parent });
      //   return value;
      // },
      // Boolean: (value: any, index: any, parent: any): any => {
      //   console.log('Boolean', { value, index, parent });
      //   return value;
      // },
      // Function: (value: any, index: any, parent: any): any => {
      //   console.log('Function', { value, index, parent });
      //   return value;
      // },
      Number: (value: any): any => {
        if (value === Infinity) return '';
        // Prevent zeros to disapear on CSV
        return `${value}`;
      },
      // String: (value: any, index: any, parent: any): any => {
      //   console.log('String', { value, index, parent });
      //   const date = moment(value);
      //   if (date.isValid()) return date.toString();
      //   return value;
      // },
      // Buffer: (value: any, index: any, parent: any): any => {
      //   console.log('Buffer', { value, index, parent });
      //   return value;
      // },
      // Object: (value: any, index: any, parent: any): any => {
      //   console.log('Object', { value, index, parent });
      //   return value;
      // },
    },
  },
  minoManagementMonthIso: moment()
    .subtract(1, 'month')
    .startOf('month')
    .toISOString(), // Do not let as Moment object as it bugs weirdly
  // dates: {
  //   defaultFormat: 'YYYY-MM-DD',
  // },
};

export default config;
