import { DataProvider, DataProviderProxy, Exporter, Translate } from 'ra-core';
import { downloadCSV } from 'react-admin';
import * as jsonExport from 'jsonexport/dist';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
import _groupBy from 'lodash/groupBy';
import { CloudWatchLogs } from 'aws-sdk';

import moment from 'moment';

import eligible from 'padh-ht-eligibility';

import config from '../../config';

import { HtRequest, HtProposition, HtResidence } from '../../types/ht/nodes';
import { enumsKeys } from '../../i18n/frenchMessages';
import { translateIf } from '../../hooks/use-translate-if';

const headers = [
  'htRequest.fields.id',
  'htRequest.fields.createdAt',
  'htRequest.fields.updatedAt',
  'htRequest.fields.origin',
  'htRequest.fields.gdpr',
  'htRequest.fields.sncfCP',
  'htRequest.fields.honorificPrefix',
  'htRequest.fields.email',
  'htRequest.fields.givenName',
  'htRequest.fields.familyName',
  'htRequest.fields.tel',
  'htRequest.fields.bday',
  'htRequest.fields.nationality',
  'htRequest.fields.maritalStatus',
  'htRequest.fields.fiscalRevenue',
  'htRequest.fields.salary',
  'htRequest.fields.sncfCompany',
  'htRequest.fields.sncfPostalCode',
  'htRequest.fields.sncfContractBeginDate',
  'htRequest.fields.sncfStatut',
  'htRequest.fields.sncfEmployeeType',
  'htRequest.fields.htOccupants',
  'htRequest.fields.htMotif',
  'htRequest.fields.htTypology',
  'htRequest.fields.htGarant',
  'htResidence.fields.address',
  'htResidence.fields.address',
  'htResidence.fields.address',
  'htResidence.fields.address',
  'htResidence.fields.address',
  'htRequest.fields.htStartDate',
  'htRequest.fields.htEndDate',
  'htRequest.fields.htAttribution',
  'htRequest.fields.htParmeEligible',
  'htRequest.fields.comment',
  'htRequest.fields.wfStatus',
  'htRequest.fields.motifRefus',
  'htRequest.fields.wfNotify',
  'htRequest.fields.nbPropositions',
  'htRequest.fields.nbRefusedPropositions',
  'htRequest.fields.nbDays',
  'htProposition.fields.provId',
  'htProposition.fields.provDate',
  'htProposition.fields.addressLevel2',
  'htProposition.fields.decision',
  'htProposition.fields.motifRefus',
  'htProposition.fields.dateBail',
  'htProposition.fields.provider',
  'htProposition.fields.residenceCode',
  'htProposition.fields.residenceName',
  'htProposition.fields.provId',
  'htProposition.fields.provDate',
  'htProposition.fields.addressLevel2',
  'htProposition.fields.decision',
  'htProposition.fields.motifRefus',
  'htProposition.fields.dateBail',
  'htProposition.fields.provider',
  'htProposition.fields.residenceCode',
  'htProposition.fields.residenceName',
  'htProposition.fields.provId',
  'htProposition.fields.provDate',
  'htProposition.fields.addressLevel2',
  'htProposition.fields.decision',
  'htProposition.fields.motifRefus',
  'htProposition.fields.dateBail',
  'htProposition.fields.provider',
  'htProposition.fields.residenceCode',
  'htProposition.fields.residenceName',
  'htProposition.fields.provId',
  'htProposition.fields.provDate',
  'htProposition.fields.addressLevel2',
  'htProposition.fields.decision',
  'htProposition.fields.motifRefus',
  'htProposition.fields.dateBail',
  'htProposition.fields.provider',
  'htProposition.fields.residenceCode',
  'htProposition.fields.residenceName',
  'htProposition.fields.provId',
  'htProposition.fields.provDate',
  'htProposition.fields.addressLevel2',
  'htProposition.fields.decision',
  'htProposition.fields.motifRefus',
  'htProposition.fields.dateBail',
  'htProposition.fields.provider',
  'htProposition.fields.residenceCode',
  'htProposition.fields.residenceName',
];
const statusEnums = [
  'EN_INSTRUCTION',
  'STANDBY',
  'VALIDEE_PARME',
  'REFUSEE_PARME',
  'VALIDEE',
  'REFUSEE',
  'TRANSMIS_PARTENAIRE',
  'TRANSMIS_ALS',
  'PROPOSITION_ACCEPTEE',
  'BAIL_SIGNE',
  'RENONCEE',
  'ANNULEE',
];
const dateFields = ['createdAt', 'updatedAt', 'gdpr'];

const requestKeyHeaders = _uniq(
  headers.reduce<string[]>((acc, header) => {
    if (header.startsWith('htRequest')) {
      const parts = header.split('.');
      acc.push(parts[parts.length - 1]);
    }
    return acc;
  }, []),
);
const propositionKeyHeaders = _uniq(
  headers.reduce<string[]>((acc, header) => {
    if (header.startsWith('htProposition')) {
      const parts = header.split('.');
      acc.push(parts[parts.length - 1]);
    }
    return acc;
  }, []),
);

function originMapping(origin: any): string | null {
  if (typeof origin === 'string') {
    if (origin.includes('e-logement.sncf.fr')) return 'E-Logement';
    if (origin.includes('padh.sncf.fr')) return 'PADH';
    if (origin.includes('d370e0fd692v2j.cloudfront.net'))
      return 'PADH (Recette)';
    if (origin.includes('e-logement-rec.phileog.com')) return 'ELS (Recette)';
    return origin;
  }
  return null;
}

async function exportRequests(
  translate: Translate,
  dataProvider: DataProvider,
  {
    full = false,
    tickCallback,
  }: { full?: boolean; tickCallback?: (p: number) => void } = {},
): Promise<void> {
  const headersStack: { [k: string]: number } = {};
  let translatedHeaders = headers.reduce<string[]>((acc, header) => {
    const nbHeaders = headers.filter(h => h === header).length;
    const tHeader = translate(`resources.${header}`);

    if (nbHeaders > 1) {
      headersStack[header] = headersStack[header]
        ? headersStack[header] + 1
        : 1;
      acc.push(`${tHeader} ${headersStack[header]}`);
    } else {
      acc.push(tHeader);
    }
    return acc;
  }, []);

  if (full) {
    translatedHeaders = statusEnums.reduce<string[]>((acc, status) => {
      const tStatus = translate(`enums.wfStatus.${status}`);
      acc.push(tStatus);
      return acc;
    }, translatedHeaders);
  }

  const [{ data: requests }, { data: residences }, getHistoryResult]: [
    { data: HtRequest[] },
    { data: HtResidence[] },
    { data: CloudWatchLogs.FilteredLogEvents },
  ] = await Promise.all([
    dataProvider.getListAll('htRequest', { withArchived: full }),
    dataProvider.getListAll('htResidence'),
    full
      ? dataProvider.getHistoryAll('htRequest', { tickCallback })
      : undefined,
  ]);
  const historyAll = getHistoryResult ? getHistoryResult.data : undefined;

  const groupedHistoryAll = _groupBy(historyAll, 'logStreamName');

  const requestsForExport = await Promise.all(
    requests
      .sort((reqA, reqB) => {
        if (!reqA.createdAt || !reqB.createdAt) return 0;
        return moment(reqA.createdAt).isSameOrBefore(reqB.createdAt) ? -1 : 1;
      })
      .map(request => {
        const requestBaseDataForExport: { [k: string]: any } = {};
        const isArchived = request.status === 'ARCHIVED';

        _forEach(_pick(request, requestKeyHeaders), (value, key) => {
          let translatedValue = value;
          if (enumsKeys.includes(key) && value) {
            translatedValue = translateIf(translate, `enums.${key}.${value}`, {
              brut: true,
            });
          } else if (dateFields.includes(key) && value) {
            translatedValue = moment(`${value}`).format('DD/MM/YYYY');
          }
          if (
            isArchived &&
            ['sncfCP', 'email', 'givenName', 'familyName', 'tel'].includes(key)
          ) {
            requestBaseDataForExport[
              translate(`resources.htRequest.fields.${key}`)
            ] = '- archivé -';
          } else if (key === 'origin') {
            requestBaseDataForExport[
              translate(`resources.htRequest.fields.${key}`)
            ] = originMapping(translatedValue);
          } else {
            requestBaseDataForExport[
              translate(`resources.htRequest.fields.${key}`)
            ] = translatedValue;
          }
        });

        request.htLocations
          ?.filter((p, index) => index < config.ht.locations.max)
          .forEach((htLocation, index) => {
            if (htLocation) {
              const { address } = htLocation;
              requestBaseDataForExport[
                `${translate(`resources.htResidence.fields.address`)} ${
                  index + 1
                }`
              ] = address;
            }
          });

        requestBaseDataForExport[
          translate('resources.htRequest.fields.htParmeEligible')
        ] =
          eligible({
            request: request as any,
            residences,
          }).length > 0;

        return Promise.all([
          dataProvider
            .getManyBase('htProposition', { ids: request.propositions })
            .then(({ data: propositions }: { data: HtProposition[] }) => {
              propositions
                .filter((p, index) => index < config.ht.propositions.max)
                .forEach((proposition, index) => {
                  const propositionForExport = _pick(
                    proposition,
                    propositionKeyHeaders,
                  );
                  _forEach(propositionForExport, (value, key) => {
                    let translatedValue = value;
                    if (enumsKeys.includes(key) && value) {
                      translatedValue = translate(`enums.${key}.${value}`);
                    }
                    requestBaseDataForExport[
                      `${translate(`resources.htProposition.fields.${key}`)} ${
                        index + 1
                      }`
                    ] = translatedValue;
                  });
                });
              return requestBaseDataForExport;
            }),
          new Promise(resolve => {
            const historyDataForExport: { [k: string]: any } = {};
            if (full) {
              const history = groupedHistoryAll[`Request/${request.id}`];
              if (history) {
                history.forEach(entry => {
                  const { timestamp, message } = entry;
                  if (message) {
                    const { wfStatus } = JSON.parse(message);
                    if (statusEnums.includes(wfStatus)) {
                      const tStatus = translate(`enums.wfStatus.${wfStatus}`);
                      // DESC order => overwrite if older value get found
                      historyDataForExport[tStatus] =
                        moment(timestamp).format('DD/MM/YYYY');
                    }
                  }
                });
              }
            }
            resolve(historyDataForExport);
          }),
        ]).then(
          ([requestDataForExport, requestHistoryDataForExport]: {
            [k: string]: any;
          }[]) => {
            if (!full) {
              return requestDataForExport;
            }
            // Add current status with the creation date if it's not in the history
            if (
              request.createdAt &&
              request.wfStatus &&
              statusEnums.includes(request.wfStatus)
            ) {
              const tStatus = translate(`enums.wfStatus.${request.wfStatus}`);
              if (
                !requestHistoryDataForExport[tStatus] &&
                Object.keys(requestHistoryDataForExport).length === 0
              ) {
                requestHistoryDataForExport[tStatus] = moment(
                  request.createdAt,
                ).format('DD/MM/YYYY');
              }
            }
            return {
              ...requestDataForExport,
              ...requestHistoryDataForExport,
            };
          },
        );
      }),
  );

  jsonExport(
    requestsForExport,
    {
      ...config.exportedCSVconfig,
      // order fields in the export
      headers: translatedHeaders,
    },
    (err: any, csv: any) => {
      if (err) console.error(err);
      downloadCSV(
        `\ufeff${csv}`,
        `PADH_HT_DATA_${full ? 'COMPLET_' : ''}${moment().format(
          'YYYY-MM-DD',
        )}`,
      );
    },
  );
  if (full) {
    // Reload the page to delete archived in cache
    window.location.reload();
  }
}

const requestsExporter =
  (translate: Translate): Exporter =>
  (
    data: any,
    fetchRelatedRecords: (
      data: any,
      field: string,
      resource: string,
    ) => Promise<any>,
    dataProvider: DataProviderProxy,
    resource?: string,
  ): void | Promise<void> => {
    exportRequests(translate, dataProvider);
  };

const requestsFullExporter = (
  translate: Translate,
  dataProvider: DataProvider,
  tickCallback?: (p: number) => void,
): Promise<void> => {
  return exportRequests(translate, dataProvider, { full: true, tickCallback });
};

export { requestsFullExporter };

export default requestsExporter;
