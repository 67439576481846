import React, { useCallback, useState } from 'react';
import { useTranslate } from 'ra-core';
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../../structure/ConfirmDialog';
import { SMinoPayment, nodeIsPayment } from '../../../types/mino/nodes';

type PaymentDeleteButtonProps = {
  record?: SMinoPayment;
  onConfirmDelete: (payment: SMinoPayment) => void;
};

const PaymentDeleteButton: React.FC<PaymentDeleteButtonProps> = ({
  record: payment,
  onConfirmDelete,
}: PaymentDeleteButtonProps) => {
  const translate = useTranslate();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const handleClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      setShowConfirmDialog(true);
    },
    [setShowConfirmDialog],
  );
  const handleCancel = useCallback(() => {
    setShowConfirmDialog(false);
  }, [setShowConfirmDialog]);
  const handleConfirm = useCallback(() => {
    if (payment && nodeIsPayment(payment)) {
      setShowConfirmDialog(false);
      onConfirmDelete(payment);
    }
  }, [payment, onConfirmDelete]);

  if (!payment || !nodeIsPayment(payment)) {
    return null;
  }

  return (
    <>
      <Tooltip title={translate('resources.minoPayment.delete')}>
        <IconButton onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={showConfirmDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        resource="minoPayment"
      />
    </>
  );
};

export default PaymentDeleteButton;
