import React, { useState, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import inflection from 'inflection';
import { makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import clsx from 'clsx';
import { DashboardMenuItem, MenuItemLink, MenuProps } from 'react-admin';
import { getResources, useTranslate, ReduxState } from 'ra-core';

import {
  Icon as MinoManagementIcon,
  key as minoManagementKey,
} from '../components/customPages/MinoManagement';
import { Icon as CguIcon, key as cguKey } from '../components/customPages/CGU';

import { resourcesKeys as htResourceKeys } from '../dataProvider/ht/resourcesKeys';
import { resourcesKeys as minoResourceKeys } from '../dataProvider/mino/resourcesKeys';
import SubMenu from './Menu/SubMenu';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});

const CustomMenu: React.FC<MenuProps> = props => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const [subMenuOpen, toggleSubMenu] = useState({ ht: true, mino: true });
  const handleToggleSubMenu = useCallback(
    (menuName: keyof typeof subMenuOpen) => (): void => {
      toggleSubMenu({ ...subMenuOpen, [menuName]: !subMenuOpen[menuName] });
    },
    [subMenuOpen],
  );

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  type RaResource = {
    name: string;
    icon?: React.Component;
    hasList: boolean;
  };
  const resources: RaResource[] = useSelector(getResources, shallowEqual);
  const htListResources = resources.filter(
    r => r.hasList && (htResourceKeys as readonly string[]).includes(r.name),
  );
  const minoListResources = resources.filter(
    r => r.hasList && (minoResourceKeys as readonly string[]).includes(r.name),
  );

  function getMenu(
    menuName: keyof typeof subMenuOpen,
    menuListResources: any[],
    additionnalItems?: React.ReactNode,
  ): JSX.Element {
    return (
      menuListResources && (
        <SubMenu
          handleToggle={handleToggleSubMenu(menuName)}
          isOpen={subMenuOpen[menuName]}
          sidebarIsOpen={open}
          name={`menu.${menuName}`}
          // icon={<orders.icon />}
          dense={dense}
        >
          {menuListResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`, 2)}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              dense={dense}
              sidebarIsOpen={open}
            />
          ))}
          {additionnalItems}
        </SubMenu>
      )
    );
  }

  return (
    <div className={clsx(classes.main, className)} {...rest}>
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {getMenu('ht', htListResources)}
      {getMenu(
        'mino',
        minoListResources,
        <MenuItemLink
          to={`/${minoManagementKey}`}
          primaryText={translate(`${minoManagementKey}.name`)}
          leftIcon={<MinoManagementIcon />}
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />,
      )}
      <MenuItemLink
        to={`/${cguKey}`}
        primaryText={translate(`${cguKey}.name`)}
        leftIcon={<CguIcon />}
        onClick={onMenuClick}
        dense={dense}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default CustomMenu;
