import React from 'react';
import { Admin as ReactAdmin, Resource } from 'react-admin';

import ResidenceIcon from '@material-ui/icons/Home';
import MinoIcon from '@material-ui/icons/Euro';

import { getSncfTheme } from '../theme/custom/sncf';

import dataProvider from '../dataProvider/dataProvider';
import dataSaga from '../dataProvider/saga';
import authProvider from '../authProvider/authProvider';
import i18nProvider from '../i18n/i18nProvider';

import CustomLayout from '../layout/CustomLayout';
import customRoutes from '../routes/customRoutes';
import LoginPage from './structure/LoginPage';

import HTRequestList from './ht/Request/RequestList';
import HTRequestCreate from './ht/Request/RequestCreate';
import HTRequestEdit from './ht/Request/RequestEdit';

import HTResidenceList from './ht/Residence/ResidenceList';
import HTResidenceCreate from './ht/Residence/ResidenceCreate';
import HTResidenceEdit from './ht/Residence/ResidenceEdit';

import MinoUserList from './mino/User/UserList';
import MinoUserEdit from './mino/User/UserEdit';

import { Rights } from '../authProvider/permissions';

const muiTheme = getSncfTheme();

const Admin: React.FC = () => {
  const dp = dataProvider;

  return (
    <ReactAdmin
      title="PADH"
      dataProvider={dp}
      customSagas={[dataSaga(dp)]}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      theme={muiTheme}
      layout={CustomLayout}
      loginPage={LoginPage}
    >
      {(permissions: Rights[]): React.ReactNode => {
        const resources = [];

        const permissionHtRead = permissions.includes(Rights.HT_READ);
        const permissionHtWrite = permissions.includes(Rights.HT_WRITE);
        const permissionResidence = permissions.includes(Rights.RESIDENCES);
        const permissionMinoRead = permissions.includes(Rights.MINO_READ);
        if (permissionHtRead) {
          resources.push(
            <Resource
              name="htRequest"
              list={HTRequestList}
              create={permissionHtWrite ? HTRequestCreate : undefined}
              edit={HTRequestEdit}
            />,
          );
          resources.push(<Resource name="htProposition" />);
          resources.push(<Resource name="htAsset" />);
        }

        if (permissionHtRead || permissionResidence) {
          resources.push(
            <Resource
              name="htResidence"
              list={permissionResidence ? HTResidenceList : undefined}
              create={permissionResidence ? HTResidenceCreate : undefined}
              edit={permissionResidence ? HTResidenceEdit : undefined}
              icon={ResidenceIcon}
            />,
          );
        }

        if (permissionMinoRead) {
          resources.push(
            <Resource
              name="minoUser"
              list={MinoUserList}
              edit={MinoUserEdit}
              icon={MinoIcon}
            />,
          );
          resources.push(<Resource name="minoRequest" />);
          resources.push(<Resource name="minoContract" />);
          resources.push(<Resource name="minoPayment" />);
          resources.push(<Resource name="minoRent" />);
          resources.push(<Resource name="minoAsset" />);
        }

        if (resources.length === 0) {
          resources.push(<Resource name="login" />);
        }

        return resources;
      }}
    </ReactAdmin>
  );
};

export default Admin;
