import AWS, { CognitoIdentityCredentials } from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';

import awsConfig from './aws-exports';
import { awsAppSyncCredentials } from './config';

// eslint-disable-next-line import/prefer-default-export
export const getS3DownloadUrl = async (key: string): Promise<string> => {
  const { auth } = awsAppSyncCredentials.config;
  if (auth.type !== 'AWS_IAM' || typeof auth.credentials !== 'function')
    throw new Error('Bad config');
  let credentials = await auth.credentials();
  if (typeof credentials === 'function') {
    // In case credentials is a CredentialProvider
    credentials = await credentials();
  }
  const S3 = new AWS.S3({
    apiVersion: awsConfig.aws_s3_apiVersion,
    region: awsConfig.aws_s3_region,
    credentials,
  });
  return S3.getSignedUrl('getObject', {
    Bucket: awsConfig.aws_s3_bucket,
    Key: awsConfig.aws_s3_prefix ? `${awsConfig.aws_s3_prefix}/${key}` : key,
  });
};

const encodeRFC5987ValueChars = (str: string): string =>
  encodeURIComponent(str)
    // Note that although RFC3986 reserves "!", RFC5987 does not, so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987, so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape);

const generateUuid = (): string => uuidv4();

/**
 * Upload a file to S3 and get it's key back
 * @param file File to be uploaded to S3
 * @param progressCallback Function to follow the upload
 * @param { needToAbort } Create an abortable promise
 */
export const uploadS3 = async (
  file: File,
  progressCallback?: () => void,
  options: { needToAbort?: boolean } = {},
): Promise<{ key: string; upload: Promise<ManagedUpload.SendData> }> => {
  const { needToAbort = false } = options;
  const { auth } = awsAppSyncCredentials.config;
  if (auth.type !== 'AWS_IAM' || typeof auth.credentials !== 'function')
    throw new Error('Bad config');
  const credentials = (await auth.credentials()) as CognitoIdentityCredentials;
  return credentials.getPromise().then(() => {
    const s3Prefix = credentials.identityId;

    const key = `${s3Prefix}/${generateUuid()}`;

    const S3 = new AWS.S3({
      apiVersion: awsConfig.aws_s3_apiVersion,
      region: awsConfig.aws_s3_region,
      credentials,
    });

    const lastModified: File['lastModified'] =
      file.lastModified || (file as any).lastModifedDate;
    const mtime = lastModified && new Date(lastModified).toISOString();

    const metadata: { [k: string]: string } = {};
    if (mtime) metadata.mtime = mtime;

    const upload = S3.upload(
      {
        Bucket: awsConfig.aws_s3_bucket,
        Key: key,
        // StorageClass: 'REDUCED_REDUNDANCY', // keep by default for now
        Body: file,
        CacheControl: 'max-age=86400',
        ContentType: file.type,
        ContentDisposition: `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
          file.name,
        )}`,
        Metadata: metadata,
      },
      { partSize: 5 * 1024 * 1024, queueSize: 4 },
    );

    if (progressCallback) upload.on('httpUploadProgress', progressCallback);
    if (needToAbort) upload.abort.bind(upload);

    return { key, upload: upload.promise() };
  });
};
