import frenchMessages from 'ra-language-french';
import { key as minoManagementKey } from '../components/customPages/MinoManagement';
import { key as cguKey } from '../components/customPages/CGU';
import { key as importContractsKey } from '../components/customPages/MinoManagement/ImportContracts';
import { key as importUsersOrganisationKey } from '../components/customPages/MinoManagement/ImportUsersOrganisation';
import { key as importPaymentsKey } from '../components/customPages/MinoManagement/ImportPayments';
import { HTMotifRefus, Typology } from '../types/ht/enums';
import { MinoMotifRefus } from '../utils/mino/enums';

const frenchMessagesCommonFields = {
  id: 'Id', // CSV Bug on first column called just "ID" ???
  createdAt: 'Création',
  updatedAt: 'Dernière mise à jour',
  version: 'Version',
  status: 'Statut',
  origin: 'Origine',

  address: 'Adresse',
  addressLevel2: 'Ville',
  bday: 'Date de naissance',
  code: 'Code',
  comment: 'Commentaire',
  coordinates: 'Coordonnées',
  dateBail: 'Date bail',
  deduction: 'Mino',
  distance: 'Distance',
  email: 'Email',
  familyName: 'Nom',
  filiere: 'Filière',
  gdpr: 'GDPR',
  givenName: 'Prénom',
  honorificPrefix: 'Titre',
  maritalStatus: 'Situation familiale',
  month: 'Mois concerné',
  nationality: 'Nationalité',
  paySlip: 'BS Concerné',
  postalCode: 'Code postal',
  priority: 'Priorité',
  provDate: 'Date proposition',
  provDeduction: 'APL/AL',
  provider: 'Gestionnaire',
  rent: 'Loyer',
  sncfBranche: 'Branche',
  sncfCompany: 'SA',
  sncfContractBeginDate: 'Date de début du contrat',
  sncfContractEndDate: 'Date de fin du contrat',
  sncfContractType: 'Type de contrat',
  sncfCP: 'CP',
  sncfDivision: 'Division',
  sncfEmployeeType: "Type d'employé",
  sncfEtablissement: 'Etablissement',
  sncfPostalCode: 'LPA',
  sncfRG: 'RG',
  sncfSA: 'SA',
  sncfStatut: 'Statut SNCF',
  streetAddress: 'Adresse',
  tel: 'Téléphone',
  wfNext: 'Workflow',
  wfStatus: 'Statut',
  motifRefus: 'Motif de refus',
  wfNotify: 'Envoyer l’email transactionnel associé au statut',
};

const coordinatesFields = {
  latitude: 'Lat.',
  longitude: 'Long.',
};

const enums = {
  decision: {
    ACCEPTATION: 'Acceptation',
    REFUS: 'Refus',
  },
  financement: {
    PLAI: 'PLAI',
    PLUS: 'PLUS',
    PLS: 'PLS',
    PLI: 'PLI',
    LIBRE: 'LIBRE',
  },
  honorificPrefix: {
    MME: 'Mme',
    MR: 'Mr',
  },
  htMotif: {
    ALTERNANT: 'Alternant',
    DECOHABITATION: 'Décohabitation',
    FORMATION: 'Formation',
    MOBILITE: 'Mobilité',
    NOUVEL_EMBAUCHE: 'Nouvel embauché',
    SANS_LOGEMENT: 'Sans logement',
    SEPARATION: 'Séparation',
    URGENCE_SOCIALE: 'Urgence sociale',
  },
  htTypology: {
    [Typology.STUDIO]: 'Studio',
    [Typology.T1]: 'T1',
    [Typology.T2]: 'T2',
  },
  maritalStatus: {
    SINGLE: 'Célibataire',
    REGISTERED: 'Concubin(e)',
    PACSED: 'Pacsé(e)',
    MARRIED: 'Marié(e)',
    SEPARATED: 'Séparé(e)',
    DIVORCED: 'Divorcé(e)',
    WIDOWED: 'Veuf/ve',
  },
  nationality: {
    FR: 'Française',
    EU: 'Union Européenne',
    NON_EU: 'Hors Union Européenne',
  },
  htMotifRefus: {
    [HTMotifRefus.GALO]: 'GALO',
    [HTMotifRefus.DOC_NON_RECUS]: 'Doc non reçus',
    [HTMotifRefus.PLUS_SALARIE]: 'Plus salarié',
    [HTMotifRefus.AYANT_DROIT]: 'Ayant droit',
  },
  minoMotifRefus: {
    [MinoMotifRefus.LOCATION_NON_ELIGIBLE]: 'Location non éligible',
    [MinoMotifRefus.A_DEJA_EU]: 'A déjà eu des minos',
    [MinoMotifRefus.PREAVIS_BAILLEUR]: 'Préavis chez le bailleur',
    [MinoMotifRefus.PREAVIS_SNCF]: 'Préavis à la SNCF',
    [MinoMotifRefus.BAIL_JUSTIFICATIF_NON_TRANSMIS]:
      'Bail ou justificatif non transmis',
    [MinoMotifRefus.DOUBLON]: 'Doublon',
  },
  sncfEmployeeType: {
    CDD: 'CDD',
    CDI: 'CDI',
    EXT: 'EXT',
    INT: 'INT',
  },
  sncfStatut: {
    AG: 'AG',
    CC: 'CC',
    CD: 'CD',
    CM: 'CM',
    CP: 'CP',
    CS: 'CS',
    MD: 'MD',
    SG: 'SG',
  },
  sncfCompany: {
    SNCF: 'SNCF',
    'SNCF RESEAU': 'SNCF RESEAU',
    'SNCF VOYAGEURS': 'SNCF VOYAGEURS',
    'SNCF GARES & CONNEXIONS': 'SNCF GARES & CONNEXIONS',
    'FRET SNCF': 'FRET SNCF',
    'SNCF Optimservices': 'SNCF Optimservices',
  },
  status: {
    DRAFT: 'Brouillon',
    PUBLISHED: 'Publié',
    ARCHIVED: 'Archivé',
  },
  wfNext: {
    VALIDATED: 'Non transmis',
    UPDATED: 'Transmis',
    DONE: 'Terminé',
  },
  wfStatus: {
    A_VALIDER: 'À valider',
    ANNULEE: 'Annulée',
    ATTENTE_ORIENTATION: 'Attente orientation',
    BAIL_SIGNE: 'Bail signé',
    EN_COURS_INSTRUCTION: 'Demande en cours d’instruction',
    EN_COURS: 'Demande en cours',
    EN_INSTRUCTION: 'En instruction',
    FIN_DROITS_INTERRUPTION: 'Droits interrompus',
    FIN_DROITS: 'Droits consommés',
    NOUVELLE: 'Nouvelle demande',
    PROPOSITION_ACCEPTEE: 'Proposition acceptée',
    PROPOSITION_EN_COURS: 'Proposition en cours',
    PROPOSITION_ALS: 'Proposition ALS',
    REFUSEE_PARME: 'Refusée Parme',
    REFUSEE: 'Demande refusée',
    RENONCEE: 'Renoncée',
    STANDBY: 'Standby',
    SUSPENDUE: 'Demande suspendue',
    TRANSFEREE: 'Demande transférée',
    TRANSMIS_ALS: 'Transmis ALS',
    TRANSMIS_PARTENAIRE: 'Transmis partenaire',
    VALIDEE_PARME: 'Validée Parme',
    VALIDEE: 'Demande validée',
    ENVOI_CONVENTION: 'Envoi convention',
  },
  residenceCode: {
    ETUDIANTE: 'Étudiante',
    PARME: 'Parme',
    AUTRE: 'Autre',
  },
};

export const enumsKeys = Object.keys(enums);

export default {
  ...frenchMessages,
  menu: {
    ht: 'HT',
    mino: 'Minorations',
  },
  commonFields: {
    ...coordinatesFields,
  },
  resources: {
    htResidence: {
      name: 'Résidence |||| Résidences',
      create: 'Créer une nouvelle résidence',
      list: 'Liste des résidences PARME',
      fields: {
        ...frenchMessagesCommonFields,
        ...coordinatesFields,
        name: 'Nom de la résidence',
      },
    },
    htRequest: {
      name: 'Demande |||| Demandes',
      list: 'Liste des demandes HT',
      create: 'Créer une nouvelle demande',
      form: {
        tabs: {
          gestion: 'Gestion',
          informations: 'Informations',
        },
      },
      fields: {
        ...frenchMessagesCommonFields,
        ...coordinatesFields,
        attachments: "Avis d'imposition",
        fiscalRevenue: 'Revenu fiscale de référence',
        htAttribution: "Norme d'attribution",
        htGarant: 'A un garant',
        htLocations: 'Lieux souhaités',
        htMotif: 'Motif de la demande',
        htOccupants: "Nombre d'occupants souhaités",
        htTypology: 'Typologie de logement',
        htStartDate: 'Date de début de bail souhaitée',
        htEndDate: 'Date de fin de location prévisionnelle',
        htParmeEligible: 'Éligibilité PARME',
        nbDays: 'Jours restants',
        nbPropositions: 'Propositions totales',
        nbRefusedPropositions: 'Propositions refusées',
        salary: 'Salaire mensuel NET',
      },
      eligibility: {
        true: 'Éligible',
        false: 'Non éligible',
      },
    },
    htProposition: {
      name: 'Proposition |||| Propositions',
      create: 'Créer une nouvelle proposition',
      delete: 'Supprimer cette proposition',
      fields: {
        ...frenchMessagesCommonFields,
        provider: 'Bailleur',
        dateDecision: 'Date décision demandeur',
        decision: 'Décision demandeur',
        motifRefus: 'Motif refus',
        provDate: 'Date proposition',
        provId: 'ID Logement',
        residenceCode: 'Référence logement (Parme)',
        residenceName: 'Nom de la résidence (Parme)',
      },
    },
    htAsset: {
      name: 'Fichier |||| Fichiers',
      fields: {
        ...frenchMessagesCommonFields,
      },
    },
    minoUser: {
      name: 'Demandeur |||| Demandeurs',
      list: 'Liste des demandeurs MINO',
      sections: {
        personnalInfo: 'Informations personnelles',
        professionalInfo: 'Informations professionnelles',
        process: 'Process',
        requests: 'Les demandes',
        payments: 'Les transactions',
      },
      fields: {
        ...frenchMessagesCommonFields,
        nbRequests: 'Nb de demandes',
        lastRequestDate: 'Création dernière demande',
        lastRequestStatus: 'Statut dernière demande',
        nbPayments: 'Nb droits perçus',
        minoEndDate: 'Date fin droits',
        lastPaymentAmount: 'Dernier montant perçu',
        totalPaymentAmount: 'Montant total perçu',
        lastRequestProvider: 'Dernier gestionnaire',
      },
      calculatedFields: {
        month: 'Mois concerné',
        fullMonthContract: 'Présent SNCF',
        fullMonthAccomodation: 'Présent Logement',
        provider: 'Gestionnaire',
        rent: 'Loyer',
        provDeduction: 'Apl',
      },
      exportFields: {
        multiProviders: 'MULTIPLES GESTIONNAIRES',
        sncfCP: 'CP',
        month: 'MOIS_CONCERNE',
        paySlip: 'BS_CONCERNE',
        rent: 'LOYER',
        provDeduction: 'APL_AL',
        mino: 'MINO',
        provider: 'GESTIONNAIRE',
        /** @deprecated */
        fullMonthAccomodation: 'LOGE_MOIS_ENTIER',
        fullMonthContract: 'AGENT_SNCF_MOIS_ENTIER',
        sncfSA: 'SA',
        sncfBranche: 'BRANCHE',
        sncfDivision: 'DIVISION',
        sncfRG: 'RG',
        sncfEtablissement: 'ETABLISSEMENT',
        requestStatus: 'STATUT_DEMANDE',
        nbPayments: 'NB_DROITS_PERCUS',
        familyName: 'NOM',
        givenName: 'PRENOM',
        bday: 'DATE_NAISSANCE',
        requestId: 'N_DEMANDE',
        anomalies: 'ANOMALIES',
        originalPeriod: "Période d'origine",
        rubric: 'Rubrique de',
        sign: 'Signe',
        centsAmount: 'Montant en centièmes',
      },
    },
    minoRequest: {
      name: 'Demande |||| Demandes',
      read_only: 'La demande est en lecture seule.',
      sections: {
        accomodationInfo: 'Informations logement',
        additionalInfo: 'Informations supplémentaires',
      },
      fields: {
        ...frenchMessagesCommonFields,
        residenceCode: 'Type de résidence',
        'receipts[0]': 'Quittance_1',
        'receipts[1]': 'Quittance_2',
        'receipts[2]': 'Quittance_3',
      },
    },
    minoPayment: {
      name: 'Transaction |||| Transactions',
      delete: 'Supprimer cette transaction',
      fields: {
        ...frenchMessagesCommonFields,
      },
    },
    minoContract: {
      name: 'Contrat |||| Contrats',
      fields: {
        ...frenchMessagesCommonFields,
      },
    },
    minoRent: {
      name: 'Loyer |||| Loyers',
      fields: {
        ...frenchMessagesCommonFields,
        endDate: 'Date de sortie',
        fullAccomodation: 'Logé mois entier',
        startDate: "Date d'entrée",
      },
    },
  },
  enums: {
    ...enums,
    filiere: enums.financement,
    htAttribution: enums.financement,
  },
  action: {
    duplicate: 'Dupliquer',
  },
  message: {
    confirm_delete_title: 'Supprimer %{name} ?',
  },
  error: {
    notacsv: "Le fichier proposé n'est pas un CSV",
    onefileonly: "Merci de ne sélectionner qu'un seul fichier",
  },
  export: {
    done: "L'export s'est terminé avec succès",
  },
  import: {
    done: "L'import s'est terminé avec succès",
    fields: {
      effectDate: "Date d'effet",
    },
  },
  transaction: {
    locked: '[LECTURE SEULE]',
    longLocked:
      "Il semble y avoir un problème avec cette demande, contactez l'administrateur du site.",
    rollbacked: 'Un problème est survenu sur cette demande: %{message}',
  },
  [minoManagementKey]: {
    name: 'Gestion',
    title: 'Gestion des minorations',
  },
  [cguKey]: {
    name: 'CGU',
    title: "Conditions Générales d'Utilisation de l'application PADH",
  },
  [importContractsKey]: {
    title: 'Minoration: Import de contrats',
    importTitle: 'Importer un CSV de contrats',
    importReady: 'Contrats prêts à être importés',
    import: 'Importer les contrats dans la base',
  },
  [importUsersOrganisationKey]: {
    title: "Minoration: Import d'organisations",
    importTitle: "Importer un CSV d'organisations",
    importReady: 'Organisations prêtes à être importées',
    import: 'Importer les organisations dans la base',
  },
  [importPaymentsKey]: {
    title: 'Minoration: Import de transactions',
    importTitle: 'Importer un CSV de transactions',
    importReady: 'Transactions prêtes à être importées',
    import: 'Importer les transactions dans la base',
    secondaryImport: 'Importer les transactions dans la base + mails',
  },
};
