import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Title, downloadCSV } from 'react-admin';
import { useTranslate, useNotify } from 'ra-core';
import { useDropzone } from 'react-dropzone';
import * as jsonExport from 'jsonexport/dist';
import csv from 'papaparse';
import _partition from 'lodash/partition';
import _isNil from 'lodash/isNil';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Backdrop,
  CircularProgress,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import moment, { Moment } from 'moment';

import config from '../../../config';
import { ClassName } from '../../../types/styles';
import { MinoUser } from '../../../types/mino/nodes';

import {
  retrieveFileFromS3,
  S3FileState,
} from '../../../aws/s3-utils-management';

interface GenericFieldInfosBase<Field extends string> {
  csvHeader: string;
  isMandatory?: boolean | ((dbUser?: MinoUser) => boolean);
  isRowKey?: boolean;
  isDbData?: boolean;
  translateFieldPath: string;
}

interface IsNumberAdd<Field extends string> {
  isNumber: true;
  emptyIsZero?: boolean;
}
interface IsBooleanAdd<Field extends string> {
  isBoolean: true;
}

export enum DateOrder {
  BEFORE,
  AFTER,
}
interface IsDateAdd<Field extends string> {
  isDate: true;
  isMonth?: boolean;
  dateCsvFormat: string;
  dateOrder?: { [k in DateOrder]?: Field };
  // dateNarrowings?: DateNarrowing[];
}

type GenericFieldInfosText<Field extends string> =
  GenericFieldInfosBase<Field> & {
    [k in
      | keyof IsNumberAdd<Field>
      | keyof IsBooleanAdd<Field>
      | keyof IsDateAdd<Field>]?: never;
  };

type GenericFieldInfosNumber<Field extends string> =
  GenericFieldInfosBase<Field> &
    IsNumberAdd<Field> & {
      [k in keyof IsBooleanAdd<Field> | keyof IsDateAdd<Field>]?: never;
    };

type GenericFieldInfosBoolean<Field extends string> =
  GenericFieldInfosBase<Field> &
    IsBooleanAdd<Field> & {
      [k in keyof IsNumberAdd<Field> | keyof IsDateAdd<Field>]?: never;
    };

type GenericFieldInfosDate<Field extends string> =
  GenericFieldInfosBase<Field> &
    IsDateAdd<Field> & {
      [k in keyof IsNumberAdd<Field> | keyof IsBooleanAdd<Field>]?: never;
    };

export type GenericFieldInfos<Field extends string> =
  | GenericFieldInfosText<Field>
  | GenericFieldInfosNumber<Field>
  | GenericFieldInfosBoolean<Field>
  | GenericFieldInfosDate<Field>;

export function isNumberFieldInfos<Field extends string>(
  fieldInfos: GenericFieldInfos<Field>,
): fieldInfos is GenericFieldInfosNumber<Field> {
  return (fieldInfos as GenericFieldInfosNumber<Field>).isNumber;
}

export function isBooleanFieldInfos<Field extends string>(
  fieldInfos: GenericFieldInfos<Field>,
): fieldInfos is GenericFieldInfosBoolean<Field> {
  return (fieldInfos as GenericFieldInfosBoolean<Field>).isBoolean;
}

export function isDateFieldInfos<Field extends string>(
  fieldInfos: GenericFieldInfos<Field>,
): fieldInfos is GenericFieldInfosDate<Field> {
  return (fieldInfos as GenericFieldInfosDate<Field>).isDate;
}

// export enum DateNarrowing {
//   START_OF_MONTH,
//   END_OF_MONTH,
// }

export type GenericFieldsInfos<Field extends string> = {
  [k in Field]: GenericFieldInfos<Field>;
};

export type GenericCsvData<Field extends string> = {
  [k in Field]?: string | null;
};
export type GenericCsvDataToImport<Field extends string> = {
  [k in Field]?: string | number | boolean | null;
};

export type GenericDataToImport<Field extends string> =
  GenericCsvDataToImport<Field> & {
    updatedFields: Field[];
    isNew?: boolean;
    processedFieldsError?: Field[];
  };

export type GenericError<Field> = { field: Field; message: string };

export type GenericErrorData<Field extends string> =
  GenericCsvDataToImport<Field> & {
    updatedFields: Field[];
    errors: GenericError<Field>[];
    processedFieldsError?: Field[];
  };

export function isErrorData<Field extends string>(
  c: GenericDataToImport<Field> | GenericErrorData<Field>,
): c is GenericErrorData<Field> {
  return (
    Array.isArray((c as GenericErrorData<Field>).errors) &&
    (c as GenericErrorData<Field>).errors.length > 0
  );
}

const { awsDateFormat } = config;
const displayFullDateFormat = 'DD/MM/YYYY';
const displayMonthDateFormat = 'MM/YYYY';

export const startOfMonth = moment(config.minoManagementMonthIso).startOf(
  'month',
);
export const endOfMonth = moment(config.minoManagementMonthIso).endOf('month');

export function checkAndUpdateFieldsData<Field extends string>(
  fieldsInfos: GenericFieldsInfos<Field>,
  csvData: GenericCsvData<Field>,
  dbUser?: MinoUser,
): {
  csvDataToImport: GenericCsvDataToImport<Field>;
  errors: GenericError<Field>[];
} {
  const csvDataToImport: GenericCsvDataToImport<Field> = { ...csvData };
  const errors: GenericError<Field>[] = [];
  const csvDates: { [field: string]: Moment } = {};

  (Object.keys(fieldsInfos) as Field[]).forEach(field => {
    const fieldInfos = fieldsInfos[field];
    const { isMandatory } = fieldInfos;

    // Mandatory fields errors
    if (!csvData[field] && typeof isMandatory !== 'undefined') {
      if (
        typeof isMandatory === 'boolean' ? isMandatory : isMandatory(dbUser)
      ) {
        errors.push({
          field,
          message: 'Donnée requise',
        });
      }
    }

    /* Date fields
        - Date validity errors
        - Format csvDateFormat to awsDateFormat
        - Order dates errors
        - Narrow date ranges
        - Make it null if empty
     */
    if (isDateFieldInfos(fieldInfos)) {
      const {
        dateCsvFormat,
        dateOrder,
        //  dateNarrowings
      } = fieldInfos;
      if (csvDataToImport[field]) {
        csvDates[field] =
          csvDates[field] || moment(`${csvDataToImport[field]}`, dateCsvFormat);
        if (csvDates[field].isValid()) {
          csvDataToImport[field] = csvDates[field].format(awsDateFormat);

          let dateOrderKeys: DateOrder[] = [];
          //  Check for dates order errors
          if (dateOrder) {
            dateOrderKeys = Object.keys(dateOrder) as unknown as DateOrder[];
            dateOrderKeys.forEach(order => {
              const comparedField = dateOrder[order];
              if (comparedField) {
                csvDates[comparedField] =
                  csvDates[comparedField] ||
                  moment(
                    `${csvDataToImport[comparedField as Field]}`,
                    dateCsvFormat,
                  );
                switch (order) {
                  case DateOrder.BEFORE:
                    if (!csvDates[field].isBefore(csvDates[comparedField])) {
                      errors.push({
                        field,
                        message: `Date située après ${comparedField}`,
                      });
                    }
                    break;
                  case DateOrder.AFTER:
                    if (!csvDates[field].isAfter(csvDates[comparedField])) {
                      errors.push({
                        field,
                        message: `Date située avant ${comparedField}`,
                      });
                    }
                    break;
                  default:
                }
              }
            });
          }

          // FIXME: Doesn't work because of csvDates not yet fully filled
          // // Narrow date span by restrictions
          // if (dateNarrowings) {
          //   dateNarrowings.forEach(dateNarrowing => {
          //     /* eslint-disable no-case-declarations */
          //     switch (dateNarrowing) {
          //       case DateNarrowing.START_OF_MONTH:
          //         const dateBefore = dateOrder && dateOrder[DateOrder.BEFORE];
          //         let narrowToMax = startOfMonth;
          //         if (dateBefore && csvDates[dateBefore]) {
          //           narrowToMax = moment.min(csvDates[dateBefore], narrowToMax);
          //         }

          //         csvDates[field] = moment.max(csvDates[field], narrowToMax);
          //         csvDataToImport[field] = csvDates[field].format(
          //           awsDateFormat,
          //         );
          //         break;
          //       case DateNarrowing.END_OF_MONTH:
          //         const dateAfter = dateOrder && dateOrder[DateOrder.AFTER];
          //         let narrowToMin = endOfMonth;
          //         if (dateAfter) {
          //           narrowToMin = moment.max(csvDates[dateAfter], narrowToMin);
          //         }

          //         csvDates[field] = moment.min(csvDates[field], narrowToMin);
          //         csvDataToImport[field] = csvDates[field].format(
          //           awsDateFormat,
          //         );

          //         break;
          //       default:
          //     }
          //     /* eslint-enable no-case-declarations */
          //   });
          // }
        } else {
          csvDataToImport[field] = null;
          errors.push({
            field,
            message: `Date invalide (au format ${fieldInfos.dateCsvFormat})`,
          });
        }
        // FIXME: Removed because of narrowing disabled (due to above FIXME)
        // // Narrow undefined dates
        // } else if (!isMandatory && dateNarrowings) {
        //   dateNarrowings.forEach(dateNarrowing => {
        //     switch (dateNarrowing) {
        //       case DateNarrowing.START_OF_MONTH:
        //         csvDates[field] = startOfMonth;
        //         csvDataToImport[field] = csvDates[field].format(awsDateFormat);
        //         break;
        //       case DateNarrowing.END_OF_MONTH:
        //         csvDates[field] = endOfMonth;
        //         csvDataToImport[field] = csvDates[field].format(awsDateFormat);

        //         break;
        //       default:
        //     }
        //   });
      } else {
        csvDataToImport[field] = null;
      }
    }

    /* Number fields
        - Format to the right type
        - Make it 0 or null if empty
     */
    if (isNumberFieldInfos(fieldInfos)) {
      let fieldValue = csvData[field];
      if (!_isNil(fieldValue) && !!fieldValue) {
        fieldValue = fieldValue.replace(',', '.');
        if (parseFloat(`${fieldValue}`) !== Number(fieldValue)) {
          errors.push({
            field,
            message: 'Ne semble pas être un nombre valide',
          });
        } else {
          csvDataToImport[field] = +fieldValue.replace(',', '.');
        }
      } else if (fieldInfos.emptyIsZero) {
        csvDataToImport[field] = 0;
      } else {
        csvDataToImport[field] = null;
      }
    }

    /* Boolean fields
        - Format to boolean
        - Make it null if empty
     */
    if (isBooleanFieldInfos(fieldInfos)) {
      const fieldValue = csvData[field];
      if (fieldValue) {
        csvDataToImport[field] = !!+fieldValue;
      } else {
        csvDataToImport[field] = null;
      }
    }
  });

  return { csvDataToImport, errors };
}

export const styles = (theme: Theme): ReturnType<typeof createStyles> =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.text.contrastText,
    },
    linearProgress: {
      width: '20vw',
      boxShadow: theme.shadows[4],
    },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
      marginTop: theme.spacing(2),
    },
    dropzoneLabel: {
      margin: 0,
    },
    usersCountSection: {
      marginTop: theme.spacing(3),
    },
    usersCountText: {
      color: theme.palette.success.main,
    },
    usersCountErrorText: {
      color: theme.palette.error.main,
      fontSize: '2em',
    },
    usersMissing: {
      color: theme.palette.error.light,
      fontStyle: 'italic',
    },
    dataSection: {
      marginTop: theme.spacing(3),
    },
    actionButton: {
      marginLeft: theme.spacing(2),
    },
    tableContainer: {
      maxHeight: `calc(100vh - 40px - ${theme.spacing(1)}px)`, // 40px is total bottom margin
    },
    table: {},
    errorIcon: {
      verticalAlign: 'middle',
      marginRight: '8px',
    },
    rowNew: {
      backgroundColor: theme.palette.success.light,
    },
    rowDataNoChange: {
      '& $cell': {
        color: theme.palette.text.hint,
      },
    },
    cell: {},
    cellDataChange: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.info.main,
    },
    cellProcessedError: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.error.main,
    },
    cellContent: {
      display: 'inline-flex',
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
  });

const useStyles = makeStyles(styles);

type GenerateTableProps<Field extends string> = {
  fieldsInfos: GenericFieldsInfos<Field>;
  data: (GenericDataToImport<Field> | GenericErrorData<Field>)[];
  className?: ClassName;
};

export function GenerateTable<Field extends string>({
  fieldsInfos,
  data,
  className = '',
}: GenerateTableProps<Field>): JSX.Element {
  const classes = useStyles();
  const translate = useTranslate();

  const fields = Object.keys(fieldsInfos) as Field[];

  const rowKeys: Field[] = fields.reduce<Field[]>((acc, field) => {
    const fieldInfos = fieldsInfos[field];
    if (fieldInfos.isRowKey) {
      acc.push(field);
    }
    return acc;
  }, []);

  return (
    <TableContainer className={clsx(classes.tableContainer, className)}>
      <Table className={classes.table} stickyHeader size="small">
        <TableHead>
          <TableRow>
            {fields.map(field => (
              <TableCell key={field} align="left">
                {translate(`${fieldsInfos[field].translateFieldPath}.${field}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(d => {
            const rowKey = rowKeys.map(rKey => d[rKey]).join(' ');
            const isNew = !isErrorData(d) && d.isNew;
            return (
              <TableRow
                key={rowKey}
                className={clsx({
                  [classes.rowDataNoChange]:
                    !isErrorData(d) && !isNew && d.updatedFields.length === 0,
                  [classes.rowNew]: !isErrorData(d) && isNew,
                })}
              >
                {fields.map((field, index) => {
                  const fieldInfos = fieldsInfos[field];
                  const isIdentifierField = rowKeys.includes(field);
                  const fieldErrors = isErrorData(d)
                    ? d.errors.filter(
                        ({ field: errorField }) => errorField === field,
                      )
                    : [];

                  let fieldValue: any = d[field];
                  if (fieldValue && isDateFieldInfos(fieldInfos)) {
                    fieldValue = moment(`${d[field]}`).format(
                      fieldInfos.isMonth
                        ? displayMonthDateFormat
                        : displayFullDateFormat,
                    );
                  }
                  if (isBooleanFieldInfos(fieldInfos)) {
                    if (fieldValue === true) {
                      fieldValue = 'Oui';
                    } else if (fieldValue === false) {
                      fieldValue = 'Non';
                    }
                  }

                  return (
                    <TableCell
                      key={`${rowKey} ${field}`}
                      align="left"
                      component={index === 0 ? 'th' : 'td'}
                      scope={index === 0 ? 'row' : undefined}
                      className={clsx(classes.cell, {
                        [classes.cellDataChange]:
                          !isNew &&
                          !isIdentifierField && // Identifier fields cannot "change" (... because they identify the matching data)
                          d.updatedFields.includes(field),
                        [classes.cellProcessedError]:
                          d.processedFieldsError?.includes(field),
                      })}
                    >
                      <div className={classes.cellContent}>
                        {fieldErrors.length > 0 && (
                          <Tooltip
                            title={fieldErrors
                              .map(({ message }) => message)
                              .join(', ')}
                          >
                            <WarningIcon
                              color="error"
                              fontSize="inherit"
                              className={classes.errorIcon}
                            />
                          </Tooltip>
                        )}
                        {fieldValue}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type GenericImportPropsBase<
  Field extends string,
  ImportField extends string = Field,
> = {
  pageKey: string;
  fieldsInfos: GenericFieldsInfos<Field>;
  importFieldsInfos?: GenericFieldsInfos<ImportField>;
  loading: boolean;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  progress: number | null;
  onCompleteCSVImport: (
    csvResult: csv.ParseResult<GenericCsvData<ImportField>>,
  ) => Promise<void>;
  /** [Array of expected sncfCPs, Set of imported sncfCPs] */
  users: [string[], Set<string>];
  dataToImport: GenericDataToImport<Field>[] | undefined;
  errorsData: GenericErrorData<ImportField>[] | undefined;
  importDataToDB: () => Promise<void>;
  secondaryImportDataToDB?: () => Promise<void>;
};

type GenericImportProps<
  Field extends string,
  ImportField extends string = Field,
> =
  | (GenericImportPropsBase<Field, ImportField> & {
      /** Regex */
      s3FileName: string;
      /** Prefix `/` Regex */
      s3Source: string;
    })
  | (GenericImportPropsBase<Field, ImportField> & {
      s3FileName?: never;
      s3Source?: never;
    });

export default function GenericImport<
  Field extends string,
  ImportField extends string,
>({
  pageKey,
  s3FileName,
  s3Source,
  fieldsInfos,
  importFieldsInfos: optionalImportFieldsInfos,
  loading,
  setLoading,
  progress,
  onCompleteCSVImport,
  users: [DbUsersCP, ImportedValidUsersCP],
  dataToImport,
  errorsData,
  importDataToDB,
  secondaryImportDataToDB,
}: GenericImportProps<Field, ImportField>): React.ReactElement<
  GenericImportProps<Field, ImportField>
> {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { pathname } = useLocation();
  const local = pathname.endsWith('/local') || !s3FileName;

  const [lastCsvResult, setLastCsvResult] =
    useState<csv.ParseResult<GenericCsvData<ImportField>>>();
  const [missingColumns, setMissingColumns] = useState<string[]>([]);

  const importFieldsInfos = (optionalImportFieldsInfos ||
    fieldsInfos) as GenericFieldsInfos<ImportField>;

  const csvHeaderMap = (
    Object.keys(importFieldsInfos) as ImportField[]
  ).reduce<{
    [k in GenericFieldInfos<ImportField>['csvHeader']]?: ImportField;
  }>((acc, field) => {
    acc[fieldsInfos[field].csvHeader] = field;
    return acc;
  }, {});

  const downloadErrors = useCallback(() => {
    jsonExport(
      errorsData?.map(({ errors, updatedFields, ...errorData }) => ({
        ...errorData,
        ...errors.reduce<{ [k: string]: string }>((acc, error, index) => {
          acc[`Erreur ${index + 1}`] = `${error.field}: ${error.message}`;
          return acc;
        }, {}),
      })),
      {
        ...config.exportedCSVconfig,
        // includeHeaders: false,
      },
      (err: any, csvData: any) => {
        if (err) console.error(err);
        downloadCSV(`\ufeff${csvData}`, 'Erreurs_imports_organisations');
      },
    );
  }, [errorsData]);

  const onCompleteCSVImportGeneric = useCallback(
    (
      csvResult: csv.ParseResult<GenericCsvData<ImportField>>,
      file?: File | undefined,
      jobDone?: boolean,
    ) => {
      setLastCsvResult(csvResult);
      const {
        meta: { fields: csvColumns },
      } = csvResult;
      const tmpMissingColumns = Object.keys(csvHeaderMap).reduce<string[]>(
        (acc, header) => {
          const mappedHeader = csvHeaderMap[header];
          if (mappedHeader && !csvColumns.includes(mappedHeader)) {
            acc.push(header);
          }
          return acc;
        },
        [],
      );

      setMissingColumns(tmpMissingColumns);

      if (tmpMissingColumns.length === 0) {
        onCompleteCSVImport(csvResult).then(() => {
          setLoading(false);
          if (jobDone) {
            // Reload page because data might be unsynced as subscriptions are not reliabley
            window.location.reload();
          }
        });
      } else {
        setLoading(false);
        if (jobDone) {
          // Reload page because data might be unsynced as subscriptions are not reliabley
          window.location.reload();
        }
      }
    },
    [csvHeaderMap, onCompleteCSVImport, setLoading],
  );

  const handleFile = useCallback(
    (file: File) => {
      setLoading(true);
      csv.parse<GenericCsvData<ImportField>>(file, {
        delimiter: ';',
        header: true,
        transformHeader: header => {
          return csvHeaderMap[header] || '';
        },
        skipEmptyLines: 'greedy',
        transform: value => value.trim(),
        // step: // use if files are too big
        // error: (err, file) => {
        //   console.log({ err, file });
        // },
        complete: onCompleteCSVImportGeneric,
      });
    },
    [csvHeaderMap, onCompleteCSVImportGeneric, setLoading],
  );

  const [s3FileState, setS3FileState] = useState<S3FileState>(
    S3FileState.TO_BE_LOADED,
  );
  useEffect(() => {
    if (!local && s3FileState === S3FileState.TO_BE_LOADED) {
      setS3FileState(S3FileState.LOADING);
      (async (): Promise<void> => {
        if (s3FileName) {
          setLoading(true);
          const file = await retrieveFileFromS3(s3FileName, s3Source);
          if (!file) {
            setS3FileState(S3FileState.NOT_FOUND);
            setLoading(false);
          } else {
            handleFile(file);
          }
        }
      })();
    }
  }, [handleFile, local, s3FileName, s3FileState, setLoading]);

  const onFileDroped = useCallback(
    (
      acceptedFiles: File[],
      rejectedFiles: File[],
      // event: DropEvent,
    ): void => {
      if (acceptedFiles.length + rejectedFiles.length > 1) {
        notify('error.onefileonly', 'warning');
      } else {
        if (rejectedFiles.length) {
          notify('error.notacsv', 'warning');
        }
        if (acceptedFiles.length) {
          handleFile(acceptedFiles[0]);
        }
      }
    },
    [handleFile, notify],
  );

  const [submitted, setSubmitted] = useState(false);
  const handleImportDataToDB = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      setSubmitted(true);

      (e?.currentTarget.dataset.variant === 'secondary' &&
      secondaryImportDataToDB
        ? secondaryImportDataToDB()
        : importDataToDB()
      ).then(() => {
        notify('import.done', 'info');
        if (lastCsvResult) {
          onCompleteCSVImportGeneric(lastCsvResult, undefined, true);
        }
      });
    },
    [
      importDataToDB,
      secondaryImportDataToDB,
      notify,
      lastCsvResult,
      onCompleteCSVImportGeneric,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({
    // ...options,
    // accept: 'text/csv',
    // maxSize,
    // minSize,
    multiple: false,
    onDrop: onFileDroped,
  });

  const [dataToImportError, dataToImportOK] = _partition(
    dataToImport,
    ({ processedFieldsError }) =>
      processedFieldsError && processedFieldsError.length > 0,
  );

  // Autosubmit if no error found
  useEffect(() => {
    if (
      !submitted && // Never autosubmit an already submitted (manual or auto) data => prevent infinite loop
      !local &&
      !loading && // No concurency on the loading state
      dataToImport &&
      errorsData?.length === 0 &&
      missingColumns.length === 0 &&
      dataToImportError.length === 0 &&
      !secondaryImportDataToDB // If there can be an other action, one cannot be chosen
    ) {
      handleImportDataToDB();
    }
  }, [
    submitted,
    local,
    loading,
    dataToImport,
    errorsData,
    handleImportDataToDB,
    secondaryImportDataToDB,
    missingColumns,
    dataToImportError,
  ]);

  return (
    <>
      <Title title={translate(`${pageKey}.title`)} />
      <Backdrop className={classes.backdrop} open={loading}>
        {loading &&
          (progress === null ? (
            <CircularProgress color="inherit" />
          ) : (
            <LinearProgress
              variant="determinate"
              value={progress * 100}
              className={classes.linearProgress}
            />
          ))}
      </Backdrop>
      <Card>
        <CardContent>
          <Typography variant="h3">
            {translate(`${pageKey}.importTitle`)}
          </Typography>
          {local && (
            <div className={classes.dropZone} {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography className={classes.dropzoneLabel}>
                Déposez le fichier à importer ou cliquez pour en sélectionner
              </Typography>
            </div>
          )}
          {s3FileState === S3FileState.NOT_FOUND && (
            <div>Fichier distant non trouvé ...</div>
          )}
          {errorsData && errorsData.length > 0 && (
            <div className={classes.dataSection}>
              <Typography variant="h3">
                {errorsData.length} Erreurs
                <Button
                  onClick={downloadErrors}
                  variant="outlined"
                  color="secondary"
                  className={classes.actionButton}
                >
                  Télécharger les erreurs
                </Button>
              </Typography>
              <GenerateTable
                fieldsInfos={importFieldsInfos}
                data={errorsData}
                className={classes.marginTop}
              />
            </div>
          )}
          {missingColumns.length > 0 && (
            <div className={classes.dataSection}>
              <Typography variant="h3">Colonnes manquantes :</Typography>
              <ul>
                {missingColumns.map(header => (
                  <Typography component="li" key={`missingColumn ${header}`}>
                    {header}
                  </Typography>
                ))}
              </ul>
            </div>
          )}
          {dataToImport && (
            <div className={classes.dataSection}>
              <Typography variant="h3">
                {dataToImport.length} {translate(`${pageKey}.importReady`)}
                {dataToImport.length > 0 && (
                  <>
                    <Button
                      onClick={handleImportDataToDB}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                    >
                      {translate(`${pageKey}.import`)}
                    </Button>
                    {secondaryImportDataToDB && (
                      <Button
                        data-variant="secondary"
                        onClick={handleImportDataToDB}
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                      >
                        {translate(`${pageKey}.secondaryImport`)}
                      </Button>
                    )}
                  </>
                )}
              </Typography>
              <div className={classes.usersCountSection}>
                <Typography
                  className={clsx(classes.usersCountText, {
                    [classes.usersCountErrorText]:
                      DbUsersCP.length !== ImportedValidUsersCP.size,
                  })}
                >
                  {ImportedValidUsersCP.size} agents / {DbUsersCP.length} de
                  l&apos;export original
                </Typography>
                {DbUsersCP.length !== ImportedValidUsersCP.size && (
                  <Typography className={classes.usersMissing}>
                    Manque:{' '}
                    {DbUsersCP.filter(
                      dbUserCp => !ImportedValidUsersCP.has(dbUserCp),
                    ).join(', ')}
                  </Typography>
                )}
              </div>
              {dataToImport.length > 0 ? (
                <>
                  {dataToImportError.length > 0 && (
                    <>
                      <Typography variant="h4" className={classes.marginTop}>
                        Données dont certaines valeurs ne correspondent pas à ce
                        à quoi l&apos;on devrait s&apos;attendre
                      </Typography>
                      <GenerateTable
                        fieldsInfos={fieldsInfos}
                        data={dataToImportError}
                        className={classes.marginTop}
                      />
                    </>
                  )}
                  {dataToImportOK.length > 0 && (
                    <GenerateTable
                      fieldsInfos={fieldsInfos}
                      data={dataToImportOK}
                      className={classes.marginTop}
                    />
                  )}
                </>
              ) : (
                <Typography>Aucune données à importer...</Typography>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}
