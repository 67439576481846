import omit from 'lodash/omit';

export default (props: object): object =>
  omit(props, [
    'allowEmpty',
    'alwaysOn',
    'basePath',
    'component',
    'defaultValue',
    'formClassName',
    'initialValue',
    'initializeForm',
    'input',
    'isRequired',
    'label',
    'limitChoicesToValue',
    'locale',
    'meta',
    'options',
    'optionText',
    'optionValue',
    'record',
    'resource',
    'source',
    'textAlign',
    'translate',
    'translateChoice',
  ]);
