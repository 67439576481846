import { Style } from '../types/styles';

export type EnumChoiceType<K = string> = {
  id: K;
  name: string;
  style?: Style;
};
export type EnumChoicesType<K = string> = EnumChoiceType<K>[];
export function mapEnum<K extends string, EK extends K>(
  enumObject: {
    [k in K]: string;
  },
  translateKey?: string,
  excludeKeys: Array<EK> = [],
): EnumChoicesType<K> {
  return (Object.keys(enumObject) as K[])
    .filter(key => !excludeKeys.includes(key as EK))
    .map(key => {
      const value = enumObject[key];
      return {
        id: key,
        name: translateKey ? `enums.${translateKey}.${value}` : value,
      };
    });
}
