import React, { useCallback, useState } from 'react';
import { useTranslate } from 'ra-core';
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { HtProposition, nodeIsProposition } from '../../../../types/ht/nodes';
import ConfirmDialog from '../../../structure/ConfirmDialog';

type PropositionDeleteButtonProps = {
  record?: HtProposition;
  onDelete?: (id: string) => void;
};

const PropositionDeleteButton: React.FC<PropositionDeleteButtonProps> = ({
  record: proposition,
  onDelete,
}: PropositionDeleteButtonProps) => {
  const translate = useTranslate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const handleClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      setShowConfirmDialog(true);
    },
    [setShowConfirmDialog],
  );
  const handleCancel = useCallback(() => {
    setShowConfirmDialog(false);
  }, [setShowConfirmDialog]);
  const handleConfirm = useCallback(() => {
    if (proposition && nodeIsProposition(proposition)) {
      setShowConfirmDialog(false);
      if (onDelete) {
        // Once deleted this component no longer exists
        onDelete(proposition.id);
      }
    }
  }, [proposition, onDelete]);

  if (!proposition || !nodeIsProposition(proposition)) {
    return null;
  }

  return (
    <>
      <Tooltip title={translate('resources.htProposition.delete')}>
        <IconButton onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={showConfirmDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        resource="htProposition"
      />
    </>
  );
};

export default PropositionDeleteButton;
