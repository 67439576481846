import moment from 'moment';
import _isObjectLike from 'lodash/isObjectLike';
import _cloneDeep from 'lodash/cloneDeep';
import {
  GQLHtNodeConnection,
  GQLHtRequest,
  GQLHtProposition,
  GQLHtAsset,
  gqlNodeIsResidence,
  gqlNodeIsRequest,
  gqlNodeIsProposition,
  gqlNodeIsAsset,
  HtNode,
  HtResidence,
  HtRequest,
  HtProposition,
  HtAsset,
  GQLHtNode,
  GQLHtResult,
  gqlResultIsNodeConnection,
  gqlResultIsNode,
} from '../../types/ht/nodes';
import {
  SimplifiedConnectionMany,
  SimplifiedConnectionOne,
} from '../../types/connections';
import { Decision, WFStatus } from '../../types/ht/enums';

function clearDeepTypenames<T extends object>(rawNode: T, root = true): T {
  const node = root ? _cloneDeep(rawNode) : rawNode;
  if (!_isObjectLike(node)) return node;
  Object.keys(node).forEach(key => {
    if (!root && key === '__typename') {
      delete (node as any)[key];
    } else {
      clearDeepTypenames((node as any)[key], false);
    }
  });
  return node;
}

function flattenConnectionMany(
  connectionEntry?: GQLHtNodeConnection,
): SimplifiedConnectionMany {
  return connectionEntry?.edges.map(({ node: { id: nodeId } }) => nodeId) ?? [];
}
function flattenConnectionOne(
  connectionEntry?: GQLHtNodeConnection,
): SimplifiedConnectionOne {
  return (
    (connectionEntry?.edges[0] ? connectionEntry?.edges[0].node.id : '') ?? ''
  );
}

function flattenRequest(request: GQLHtRequest): HtRequest {
  if (!request) return request;
  const flattenedPropositions = flattenConnectionMany(request.propositions);

  return {
    ...request,
    // Many `Proposition`s per `Request`
    propositions: flattenedPropositions,
    // Many `Asset` per `Request`
    attachments: flattenConnectionMany(request.attachments),
    nbPropositions: flattenedPropositions.length,
    nbRefusedPropositions:
      request.propositions?.edges.reduce((acc, { node: { decision } }) => {
        return decision && Decision[decision] === Decision.REFUS
          ? acc + 1
          : acc;
      }, 0) ?? 0,
    nbDays:
      (request.wfStatus &&
        [WFStatus.ANNULEE, WFStatus.RENONCEE, WFStatus.BAIL_SIGNE].includes(
          WFStatus[request.wfStatus],
        )) ||
      !request.htStartDate
        ? Infinity
        : moment(request.htStartDate).diff(moment(), 'days'),
  };
}
function flattenProposition(proposition: GQLHtProposition): HtProposition {
  if (!proposition) return proposition;
  return {
    ...proposition,
    // Only one `Request` per `Proposition`
    request: flattenConnectionOne(proposition.request),
  };
}
function flattenAsset(asset: GQLHtAsset): HtAsset {
  if (!asset) return asset;
  return {
    ...asset,
    // Only one `Request` per `Asset`
    request: flattenConnectionOne(asset.request),
  };
}

export function flattenQueryOneResult(rawNode: GQLHtNode): HtNode {
  const node = clearDeepTypenames(rawNode);

  if (gqlNodeIsResidence(node)) {
    return node as HtResidence;
  }
  if (gqlNodeIsRequest(node)) {
    return flattenRequest(node);
  }
  if (gqlNodeIsProposition(node)) {
    return flattenProposition(node);
  }
  if (gqlNodeIsAsset(node)) {
    return flattenAsset(node);
  }
  // Never
  return node as HtNode;
}

export function flattenQueryConnectionResult(
  nodeConnection?: GQLHtNodeConnection,
): HtNode[] {
  return (
    nodeConnection?.edges.map(({ node }) => flattenQueryOneResult(node)) ?? []
  );
}

// I don't understand why it has to have that many casts and type guards, but TS complains if not...
export function enhanceQueryResult<
  RecordType,
  T extends GQLHtResult | GQLHtNodeConnection,
>(result: T): T extends GQLHtNodeConnection ? RecordType[] : RecordType {
  if (gqlResultIsNodeConnection(result)) {
    return flattenQueryConnectionResult(result) as any;
  }
  if (gqlResultIsNode(result)) {
    return flattenQueryOneResult(result) as any;
  }
  return undefined as any;
}
