import {
  QUERY_ALL_MINO_USER,
  QUERY_ALL_MINO_REQUEST,
  QUERY_ALL_MINO_PAYMENT,
  QUERY_ALL_MINO_CONTRACT,
  QUERY_ALL_MINO_RENT,
  QUERY_ALL_MINO_ASSET,
} from '../../graphql/mino/queries';
import {
  MinoUserFields,
  MinoUserFieldsBase,
  MinoRequestFields,
  MinoRequestFieldsBase,
  MinoPaymentFields,
  MinoPaymentFieldsBase,
  MinoContractFields,
  MinoContractFieldsBase,
  MinoRentFields,
  MinoRentFieldsBase,
  MinoAssetFields,
  MinoAssetFieldsBase,
} from '../../graphql/mino/fragments';

import {
  GenericResourceInfos,
  GenericResourceConnectionInfo,
  GenericResourcesInfos,
} from '../resourcesInfos';

import { ResourceKey } from './resourcesKeys';

export type ResourceInfos = GenericResourceInfos<ResourceKey>;
export type ResourceConnectionInfo = GenericResourceConnectionInfo<ResourceKey>;

const minoUserInfos: ResourceInfos = {
  typename: 'User',
  dataId: 'User',
  fragment: {
    name: 'MinoUserFields',
    full: MinoUserFields,
    base: MinoUserFieldsBase,
  },
  query: {
    name: 'user',
    allList: QUERY_ALL_MINO_USER,
  },
  mutation: {
    transactionnal: true,
    dataInputGQLType: 'UserInput',
    createName: 'createUser',
    updateName: 'updateUser',
    deleteName: 'deleteUser',
  },
  connections: [
    {
      resource: 'minoRequest',
      key: 'requests',
      multiple: true,
    },
    {
      resource: 'minoContract',
      key: 'contracts',
      multiple: true,
    },
    {
      resource: 'minoPayment',
      key: 'payments',
      multiple: true,
    },
  ],
};
const minoRequestInfos: ResourceInfos = {
  typename: 'Request',
  dataId: 'Request',
  fragment: {
    name: 'MinoRequestFields',
    full: MinoRequestFields,
    base: MinoRequestFieldsBase,
  },
  query: {
    name: 'request',
    allList: QUERY_ALL_MINO_REQUEST,
  },
  mutation: {
    transactionnal: true,
    dataInputGQLType: 'RequestInput',
    createName: 'createRequest',
    updateName: 'updateRequest',
  },
  connections: [
    {
      resource: 'minoUser',
      key: 'user',
      multiple: false,
    },
    {
      resource: 'minoRent',
      key: 'rents',
      multiple: true,
    },
    {
      resource: 'minoAsset',
      key: 'attachments',
      multiple: true,
    },
  ],
};
const minoContractInfos: ResourceInfos = {
  typename: 'Contract',
  dataId: 'Contract',
  fragment: {
    name: 'MinoContractFields',
    full: MinoContractFields,
    base: MinoContractFieldsBase,
  },
  query: {
    name: 'contract',
    allList: QUERY_ALL_MINO_CONTRACT,
  },
  mutation: {
    transactionnal: true,
    dataInputGQLType: 'ContractInput',
    createName: 'createContract',
    updateName: 'updateContract',
  },
  connections: [
    {
      resource: 'minoUser',
      key: 'user',
      multiple: false,
    },
  ],
};
const minoPaymentInfos: ResourceInfos = {
  typename: 'Payment',
  dataId: 'Payment',
  fragment: {
    name: 'MinoPaymentFields',
    full: MinoPaymentFields,
    base: MinoPaymentFieldsBase,
  },
  query: {
    name: 'payment',
    allList: QUERY_ALL_MINO_PAYMENT,
  },
  mutation: {
    transactionnal: true,
    dataInputGQLType: 'PaymentInput',
    createName: 'createPayment',
    updateName: 'updatePayment',
    deleteName: 'deletePayment',
  },
  connections: [
    {
      resource: 'minoUser',
      key: 'user',
      multiple: false,
    },
  ],
};
const minoRentInfos: ResourceInfos = {
  typename: 'Rent',
  dataId: 'Rent',
  fragment: {
    name: 'MinoRentFields',
    full: MinoRentFields,
    base: MinoRentFieldsBase,
  },
  query: {
    name: 'rent',
    allList: QUERY_ALL_MINO_RENT,
  },
  mutation: {
    transactionnal: true,
    dataInputGQLType: 'RentInput',
    createName: 'createRent',
    updateName: 'updateRent',
  },
  connections: [
    {
      resource: 'minoRequest',
      key: 'request',
      multiple: false,
    },
  ],
};
const minoAssetInfos: ResourceInfos = {
  typename: 'Asset',
  dataId: 'Asset',
  isAsset: true,
  fragment: {
    name: 'MinoAssetFields',
    full: MinoAssetFields,
    base: MinoAssetFieldsBase,
  },
  query: {
    name: 'asset',
    allList: QUERY_ALL_MINO_ASSET,
  },
  mutation: {
    transactionnal: false,
    dataInputGQLType: 'AssetInput',
    createName: 'createAsset',
    updateName: 'updateAsset',
  },
  connections: [
    {
      resource: 'minoRequest',
      key: 'request',
      multiple: false,
    },
  ],
};

const resourcesInfos: GenericResourcesInfos<ResourceKey, ResourceInfos> = {
  minoUser: minoUserInfos,
  minoRequest: minoRequestInfos,
  minoContract: minoContractInfos,
  minoPayment: minoPaymentInfos,
  minoRent: minoRentInfos,
  minoAsset: minoAssetInfos,
};

export default resourcesInfos;
