import { ID, GQLNodeBase } from '../types/ht/base';
import { NodeUpdateInfo } from './ht/mutations';

export type NodeUpdate<Node extends Partial<GQLNodeBase> = GQLNodeBase> = {
  id: ID;
  node: Node;
  updateInfo: NodeUpdateInfo;
};

export enum UpdateType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
