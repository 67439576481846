import React from 'react';
import {
  SimpleForm,
  NumberInput,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
} from 'react-admin';
import { ReduxState, useTranslate } from 'ra-core';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import TextInput from '../../CustomInputs/TextInput';

import FormSection from '../../structure/FormSection';
import FormRow from '../../structure/FormRow';
import ContainerForward from '../../structure/ContainerForward';

import CoordinatesInput from '../../CustomInputs/CoordinatesInput';

import { filiereChoices } from '../../../utils/ht/enums';
import { Provider } from '../../../types/ht/enums';
import { HtResidence } from '../../../types/ht/nodes';

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
    },
  }),
);

type ResidenceEditToolbarProps = {
  record?: HtResidence;
  submitting?: boolean;
  readOnly?: boolean;
  basePath?: string;
  create: boolean;
};
const ResidenceEditToolbar: React.FC<ResidenceEditToolbarProps> = ({
  submitting = false,
  readOnly = false,
  create,
  ...props
}: ResidenceEditToolbarProps) => {
  const { basePath } = props;
  const classes = useToolbarStyles();
  const translate = useTranslate();

  return (
    <Toolbar {...props} className={classes.root}>
      <ContainerForward>
        <SaveButton
          label="ra.action.save"
          redirect="list"
          submitOnEnter={false}
          disabled={readOnly || submitting}
        />
        <>
          {!!basePath && (
            <Button
              component={RouterLink}
              to={basePath ?? ''}
              className={classes.cancelButton}
            >
              {translate('ra.action.cancel')}
            </Button>
          )}
        </>
      </ContainerForward>
      {!create && (
        <DeleteButton
          label="ra.action.delete"
          redirect="list"
          disabled={readOnly || submitting}
          undoable={false}
          confirmTitle={translate('message.confirm_delete_title', {
            name: translate(
              `resources.htResidence.name`,
              1,
            ).toLocaleLowerCase(),
          })}
        />
      )}
    </Toolbar>
  );
};

type ResidenceFormProps = {
  create?: boolean;
};

const ResidenceForm: React.FC<ResidenceFormProps> = (
  props: ResidenceFormProps,
) => {
  const { create = false, ...propsRest } = props;
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );

  return (
    <SimpleForm
      {...propsRest}
      redirect="list"
      // warnWhenUnsavedChanges
      toolbar={<ResidenceEditToolbar submitting={submitting} create={create} />}
      submitOnEnter={false}
    >
      <FormSection title="Identification">
        <FormRow>
          <TextInput source="code" validate={required()} />
          <TextInput source="name" validate={required()} />
        </FormRow>
      </FormSection>
      <FormSection title="Localisation">
        <FormRow>
          <TextInput source="streetAddress" />
        </FormRow>
        <FormRow>
          <TextInput source="postalCode" />
          <TextInput source="addressLevel2" validate={required()} />
        </FormRow>
        <FormRow>
          <CoordinatesInput validate={required()} />
        </FormRow>
      </FormSection>
      <FormSection title="Caractéristiques">
        <FormRow>
          <NumberInput
            source="distance"
            initialValue={50}
            validate={required()}
          />
          <NumberInput source="priority" validate={required()} />
        </FormRow>
        <FormRow>
          <SelectArrayInput
            source="filiere"
            choices={filiereChoices}
            validate={required()}
          />
        </FormRow>
      </FormSection>
      <>
        <Field
          name="provider"
          component="input"
          type="hidden"
          initialValue={Provider.PARME}
        />
      </>
    </SimpleForm>
  );
};

export default ResidenceForm;
