import React from 'react';
import {
  TextInput as RATextInput,
  TextInputProps as RATextInputProps,
} from 'react-admin';

import config from '../../config';

function TextInput(Component: typeof RATextInput): React.FC<RATextInputProps> {
  const res: React.FC<RATextInputProps> = props => {
    const { inputProps } = props;

    return (
      <Component
        {...props}
        inputProps={{
          ...inputProps,
          maxLength: inputProps?.maxLength || config.textFieldMaxLength,
        }}
      />
    );
  };
  return res;
}

export default TextInput(RATextInput);
