import React, { useCallback } from 'react';
import { useTranslate } from 'ra-core';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { HtProposition } from '../../../../types/ht/nodes';
import PropositionEditForm from './PropositionEditForm';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
  }),
);

type PropositionEditDialogProps = {
  proposition: HtProposition | null;
  onClose: () => void;
};

const PropositionEditDialog: React.FC<PropositionEditDialogProps> = ({
  proposition,
  onClose,
}: PropositionEditDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog fullWidth open={proposition !== null} onClose={handleClose}>
      <DialogTitle>{`${translate('resources.htProposition.name', 1)} ${
        proposition?.addressLevel2
      } - ${translate('resources.htResidence.name', 1)} ${
        proposition?.residenceName
      }`}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {!!proposition && (
          <PropositionEditForm
            propositionId={proposition.id}
            onClose={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PropositionEditDialog;
