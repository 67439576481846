import { Record } from 'react-admin';

import { GQLNodeBase, Coordinates, Geolocation, GQLTransaction } from './base';
import {
  WFStatus,
  Financement,
  Decision,
  Nationality,
  Marital,
  Reason,
  HonorificPrefix,
  SNCFStatus,
  SNCFEmployeeType,
  WFNext,
  Typology,
} from './enums';
import {
  Connection,
  SimplifyNodeConnectionMany,
  SimplifyNodeConnectionOne,
} from '../connections';

type AWSDate = string;

/** ********************
 * Résidence PARME
 */
export interface GQLHtResidence extends GQLNodeBase {
  __typename: 'Residence';

  /** Gestionnaire */
  provider?: string;
  /** Code PARME de la résidence */
  code?: string;
  /** Nom de la résidence */
  name?: string;
  /** Addresse */
  streetAddress?: string;
  /** Code postal */
  postalCode?: string;
  /** Ville */
  addressLevel2?: string;
  /** Distance d’éligibilité (km) */
  distance?: number;
  /** Plafond de ressources */
  filiere?: Financement[];
  /** Priorité */
  priority?: number;
  /** Coordonnees (GeoJSON Point) */
  coordinates?: Coordinates;
}
export type HtResidenceConnection = Connection<GQLHtResidence>;

export type HtAssetConnection = Connection<GQLHtAsset>;

/** ********************
 * Demande HT
 */
export interface GQLHtRequest extends GQLNodeBase {
  __typename: 'Request';

  /** Consentement RGPD - Une date plutot qu'une checkbox,  permettra de valider l'expiration
   */
  gdpr?: AWSDate;

  // # User
  /** Code CP (user ID) */
  sncfCP?: string;
  /** Civilité */
  honorificPrefix?: HonorificPrefix;
  email?: string;
  /** Prénom */
  givenName?: string;
  /** Nom */
  familyName?: string;
  /** Téléphone */
  tel?: string;
  /** Date de naissance */
  bday?: AWSDate;
  /** Nationalité */
  nationality?: Nationality;
  /** Situation familiale */
  maritalStatus?: Marital;

  // # Fiscal
  /** Revenu fiscal de référence (0-99999) */
  fiscalRevenue?: number;
  /** Salaire mensuel brut (1000-99999) */
  salary?: number;

  // # FID
  /** SA - SNCFCompany enum on creation */
  sncfCompany?: string;
  /** LPA (Site) ??? lieu de travail (use sncf_site) */
  sncfPostalCode?: string;
  /** Note: convert from special format... */
  sncfContractBeginDate?: AWSDate;
  sncfStatut?: SNCFStatus;
  sncfEmployeeType?: SNCFEmployeeType;

  // # HT Request
  /** Nombre d'occupants prévu */
  htOccupants?: number;
  /** Motif de la demande */
  htMotif?: Reason;
  /** Garant */
  htGarant?: boolean;
  /** Lieux souhaités - 5 max */
  htLocations?: Geolocation[];
  /** Date d’entrée souhaitée */
  htStartDate?: AWSDate;
  /** Date de fin de location prévisionnelle */
  htEndDate?: AWSDate;
  // # Admin only
  /** Voir remarque sur résidences - Algo d'affectation ? */
  htAttribution?: Financement;
  /** Typologie de logement */
  htTypology: Typology;

  comment?: string;

  /** Origin hostname */
  origin: string;

  // # Workflow fields
  /** Statut (workflow condition) */
  wfStatus?: WFStatus;
  /** Envoi notification (email/teams?) - non persistent? */
  wfNotify?: boolean;
  /** For now 'validated', 'updated' or 'done' */
  wfNext?: WFNext;

  /** Motif refus (string but MotifRefus enum in UI) */
  motifRefus: string;

  // # Connections
  /** Pièces jointes: Avis imposition + CSV export + ??? */
  attachments?: HtAssetConnection;
  /** Propositions */
  propositions?: HtPropositionConnection;
  transaction?: GQLTransaction;
}
export type HtRequestConnection = Connection<GQLHtRequest>;

/** ********************
 * Request Proposition
 */
export interface GQLHtProposition extends GQLNodeBase {
  __typename: 'Proposition';

  /** ID-LOGEMENT (15) */
  provId?: string;
  /** Date proposition */
  provDate?: AWSDate;
  /** Ville */
  addressLevel2?: string;
  /** Date décision demandeur */
  dateDecision?: AWSDate;
  /** Décision demandeur */
  decision?: Decision;
  /** Motif refus */
  motifRefus?: string;
  /** Date début bail */
  dateBail?: AWSDate;
  /** Gestionnaire */
  provider?: string;
  /** Code parme de la résidence */
  residenceCode?: string;
  /** Nom de la résidence */
  residenceName?: string;
  request?: HtRequestConnection;
}
export type HtPropositionConnection = Connection<GQLHtProposition>;

/** ********************
 * Request Asset
 */
export interface GQLHtAsset extends GQLNodeBase {
  __typename: 'Asset';

  /** File name */
  filename?: string;
  /** File size */
  size?: number;
  /** Mime type */
  type?: string;
  /** S3 Key (suffix only) */
  key?: string;
  request?: HtRequestConnection;
}

/** ********************
 * Node
 */
export type GQLHtNode =
  | GQLHtResidence
  | GQLHtRequest
  | GQLHtProposition
  | GQLHtAsset;
export type GQLHtNodeConnection = Connection<GQLHtNode>;
export type GQLHtResult = GQLHtNode | GQLHtNodeConnection;

/**
 * Residence | Request | Proposition | Asset
 *
 * Node with connections simplified to ID[] or ID
 */
export type HtNode = Record & {
  __typename: string;
  createdAt: string;
  updatedAt: string;
  version: number;
};

/**
 * Résidence PARME
 */
export type HtResidence = HtNode & GQLHtResidence;
/**
 * Demande HT
 *
 * GQLRequest with connections simplified to ID[]
 */
export type HtRequest = HtNode &
  SimplifyNodeConnectionMany<GQLHtRequest, 'propositions' | 'attachments'> & {
    nbPropositions: number;
    nbRefusedPropositions: number;
    nbDays: number;
  };
/**
 * Propositions aux demandes
 *
 * GQLProposition with connections simplified to ID
 */
export type HtProposition = HtNode &
  SimplifyNodeConnectionOne<GQLHtProposition, 'request'>;
/**
 * Fichiers joints aux demandes
 *
 * GQLAsset with connections simplified to ID
 */
export type HtAsset = HtNode & SimplifyNodeConnectionOne<GQLHtAsset, 'request'>;

/* *******************
 * GQL Result type guards
 */
export function gqlResultIsNodeConnection(
  result: GQLHtResult,
): result is GQLHtNodeConnection {
  return Array.isArray((result as GQLHtNodeConnection).edges);
}
export function gqlResultIsNode(result: GQLHtResult): result is GQLHtNode {
  return !Array.isArray((result as GQLHtNodeConnection).edges);
}
/* *******************
 * GQLNode type guards
 */
export function gqlNodeIsResidence(node: GQLHtNode): node is GQLHtResidence {
  return (node as GQLHtResidence).__typename === 'Residence';
}
export function gqlNodeIsRequest(node: GQLHtNode): node is GQLHtRequest {
  return (node as GQLHtRequest).__typename === 'Request';
}
export function gqlNodeIsProposition(
  node: GQLHtNode,
): node is GQLHtProposition {
  return (node as GQLHtProposition).__typename === 'Proposition';
}
export function gqlNodeIsAsset(node: GQLHtNode): node is GQLHtAsset {
  return (node as GQLHtAsset | HtAsset).__typename === 'Asset';
}
/* *******************
 * Node type guards
 */
export function nodeIsResidence(node: Record): node is HtResidence {
  return (node as HtResidence).__typename === 'Residence';
}
export function nodeIsRequest(node: Record): node is HtRequest {
  return (node as HtRequest).__typename === 'Request';
}
export function nodeIsProposition(node: Record): node is HtProposition {
  return (node as HtProposition).__typename === 'Proposition';
}
export function nodeIsAsset(node: Record): node is HtAsset {
  return (node as HtAsset).__typename === 'Asset';
}
