import gql from 'graphql-tag';

import { LogEventFields } from '../fragments';
import {
  HtResidenceFields,
  HtRequestFields,
  HtPropositionFields,
  HtAssetFields,
} from './fragments';
import { Connection } from '../../types/connections';
import {
  GQLHtResidence,
  GQLHtRequest,
  GQLHtProposition,
  GQLHtAsset,
} from '../../types/ht/nodes';

export type QUERY_ALL<T> = {
  allList: Connection<T>;
};

export const QUERY_ALL_HT_RESIDENCE = gql`
  query ($last: Int, $before: ID) {
    allList: allResidence(last: $last, before: $before)
      @connection(key: "residence") {
      edges {
        node {
          ...HtResidenceFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${HtResidenceFields}
`;

export const QUERY_ALL_HT_REQUEST = gql`
  query ($last: Int, $before: ID) {
    allList: allRequest(
      last: $last
      before: $before
      where: { status: PUBLISHED }
    ) @connection(key: "request") {
      edges {
        node {
          ...HtRequestFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${HtRequestFields}
`;

export const QUERY_ALL_HT_REQUEST_WITH_ARCHIVED = gql`
  query ($last: Int, $before: ID) {
    allList: allRequest(last: $last, before: $before)
      @connection(key: "request") {
      edges {
        node {
          ...HtRequestFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${HtRequestFields}
`;

export const QUERY_ALL_HT_PROPOSITION = gql`
  query ($last: Int, $before: ID) {
    allList: allProposition(last: $last, before: $before)
      @connection(key: "proposition") {
      edges {
        node {
          ...HtPropositionFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${HtPropositionFields}
`;

export const QUERY_ALL_HT_ASSET = gql`
  query ($last: Int, $before: ID) {
    allList: allAsset(last: $last, before: $before) @connection(key: "asset") {
      edges {
        node {
          ...HtAssetFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${HtAssetFields}
`;
export type QueryAllNode =
  | QUERY_ALL<GQLHtResidence>
  | QUERY_ALL<GQLHtRequest>
  | QUERY_ALL<GQLHtProposition>
  | QUERY_ALL<GQLHtAsset>;

export const QUERY_HISTORY = gql`
  query ($id: String!, $first: Int, $after: ID) {
    history(id: $id, first: $first, after: $after) {
      edges {
        node {
          ...LogEventFields
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
  ${LogEventFields}
`;
