import memoizeOne from 'memoize-one';
import moment from 'moment';

import { WFNext, WFStatus, TransactionState } from '../../types/ht/enums';
import { wfStatusChoices } from './enums';
import { EnumChoicesType } from '../enumsManip';
import {
  CREATE_ENUM,
  statusHasSubStates,
  statusWorkflowStates,
  StatusState,
  substatusHasBail,
  substatusHasNotBail,
} from './workflow';
import { Style } from '../../types/styles';
import { HtRequest, HtProposition } from '../../types/ht/nodes';
import { DeepPartial } from '../../types/utils';

/* **********
   WFNext
 */
export const isWfNextValidated = memoizeOne(
  (request?: HtRequest): boolean =>
    (request?.wfNext && WFNext[request.wfNext] === WFNext.VALIDATED) ?? false,
);
export const isWfNextDone = memoizeOne(
  (request?: HtRequest): boolean =>
    (request?.wfNext && WFNext[request.wfNext] === WFNext.DONE) ?? false,
);

/* **********
   WFStatus
 */
const { CREATE } = CREATE_ENUM;
const { VALID, OK, INVALID } = StatusState;
const stateStyles: { [K in StatusState]: Style } = {
  [VALID]: { fontWeight: 'bold' },
  [OK]: {},
  [INVALID]: { opacity: 0.3 },
};
export const getWfStatusChoices = memoizeOne(
  (
    request?: DeepPartial<HtRequest>,
    create = false,
    { hasBail }: { hasBail?: boolean } = {},
    additionnalWfStatus = null,
  ): EnumChoicesType => {
    if (!request) return [];
    const { wfNext = 'DONE', wfStatus } = request;
    const nextState = create ? CREATE : WFNext[wfNext];
    return wfStatusChoices.reduce((acc: EnumChoicesType, wfStatusChoice) => {
      const statusKey = wfStatusChoice.id;
      const status = WFStatus[statusKey];
      const states = statusWorkflowStates[status];
      const statusState = states[nextState];
      if (typeof statusState !== 'undefined') {
        if (statusHasSubStates(statusState)) {
          if (hasBail) {
            if (substatusHasBail(statusState)) {
              return [
                ...acc,
                { ...wfStatusChoice, style: stateStyles[statusState.hasBail] },
              ];
            }
          } else if (substatusHasNotBail(statusState)) {
            return [
              ...acc,
              { ...wfStatusChoice, style: stateStyles[statusState.hasNotBail] },
            ];
          }
        } else {
          return [
            ...acc,
            { ...wfStatusChoice, style: stateStyles[statusState] },
          ];
        }
      }
      if (
        (wfStatus && wfStatus === statusKey) ||
        (additionnalWfStatus && additionnalWfStatus === statusKey)
      ) {
        // Always keep current value as OK
        return [...acc, { ...wfStatusChoice, style: stateStyles[OK] }];
      }

      return acc;
    }, []);
  },
);

/* **********
   Propositions
 */
export const propositionsHasBail = memoizeOne(
  (propositions: HtProposition[]): boolean =>
    propositions.some(proposition => proposition.dateBail),
);

/* **********
   Transactionnal status
 */
export const isLocked = memoizeOne(
  (request?: HtRequest): boolean =>
    request?.transaction?.state === TransactionState.BEGIN ?? false,
);

export const isLongLocked = memoizeOne((request?: HtRequest): boolean => {
  const mNow = moment();
  return (
    isLocked(request) &&
    moment(request?.transaction?.begin ?? mNow).isSameOrBefore(
      mNow.subtract(10, 'minutes'),
    )
  );
});

export const isRollbacked = memoizeOne(
  (request?: HtRequest): boolean =>
    request?.transaction?.state === TransactionState.ROLLBACK ?? false,
);

export const getRollbackMessage = memoizeOne(
  (request?: HtRequest): string | false =>
    (isRollbacked(request) && request?.transaction?.message) ?? false,
);

/* **********
   Readonly state
 */

export const isReadonly = memoizeOne((request?: HtRequest): boolean =>
  isLocked(request),
);

export const isPropositionsReadonly = memoizeOne(
  (request?: HtRequest): boolean => isReadonly(request) ?? false,
);
