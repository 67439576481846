declare global {
  interface Navigator {
    msSaveOrOpenBlob: any;
  }
}

export const download = (
  payload: any,
  /** should include extension */ filename: string,
  /** may include charset */ type = 'text/csv;charset=utf-8',
  /** defaults to true */ forceDownload = true,
) => {
  const b = payload instanceof Blob ? payload : new Blob([payload], { type });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge - why not
    window.navigator.msSaveOrOpenBlob(b, filename);
  } else {
    const urlDownload = window.URL.createObjectURL(b);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = urlDownload;
    if (forceDownload) {
      a.download = filename;
    } else {
      a.target = '_blank';
    }
    document.body.appendChild(a);
    a.click();
    requestAnimationFrame(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlDownload);
    });
  }
};

export const visualize = (payload: File) =>
  download(payload, payload.name, undefined, false);

export default download;
