import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  NumberInput,
  BooleanInput,
  DateInput,
  SelectInput,
  LinearProgress,
  FormDataConsumer,
  required,
} from 'react-admin';
import {
  useDataProvider,
  useTranslate,
  useGetMany,
  CRUD_GET_MANY,
  useNotify,
} from 'ra-core';
import { useFormState, useForm, Field } from 'react-final-form';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import _compact from 'lodash/compact';

import eligible, { invalidEligibility } from 'padh-ht-eligibility';

import TextInput from '../../CustomInputs/TextInput';

import FormSection from '../../structure/FormSection';
import FormRow from '../../structure/FormRow';

import BooleanCheckboxInput from '../../CustomInputs/BooleanCheckboxInput';
import AssetInput from '../../CustomInputs/AssetInput';
import GeolocAutocompleteInput from '../../CustomInputs/GeolocAutocompleteInput';

import TooltipRGPD from '../../Tooltip/TooltipRGPD';

import {
  honorificPrefixChoices,
  htAttributionChoices,
  maritalStatusChoices,
  htMotifChoices,
  nationalityChoices,
  sncfStatutChoices,
  sncfCompanyChoices,
  sncfEmployeeTypeChoices,
  motifRefusChoices,
  htTypologyChoices,
} from '../../../utils/ht/enums';
import {
  getWfStatusChoices,
  isReadonly,
  propositionsHasBail,
} from '../../../utils/ht/request';
import { EnumChoiceType, EnumChoicesType } from '../../../utils/enumsManip';

import { ClassName } from '../../../types/styles';
import { HtResidence, HtRequest, HtProposition } from '../../../types/ht/nodes';
import { DeepPartial } from '../../../types/utils';
import { Rights } from '../../../authProvider/permissions';
import { SNCFStatus, WFStatus } from '../../../types/ht/enums';
import { CustomHTProvider } from '../../../dataProvider/dataProvider';

const sncfCompanyEnumChoices = sncfCompanyChoices.map(({ id }) => id);

const SNCFStatusesWithoutRevenues = [SNCFStatus.CC, SNCFStatus.CS];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {
      minWidth: 160,
    },
    dialogField: {
      width: '100%',
    },
    small: {
      flexGrow: 0,
      flexBasis: 'auto',
    },
    eligibility: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1),
    },
    validEligibility: {
      color: theme.palette.getContrastText(theme.palette.success.main),
      backgroundColor: theme.palette.success.main,
    },
    invalidEligibility: {
      color: theme.palette.getContrastText(theme.palette.error.main),
      backgroundColor: theme.palette.error.main,
    },
    resetButton: {
      marginTop: theme.spacing(2),
    },
  }),
);

// Bunch of props given by RA
type RequestFormBaseInputsProps = {
  create?: boolean;
  className?: ClassName;
  fullWidth?: boolean;
  record?: HtRequest;
  permissions?: Rights[];
};

const RequestFormBaseInputs: React.FC<RequestFormBaseInputsProps> = ({
  create = false,
  className,
  permissions,
  ...inputProps
}: RequestFormBaseInputsProps) => {
  const { record: request } = inputProps;
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const permAdminFields = permissions?.includes(Rights.EDIT_RESTRICTED);

  const dataProvider = useDataProvider<CustomHTProvider>();
  const [residences, setResidences] = useState<HtResidence[]>([]);
  useEffect(() => {
    dataProvider
      .getListAll('htResidence')
      .then(({ data }) => setResidences(data as HtResidence[]));
  }, [dataProvider]);

  const form = useForm();
  const { values: formStateValues } = useFormState();
  const requestValues = formStateValues as DeepPartial<HtRequest>;

  const { propositions: propositionsIds = [] } = requestValues;
  const { data: propositionsData, loading: propositionLoading } = useGetMany(
    'htProposition',
    propositionsIds,
    { action: CRUD_GET_MANY } as any,
  );
  const propositions = _compact(propositionsData) as HtProposition[];

  const hasBail = useMemo(
    () => propositionsHasBail(propositions),
    [propositions],
  );
  const readOnly = useMemo(() => isReadonly(request), [request]);
  const wfStatusRefinedChoices = useMemo(
    () =>
      getWfStatusChoices(requestValues, create, { hasBail }, request?.wfStatus),
    [requestValues, create, hasBail, request],
  );
  const loading = propositionLoading;

  // Fix `wfStatus` value according to bail status
  useEffect(() => {
    if (hasBail) {
      if (requestValues.wfStatus !== 'BAIL_SIGNE') {
        form.change('wfStatus', 'BAIL_SIGNE');
        notify(
          "Le statut BAIL_SIGNE a été sélectionné automatiquement mais n'a pas encore été validé. Veuillez enregistrer la demande",
          'warning',
        );
      }
    } else if (
      requestValues.wfStatus === 'BAIL_SIGNE' &&
      request &&
      request.wfStatus !== 'BAIL_SIGNE'
    ) {
      // Get back to initial status
      form.change('wfStatus', request.wfStatus);
    }
  }, [notify, form, hasBail, request, requestValues]);

  // Reset `motifRefus` if status is not REFUSEE
  useEffect(() => {
    if (
      requestValues.wfStatus !== WFStatus.REFUSEE &&
      requestValues.motifRefus
    ) {
      form.change(
        'motifRefus',
        // Force `null` only if db value is set to something relevant
        request?.wfStatus && request.wfStatus !== null ? null : undefined,
      );
    }
  }, [form, request, requestValues]);

  // Reset `fiscalRevenue` and `salary` if sncfStatus is not CS or CC
  useEffect(() => {
    if (
      requestValues.sncfStatut &&
      SNCFStatusesWithoutRevenues.includes(requestValues.sncfStatut)
    ) {
      form.change(
        'fiscalRevenue',
        // Force `null` only if db value is set to something relevant
        request?.fiscalRevenue && request.fiscalRevenue !== null
          ? null
          : undefined,
      );
      form.change(
        'salary',
        // Force `null` only if db value is set to something relevant
        request?.salary && request.salary !== null ? null : undefined,
      );
    }
  }, [form, request, requestValues]);

  // Reset `sncfCompany` if its value is not valid (anymore)
  useEffect(() => {
    if (
      requestValues.sncfCompany &&
      !sncfCompanyEnumChoices.includes(
        requestValues.sncfCompany as (typeof sncfCompanyEnumChoices)[number],
      )
    ) {
      form.change('sncfCompany', null);
    }
  }, [form, request, requestValues]);

  const optionRenderer = useCallback(
    (choice: EnumChoiceType) => {
      return loading ? (
        <LinearProgress style={{ width: '80%', marginLeft: '10%' }} />
      ) : (
        <span style={choice.style ?? {}}>{translate(choice.name)}</span>
      );
    },
    [loading, translate],
  );

  const formatInt = useCallback(
    (v: number): number | '' => (v ? Math.floor(v) : ''),
    [],
  );

  const handleClickReset = useCallback(() => {
    form.change('wfNext', 'VALIDATED');
    form.change('wfStatus', 'ATTENTE_ORIENTATION');
  }, [form]);
  const showResetButton = useMemo(
    () => !create && permAdminFields && requestValues.wfNext === 'DONE',
    [requestValues, create, permAdminFields],
  );

  const getFieldProps = useCallback(
    (name: string): { [k: string]: any } => {
      const fieldProps = {
        className: classes.field,
        disabled: readOnly,
      };

      if (name === 'sncfCP') {
        fieldProps.disabled =
          fieldProps.disabled || (!permAdminFields && !create);
      }
      if (name === 'wfStatus') {
        fieldProps.disabled = fieldProps.disabled || loading || hasBail;
      }
      if (name === 'wfNext') {
        fieldProps.className = clsx(fieldProps.className, classes.dialogField);
      }
      if (name === 'htGarant') {
        fieldProps.className = clsx(fieldProps.className, classes.small);
      }

      if (name !== 'comment') {
        fieldProps.disabled =
          fieldProps.disabled || requestValues?.wfNext === 'DONE';
      }

      return fieldProps;
    },
    [
      classes,
      readOnly,
      permAdminFields,
      create,
      loading,
      hasBail,
      requestValues,
    ],
  );

  return (
    <div className={classes.root}>
      <section className={classes.leftSide}>
        <FormSection className={classes.formSection}>
          <FormRow>
            <TextInput
              {...inputProps}
              source="sncfCP"
              {...getFieldProps('sncfCP')}
              validate={required()}
            />
            <div className={classes.field}>
              <FormDataConsumer>
                {({
                  formData,
                }: {
                  formData: DeepPartial<HtRequest>;
                }): React.ReactNode => {
                  const isEligible =
                    eligible({
                      request: formData as any,
                      residences,
                    }).length > 0;
                  return (
                    <div
                      className={clsx(classes.eligibility, {
                        [classes.validEligibility]: isEligible,
                        [classes.invalidEligibility]:
                          !isEligible && invalidEligibility(formData as any),
                      })}
                    >
                      <Typography>
                        {translate(
                          isEligible
                            ? 'resources.htRequest.eligibility.true'
                            : 'resources.htRequest.eligibility.false',
                        )}
                      </Typography>
                    </div>
                  );
                }}
              </FormDataConsumer>
            </div>
          </FormRow>
          <FormRow>
            <SelectInput
              {...inputProps}
              source="wfStatus"
              choices={wfStatusRefinedChoices}
              optionText={optionRenderer}
              translateChoice={false}
              initialValue={
                wfStatusRefinedChoices.length === 1
                  ? wfStatusRefinedChoices[0].id
                  : undefined
              }
              {...getFieldProps('wfStatus')}
            />
            <FormDataConsumer>
              {({
                formData,
              }: {
                formData: DeepPartial<HtRequest>;
              }): React.ReactNode => {
                if (formData.wfStatus !== WFStatus.REFUSEE) return null;
                return (
                  <SelectInput
                    {...inputProps}
                    source="motifRefus"
                    choices={motifRefusChoices}
                    {...getFieldProps('motifRefus')}
                    validate={required()}
                  />
                );
              }}
            </FormDataConsumer>
          </FormRow>
          <BooleanCheckboxInput
            {...inputProps}
            source="wfNotify"
            {...getFieldProps('wfNotify')}
          />
          <TooltipRGPD>
            <TextInput
              {...inputProps}
              source="comment"
              multiline
              minRows={10}
              maxRows={10}
              {...getFieldProps('comment')}
            />
          </TooltipRGPD>
          <AssetInput
            {...inputProps}
            source="attachments"
            multiple
            assetResource="htAsset"
            {...getFieldProps('attachments')}
          />
          {showResetButton && (
            <Button
              onClick={handleClickReset}
              variant="outlined"
              className={clsx(classes.field, classes.resetButton)}
            >
              Reset
            </Button>
          )}
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection
          title="Informations personnelles"
          className={classes.formSection}
        >
          <FormRow>
            <SelectInput
              {...inputProps}
              source="honorificPrefix"
              choices={honorificPrefixChoices}
              {...getFieldProps('honorificPrefix')}
              validate={required()}
            />
            <TextInput
              {...inputProps}
              source="familyName"
              {...getFieldProps('familyName')}
              validate={required()}
            />
            <TextInput
              {...inputProps}
              source="givenName"
              {...getFieldProps('givenName')}
              validate={required()}
            />
          </FormRow>
          <FormRow>
            <TextInput
              {...inputProps}
              source="email"
              type="email"
              {...getFieldProps('email')}
              validate={required()}
            />
            <TextInput
              {...inputProps}
              source="tel"
              type="phone"
              {...getFieldProps('tel')}
              validate={required()}
            />
          </FormRow>
          <FormRow>
            <DateInput
              {...inputProps}
              source="bday"
              {...getFieldProps('bday')}
              validate={required()}
            />
            <SelectInput
              {...inputProps}
              source="nationality"
              choices={nationalityChoices}
              {...getFieldProps('nationality')}
              validate={required()}
            />
            <SelectInput
              {...inputProps}
              source="maritalStatus"
              choices={maritalStatusChoices}
              {...getFieldProps('maritalStatus')}
              validate={required()}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Informations professionnelles">
          <FormRow>
            <SelectInput
              {...inputProps}
              source="sncfStatut"
              choices={sncfStatutChoices}
              {...getFieldProps('sncfStatut')}
              validate={required()}
            />
            <FormDataConsumer>
              {({
                formData,
                ...rest
              }: {
                formData: DeepPartial<HtRequest>;
              }): React.ReactNode => {
                const enhancedSncfEmployeeTypeChoices: EnumChoicesType = [
                  ...sncfEmployeeTypeChoices,
                ];
                if (
                  formData.sncfEmployeeType &&
                  !sncfEmployeeTypeChoices
                    .map(({ id }) => id)
                    .includes(formData.sncfEmployeeType)
                ) {
                  enhancedSncfEmployeeTypeChoices.push({
                    id: formData.sncfEmployeeType,
                    name: formData.sncfEmployeeType,
                  });
                }

                return (
                  <SelectInput
                    {...inputProps}
                    source="sncfEmployeeType"
                    choices={enhancedSncfEmployeeTypeChoices}
                    {...getFieldProps('sncfEmployeeType')}
                    {...rest}
                    validate={required()}
                  />
                );
              }}
            </FormDataConsumer>

            <DateInput
              {...inputProps}
              source="sncfContractBeginDate"
              {...getFieldProps('sncfContractBeginDate')}
              validate={required()}
            />
          </FormRow>
          <FormRow>
            <SelectInput
              {...inputProps}
              source="sncfCompany"
              choices={sncfCompanyChoices}
              {...getFieldProps('sncfCompany')}
              validate={required()}
            />
            <TextInput
              {...inputProps}
              source="sncfPostalCode"
              {...getFieldProps('sncfPostalCode')}
              validate={required()}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Informations fiscales">
          <FormRow>
            <SelectInput
              {...inputProps}
              source="htAttribution"
              choices={htAttributionChoices}
              {...getFieldProps('htAttribution')}
            />
            <FormDataConsumer>
              {({
                formData,
              }: {
                formData: DeepPartial<HtRequest>;
              }): React.ReactNode => {
                if (
                  formData.sncfStatut &&
                  SNCFStatusesWithoutRevenues.includes(formData.sncfStatut)
                ) {
                  return null;
                }

                return (
                  <>
                    <NumberInput
                      {...inputProps}
                      source="fiscalRevenue"
                      {...getFieldProps('fiscalRevenue')}
                      step={1}
                      format={formatInt}
                      parse={formatInt}
                      validate={required()}
                    />
                    <NumberInput
                      {...inputProps}
                      source="salary"
                      {...getFieldProps('salary')}
                      step={1}
                      format={formatInt}
                      parse={formatInt}
                      validate={required()}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
          </FormRow>
        </FormSection>
        <FormSection title="La demande">
          <FormRow>
            <NumberInput
              {...inputProps}
              source="htOccupants"
              {...getFieldProps('htOccupants')}
              step={1}
              format={formatInt}
              parse={formatInt}
              validate={required()}
            />
            <DateInput
              {...inputProps}
              source="htStartDate"
              {...getFieldProps('htStartDate')}
              validate={required()}
            />
            <DateInput
              {...inputProps}
              source="htEndDate"
              {...getFieldProps('htEndDate')}
            />
          </FormRow>
          <FormRow>
            <SelectInput
              {...inputProps}
              source="htMotif"
              choices={htMotifChoices}
              {...getFieldProps('htMotif')}
              validate={required()}
            />
            <SelectInput
              {...inputProps}
              source="htTypology"
              choices={htTypologyChoices}
              {...getFieldProps('htTypology')}
              validate={required()}
            />
          </FormRow>
          <FormRow>
            <GeolocAutocompleteInput
              {...inputProps}
              source="htLocations"
              {...getFieldProps('htLocations')}
              validate={required()}
            />
            <BooleanInput
              {...inputProps}
              source="htGarant"
              {...getFieldProps('htGarant')}
            />
          </FormRow>
        </FormSection>
      </section>
      {create && (
        <Field
          name="origin"
          component="input"
          type="hidden"
          initialValue={window.location.hostname}
        />
      )}
    </div>
  );
};

export default RequestFormBaseInputs;
