import React, { useCallback } from 'react';
import { SimpleForm, SaveButton, Toolbar, useGetOne } from 'react-admin';
import {
  useTranslate,
  useDataProvider,
  ReduxState,
  useNotify,
  CRUD_GET_ONE,
} from 'ra-core';
import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import RequestEditFormInputs from './RequestEditFormInputs';

import { ID } from '../../../../types/nodes';
import { Rights } from '../../../../authProvider/permissions';
import {
  GQLMinoMinoStatus,
  GQLMinoRequest,
} from '../../../../types/mino/schema';

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    readOnly: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.warning.main,
    },
  }),
);

type RequestEditButtonToolbarProps = {
  submitting?: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
  closedStatus?: boolean;
};
const RequestEditDialogToolbar: React.FC<RequestEditButtonToolbarProps> = ({
  submitting,
  onCancel,
  isReadOnly,
  closedStatus,
  ...props
}: RequestEditButtonToolbarProps) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();

  return (
    <Toolbar {...props} className={classes.root}>
      {isReadOnly ? <div /> : <SaveButton />}
      {closedStatus && (
        <span className={classes.readOnly}>
          {translate('resources.minoRequest.read_only')}
        </span>
      )}
      <>
        <Button onClick={onCancel}>
          {translate(isReadOnly ? 'ra.action.close' : 'ra.action.cancel')}
        </Button>
      </>
    </Toolbar>
  );
};

type RequestEditFormProps = {
  requestId: ID;
  onClose?: () => void;
  permissions?: Rights[];
};

const RequestEditForm: React.FC<RequestEditFormProps> = ({
  requestId,
  onClose,
  permissions,
}: RequestEditFormProps) => {
  const resource = 'minoRequest';

  const permAdminFields = permissions?.includes(Rights.EDIT_RESTRICTED);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );

  const {
    data: request,
    loading,
    loaded,
    error,
  }: {
    data?: GQLMinoRequest;
  } & { [k: string]: any } = useGetOne(resource, requestId, {
    action: CRUD_GET_ONE,
  });

  const closedStatuses = [
    GQLMinoMinoStatus.REFUSEE,
    GQLMinoMinoStatus.FIN_DROITS,
    GQLMinoMinoStatus.FIN_DROITS_INTERRUPTION,
  ];
  const closedStatus =
    request?.wfStatus && closedStatuses.includes(request.wfStatus);
  const canEditFields = request && (permAdminFields || !closedStatus);

  const handleSave = useCallback(
    values => {
      if (!request || !canEditFields) return;
      dataProvider
        .update(resource, {
          id: request.id,
          data: values,
          previousData: request,
        })
        .then(() => {
          notify('ra.notification.updated', 'info', 1);
          if (onClose) {
            onClose();
          }
        });
    },
    [dataProvider, request, canEditFields, notify, onClose],
  );

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  if (loading || !loaded || error || !request) return <div />;

  return (
    <SimpleForm
      record={request}
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <RequestEditDialogToolbar
          onCancel={handleCancel}
          submitting={submitting}
          isReadOnly={!canEditFields}
          closedStatus={closedStatus}
        />
      }
      resource={resource}
      submitOnEnter={false}
    >
      <RequestEditFormInputs record={request} disabled={!canEditFields} />
    </SimpleForm>
  );
};

export default RequestEditForm;
