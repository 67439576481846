import { getEnvironmentLevel, Level } from 'phicomas-client';

export const ENV_LEVEL: Level = getEnvironmentLevel();

export type AwsExports = {
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_appsync_graphqlEndpoint_ht: string;
  aws_appsync_graphqlEndpoint_mino: string;
  aws_appsync_region: string;
  aws_s3_region: string;
  aws_s3_bucket: string;
  aws_s3_prefix?: string;
  aws_s3_apiVersion: string;

  aws_s3_mino: {
    bucket: {
      [k in Level]: string;
    };
    key: {
      upload: {
        [k in Level]: string;
      };
    };
  };

  aws_cloudwatch_region: string;
  aws_cloudwatch_apiVersion: string;
  aws_cloudwatch_endpoint: string | undefined;
};

const awsExports: AwsExports = {
  // aws_user_pools_id: 'XX-XXXX-X_abcd1234',

  /* Cognito */
  aws_cognito_identity_pool_id: {
    [Level.DEV]: 'us-east-1:2c271a78-28f5-446d-92e1-9d1b9dee59e8',
    [Level.STAGE]: 'eu-west-1:ff5da156-5ded-43df-bfe0-4c25d86c9814',
    [Level.PROD]: 'eu-west-1:ff5da156-5ded-43df-bfe0-4c25d86c9814',
  }[ENV_LEVEL],
  aws_cognito_region: {
    [Level.DEV]: 'us-east-1',
    [Level.STAGE]: 'eu-west-1',
    [Level.PROD]: 'eu-west-1',
  }[ENV_LEVEL],
  /* AppSync */
  aws_appsync_graphqlEndpoint_ht: {
    [Level.DEV]:
      'https://rxkmwwmobrejvesmfn2p6dcvmm.appsync-api.us-east-1.amazonaws.com/graphql',
    [Level.STAGE]:
      'https://rchocgm3gjbd3kdxx2xmwljw4y.appsync-api.eu-west-1.amazonaws.com/graphql',
    [Level.PROD]:
      'https://7h46iamzr5bonijk5mgr4wo554.appsync-api.eu-west-1.amazonaws.com/graphql',
  }[ENV_LEVEL],
  aws_appsync_graphqlEndpoint_mino: {
    [Level.DEV]:
      'https://kltau5afg5gf5luns67o2cvpca.appsync-api.us-east-1.amazonaws.com/graphql',
    [Level.STAGE]:
      'https://yx3sul5nmza4jaufaimrvd24lm.appsync-api.eu-west-1.amazonaws.com/graphql',
    [Level.PROD]:
      'https://iyijdzpqybfhbjomcm4bf3ryam.appsync-api.eu-west-1.amazonaws.com/graphql',
  }[ENV_LEVEL],
  aws_appsync_region: {
    [Level.DEV]: 'us-east-1',
    [Level.STAGE]: 'eu-west-1',
    [Level.PROD]: 'eu-west-1',
  }[ENV_LEVEL],
  // aws_appsync_authenticationType: 'API_KEY', // 'AMAZON_COGNITO_USER_POOLS' | 'OPENID_CONNECT' | 'API_KEY' | ?,
  // aws_appsync_apiKey: 'da2-vbi56o5janerzjbp5v4zxicohq',

  /* S3 - FIXME: Should be a bucket config */
  aws_s3_region: {
    [Level.DEV]: 'eu-west-3',
    [Level.STAGE]: 'eu-west-3',
    [Level.PROD]: 'eu-west-3',
  }[ENV_LEVEL],
  aws_s3_bucket: {
    [Level.DEV]: 'userdata.padh.phileog.com',
    [Level.STAGE]: 'userdata.padh.sncf.fr',
    [Level.PROD]: 'userdata.padh.sncf.fr',
  }[ENV_LEVEL],
  aws_s3_apiVersion: '2006-03-01',

  aws_s3_mino: {
    bucket: {
      [Level.DEV]: 'rec-fluxbox.padh.sncf.fr',
      [Level.STAGE]: 'rec-fluxbox.padh.sncf.fr',
      [Level.PROD]: 'fluxbox.padh.sncf.fr',
    },
    key: {
      upload: {
        [Level.DEV]: 'padh-npf',
        [Level.STAGE]: 'padh-npf',
        [Level.PROD]: 'padh-npf',
      },
    },
  },

  aws_cloudwatch_region: {
    [Level.DEV]: 'us-east-1',
    [Level.STAGE]: 'eu-west-1',
    [Level.PROD]: 'eu-west-1',
  }[ENV_LEVEL],
  aws_cloudwatch_apiVersion: '2014-03-28',
  aws_cloudwatch_endpoint: {
    [Level.DEV]: undefined,
    [Level.STAGE]: undefined,
    [Level.PROD]: undefined, // apigw is BROKEN ! 'np1.logs.eu-west-1.padh.phileog.com', // note we don't need np2 since apigw is http2!
  }[ENV_LEVEL],
};

export default awsExports;
