import React, { useCallback, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  EditButton,
  Filter,
  TextInput,
  NumberInput,
  DateInput,
  SelectInput,
  BulkDeleteButton,
  TopToolbar,
  // CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  BulkDeleteButtonProps,
  Record,
} from 'react-admin';
import {
  useTranslate,
  useListContext,
  useDataProvider,
  DataProvider,
} from 'ra-core';
import { ListProps } from 'ra-core/esm/controller/useListController';
import { Backdrop, LinearProgress, useTheme } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Color from 'color';
import moment from 'moment';

import IconArchive from '@material-ui/icons/Archive';

import { Rights } from '../../../authProvider/permissions';

import { wfStatusChoices } from '../../../utils/mino/enums';

import usersExporter, {
  usersExporterWithArchived,
} from '../../../exporters/mino/user';

import { Style } from '../../../types/styles';
import { SMinoUser } from '../../../types/mino/nodes';
import config from '../../../config';
import { CustomMinoProvider } from '../../../dataProvider/dataProvider';

const UserBulkActionButtons: React.FC<BulkDeleteButtonProps> = props => {
  return <BulkDeleteButton {...props} undoable={false} />;
};

const Actions: React.FC<any> = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    permissions,
    setProgress,
    ...rest
  } = props;
  const permExport = permissions?.includes(Rights.EXPORT);

  const translate = useTranslate();
  const dataProvider = useDataProvider<DataProvider, CustomMinoProvider>();

  const tickCallback = useCallback(
    (n: number) => {
      setProgress(n);
    },
    [setProgress],
  );

  const usersExporterWithArchivedCb = useCallback(() => {
    if (permExport) {
      setProgress(0);
      usersExporterWithArchived(translate, dataProvider, tickCallback).finally(
        () => {
          setProgress(null);
        },
      );
    }
  }, [permExport, setProgress, translate, dataProvider, tickCallback]);

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    // hasCreate,
    basePath,
    // selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* <CreateButton basePath={basePath} /> // not implemented ! */}
      <ExportButton
        disabled={total === 0 || !permExport}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        disabled={total === 0 || !permExport}
        onClick={usersExporterWithArchivedCb}
        label="Exporter tout"
      >
        <IconArchive />
      </Button>
    </TopToolbar>
  );
};

const useFilterStyles = makeStyles<Theme>(() =>
  createStyles({
    input: {
      minWidth: 250,
    },
  }),
);

const UserFilter: React.FC = props => {
  const classes = useFilterStyles();
  return (
    <Filter {...props}>
      <TextInput source="sncfCP" alwaysOn className={classes.input} />
      <TextInput source="givenName" className={classes.input} />
      <TextInput source="familyName" alwaysOn className={classes.input} />
      <NumberInput source="nbRequests" className={classes.input} />
      <DateInput
        source="lastRequestDate"
        locales="fr-FR"
        className={classes.input}
      />
      <SelectInput
        source="lastRequestStatus"
        choices={wfStatusChoices}
        className={classes.input}
      />
      <NumberInput source="nbPayments" className={classes.input} />
      <DateInput source="minoEndDate" className={classes.input} />
      <NumberInput source="lastPaymentAmount" className={classes.input} />
      <TextInput source="lastRequestProvider" className={classes.input} />
    </Filter>
  );
};

const requestRowStyle =
  (theme: Theme) =>
  (record: Record, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.secondary.light).alpha(0.1).toString(),
    };
  };

const useStyles = makeStyles(theme =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.text.contrastText,
    },
    linearProgress: {
      width: '20vw',
      boxShadow: theme.shadows[4],
    },
    numOrDateCell: {
      textAlign: 'center',
      flexDirection: 'row',
    },
  }),
);

const UserList: React.FC<ListProps> = (props: ListProps) => {
  const { permissions } = props;
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const dataProvider = useDataProvider<DataProvider, CustomMinoProvider>();

  const permExport = permissions?.includes(Rights.EXPORT);
  const permEdit = permissions?.includes(Rights.MINO_WRITE);
  const canDelete = permissions?.includes(Rights.MINO_DELETE);

  const [progress, setProgress] = useState<number | null>(null);
  const tickCallback = (n: number) => {
    setProgress(n);
  };

  const usersExporterCb = useCallback(() => {
    if (permExport) {
      setProgress(0);
      usersExporter(translate, dataProvider, tickCallback).finally(() => {
        setProgress(null);
      });
    }
  }, [permExport, translate, dataProvider]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={progress !== null}>
        {progress !== null && (
          <LinearProgress
            // Usefull when archived users are being loaded (no way of knowing progress of it)
            variant={progress !== 1 ? 'determinate' : 'indeterminate'}
            value={progress * 100}
            className={classes.linearProgress}
          />
        )}
      </Backdrop>
      <List
        {...props}
        filters={<UserFilter />}
        sort={{ field: 'lastRequestDate', order: 'DESC' }}
        title={translate('resources.minoUser.list')}
        bulkActionButtons={canDelete ? <UserBulkActionButtons /> : false}
        exporter={permExport ? usersExporterCb : false}
        actions={
          <Actions permissions={permissions} setProgress={setProgress} />
        }
      >
        <Datagrid rowClick="edit" rowStyle={requestRowStyle(theme)}>
          <TextField source="sncfCP" />
          <TextField source="givenName" />
          <TextField source="familyName" />
          <NumberField
            source="nbRequests"
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <FunctionField
            source="lastRequestDate"
            render={(record?: Record): React.ReactNode => {
              const { lastRequestDate } = (record as SMinoUser) || {};
              if (!lastRequestDate) return null;
              return moment(lastRequestDate, config.awsDateFormat).format(
                'DD/MM/YYYY',
              );
            }}
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <FunctionField
            source="lastRequestStatus"
            render={(record?: Record): React.ReactNode => {
              const { lastRequestStatus } = (record as SMinoUser) || {};
              if (!lastRequestStatus) return null;
              return translate(`enums.wfStatus.${lastRequestStatus}`);
            }}
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <NumberField
            source="nbPayments"
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <FunctionField
            source="minoEndDate"
            render={(record?: Record): React.ReactNode => {
              const { minoEndDate } = (record as SMinoUser) || {};
              return minoEndDate
                ? moment(minoEndDate, config.awsDateFormat).format('MM/YYYY')
                : '';
            }}
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <NumberField
            source="lastPaymentAmount"
            cellClassName={classes.numOrDateCell}
            headerClassName={classes.numOrDateCell}
          />
          <TextField source="lastRequestProvider" />
          <EditButton label={permEdit ? 'ra.action.edit' : 'ra.action.show'} />
        </Datagrid>
      </List>
    </>
  );
};

export default UserList;
