import AWS, { CognitoIdentityCredentials } from 'aws-sdk';
import { AuthOptions } from 'aws-appsync-auth-link';

import awsExports from './aws-exports';
import {
  PADHCredentials,
  PADHError,
  refreshCredentials,
} from '../authProvider/phileog-login';

type Config = {
  url: string;
  region: string;
  auth: AuthOptions;
};

type Ready = {
  p: Promise<void>;
  fetching: boolean;
  done: boolean;
  res?: () => void;
  rej?: (reason?: any) => void;
};

const isCreds = (result: any): result is PADHCredentials => result?.sub;

class AWSAppSyncCredentials {
  config: Pick<Config, 'auth'>;

  credentials: CognitoIdentityCredentials | null;

  padhCreds: PADHCredentials | null = null;

  ready: Ready = AWSAppSyncCredentials.initReady();

  constructor() {
    this.credentials = null;
    this.config = {
      auth: {
        type: 'AWS_IAM',
        credentials: this.waitCredentials,
      },
      // disableOffline: true,
    };
    this.deleteCredentials();
  }

  static initReady(): Ready {
    const ready: Ready = { p: Promise.resolve(), fetching: false, done: false };
    ready.p = new Promise((res, rej) => {
      ready.res = res;
      ready.rej = rej;
    });
    // catch all
    ready.p.catch(() => {});
    return ready;
  }

  resolveReady(e?: any) {
    const { ready } = this;
    if (e) {
      this.ready = AWSAppSyncCredentials.initReady();
      ready.rej?.(e);
    }
    ready.fetching = false;
    ready.done = true;
    ready.res?.();
  }

  getCredentials = async (): Promise<CognitoIdentityCredentials> => {
    console.info('getting credentials');
    if (!this.credentials || this.credentials.needsRefresh()) {
      await this.setCredentials();
    }
    if (!this.credentials) throw new Error('missing creds');
    return this.credentials;
  };

  waitCredentials = async (): Promise<CognitoIdentityCredentials> => {
    try {
      await this.ready.p;
    } catch (e) {
      return this.waitCredentials();
    }
    return this.getCredentials();
  };

  async setCredentials(): Promise<void> {
    if (this.ready.fetching) return this.ready.p;
    if (this.ready.done) this.ready = AWSAppSyncCredentials.initReady();
    this.ready.fetching = true;
    const ls = localStorage.getItem('padhCredentials');
    const authResult: PADHCredentials | PADHError = ls && JSON.parse(ls);
    let refreshToken;
    if (isCreds(authResult)) refreshToken = authResult.refresh_token;
    const padhCreds = refreshToken
      ? await refreshCredentials(refreshToken)
      : { reason: 'No token' };
    if (isCreds(padhCreds)) {
      this.padhCreds = padhCreds;
      this.credentials = new AWS.CognitoIdentityCredentials(
        padhCreds.credentials,
        {
          region: awsExports.aws_cognito_region,
        },
      );
      await this.credentials.getPromise();
      this.resolveReady();
      // (<any>window).DBG = { credentials: this.credentials } // test with .expired = true, .expireTime = 0
    } else {
      this.deleteCredentials();
      const err = new Error(padhCreds?.reason ?? 'Unauthorized');
      this.resolveReady(err);
      throw err;
    }

    this.resolveReady();
    return undefined;
  }

  deleteCredentials(): void {
    this.credentials = null;
    this.padhCreds = null;
  }
}

export const awsAppSyncCredentials = new AWSAppSyncCredentials();

export const awsAppSyncHtConfig: Config = {
  ...awsAppSyncCredentials.config,
  url: awsExports.aws_appsync_graphqlEndpoint_ht,
  region: awsExports.aws_appsync_region,
};
export const awsAppSyncMinoConfig: Config = {
  ...awsAppSyncCredentials.config,
  url: awsExports.aws_appsync_graphqlEndpoint_mino,
  region: awsExports.aws_appsync_region,
};

export async function getAwsCloudWatchConfig(): Promise<{
  region: string;
  apiVersion: string;
  credentials?: AWS.CognitoIdentityCredentials;
  endpoint?: string;
}> {
  return {
    region: awsExports.aws_cloudwatch_region,
    apiVersion: awsExports.aws_cloudwatch_apiVersion,
    credentials: await awsAppSyncCredentials.waitCredentials(),
    endpoint: awsExports.aws_cloudwatch_endpoint,
  };
}
