import gql from 'graphql-tag';

import {
  MinoUserFields,
  MinoRequestFields,
  MinoPaymentFields,
  MinoContractFields,
  MinoRentFields,
  MinoAssetFields,
  MinoLogEventFields,
} from './fragments';
import {
  MinoUser,
  MinoRequest,
  MinoPayment,
  MinoContract,
  MinoAsset,
  MinoRent,
} from '../../types/mino/nodes';
import { QUERY_ALL } from '../queries';

export const QUERY_ALL_MINO_USER = gql`
  query ($last: Int, $before: ID) {
    allList: allUser(
      last: $last
      before: $before
      where: { status: PUBLISHED }
    ) @connection(key: "user") {
      edges {
        node {
          ...MinoUserFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoUserFields}
`;

export const QUERY_ALL_MINO_USER_WITH_ARCHIVED = gql`
  query ($last: Int, $before: ID) {
    allList: allUser(last: $last, before: $before) @connection(key: "user") {
      edges {
        node {
          ...MinoUserFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoUserFields}
`;

export const QUERY_ALL_MINO_REQUEST = gql`
  query ($last: Int, $before: ID) {
    allList: allRequest(last: $last, before: $before)
      @connection(key: "request") {
      edges {
        node {
          ...MinoRequestFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoRequestFields}
`;

export const QUERY_ALL_MINO_CONTRACT = gql`
  query ($last: Int, $before: ID) {
    allList: allContract(last: $last, before: $before)
      @connection(key: "contract") {
      edges {
        node {
          ...MinoContractFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoContractFields}
`;

export const QUERY_ALL_MINO_PAYMENT = gql`
  query ($last: Int, $before: ID) {
    allList: allPayment(last: $last, before: $before)
      @connection(key: "payment") {
      edges {
        node {
          ...MinoPaymentFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoPaymentFields}
`;

export const QUERY_ALL_MINO_RENT = gql`
  query ($last: Int, $before: ID) {
    allList: allRent(last: $last, before: $before) @connection(key: "rent") {
      edges {
        node {
          ...MinoRentFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoRentFields}
`;

export const QUERY_ALL_MINO_ASSET = gql`
  query ($last: Int, $before: ID) {
    allList: allAsset(last: $last, before: $before) @connection(key: "asset") {
      edges {
        node {
          ...MinoAssetFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${MinoAssetFields}
`;

export type QueryAll = QUERY_ALL<
  MinoUser | MinoRequest | MinoContract | MinoPayment | MinoRent | MinoAsset
>;

export const QUERY_MINO_HISTORY = gql`
  query ($id: String!, $first: Int, $after: ID) {
    history(id: $id, first: $first, after: $after) {
      edges {
        node {
          ...MinoLogEventFields
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
  ${MinoLogEventFields}
`;
