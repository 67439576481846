import { mapEnum } from '../enumsManip';

import {
  Financement,
  Status,
  WFStatus,
  HonorificPrefix,
  Marital,
  Reason,
  Nationality,
  SNCFStatus,
  SNCFEmployeeType,
  SNCFCompany,
  Decision,
  WFNext,
  HTMotifRefus,
  Typology,
} from '../../types/ht/enums';

export const wfNextChoices = mapEnum(WFNext, 'wfNext');

export const statusChoices = mapEnum(Status, 'status');

export const motifRefusChoices = mapEnum(HTMotifRefus, 'htMotifRefus');

export const honorificPrefixChoices = mapEnum(
  HonorificPrefix,
  'honorificPrefix',
);
export const maritalStatusChoices = mapEnum(Marital, 'maritalStatus');
export const nationalityChoices = mapEnum(Nationality, 'nationality');

export const sncfStatutChoices = mapEnum(SNCFStatus, 'sncfStatut');
export const sncfEmployeeTypeChoices = mapEnum(
  SNCFEmployeeType,
  'sncfEmployeeType',
  ['INT', 'EXT'],
);
export const sncfCompanyChoices = mapEnum(SNCFCompany, 'sncfCompany');

export const filiereChoices = mapEnum(Financement, 'filiere');
export const htAttributionChoices = mapEnum(Financement, 'htAttribution');

export const htMotifChoices = mapEnum(Reason, 'htMotif');
export const htTypologyChoices = mapEnum(Typology, 'htTypology');

export const wfStatusChoices = mapEnum(WFStatus, 'wfStatus');

export const decisionChoices = mapEnum(Decision, 'decision');
