import React from 'react';
import moment from 'moment';
import { usePermissions } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';

import { GQLMinoLogEvent } from '../../../../types/mino/schema';
import { Rights } from '../../../../authProvider/permissions';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);

type HistoryEntryProps = {
  logEvent: GQLMinoLogEvent;
};

const HistoryEntry: React.FC<HistoryEntryProps> = ({
  logEvent,
}: HistoryEntryProps) => {
  const classes = useStyles();
  const { permissions }: { permissions?: Rights[] } = usePermissions();

  const date = moment(logEvent.timestamp).format('ddd D MMM YYYY - HH:mm');
  const { message: logEventMessage } = logEvent;

  if (!logEventMessage) return null;

  const {
    action,
    connType,
    details,
    identity: identityRaw,
    wfStatus,
  } = logEventMessage;

  if (!action || !identityRaw) return null;

  const [actionType, actionVerb] = action.split(':');

  const identity = identityRaw.startsWith('arn:')
    ? identityRaw.split('/').reverse()[0]
    : identityRaw;

  const authorizedIdentity = permissions?.includes(Rights.HISTORY_ALL)
    ? ` [${identity}]`
    : '';

  let message = '';
  switch (actionType) {
    case 'mutation':
      switch (actionVerb) {
        case 'create':
          switch (connType) {
            case 'Request':
              message = `Création de demande`;
              break;
            case 'Contrat':
              message = 'Import contrat';
              break;
            case 'Rent':
              message = 'Import gestionnaire';
              break;
            case 'Payment':
              message = 'Import transaction';
              break;
            default:
              message = 'Création utilisateur';
          }
          break;
        case 'update':
          switch (connType) {
            case 'Request':
              message = `Mise à jour de demande${
                wfStatus ? `- ${wfStatus}` : ''
              }`;
              break;
            case 'Contrat':
              message = 'Import contrat mis à jour';
              break;
            case 'Rent':
              message = 'Import gestionnaire mis à jour';
              break;
            case 'Payment':
              message = 'Import transaction mis à jour';
              break;
            default:
              message = 'Mise à jour utilisateur';
          }
          break;
        case 'delete':
          message = `Suppression de resource`;
          break;
        default:
          message = `Changement effectué`;
      }
      break;
    case 'notify':
      message = `Email envoyé`;
      break;
    case 'error':
      message = `Erreur`;
      break;
    case 'commit':
      message = `Mise à jour enregistrée`;
      break;
    default:
      message = `${actionType}: ${actionVerb}`;
  }

  if (!message) return null;

  return (
    <Card className={classes.root}>
      <CardHeader title={date} />
      <CardContent>
        <Typography>
          {message}
          {details ? ` - ${details}` : null}
          {authorizedIdentity}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HistoryEntry;
