import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, ReduxState } from 'ra-core';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  resource: string;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  resource,
}: ConfirmDialogProps) => {
  const translate = useTranslate();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );
  const handleCancel = useCallback(
    event => {
      event.preventDefault();
      onClose();
    },
    [onClose],
  );
  const handleConfirm = useCallback(
    event => {
      event.preventDefault();
      onConfirm();
    },
    [onConfirm],
  );

  // Prevent click to propagate out of the dialog (on parent datagrid rowClick for example)
  const handleDialogClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <Dialog
      onClick={handleDialogClick}
      fullWidth
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle>
        {translate('message.confirm_delete_title', {
          name: translate(`resources.${resource}.name`, 1),
        })}
      </DialogTitle>
      <DialogContent>
        <Typography>{translate('ra.message.delete_content')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="primary"
          disabled={submitting}
        >
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={submitting}>
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
