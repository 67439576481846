import { Record } from 'ra-core';

import {
  Connection,
  SimplifyNodeConnectionMany,
  SimplifyNodeConnectionOne,
  OptionalConnections,
} from '../connections';
import {
  GQLMinoUser,
  GQLMinoRequest,
  GQLMinoContract,
  GQLMinoPayment,
  GQLMinoMinoStatus,
  GQLMinoRent,
  GQLMinoAsset,
} from './schema';
import { NodeBase } from '../nodes';

export type MinoNode = NodeBase;

/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoUser = MinoNode & OptionalConnections<GQLMinoUser>;
/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoRequest = MinoNode & OptionalConnections<GQLMinoRequest>;
/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoContract = MinoNode & OptionalConnections<GQLMinoContract>;
/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoPayment = MinoNode & OptionalConnections<GQLMinoPayment>;
/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoRent = MinoNode & OptionalConnections<GQLMinoRent>;
/** Connections are optionnal because they are not brought back on fragmentBases */
export type MinoAsset = MinoNode & OptionalConnections<GQLMinoAsset>;

export type MinoNodeConnection<Node extends MinoNode = MinoNode> =
  Connection<Node>;
export type MinoResult<Node extends MinoNode = MinoNode> =
  | Node
  | MinoNodeConnection<Node>;

export type SMinoNode = NodeBase;

export type SMinoUser = SMinoNode &
  SimplifyNodeConnectionMany<
    MinoUser,
    'requests' | 'contracts' | 'payments'
  > & {
    nbRequests: number;
    nbPayments: number;
    lastRequestDate: string | undefined;
    lastRequestStatus: GQLMinoMinoStatus | undefined;
    minoEndDate: string | undefined;
    lastPaymentAmount: number | undefined;
    lastRequestProvider: string | undefined;
  };

// Simplified Mino Request aka user, rents and attachments are "flattened" (!)
export type SMinoRequest = SMinoNode &
  SimplifyNodeConnectionMany<
    SimplifyNodeConnectionOne<MinoRequest, 'user'>,
    'rents' | 'attachments'
  >;
export type SMinoContract = SMinoNode &
  SimplifyNodeConnectionOne<MinoContract, 'user'>;
export type SMinoPayment = SMinoNode &
  SimplifyNodeConnectionOne<MinoPayment, 'user'>;
export type SMinoRent = SMinoNode &
  SimplifyNodeConnectionOne<MinoRent, 'request'>;
export type SMinoAsset = SMinoNode &
  SimplifyNodeConnectionOne<MinoAsset, 'request'>;

export type MinoNodeFromName<
  T extends 'User' | 'Request' | 'Contract' | 'Payment' | 'Rent' | 'Asset',
> = T extends 'User'
  ? MinoUser
  : T extends 'Request'
  ? MinoRequest
  : T extends 'Contract'
  ? MinoContract
  : T extends 'Payment'
  ? MinoPayment
  : T extends 'Rent'
  ? MinoRent
  : T extends 'Asset'
  ? MinoAsset
  : never;

export type MinoNodeFromResource<
  T extends
    | 'minoUser'
    | 'minoRequest'
    | 'minoContract'
    | 'minoPayment'
    | 'minoRent'
    | 'minoAsset',
> = T extends 'minoUser'
  ? MinoUser
  : T extends 'minoRequest'
  ? MinoRequest
  : T extends 'minoContract'
  ? MinoRequest
  : T extends 'minoPayment'
  ? MinoContract
  : T extends 'minoRent'
  ? MinoPayment
  : T extends 'minoAsset'
  ? MinoAsset
  : never;

/* *******************
 * GQLNode type guards
 */
export function gqlNodeIsUser(node: MinoNode): node is MinoUser {
  return (node as MinoUser).__typename === 'User';
}
export function gqlNodeIsRequest(node: MinoNode): node is MinoRequest {
  return (node as MinoRequest).__typename === 'Request';
}
export function gqlNodeIsContract(node: MinoNode): node is MinoContract {
  return (node as MinoContract).__typename === 'Contract';
}
export function gqlNodeIsPayment(node: MinoNode): node is MinoPayment {
  return (node as MinoPayment).__typename === 'Payment';
}
export function gqlNodeIsRent(node: MinoNode): node is MinoRent {
  return (node as MinoRent).__typename === 'Rent';
}
export function gqlNodeIsAsset(node: MinoNode): node is MinoAsset {
  return (node as MinoAsset).__typename === 'Asset';
}

/* *******************
 * Node type guards
 */
export function nodeIsUser(node: Record): node is SMinoUser {
  return (node as SMinoUser).__typename === 'User';
}
export function nodeIsRequest(node: Record): node is SMinoRequest {
  return (node as SMinoRequest).__typename === 'Request';
}
export function nodeIsContract(node: Record): node is SMinoContract {
  return (node as SMinoContract).__typename === 'Contract';
}
export function nodeIsPayment(node: Record): node is SMinoPayment {
  return (node as SMinoPayment).__typename === 'Payment';
}
export function nodeIsRent(node: Record): node is SMinoRent {
  return (node as SMinoRent).__typename === 'Rent';
}
export function nodeIsAsset(node: Record): node is SMinoAsset {
  return (node as SMinoAsset).__typename === 'Asset';
}

/**
 * GQL Result type guards
 */
export function gqlResultIsNodeConnection(
  result: MinoResult,
): result is MinoNodeConnection {
  return Array.isArray((result as MinoNodeConnection).edges);
}
export function gqlResultIsNode(result: MinoResult): result is MinoNode {
  return !Array.isArray((result as MinoNodeConnection).edges);
}

export function gqlResultIsUserNodeConnection(
  result: MinoResult,
): result is MinoNodeConnection<MinoUser> {
  return (
    gqlResultIsNodeConnection(result) &&
    result.edges.length > 0 &&
    gqlNodeIsUser(result.edges[0].node)
  );
}
