import moment from 'moment';
import { Record } from 'react-admin';

import {
  MinoNode,
  MinoUser,
  MinoRequest,
  MinoContract,
  MinoPayment,
  MinoRent,
  MinoAsset,
  SMinoNode,
  SMinoUser,
  SMinoRequest,
  SMinoContract,
  SMinoPayment,
  SMinoRent,
  SMinoAsset,
  MinoNodeConnection,
  MinoResult,
  gqlNodeIsUser,
  gqlNodeIsRequest,
  gqlNodeIsContract,
  gqlNodeIsPayment,
  gqlResultIsNode,
  gqlResultIsNodeConnection,
  gqlNodeIsRent,
  gqlNodeIsAsset,
} from '../../types/mino/nodes';
import {
  clearDeepTypenames,
  flattenConnectionMany,
  flattenConnectionOne,
} from '../flattener';
import {
  GQLMinoRequest,
  GQLMinoPayment,
  GQLMinoPaymentEdge,
  GQLMinoRequestEdge,
} from '../../types/mino/schema';

import config from '../../config';

function flattenUser(user: MinoUser): SMinoUser {
  if (!user) return user;

  const flattenedRequests = flattenConnectionMany<MinoNode>(user.requests);
  const flattenedContracts = flattenConnectionMany<MinoNode>(user.contracts);
  const flattenedPayments = flattenConnectionMany<MinoNode>(user.payments);

  let lastRequest: undefined | GQLMinoRequest;

  user.requests?.edges.forEach(({ node: request }: GQLMinoRequestEdge) => {
    if (request.createdAt) {
      const lastDate = moment(lastRequest?.createdAt ?? '');
      const reqDate = moment(request.createdAt);
      if (!lastDate.isValid() || reqDate.isAfter(lastDate)) {
        lastRequest = request;
      }
    }
  });

  const monthPayments = new Set();
  const [firstPayment, lastPayment, totalDeduction] =
    user.payments?.edges.reduce<
      [undefined | GQLMinoPayment, undefined | GQLMinoPayment, number]
    >(
      (lastFirst, paymentEdge: GQLMinoPaymentEdge) => {
        const [first, last, total] = lastFirst;
        let newFirst = first;
        let newLast = last;
        const payment = paymentEdge.node;
        if (payment.month) {
          monthPayments.add(payment.month);
          const paymentDate = moment(payment.month);
          const firstDate = moment(first?.month ?? '');
          if (!firstDate.isValid() || paymentDate.isBefore(firstDate)) {
            newFirst = payment;
          }
          const lastDate = moment(last?.month ?? '');
          if (!lastDate.isValid() || paymentDate.isAfter(lastDate)) {
            newLast = payment;
          }
        }
        return [newFirst, newLast, total + (payment.deduction || 0)];
      },
      [undefined, undefined, 0],
    ) ?? [undefined, undefined, 0];

  const nbPayments = monthPayments.size;
  const firstPaymentMonth = firstPayment && moment(firstPayment.month);
  return {
    ...user,
    requests: flattenedRequests,
    nbRequests: flattenedRequests.length,
    contracts: flattenedContracts,
    payments: flattenedPayments,
    nbPayments,
    lastRequestDate: lastRequest?.createdAt,
    lastRequestStatus: lastRequest?.wfStatus,
    minoEndDate:
      firstPaymentMonth && firstPaymentMonth.isValid()
        ? firstPaymentMonth
            .add(12, 'months')
            .subtract(1, 'day')
            .format(config.awsDateFormat)
        : undefined,
    lastPaymentAmount: lastPayment?.deduction, // Last payements
    totalPaymentAmount: totalDeduction,
    lastRequestProvider: lastRequest?.provider, // in request
  };
}

function flattenRequest(request: MinoRequest): SMinoRequest {
  if (!request) return request;
  const flattenedUser = flattenConnectionOne<MinoNode>(request.user);
  const flattenedRents = flattenConnectionMany<MinoNode>(request.rents);
  const flattenedAttachments = flattenConnectionMany<MinoNode>(
    request.attachments,
  );

  return {
    ...request,
    user: flattenedUser,
    rents: flattenedRents,
    attachments: flattenedAttachments,
  };
}

function flattenContract(contract: MinoContract): SMinoContract {
  if (!contract) return contract;
  const flattenedUser = flattenConnectionOne<MinoNode>(contract.user);

  return {
    ...contract,
    user: flattenedUser,
  };
}

function flattenPayment(payment: MinoPayment): SMinoPayment {
  if (!payment) return payment;
  const flattenedUser = flattenConnectionOne<MinoNode>(payment.user);

  return {
    ...payment,
    user: flattenedUser,
  };
}

function flattenRent(rent: MinoRent): SMinoRent {
  if (!rent) return rent;
  const flattenedRequest = flattenConnectionOne<MinoNode>(rent.request);

  return {
    ...rent,
    request: flattenedRequest,
  };
}

function flattenAsset(asset: MinoAsset): SMinoAsset {
  if (!asset) return asset;
  const flattenedRequest = flattenConnectionOne<MinoNode>(asset.request);

  return {
    ...asset,
    request: flattenedRequest,
  };
}

export function flattenQueryOneResult(rawNode: MinoNode): SMinoNode {
  const node = clearDeepTypenames(rawNode);

  if (gqlNodeIsUser(node)) {
    return flattenUser(node);
  }
  if (gqlNodeIsRequest(node)) {
    return flattenRequest(node);
  }
  if (gqlNodeIsContract(node)) {
    return flattenContract(node);
  }
  if (gqlNodeIsPayment(node)) {
    return flattenPayment(node);
  }
  if (gqlNodeIsRent(node)) {
    return flattenRent(node);
  }
  if (gqlNodeIsAsset(node)) {
    return flattenAsset(node);
  }
  // Never
  return node as SMinoNode;
}

export function flattenQueryConnectionResult<F extends (...args: any) => any>(
  nodeConnection?: MinoNodeConnection,
  flattener?: F,
): ReturnType<F>[] {
  return (
    nodeConnection?.edges.map(({ node }) =>
      flattener ? flattener(node) : node,
    ) ?? []
  );
}

// I don't understand why it has to have that many casts and type guards, but TS complains if not...
export function enhanceQueryResult<
  T extends MinoResult,
  RecordType extends Record = MinoNode,
>(result: T): T extends MinoNodeConnection ? RecordType[] : RecordType {
  if (gqlResultIsNodeConnection(result)) {
    return flattenQueryConnectionResult(result, flattenQueryOneResult) as any;
  }
  if (gqlResultIsNode(result)) {
    return flattenQueryOneResult(result) as any;
  }
  return undefined as any;
}
