import React from 'react';
import {
  LoginComponent,
  useLogin,
  // useNotify,
  // Notification
} from 'react-admin';
import { ThemeProvider, Button } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const LoginPage: LoginComponent = ({ theme }) => {
  const login = useLogin();

  if (!theme) return null;

  /*
  const loginButtons = [
    { realUser: 'Benjamin', username: 'PBDE11181', roles: [Roles.ADMIN] },
    { realUser: 'José', username: 'PJQS11641', roles: [Roles.ADMIN] },
    {
      realUser: 'Moussa',
      username: '7804527G',
      roles: [Roles.HT_VIEWER, Roles.MINO_VIEWER],
    },
    {
      realUser: 'Julien',
      username: '8202428H',
      roles: [Roles.MINO_VIEWER],
    },
    {
      username: 'ht',
      roles: [Roles.HT_EDITOR],
    },
  ];
  */

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={login}
          variant="outlined"
          color="primary"
          style={{
            margin: 2,
          }}
        >
          Login with SNCF
        </Button>
      </div>

      {/* <Notification /> */}
    </ThemeProvider>
  );
};

export default LoginPage;
