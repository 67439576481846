import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  withStyles,
} from '@material-ui/core';

import { Classes, ClassesStyles } from '../types/styles';

const styles = (theme: Theme): ClassesStyles => ({
  error: {
    padding: theme.spacing(2),
    background: 'rgba(255, 0, 0, 0.2)',
  },
  errorDialog: {
    background: theme.palette.error.main,
  },
});

type ErrorBoundaryProps = React.PropsWithChildren<{
  classes: Classes;
}>;
type ErrorBoundaryState = { error: Error | null; dialogOpen: boolean };

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error, dialogOpen: !!error };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, dialogOpen: false };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleCloseDialog(): void {
    this.setState({ dialogOpen: false });
  }

  render(): JSX.Element {
    const { classes, ...props } = this.props;
    const { children } = props;

    const { error, dialogOpen } = this.state;

    if (error) {
      return (
        <>
          <Dialog
            open={dialogOpen}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-error-title"
            aria-describedby="alert-dialog-error-description"
            maxWidth="xl"
            classes={{ paper: classes.errorDialog }}
          >
            <DialogTitle id="alert-dialog-error-title">
              An error occured
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-error-description">
                Thank you to contact your administrator.
              </DialogContentText>
              <pre>{error.message}</pre>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog}>Ok</Button>
            </DialogActions>
          </Dialog>

          <div className={classes.error}>
            <h2>An error occured</h2>
            <pre>{error.message}</pre>
          </div>
        </>
      );
    }

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });

    return <>{childrenWithProps}</>;
  }
}

export default withStyles(styles)(ErrorBoundary);
