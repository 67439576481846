import { Translate } from 'ra-core';

import { XOR } from '../types/utils';

export type FieldEntry = {
  resource: string;
  field: string;
  formatDate?: boolean;
  anonymiseArchive?: boolean;
};
export type EnumEntry = {
  enumName: string;
  value: string;
};
export type Entry = XOR<FieldEntry, EnumEntry>;
export type Columns = Array<Entry>;

export function getHeaders(columns: Columns, translate: Translate): string[] {
  const headersStack: { [k: string]: number } = {};
  return columns.reduce<string[]>((acc, column) => {
    if (column.resource) {
      const { resource, field } = column;

      const nbHomologousHeaders = columns.filter(
        c => c.resource === resource && c.field === field,
      ).length;
      const header = translate(`resources.${resource}.fields.${field}`);

      if (nbHomologousHeaders > 1) {
        headersStack[header] = headersStack[header]
          ? headersStack[header] + 1
          : 1;
        acc.push(`${header} ${headersStack[header]}`);
      } else {
        acc.push(header);
      }
    } else if (column.enumName) {
      const { enumName, value } = column;

      const nbHomologousHeaders = columns.filter(
        c => c.enumName === enumName && c.value === value,
      ).length;
      const header = translate(`enums.${enumName}.${value}`);

      if (nbHomologousHeaders > 1) {
        headersStack[header] = headersStack[header]
          ? headersStack[header] + 1
          : 1;
        acc.push(`${header} ${headersStack[header]}`);
      } else {
        acc.push(header);
      }
    }
    return acc;
  }, []);
}

export function getResourceFields(
  columns: Columns,
  resource: string,
): Map<string, FieldEntry> {
  return columns.reduce<Map<string, FieldEntry>>((acc, entry) => {
    if (entry.resource === resource) {
      acc.set(entry.field, entry);
    }
    return acc;
  }, new Map());
}
