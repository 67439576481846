import React from 'react';
import { Edit, EditProps } from 'react-admin';
import { useTranslate } from 'ra-core';

import ResidenceForm from './ResidenceForm';

import { HtResidence } from '../../../types/ht/nodes';

type ResidenceTitleProps = {
  record?: HtResidence;
};

const ResidenceTitle: React.FC<ResidenceTitleProps> = ({
  record,
}: ResidenceTitleProps) => {
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.htResidence.name', { smart_count: 1 })}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

type ResidenceEditProps = EditProps & {};

const ResidenceEdit: React.FC<ResidenceEditProps> = (
  props: ResidenceEditProps,
) => {
  return (
    <Edit {...props} title={<ResidenceTitle />} undoable={false}>
      <ResidenceForm />
    </Edit>
  );
};

export default ResidenceEdit;
