import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import _findKey from 'lodash/findKey';
import memoizeOne from 'memoize-one';

import { QueryAll } from './queries';
import { Edge } from '../types/connections';
import { UpdateType, NodeUpdate } from './mutations';

import { GenericResourceKey } from '../dataProvider/resourcesKeys';
import { NodeBase, ID } from '../types/nodes';
import {
  GenericResourcesInfos,
  resourceInfosHasConnections,
  GenericResourceConnectionInfo,
} from '../dataProvider/resourcesInfos';

// TODO: Memoize
function getNode<ResourceKey extends GenericResourceKey = GenericResourceKey>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
  resource: GenericResourceKey<ResourceKey>,
  id: ID,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<NodeBase> {
  const resourceInfos = resourcesInfos[resource];
  let nodeFragmentData;
  try {
    nodeFragmentData = client.readFragment<NodeBase>({
      id: `${resourceInfos.typename}:${id}`,
      fragment: resourceInfos.fragment.full,
      fragmentName: resourceInfos.fragment.name,
    });
  } catch (e) {
    // it fails if the fragment is in the cache, but not the connections
  }

  if (!nodeFragmentData) {
    return client
      .query<{ [k: string]: NodeBase }>({
        query: gql`
          query {
            ${resourceInfos.query.name}(id: "${id}") {
              ...${resourceInfos.fragment.name}
            }
          }
          ${resourceInfos.fragment.full}
        `,
      })
      .then(response => {
        return Object.values(response.data)[0];
      });
  }
  return Promise.resolve(nodeFragmentData);
}

/**
 * Add a connection to the specified node
 * @param param0 Updated node (resource and id)
 * @param param1 Connection to add to node above (key and id)
 * @param client Apollo client
 * @returns true if store was updated
 */
async function addConnectionToNode<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
  { resource, id }: { resource: ResourceKey; id: ID },
  {
    connectionKey,
    connectionId,
  }: {
    connectionKey: GenericResourceConnectionInfo<ResourceKey>['key'];
    connectionId: ID;
  },
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ resource: string; id: ID } | undefined> {
  const resourceInfos = resourcesInfos[resource];
  if (!resourceInfosHasConnections(resourceInfos)) return undefined;
  const connectionInfos = resourceInfos.connections.find(
    cInfos => cInfos.key === connectionKey,
  );
  if (!connectionInfos) return undefined;

  return getNode(
    resourcesInfos,
    connectionInfos.resource,
    connectionId,
    client,
  ).then(connectedNode => {
    // Update the node's field with the id
    let nodeFragmentData;
    try {
      nodeFragmentData = client.readFragment<NodeBase>({
        id: `${resourceInfos.typename}:${id}`,
        fragment: resourceInfos.fragment.full,
        fragmentName: resourceInfos.fragment.name,
      });
    } catch (e) {
      // fragment found, but missing fields (presumably connections) - ignore
    }
    if (nodeFragmentData) {
      // ... on it's fragment ...

      const { edges } = (nodeFragmentData as any)[connectionInfos.key];
      // The node could already be there
      if (edges.some((e: Edge<NodeBase>) => e.node.id === connectedNode.id))
        return undefined;

      client.writeFragment({
        id: `${resourceInfos.typename}:${id}`,
        fragment: resourceInfos.fragment.full,
        fragmentName: resourceInfos.fragment.name,
        data: {
          ...nodeFragmentData,
          [connectionInfos.key]: {
            ...(nodeFragmentData as any)[connectionInfos.key],
            edges: [
              ...edges,
              // ... add the new node
              {
                __typename: `${
                  resourcesInfos[connectionInfos.resource].typename
                }Edge`,
                node: connectedNode,
              },
            ],
          },
        },
      });
      return { resource, id };
    }
    return undefined;
  });
}

async function removeConnectionFromNode<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
  { resource, id }: { resource: ResourceKey; id: ID },
  {
    connectionKey,
    connectionId,
  }: {
    connectionKey: GenericResourceConnectionInfo<ResourceKey>['key'];
    connectionId: ID;
  },
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ resource: string; id: ID } | undefined> {
  const resourceInfos = resourcesInfos[resource];
  if (!resourceInfosHasConnections(resourceInfos)) return undefined;
  const connectionInfos = resourceInfos.connections.find(
    cInfos => cInfos.key === connectionKey,
  );
  if (!connectionInfos) return undefined;

  // Update the node's field with the id
  let nodeFragmentData;
  try {
    nodeFragmentData = client.readFragment<NodeBase>({
      id: `${resourceInfos.typename}:${id}`,
      fragment: resourceInfos.fragment.full,
      fragmentName: resourceInfos.fragment.name,
    });
  } catch (e) {
    // fragment found, but missing fields (presumably connections) - ignore
    return undefined;
  }

  if (nodeFragmentData) {
    // ... on it's fragment ...

    const { edges } = (nodeFragmentData as any)[connectionInfos.key];
    // The node could already be removed
    if (!edges.some((e: Edge<NodeBase>) => e.node.id === connectionId))
      return undefined;

    client.writeFragment({
      id: `${resourceInfos.typename}:${id}`,
      fragment: resourceInfos.fragment.full,
      fragmentName: resourceInfos.fragment.name,
      data: {
        ...nodeFragmentData,
        [connectionInfos.key]: {
          ...(nodeFragmentData as any)[connectionInfos.key],
          edges: (nodeFragmentData as any)[connectionInfos.key].edges.filter(
            (connectedEdge: Edge<NodeBase>) =>
              connectedEdge.node.id !== connectionId,
          ),
        },
      },
    });
    return { resource, id };
  }
  return undefined;
}

type ReverseFields<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
> = {
  [r in ResourceKey]: { [res in ResourceKey /** other field */]: string };
};
/** Index other fields by resources / other resource */
const reverseFieldsM = memoizeOne(
  <ResourceKey extends GenericResourceKey = GenericResourceKey>(
    resourcesInfos: GenericResourcesInfos<ResourceKey>,
  ) =>
    Object.keys(resourcesInfos).reduce<Partial<ReverseFields<ResourceKey>>>(
      (infos, res) => {
        const resource = res as unknown as ResourceKey;
        const resourceInfos = resourcesInfos[resource];
        let others = {};
        if (resourceInfosHasConnections(resourceInfos)) {
          others = resourceInfos.connections.reduce((conn, c) => {
            const otherResource = c.resource;
            const otherResourceInfo = resourcesInfos[otherResource];
            // FIXME - this only finds the first one...
            const otherConnection = otherResourceInfo.connections?.find(
              oc => oc.resource === resource,
            );
            if (!otherConnection) return conn;
            return { ...conn, [c.resource]: otherConnection.key };
          }, {});
        }

        return {
          ...infos,
          [resource]: others,
        };
      },
      {},
    ) as ReverseFields<ResourceKey>,
);

export type CacheUpdateResult = {
  [k: string]: {
    // list?: true;
    [k: string]: true | undefined;
  };
};

/**
 * Manual Apollo cache update
 * @param update
 * @param client
 * @returns map of resources / id of resource (note: current resource list is always updated for free...)
 */
export async function updateStore<
  ResourceKey extends GenericResourceKey = GenericResourceKey,
  Node extends Partial<NodeBase> = NodeBase,
>(
  resourcesInfos: GenericResourcesInfos<ResourceKey>,
  update: NodeUpdate<Node>,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<CacheUpdateResult> {
  const resource = _findKey(
    resourcesInfos,
    rI => rI.typename === update.node.__typename,
  ) as ResourceKey | undefined;
  if (!resource) return {};
  const { id: nodeId, updateInfo } = update;

  const result: CacheUpdateResult = { [resource]: { [nodeId]: true } };
  const resourceInfos = resourcesInfos[resource];
  const connectionByField = (resourceInfos.connections ?? []).reduce<{
    [k: string]: GenericResourceConnectionInfo<ResourceKey>;
  }>((r, c) => ({ ...r, [c.key]: c }), {});
  // if (!resourceInfosHasConnections(resourceInfos)) return; // no, we still need to handle create and delete

  const reverseFields = reverseFieldsM<ResourceKey>(resourcesInfos);

  // Connect and disconnect to node
  const { connect, disconnect } = updateInfo;
  const connected = await Promise.all(
    connect.map(({ field, id }) => {
      const otherConnection = connectionByField[field];
      const { resource: otherResource } = otherConnection;
      const rev = reverseFields[resource];
      const otherField = rev[otherResource];
      return Promise.all([
        // Ours
        addConnectionToNode(
          resourcesInfos,
          { resource, id: nodeId },
          { connectionKey: field, connectionId: id },
          client,
        ),
        // Theirs
        addConnectionToNode(
          resourcesInfos,
          { resource: otherResource, id },
          { connectionKey: otherField, connectionId: nodeId },
          client,
        ),
      ]);
    }),
  );
  const disconnected = await Promise.all(
    disconnect.map(({ field, id }) => {
      const otherConnection = connectionByField[field];
      const { resource: otherResource } = otherConnection;
      const otherField = reverseFields[resource][otherResource];
      return Promise.all([
        // Ours
        removeConnectionFromNode(
          resourcesInfos,
          { resource, id: nodeId },
          { connectionKey: field, connectionId: id },
          client,
        ),
        // Theirs
        removeConnectionFromNode(
          resourcesInfos,
          { resource: otherResource, id },
          { connectionKey: otherField, connectionId: nodeId },
          client,
        ),
      ]);
    }),
  );
  const modifiedConnect = ([] as any[]).concat(...connected).filter(r => r);
  const modifiedDisconnect = ([] as any[])
    .concat(...disconnected)
    .filter(r => r);
  [...modifiedConnect, ...modifiedDisconnect].forEach(
    ({ resource: res, id }) => {
      result[res] = { ...result[res], [id]: true };
    },
  );

  if (updateInfo.mutation === UpdateType.CREATE) {
    // Add (full) node to its queryAll
    return getNode(resourcesInfos, resource, nodeId, client).then(fullNode => {
      let storeData;
      try {
        storeData = client.readQuery<QueryAll>({
          query: resourceInfos.query.allList,
        });
      } catch {
        return result; // query not found in store, do nothing (eg. asset + proposition)
      }
      if (!storeData) {
        return result;
      }
      const value = storeData.allList;
      const { edges } = value;

      // Make sure it's not already there (double update on subscription)
      if (edges.some((e: Edge<NodeBase>) => e.node.id === nodeId))
        return result;

      // FIXME: Check order same as queryAll?
      const newEdges = [
        ...edges,
        { node: fullNode as any, __typename: `${resourceInfos.typename}Edge` },
      ];

      client.writeQuery({
        query: resourceInfos.query.allList,
        data: { allList: { ...value, edges: newEdges } },
      });
      result[resource] = { ...result[resource], [nodeId]: true };
      return result;
    });
  }

  if (updateInfo.mutation === UpdateType.DELETE) {
    console.warn('TODO: We should also remove all reverse connections !');
    let storeData;
    try {
      storeData = client.readQuery<QueryAll>({
        query: resourceInfos.query.allList,
      });
    } catch {
      // query not found in store, do nothing
      return result;
    }
    if (storeData) {
      const value = storeData.allList;
      const { edges } = value;

      // Make sure it's still here (double update on subscription)
      if (!edges.some((e: Edge<NodeBase>) => e.node.id === nodeId))
        return result;

      const newEdges = [...edges].filter(
        ({ node: edgeNode }) => edgeNode.id !== nodeId,
      );

      client.writeQuery({
        query: resourceInfos.query.allList,
        data: { allList: { ...value, edges: newEdges } },
      });

      result[resource] = { ...result[resource], [nodeId]: true };
    }
  }
  return result;
}
