import moment, { Moment } from 'moment';
import _reduce from 'lodash/reduce';

import { MinoUser } from '../../types/mino/nodes';
import {
  GQLMinoContractEdge,
  GQLMinoMinoStatus,
  GQLMinoPaymentEdge,
  GQLMinoRentEdge,
  GQLMinoRequest,
  GQLMinoRequestEdge,
  GQLMinoStatus,
} from '../../types/mino/schema';

import config from '../../config';
import { areDatesFullMonth, DateTuple } from '../dates';

export type UserMonthData = {
  sncfCP: NonNullable<MinoUser['sncfCP']>;
  month: string;
  paySlip: string;
  rent: number;
  provDeduction: number;
  mino?: number | string | undefined;
  provider: string;
  fullMonthAccomodation: boolean;
  fullMonthContract: boolean;
  sncfSA: MinoUser['sncfSA'];
  sncfBranche: MinoUser['sncfBranche'];
  sncfDivision: MinoUser['sncfDivision'];
  sncfRG: MinoUser['sncfRG'];
  sncfEtablissement: MinoUser['sncfEtablissement'];
  requestStatus: string;
  nbPayments: number;
  familyName: MinoUser['familyName'];
  givenName: MinoUser['givenName'];
  bday: MinoUser['bday'];
  requestId: string;
  anomalies?: Set<string> | undefined;
};

export const NO_MINORATION = 'PAS_DE_MINO';

// eslint-disable-next-line import/prefer-default-export
export function getUserMonthData(user: MinoUser): UserMonthData | undefined {
  if (user.sncfCP) {
    let monthRent = 0;
    let monthDeduction = 0;
    const monthProvider = new Set<NonNullable<GQLMinoRequest['provider']>>();
    const monthRequestStatus = new Set<
      NonNullable<GQLMinoRequest['wfStatus']>
    >();
    const monthRequestId = new Set<NonNullable<GQLMinoRequest['id']>>();
    const allProvidersRentsDates: Array<[Moment, Moment | undefined]> = [];
    let dbFullAccomodation = false;

    (user.requests?.edges as GQLMinoRequestEdge[])
      .filter(({ node: request }) => request.status === GQLMinoStatus.PUBLISHED) // #199 Hide archived requests
      .forEach(({ node: request }) => {
        if (
          request.createdAt &&
          request.provider &&
          request.wfStatus &&
          [
            GQLMinoMinoStatus.EN_COURS,
            GQLMinoMinoStatus.SUSPENDUE,
            GQLMinoMinoStatus.VALIDEE,
          ].includes(request.wfStatus)
        ) {
          monthRequestStatus.add(request.wfStatus);
          monthRequestId.add(request.id);
          monthProvider.add(request.provider);
          const requestRents = request.rents.edges.filter(
            ({ node: rent }: GQLMinoRentEdge) =>
              moment(rent.month).isSame(
                moment(config.minoManagementMonthIso),
                'month',
              ),
          );
          if (requestRents.length > 1) {
            throw new Error(
              `More than 1 rent was found for ${JSON.stringify({
                userId: user.id,
                requestId: request.id,
              })}`,
            );
          } else {
            requestRents.forEach(({ node: rent }: GQLMinoRentEdge) => {
              monthRent += rent.rent ?? 0;
              monthDeduction += rent.provDeduction ?? 0;
              allProvidersRentsDates.push([
                moment(rent.startDate),
                rent.endDate ? moment(rent.endDate) : undefined,
              ]);
              dbFullAccomodation =
                dbFullAccomodation || !!rent.fullAccomodation;
            });
          }
        }
      });

    if (monthRequestId.size > 0) {
      const allContractsDates: DateTuple[] = [];
      user.contracts?.edges.forEach(
        ({ node: contract }: GQLMinoContractEdge) => {
          allContractsDates.push([
            moment(contract.sncfContractBeginDate),
            contract.sncfContractEndDate
              ? moment(contract.sncfContractEndDate)
              : undefined,
          ]);
        },
      );

      const monthPayments = new Set();
      user.payments?.edges.forEach(({ node: payment }: GQLMinoPaymentEdge) => {
        if (payment.month) {
          monthPayments.add(payment.month);
        }
      });
      const nbPayments = monthPayments.size;

      const userMonthData: UserMonthData = {
        sncfCP: user.sncfCP,
        month: config.minoManagementMonthIso,
        paySlip: moment(config.minoManagementMonthIso)
          .add(1, 'month')
          .toISOString(),
        rent: monthRent,
        provDeduction: monthDeduction,
        // `mino` is processed later
        provider: Array.from(monthProvider).join('/'),
        fullMonthAccomodation: dbFullAccomodation,
        fullMonthContract:
          allContractsDates.length > 0
            ? areDatesFullMonth(
                allContractsDates,
                moment(config.minoManagementMonthIso),
              )
            : false,
        sncfSA: user.sncfSA || undefined,
        sncfBranche: user.sncfBranche || undefined,
        sncfDivision: user.sncfDivision || undefined,
        sncfRG: user.sncfRG || undefined,
        sncfEtablissement: user.sncfEtablissement || undefined,
        requestStatus: Array.from(monthRequestStatus).join('/'),
        nbPayments, // Deduped (Set don't dup)
        familyName: user.familyName || undefined,
        givenName: user.givenName || undefined,
        bday: user.bday || undefined,
        requestId: Array.from(monthRequestId).join('/'),
      };

      const dateBail = (
        user.requests?.edges as GQLMinoRequestEdge[]
      ).reduce<null | Moment>((acc, { node: request }) => {
        // TBC: request.wfStatus === GQLMinoMinoStatus.EN_COURS || VALIDEE ?
        const requestDateBail = request.dateBail
          ? moment(request.dateBail)
          : null;
        if (
          requestDateBail &&
          requestDateBail.isValid() &&
          (!acc || requestDateBail.isBefore(acc))
        )
          return requestDateBail;
        return acc;
      }, null);

      const latestValidCreated = (user.requests?.edges as GQLMinoRequestEdge[])
        .map(e => e.node)
        .filter(
          r =>
            r.wfStatus === GQLMinoMinoStatus.EN_COURS ||
            r.wfStatus === GQLMinoMinoStatus.VALIDEE,
        )
        .reduce<null | Moment>((acc, rq) => {
          // TBC: request.wfStatus === GQLMinoMinoStatus.EN_COURS || VALIDEE ?
          const created = rq.createdAt ? moment(rq.createdAt) : null;
          if (created && created.isValid() && (!acc || created.isAfter(acc)))
            return created;
          return acc;
        }, null);

      userMonthData.mino = (function getMino(): (typeof userMonthData)['mino'] {
        if (
          dateBail &&
          dateBail.isSameOrBefore(config.minoManagementMonthIso) &&
          userMonthData.fullMonthContract &&
          nbPayments < 12
        ) {
          // #225,#285 / FD331 - montant mino dépend de la date de création de la demande active la plus récente
          return 207;
        }
        return NO_MINORATION;
      })();

      // Unsetted anomalies
      userMonthData.anomalies = _reduce<typeof userMonthData, Set<string>>(
        userMonthData,
        (acc, mData, mDataKey) => {
          if (typeof mData === 'undefined') {
            acc.add(mDataKey);
          }
          return acc;
        },
        new Set(),
      );
      // multiProviders anomaly
      if (monthProvider.size > 1) {
        userMonthData.anomalies.add('multiProviders');
      }
      // fullMonthAccomodation anomaly: Check fullMonthAccomodation value and processed value
      if (
        allProvidersRentsDates.length > 0 &&
        typeof dbFullAccomodation !== 'undefined' &&
        dbFullAccomodation !==
          areDatesFullMonth(
            allProvidersRentsDates,
            moment(config.minoManagementMonthIso),
          )
      ) {
        userMonthData.anomalies.add('fullMonthAccomodation');
      }

      return userMonthData;
    }
  }

  return undefined;
}
