import { getProjectApolloClient } from 'phicomas-client/dist/projects/sncfPadhHt/apolloClient';
import { Environment, Instance } from 'phicomas-client';

import { ENV_LEVEL } from '../../aws/aws-exports';
import { awsAppSyncHtConfig } from '../../aws/config';

const environment: Environment = {
  instance: Instance.BACK,
  level: ENV_LEVEL,
};

export default getProjectApolloClient(environment, {
  awsAppSyncCustomConfig: { auth: awsAppSyncHtConfig.auth },
  retry: {
    delay: {
      max: 5000,
    },
    attempts: {
      max: 99,
    },
  },
});
