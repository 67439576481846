import React from 'react';
import moment from 'moment';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';

import { LogEvent } from '../../../../types/ht/history';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);

type HistoryEntryProps = {
  logEvent: LogEvent;
};

const HistoryEntry: React.FC<HistoryEntryProps> = ({
  logEvent,
}: HistoryEntryProps) => {
  const classes = useStyles();

  const date = moment(logEvent.timestamp).format('ddd D MMM YYYY - HH:mm');
  const {
    message: { identity: identityRaw, wfStatus, action, details },
  } = logEvent;
  const [actionType, actionVerb] = action.split(':');

  const identity = identityRaw.startsWith('arn:')
    ? identityRaw.split('/').reverse()[0]
    : identityRaw;

  let message = '';
  switch (actionType) {
    case 'mutation':
      switch (actionVerb) {
        case 'create':
          message = `Création de demande`;
          break;
        case 'update':
          message = `Mise à jour de demande: ${wfStatus}`;
          break;
        case 'delete':
          message = `Suppression de demande`;
          break;
        default:
          message = `Changement effectué`;
      }
      break;
    case 'notify':
      message = `Email envoyé`;
      break;
    case 'export':
      switch (actionVerb) {
        case 'parme':
          message = `Envoyé à Parme`;
          break;
        default:
          message = `Export ${actionVerb}`;
      }
      break;
    case 'import':
      switch (actionVerb) {
        case 'parme':
          message = 'Import Parme';
          break;
        default:
          message = `Import ${actionVerb}`;
      }
      break;
    case 'error':
      message = `Erreur`;
      break;
    case 'commit':
      message = `Mise à jour enregistrée`;
      break;
    default:
      message = `${actionType}: ${actionVerb}`;
  }

  if (!message) return null;

  return (
    <Card className={classes.root}>
      <CardHeader title={date} />
      <CardContent>
        <Typography>
          {message}
          {details ? ` - ${details}` : null} [{identity}]
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HistoryEntry;
