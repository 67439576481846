import {
  MinoNodeConnection,
  MinoUser,
  gqlResultIsUserNodeConnection,
} from '../../types/mino/nodes';
import { GQLMinoRequestEdge, GQLMinoStatus } from '../../types/mino/schema';

/** For users, prefiltering includes :
 * - Remove users' requests that are NOT PUBLISHED
 * - Remove users without any requests
 * */
function preFilterUserResult(
  nodeConnection: MinoNodeConnection<MinoUser>,
): MinoNodeConnection<MinoUser> {
  return {
    ...nodeConnection,
    edges: nodeConnection.edges
      .map(edge => {
        const edgeWithPublishedRequests = {
          ...edge,
          node: {
            ...edge.node,
          },
        };
        if (edge.node.requests) {
          edgeWithPublishedRequests.node = {
            ...edgeWithPublishedRequests.node,
            requests: {
              ...edge.node.requests,
              edges: (edge.node.requests?.edges as GQLMinoRequestEdge[]).filter(
                ({ node: request }) =>
                  request.status === GQLMinoStatus.PUBLISHED,
              ),
            },
          };
        }
        return edgeWithPublishedRequests;
      })
      .filter(({ node }) => (node.requests?.edges.length ?? 0) > 0),
  };
}

export default function preFilterResult(
  result: MinoNodeConnection,
): MinoNodeConnection {
  if (gqlResultIsUserNodeConnection(result)) {
    return preFilterUserResult(result);
  }
  return result;
}
