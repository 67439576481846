import _filter from 'lodash/filter';
import moment from 'moment';
import { Record } from 'react-admin';

import { SMinoNode, SMinoUser, nodeIsUser } from '../../types/mino/nodes';

type Filters = any;

function filterUsers(users: SMinoUser[], filters: Filters): SMinoUser[] {
  const remainingFilters: Filters = {};
  let filteredUsers: SMinoUser[] = users;
  Object.keys(filters).forEach(filterKey => {
    const filterValue = filters[filterKey];
    switch (filterKey) {
      case 'sncfCP':
      case 'givenName':
      case 'familyName':
        filteredUsers = filteredUsers.filter(user => {
          const userKeyValue = user[filterKey];
          return userKeyValue
            ? new RegExp(filterValue, 'i').test(userKeyValue)
            : false;
        });
        break;
      case 'nbRequests':
        filteredUsers = filteredUsers.filter(
          ({ requests }) => requests.length >= +filterValue,
        );
        break;
      case 'nbPayments':
        filteredUsers = filteredUsers.filter(
          ({ payments }) => payments.length >= +filterValue,
        );
        break;
      case 'lastRequestDate':
      case 'minoEndDate':
        filteredUsers = filteredUsers.filter(
          ({ [filterKey]: userValue }) =>
            userValue && moment(userValue).isSameOrAfter(filterValue),
        );
        break;
      case 'lastPaymentAmount':
        filteredUsers = filteredUsers.filter(
          // eslint-disable-next-line eqeqeq
          ({ [filterKey]: userValue }) => userValue == filterValue,
        );
        break;
      default:
        remainingFilters[filterKey] = filterValue;
    }
  });
  return _filter(filteredUsers, remainingFilters);
}

const isUserArray = (a: Record[]): a is SMinoUser[] =>
  a.length ? nodeIsUser(a[0]) : false;

function filterNodes(data: Record[], filters: Filters): Record[] {
  if (!data.length || !Object.keys(filters).length) return data;
  if (isUserArray(data)) {
    return filterUsers(data as SMinoUser[], filters);
  }
  return _filter(data, filters);
}

export default filterNodes;
