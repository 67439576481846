/**
 * This file was created based on ract-admin's BooleanInput to replace Switch by Checkbox
 */
import React, { useCallback } from 'react';
import { InputHelperText } from 'react-admin';
import { FieldTitle, useInput, InputProps } from 'ra-core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import sanitizeRestProps from './sanitizeRestProps';

type BooleanCheckboxInputProps = InputProps<CheckboxProps> &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

const BooleanCheckboxInput: React.FC<BooleanCheckboxInputProps> = ({
  format,
  label,
  fullWidth,
  helperText,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  ...rest
}: BooleanCheckboxInputProps) => {
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProps },
    isRequired,
    meta: { error, touched = false },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event, newValue) => {
      finalFormOnChange(newValue);
    },
    [finalFormOnChange],
  );

  return (
    <FormGroup {...sanitizeRestProps(rest)}>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            color="primary"
            onChange={handleChange}
            {...inputProps}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      <FormHelperText error={!!error}>
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  );
};

export default BooleanCheckboxInput;
