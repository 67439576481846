// in src/customRoutes.js
import * as React from 'react';
import { Route } from 'react-router-dom';
import CGU, { key as cguKey } from '../components/customPages/CGU';
import OAuth from '../components/structure/OAuth';
import MinoManagementWrapper from '../components/customPages/MinoManagementWrapper';
import { key as minoManagementKey } from '../components/customPages/MinoManagement';
import ImportContracts, {
  key as importContractsKey,
} from '../components/customPages/MinoManagement/ImportContracts';
import ImportUsersOrganisation, {
  key as importUsersOrganisationKey,
} from '../components/customPages/MinoManagement/ImportUsersOrganisation';
import ImportPayments, {
  key as importPaymentsKey,
} from '../components/customPages/MinoManagement/ImportPayments';

export default [
  <Route exact path="/oauth" component={OAuth} noLayout />,
  <Route
    exact
    path={`/${minoManagementKey}`}
    component={MinoManagementWrapper}
  />,
  <Route
    exact
    path={`/${importContractsKey}/(local)?`}
    component={ImportContracts}
  />,
  <Route
    exact
    path={`/${importUsersOrganisationKey}/(local)?`}
    component={ImportUsersOrganisation}
  />,
  <Route
    exact
    path={`/${importPaymentsKey}/(local)?`}
    component={ImportPayments}
  />,
  <Route exact path={`/${cguKey}`} component={CGU} />,
];
