import React from 'react';
import { NumberInput } from 'react-admin';
import { useTranslate } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ClassName } from '../../types/styles';

const useStyles = makeStyles<Theme, { fullWidth: boolean }>(theme =>
  createStyles({
    wrapper: {
      width: ({ fullWidth }): string | undefined =>
        fullWidth ? `calc(100% + ${theme.spacing(0.5) * 2}px)` : 'auto',
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(0, -0.5),

      '& > *': {
        flex: '1 1 0%',
        margin: theme.spacing(0, 0.5),
      },
    },
    input: {},
  }),
);

type CoordinatesInputProps = {
  id?: string;
  source?: string;
  fullWidth?: boolean;
  className?: ClassName;
  validate?: any;
};
const CoordinatesInput: React.FC<CoordinatesInputProps> = ({
  id,
  source,
  fullWidth = false,
  className,
  ...props
}: CoordinatesInputProps) => {
  const classes = useStyles({ fullWidth });
  const translate = useTranslate();

  return (
    <div className={className}>
      <span className={classes.wrapper}>
        <NumberInput
          {...props}
          id={id ? `${id}.coordinates[0]` : undefined}
          source={source ? `${source}.coordinates[0]` : 'coordinates[0]'}
          label={translate('commonFields.longitude')}
          className={classes.input}
        />
        <NumberInput
          {...props}
          id={id ? `${id}.coordinates[1]` : undefined}
          source={source ? `${source}.coordinates[1]` : 'coordinates[1]'}
          label={translate('commonFields.latitude')}
          className={classes.input}
        />
      </span>
    </div>
  );
};

export default CoordinatesInput;
