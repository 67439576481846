import memoizeOne from 'memoize-one';
import moment from 'moment';

import { TransactionState, TransactionalNode, NodeBase } from '../types/nodes';

/* **********
   Transactional state
 */
/** Node is locked (by transaction) */
export const isLocked = memoizeOne(
  (node?: TransactionalNode): boolean =>
    node?.transaction?.state === TransactionState.BEGIN ?? false,
);

/** Node is locked (by transaction) since more than 10 minutes */
export const isLongLocked = memoizeOne((node?: TransactionalNode): boolean => {
  const mNow = moment();
  return (
    isLocked(node) &&
    moment(node?.transaction?.begin ?? mNow).isSameOrBefore(
      mNow.subtract(10, 'minutes'),
    )
  );
});

/** Node has been rollbacked (by transaction) */
export const isRollbacked = memoizeOne(
  (node?: TransactionalNode): boolean =>
    node?.transaction?.state === TransactionState.ROLLBACK ?? false,
);

/** Node's rollback message (by transaction) */
export const getRollbackMessage = memoizeOne(
  (node?: TransactionalNode): string | false =>
    (isRollbacked(node) && node?.transaction?.message) ?? false,
);

/* **********
   Readonly state
 */
export const isReadonly = memoizeOne(
  (node?: NodeBase & Partial<TransactionalNode>): boolean => isLocked(node),
);
