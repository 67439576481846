/**
 * Inspired by:
 * - https://github.com/marmelab/react-admin/tree/v2.9.9/packages/ra-realtime
 * - https://github.com/marmelab/react-admin/issues/4452
 * - https://github.com/marmelab/react-admin/pull/3908
 * - https://stackoverflow.com/questions/54617743/how-can-i-push-data-into-react-admin-store
 */

import {
  takeLatest,
  call,
  takeEvery,
  take,
  put,
  cancelled,
} from 'redux-saga/effects';

import { LOCATION_CHANGE } from 'connected-react-router';
import {
  DataProvider,
  CRUD_GET_LIST,
  CRUD_GET_ONE,
  CrudGetListAction,
  CrudGetOneAction,
  FETCH_START,
  FETCH_END,
  CrudGetListSuccessAction,
  CrudGetListLoadingAction,
  CrudGetManyAction,
  GET_LIST,
  CRUD_GET_LIST_SUCCESS,
  CRUD_GET_LIST_LOADING,
  CRUD_GET_MANY,
  CRUD_GET_ONE_SUCCESS,
  GET_ONE,
  GET_MANY,
  CRUD_GET_MANY_SUCCESS,
  CrudGetOneSuccessAction,
  CrudGetManySuccessAction,
  CrudGetOneLoadingAction,
  CRUD_GET_ONE_LOADING,
  CRUD_GET_MANY_LOADING,
  CrudGetManyLoadingAction,
  showNotification,
} from 'ra-core';
import { Action } from 'redux';

const buildListAction = (
  { payload: requestPayload, meta: { resource } }: CrudGetListAction,
  payload: CrudGetListSuccessAction['payload'],
): CrudGetListSuccessAction => ({
  type: CRUD_GET_LIST_SUCCESS,
  payload,
  requestPayload,
  meta: { resource, fetchResponse: GET_LIST, fetchStatus: FETCH_END },
});

const buildOneAction = (
  { payload: requestPayload, meta: { resource } }: CrudGetOneAction,
  payload: CrudGetOneSuccessAction['payload'],
): CrudGetOneSuccessAction => ({
  type: CRUD_GET_ONE_SUCCESS,
  payload,
  requestPayload,
  meta: { resource, fetchResponse: GET_ONE, fetchStatus: FETCH_END },
});

const buildManyAction = (
  { payload: requestPayload, meta: { resource } }: CrudGetManyAction,
  payload: CrudGetManySuccessAction['payload'],
): CrudGetManySuccessAction => ({
  type: CRUD_GET_MANY_SUCCESS,
  payload,
  requestPayload,
  meta: { resource, fetchResponse: GET_MANY, fetchStatus: FETCH_END },
});

export const isGetListAction = (action: Action): action is CrudGetListAction =>
  action.type === CRUD_GET_LIST;

export const isGetOneAction = (action: Action): action is CrudGetOneAction =>
  action.type === CRUD_GET_ONE;

export const isGetManyAction = (action: Action): action is CrudGetManyAction =>
  action.type === CRUD_GET_MANY;

export const watchSubscriptionFactory = (dataProvider: DataProvider) =>
  function* watchSub(
    action: CrudGetListAction | CrudGetOneAction | CrudGetManyAction,
  ): Generator<any, any, any> {
    const channel = yield call(
      dataProvider.getObserver,
      action.meta.resource,
      action,
    );
    const {
      payload,
      meta: { resource },
    } = action;
    try {
      while (true) {
        // get next push update
        const data = yield take(channel);

        if (isGetListAction(action)) {
          // replay with RA/CRUD_GET_LIST_LOADING, RA/FETCH_START, RA/CRUD_GET_LIST_SUCCESS, RA/FETCH_END
          // only _SUCCESS necessary ???
          yield put({
            type: CRUD_GET_LIST_LOADING,
            payload, // original payload
            meta: { resource },
          } as CrudGetListLoadingAction);
          yield put({ type: FETCH_START });
          yield put(buildListAction(action, data));
          yield put({ type: FETCH_END });
        } else if (isGetOneAction(action)) {
          yield put({
            type: CRUD_GET_ONE_LOADING,
            payload,
            meta: { resource },
          } as CrudGetOneLoadingAction);
          yield put({ type: FETCH_START });
          yield put(buildOneAction(action, data));
          yield put({ type: FETCH_END });
          // for getOne we also notify the user
          yield put(
            showNotification('ra.notification.updated', 'info', {
              messageArgs: { smart_count: 1 },
            }),
          ); // ra.notification.updated
        } else if (isGetManyAction(action)) {
          yield put({
            type: CRUD_GET_MANY_LOADING,
            payload,
            meta: { resource },
          } as CrudGetManyLoadingAction);
          yield put({ type: FETCH_START });
          yield put(buildManyAction(action, data));
          yield put({ type: FETCH_END });
        }
      }
    } finally {
      // this is magically called when changing route (because of takeLatest?)
      if (yield cancelled() && channel) {
        channel.close();
      }
    }
  };

export const watchLocationFactory = (
  watch: (p: CrudGetListAction | CrudGetOneAction) => any,
) =>
  function* watchLocation(): Generator<any, any, any> {
    yield takeEvery([CRUD_GET_LIST, CRUD_GET_ONE, CRUD_GET_MANY], watch);
  };

export default (dataProvider: DataProvider) =>
  function* customSaga(): Generator<ReturnType<typeof takeLatest>, any, any> {
    const watch = watchSubscriptionFactory(dataProvider);
    yield takeLatest(LOCATION_CHANGE, watchLocationFactory(watch));
  };
