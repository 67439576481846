import { DocumentNode } from 'graphql';
import {
  QUERY_ALL_HT_RESIDENCE,
  QUERY_ALL_HT_REQUEST,
  QUERY_ALL_HT_PROPOSITION,
  QUERY_ALL_HT_ASSET,
} from '../../graphql/ht/queries';
import {
  HtResidenceFields,
  HtRequestFields,
  HtPropositionFields,
  HtAssetFields,
} from '../../graphql/ht/fragments';
import { GQLHtNode } from '../../types/ht/nodes';
import { KeysOfUnion } from '../../types/utils';
import { ResourceKey } from './resourcesKeys';

export type ResourceInfos = {
  typename: string;
  queryName: string;
  queryAll: DocumentNode;
  fragment: DocumentNode;
  fragmentName: string;
  connections?: ResourceConnectionInfo[]; // use map ?
};

export type ResourceConnectionInfo = {
  resource: ResourceKey;
  key: KeysOfUnion<GQLHtNode>;
  multiple: boolean;
};

const htResidenceInfos: ResourceInfos = {
  typename: 'Residence',
  queryName: 'residence',
  queryAll: QUERY_ALL_HT_RESIDENCE,
  fragment: HtResidenceFields,
  fragmentName: 'HtResidenceFields',
};
const htRequestInfos: ResourceInfos = {
  typename: 'Request',
  queryName: 'request',
  queryAll: QUERY_ALL_HT_REQUEST,
  fragment: HtRequestFields,
  fragmentName: 'HtRequestFields',
  connections: [
    {
      resource: 'htAsset',
      key: 'attachments',
      multiple: true,
    },
    {
      resource: 'htProposition',
      key: 'propositions',
      multiple: true,
    },
  ],
};
const htPropositionInfos: ResourceInfos = {
  typename: 'Proposition',
  queryName: 'proposition',
  queryAll: QUERY_ALL_HT_PROPOSITION,
  fragment: HtPropositionFields,
  fragmentName: 'HtPropositionFields',
  connections: [
    {
      resource: 'htRequest',
      key: 'request',
      multiple: false,
    },
  ],
};
const htAssetInfos: ResourceInfos = {
  typename: 'Asset',
  queryName: 'asset',
  queryAll: QUERY_ALL_HT_ASSET,
  fragment: HtAssetFields,
  fragmentName: 'HtAssetFields',
  connections: [
    {
      resource: 'htRequest',
      key: 'request',
      multiple: false,
    },
  ],
};

const resourcesInfos: { [k in ResourceKey]: ResourceInfos } = {
  htResidence: htResidenceInfos,
  htRequest: htRequestInfos,
  htProposition: htPropositionInfos,
  htAsset: htAssetInfos,
};

type ResourceInfosWithConnections = Exclude<ResourceInfos, 'connections'> &
  Pick<Required<ResourceInfos>, 'connections'>;
export function resourceInfosHasConnections(
  resourceInfos: ResourceInfos,
): resourceInfos is ResourceInfosWithConnections {
  return (
    !!resourceInfos.connections && Array.isArray(resourceInfos.connections)
  );
}

export default resourcesInfos;
