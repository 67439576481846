import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Admin from './components/Admin';

import { CssBaseline } from './theme/custom/sncf';

moment.locale('fr');

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Admin />
    </>
  );
};

export default App;
