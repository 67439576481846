import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import {
  updateStore as genericUpdateStore,
  CacheUpdateResult,
} from '../cacheUtils';

import resourcesInfos from '../../dataProvider/mino/resourcesInfos';

import { MinoNode } from '../../types/mino/nodes';
import { NodeUpdate } from '../mutations';

// eslint-disable-next-line import/prefer-default-export
export const updateStore = (
  update: NodeUpdate<Partial<MinoNode>>,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<CacheUpdateResult> => {
  return genericUpdateStore(resourcesInfos, update, client);
};
