import React, { useCallback } from 'react';
import {
  SimpleForm,
  SaveButton,
  DateInput,
  DateTimeInput,
  SelectInput,
  Toolbar,
  useGetOne,
} from 'react-admin';
import {
  useTranslate,
  useDataProvider,
  ReduxState,
  useNotify,
  CRUD_GET_ONE,
} from 'ra-core';
import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import TextInput from '../../../CustomInputs/TextInput';

import { decisionChoices } from '../../../../utils/ht/enums';
import { ID } from '../../../../types/ht/base';

const useToolbarStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

type PropositionEditButtonToolbarProps = {
  submitting?: boolean;
  onCancel: () => void;
};
const PropositionEditDialogToolbar: React.FC<
  PropositionEditButtonToolbarProps
> = ({ submitting, onCancel, ...props }: PropositionEditButtonToolbarProps) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();

  return (
    <Toolbar {...props} className={classes.root}>
      <SaveButton />
      <>
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
      </>
    </Toolbar>
  );
};

type PropositionEditFormProps = {
  propositionId: ID;
  onClose?: () => void;
};

const PropositionEditForm: React.FC<PropositionEditFormProps> = ({
  propositionId,
  onClose,
}: PropositionEditFormProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );
  const {
    data: proposition,
    loading,
    loaded,
    error,
  } = useGetOne('htProposition', propositionId, { action: CRUD_GET_ONE });

  const handleSave = useCallback(
    values => {
      if (!proposition) return;
      dataProvider
        .update('htProposition', {
          id: proposition.id,
          data: values,
          previousData: proposition,
        })
        .then(() => {
          notify('ra.notification.updated', 'info', 1);
          if (onClose) {
            onClose();
          }
        });
    },
    [dataProvider, proposition, notify, onClose],
  );

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  if (loading || !loaded || error) return <div />;

  return (
    <SimpleForm
      record={proposition}
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <PropositionEditDialogToolbar
          onCancel={handleCancel}
          submitting={submitting}
        />
      }
      resource="htProposition"
      submitOnEnter={false}
    >
      <DateInput source="provDate" fullWidth />
      <TextInput source="addressLevel2" fullWidth />
      <DateTimeInput source="dateDecision" fullWidth />
      <SelectInput source="decision" choices={decisionChoices} fullWidth />
      <TextInput
        source="motifRefus"
        multiline
        minRows={3}
        maxRows={10}
        fullWidth
      />
      <DateInput source="dateBail" fullWidth />
      <TextInput source="provider" fullWidth />
      <TextInput source="residenceCode" fullWidth />
      <TextInput source="residenceName" fullWidth />
      <TextInput source="provId" fullWidth />
    </SimpleForm>
  );
};

export default PropositionEditForm;
