import React, { useCallback } from 'react';
import { useTranslate } from 'ra-core';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { SMinoRequest } from '../../../../types/mino/nodes';
import RequestEditForm from './RequestEditForm';
import { Rights } from '../../../../authProvider/permissions';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
  }),
);

type RequestEditDialogProps = {
  request: SMinoRequest | null;
  onClose: () => void;
  permissions?: Rights[];
};

const RequestEditDialog: React.FC<RequestEditDialogProps> = ({
  request,
  onClose,
  permissions,
}: RequestEditDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      fullWidth
      open={request !== null}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>{translate('resources.minoRequest.name', 1)}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {!!request && (
          <RequestEditForm
            requestId={request.id}
            permissions={permissions}
            onClose={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RequestEditDialog;
