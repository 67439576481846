import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

import { ResourceKey } from '../../dataProvider/ht/resourcesKeys';
import { NodeFragment, NodeUpdateInfoFields } from '../fragments';

export const HtResidenceFieldsBase = gql`
  fragment HtResidenceFieldsBase on Residence {
    provider
    code
    name
    streetAddress
    postalCode
    addressLevel2
    distance
    filiere
    priority
    coordinates
  }
`;

export const HtRequestFieldsBase = gql`
  fragment HtRequestFieldsBase on Request {
    gdpr

    sncfCP
    honorificPrefix
    email
    givenName
    familyName
    tel
    bday
    nationality
    maritalStatus

    fiscalRevenue
    salary

    sncfCompany
    sncfPostalCode
    sncfContractBeginDate
    sncfStatut
    sncfEmployeeType

    htOccupants
    htMotif
    htTypology
    htGarant
    htLocations {
      coordinates
      address
    }
    htStartDate
    htEndDate
    htAttribution

    comment
    motifRefus

    origin

    wfStatus
    wfNotify
    wfNext
  }
`;

export const HtPropositionFieldsBase = gql`
  fragment HtPropositionFieldsBase on Proposition {
    provId
    provDate
    addressLevel2
    dateDecision
    decision
    motifRefus
    dateBail
    provider
    residenceCode
    residenceName
  }
`;

export const HtAssetFieldsBase = gql`
  fragment HtAssetFieldsBase on Asset {
    filename
    size
    type
    key
  }
`;

export const HtResidenceFields = gql`
  fragment HtResidenceFields on Residence {
    ...NodeFields
    ...HtResidenceFieldsBase
  }
  ${NodeFragment}
  ${HtResidenceFieldsBase}
`;

const TransactionField = gql`
  fragment TransactionField on Request {
    transaction {
      state
      begin
      commit
      rollback
      message
    }
  }
`;

export const HtRequestFields = gql`
  fragment HtRequestFields on Request {
    ...NodeFields
    ...TransactionField
    ...HtRequestFieldsBase

    propositions @connection(key: "propositions") {
      edges {
        node {
          ...NodeFields
          ...HtPropositionFieldsBase
        }
      }
    }
    attachments @connection(key: "attachments") {
      edges {
        node {
          ...NodeFields
          ...HtAssetFieldsBase
        }
      }
    }
  }

  ${NodeFragment}
  ${HtRequestFieldsBase}
  ${HtPropositionFieldsBase}
  ${HtAssetFieldsBase}
  ${TransactionField}
`;

export const HtPropositionFields = gql`
  fragment HtPropositionFields on Proposition {
    ...NodeFields
    ...HtPropositionFieldsBase

    request @connection(key: "request") {
      edges {
        node {
          ...NodeFields
          ...HtRequestFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${HtPropositionFieldsBase}
  ${HtRequestFieldsBase}
`;

export const HtAssetFields = gql`
  fragment HtAssetFields on Asset {
    ...NodeFields
    ...HtAssetFieldsBase

    request @connection(key: "request") {
      edges {
        node {
          ...NodeFields
          ...HtRequestFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${HtAssetFieldsBase}
  ${HtRequestFieldsBase}
`;

export const AllHtFragmentsBase = gql`
  ${HtRequestFieldsBase}
  ${HtPropositionFieldsBase}
  ${HtResidenceFieldsBase}
  ${HtAssetFieldsBase}
`;

export const UpdateFragmentsBase = (
  resource: 'all' | ResourceKey = 'all',
): DocumentNode => {
  let fragments: DocumentNode;
  let fragmentFields: string;
  // TODO: replace by resourceInfo fragmentBase (to be added along fragment) and fragmentBaseName (to be added along fragmentName)
  switch (resource) {
    case 'htRequest':
      fragments = HtRequestFieldsBase;
      fragmentFields = '...HtRequestFieldsBase';
      break;
    case 'htResidence':
      fragments = HtResidenceFieldsBase;
      fragmentFields = '...HtResidenceFieldsBase';
      break;
    case 'htProposition':
      fragments = HtPropositionFieldsBase;
      fragmentFields = '...HtPropositionFieldsBase';
      break;
    case 'htAsset':
      fragments = HtAssetFieldsBase;
      fragmentFields = '...HtAssetFieldsBase';
      break;
    default:
      fragments = AllHtFragmentsBase;
      fragmentFields = `
        ...HtRequestFieldsBase
        ...HtResidenceFieldsBase
        ...HtPropositionFieldsBase
        ...HtAssetFieldsBase
        `;
  }
  return gql`
    fragment UpdateFragments on NodeUpdate {
      __typename
      id
      node {
        ...NodeFields
        ...TransactionField
        ${fragmentFields}
      }
      updateInfo {
        ...NodeUpdateInfoFields
      }
    }
    ${NodeFragment}
    ${fragments}
    ${NodeUpdateInfoFields}
    ${TransactionField}
  `;
};
