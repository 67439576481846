import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useTranslate } from 'ra-core';
import moment from 'moment';

import { TextField } from '@material-ui/core';

import { MinoUser, SMinoUser } from '../../../types/mino/nodes';
import FormRow from '../../structure/FormRow';
import { getUserMonthData } from '../../../utils/mino/utils';
import { CustomMinoProvider } from '../../../dataProvider/dataProvider';

type UserLastRequestsInfosProps = {
  record?: SMinoUser;
};

const UserLastRequestsInfos: React.FC<UserLastRequestsInfosProps> = ({
  ...raProps
}: UserLastRequestsInfosProps) => {
  const { record: sUser } = raProps;

  const translate = useTranslate();
  const dataProvider = useDataProvider<CustomMinoProvider>();

  const [user, setUser] = useState<MinoUser>();
  useEffect(() => {
    if (sUser) {
      dataProvider.getOneFull('minoUser', { id: sUser.id }).then(({ data }) => {
        setUser(data);
      });
    }
  }, [dataProvider, sUser]);

  const userMonthData = useMemo(() => {
    if (user) {
      return getUserMonthData(user);
    }
    return undefined;
  }, [user]);

  if (!userMonthData) {
    return null;
  }

  return (
    <FormRow>
      <TextField
        label={translate(`resources.minoUser.calculatedFields.month`)}
        value={moment(userMonthData.month).format('YYYY-MM-DD')}
        variant="filled"
        fullWidth
        margin="dense"
        type="date"
        disabled
      />
      <TextField
        label={translate(
          `resources.minoUser.calculatedFields.fullMonthContract`,
        )}
        value={translate(
          `ra.boolean.${userMonthData.fullMonthContract ? 'true' : 'false'}`,
        )}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(
          `resources.minoUser.calculatedFields.fullMonthAccomodation`,
        )}
        value={translate(
          `ra.boolean.${
            userMonthData.fullMonthAccomodation ? 'true' : 'false'
          }`,
        )}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoUser.calculatedFields.provider`)}
        value={userMonthData.provider}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoUser.calculatedFields.rent`)}
        value={userMonthData.rent}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoUser.calculatedFields.provDeduction`)}
        value={userMonthData.provDeduction}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
    </FormRow>
  );
};

export default UserLastRequestsInfos;
