/* eslint-disable @typescript-eslint/no-unused-vars */
import AWS, { CognitoIdentityCredentials } from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import JSZip from 'jszip';
import moment from 'moment';

import { awsAppSyncCredentials } from './config';
import awsConfig, { ENV_LEVEL } from './aws-exports';
import config from '../config';

export const MM = moment(config.minoManagementMonthIso)
  .add(1, 'month')
  .format('MM');

const encodeRFC5987ValueChars = (str: string): string =>
  encodeURIComponent(str)
    // Note that although RFC3986 reserves "!", RFC5987 does not, so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987, so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape);

/**
 * Upload a file to S3 and get it's key back
 * @param file File to be uploaded to S3
 * @param progressCallback Function to follow the upload
 * @param { needToAbort } Create an abortable promise
 */
export const uploadToS3 = async (
  file: File,
  progressCallback?: () => void,
  options: { needToAbort?: boolean } = {},
): Promise<{ key: string; upload: Promise<ManagedUpload.SendData> }> => {
  const { needToAbort = false } = options;
  const { auth } = awsAppSyncCredentials.config;
  if (auth.type !== 'AWS_IAM' || typeof auth.credentials !== 'function')
    throw new Error('Bad config');
  const credentials = (await auth.credentials()) as CognitoIdentityCredentials;
  return credentials.getPromise().then(() => {
    const S3 = new AWS.S3({
      apiVersion: awsConfig.aws_s3_apiVersion,
      region: awsConfig.aws_s3_region,
      credentials,
    });

    const lastModified: File['lastModified'] = Math.round(
      (file.lastModified || (file as any).lastModifedDate || Date.now()) / 1000,
    );

    const metadata: AWS.S3.Metadata = {
      gid: '100',
      uid: '1002', // user parme in sftp
      mode: '33188',
      ctime: String(lastModified),
      mtime: String(lastModified),
    };

    const bucket = awsConfig.aws_s3_mino.bucket[ENV_LEVEL];
    const key = awsConfig.aws_s3_mino.key.upload[ENV_LEVEL];
    const upload = S3.upload(
      {
        Bucket: bucket,
        Key: `${key}/${file.name}`,
        // StorageClass: 'REDUCED_REDUNDANCY', // keep by default for now
        Body: file,
        CacheControl: 'max-age=86400',
        ContentType: file.type,
        ContentDisposition: `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
          file.name,
        )}`,
        Metadata: metadata,
        ACL: 'bucket-owner-full-control',
      },
      { partSize: 5 * 1024 * 1024, queueSize: 4 },
    );

    if (progressCallback) upload.on('httpUploadProgress', progressCallback);
    if (needToAbort) upload.abort.bind(upload);

    return { key, upload: upload.promise() };
  });
};

const callback =
  <T>(res: (r: T) => void, rej: (r: AWS.AWSError) => void) =>
  (err: AWS.AWSError, result: T): void => {
    if (err) {
      rej(err);
      return;
    }
    res(result);
  };

export const listFromS3 = async (
  prefix: string,
  credentials: AWS.CognitoIdentityCredentials,
): Promise<AWS.S3.ListObjectsV2Output['Contents']> => {
  const S3 = new AWS.S3({
    apiVersion: awsConfig.aws_s3_apiVersion,
    region: awsConfig.aws_s3_region,
    credentials,
  });

  const bucket = awsConfig.aws_s3_mino.bucket[ENV_LEVEL];
  const params: AWS.S3.ListObjectsV2Request = {
    Bucket: bucket,
    Prefix: `${prefix}/`,
  };
  const list = await new Promise<AWS.S3.ListObjectsV2Output>((res, rej) => {
    S3.listObjectsV2(params, callback(res, rej));
  });
  if (list.IsTruncated) throw new Error('Truncated not supported !');
  return list.Contents;
};

export const downloadFromS3 = async (
  credentials: AWS.CognitoIdentityCredentials,
  key: string,
): Promise<AWS.S3.GetObjectOutput['Body']> => {
  const S3 = new AWS.S3({
    apiVersion: awsConfig.aws_s3_apiVersion,
    region: awsConfig.aws_s3_region,
    credentials,
  });

  const bucket = awsConfig.aws_s3_mino.bucket[ENV_LEVEL];
  const params: AWS.S3.GetObjectRequest = { Bucket: bucket, Key: key };
  const object = await new Promise<AWS.S3.GetObjectOutput>((res, rej) => {
    S3.getObject(params, callback(res, rej));
  });
  return object.Body;
};

export enum S3FileState {
  'TO_BE_LOADED',
  'LOADING',
  'LOADED',
  'NOT_FOUND',
}

/**
 * @param fileName
 * @param source Source is prefix/regex
 */
export async function retrieveFileFromS3(
  fileName: string,
  source: string,
): Promise<File | undefined> {
  const { auth } = awsAppSyncCredentials.config;
  if (auth.type !== 'AWS_IAM' || typeof auth.credentials !== 'function')
    throw new Error('Bad config');
  const credentials = (await auth.credentials()) as CognitoIdentityCredentials;
  return credentials.getPromise().then(async (): Promise<File | undefined> => {
    const [prefix, regex] = source.split('/');
    const list = await listFromS3(prefix, credentials);
    const rx = new RegExp(regex, 'i');
    const zipFiles = list?.filter(f => f.Size && f.Key?.match(rx));
    const zipFile = zipFiles?.reduce<AWS.S3.Object | undefined>(
      (selectedZipF, zipF) => {
        if (!selectedZipF || !selectedZipF.Key) return zipF;
        if (zipF.Key) {
          const sTmp = selectedZipF.Key.split('.');
          const sDate = `${sTmp[sTmp.length - 3]}${sTmp[sTmp.length - 2]}`;
          const zTmp = zipF.Key.split('.');
          const zDate = `${zTmp[zTmp.length - 3]}${zTmp[zTmp.length - 2]}`;
          if (sDate && zDate && zDate > sDate) {
            return zipF;
          }
        }
        return selectedZipF;
      },
      undefined,
    );
    if (!zipFile || !zipFile.Key) return undefined;
    const data = await downloadFromS3(credentials, zipFile.Key);
    if (!(data instanceof Uint8Array))
      throw new Error('not Uint8Array - check Buffer / Blob ?');
    const zip = await JSZip.loadAsync(data);
    const jsZipObject = await zip.file(new RegExp(fileName));
    if (!jsZipObject.length) {
      return undefined;
    }
    const fileBlob = await jsZipObject[0].async('blob');
    const file = new File([fileBlob], jsZipObject[0].name);
    return file;
  });
}
