/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import {
  GetListParams,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  // GetManyReferenceParams,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  // UpdateManyParams,
  UpdateManyResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  GetListResult,
  Record,
  DataProvider,
} from 'ra-core';
import { ApolloQueryResult } from '@apollo/client';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { CloudWatchLogs } from 'aws-sdk';

import _orderBy from 'lodash/orderBy';
import _castArray from 'lodash/castArray';
import _once from 'lodash/once';

import apolloClient from '../../graphql/ht/client';

import { enhanceQueryResult } from './flattener';

import { NodeUpdateInfoFields } from '../../graphql/fragments';
import {
  HtResidenceFields,
  HtRequestFields,
  HtPropositionFields,
  HtAssetFields,
  UpdateFragmentsBase,
  HtPropositionFieldsBase,
} from '../../graphql/ht/fragments';
import {
  QueryAllNode,
  QUERY_HISTORY,
  QUERY_ALL_HT_RESIDENCE,
  QUERY_ALL_HT_REQUEST,
  QUERY_ALL_HT_REQUEST_WITH_ARCHIVED,
} from '../../graphql/ht/queries';
import {
  HtNode,
  GQLHtNode,
  nodeIsResidence,
  nodeIsRequest,
  nodeIsProposition,
  HtResidence,
} from '../../types/ht/nodes';
import {
  SimplifiedConnectionMany,
  SimplifiedConnection,
  SimplifiedConnectionOne,
  nodeEntryIsSimplifiedConnectionMany,
} from '../../types/connections';
import {
  UpdateNodeVariables,
  CreateNodeVariables,
  ResidenceInput,
  NodeInput,
  RequestInput,
  AssetInput,
  PropositionInput,
} from '../../types/ht/mutations';
import {
  MutationUpdateNode,
  MutationCreateHtNode,
  MutationDeleteNode,
  MutationDeleteNodesID,
  HtNodeUpdate,
} from '../../graphql/ht/mutations';
import { extractUpdateDiff } from '../utils';
import { ID } from '../../types/ht/base';
import resourcesInfos, { resourceInfosHasConnections } from './resourcesInfos';
import { resourceIsValid } from './resourcesKeys';
import { updateStore } from '../../graphql/ht/cacheUtils';
import filterNodes from './filters';

import withFileUpload from './withFileUpload';
import withObserver from './withObserver';

import { LogEventConnection } from '../../types/ht/history';

import getObservableQuery from '../observable';
import { getCloudWatchLogs } from '../../lib/cloudWatch';
import { CustomHTProvider, GetHTListAll } from '../dataProvider';

function InvalidResourceError(resource: string): Error {
  return Error(`Resource "${resource}" is not a valid resource`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (): CustomHTProvider => {
  // init all observable queries
  const [obsHtResidence, obsHtResidenceInit, obsHtResidenceFull] =
    getObservableQuery(QUERY_ALL_HT_RESIDENCE, apolloClient);
  const [obsHtRequest, obsHtRequestInit, obsHtRequestFull] = getObservableQuery(
    QUERY_ALL_HT_REQUEST,
    apolloClient,
  );

  var getObsHtRequestWithArchived = _once(() =>
    getObservableQuery(QUERY_ALL_HT_REQUEST_WITH_ARCHIVED, apolloClient),
  );

  const htDataProvider: DataProvider &
    Pick<CustomHTProvider, 'getListAll' | 'getHistoryAll' | 'getHistory'> = {
    // Search for resources
    getListAll: ((
      resource: string,
      { withArchived }: { withArchived?: boolean } = {},
    ): Promise<{ data: HtNode[] }> => {
      console.log('getListAll', { resource, withArchived });
      let query: Promise<ApolloQueryResult<QueryAllNode>>;
      switch (resource) {
        case 'htResidence':
          if (withArchived) {
            throw InvalidResourceError(
              `${resource} is not available withArchived`,
            );
          }
          query = obsHtResidenceFull.then(() =>
            obsHtResidence.getCurrentResult(),
          ); // a.k.a query with fetchPolicy 'cache-only'
          break;
        case 'htRequest':
          if (withArchived) {
            const [obsHtRequestWithArchived, , obsHtRequestWithArchivedFull] =
              getObsHtRequestWithArchived();
            query = obsHtRequestWithArchivedFull.then(() =>
              obsHtRequestWithArchived.getCurrentResult(),
            );
          } else {
            query = obsHtRequestFull.then(() =>
              obsHtRequest.getCurrentResult(),
            );
          }
          break;
        default:
          throw InvalidResourceError(resource);
      }

      return (
        query
          // Query response to data
          .then(response => {
            const responseData = response.data;
            let data: HtNode[] = [];
            if (responseData) {
              data = enhanceQueryResult(responseData.allList);
            }
            return { data };
          })
          .then(res => {
            console.log('getListAll result', { resource, withArchived }, res);
            return res;
          })
      );
    }) as GetHTListAll,

    getHistoryAll: (
      resource: string,
      { tickCallback }: { tickCallback: (p: number) => void },
    ): Promise<{ data: CloudWatchLogs.FilteredLogEvents }> => {
      console.log('getHistoryAll', { resource });
      let cloudWatchPromise = Promise.resolve<CloudWatchLogs.FilteredLogEvents>(
        [],
      );

      if (resource === 'htRequest') {
        cloudWatchPromise = getCloudWatchLogs(
          {
            logGroupName: '/PADH/HT',
            logStreamNamePrefix: 'Request/',
          },
          tickCallback,
        );
      }

      return cloudWatchPromise
        .then(historyAll => {
          return { data: historyAll };
        })
        .then(res => {
          console.log('getHistoryAll result', { resource }, res);
          return res;
        });
    },

    // Get node history
    getHistory: ((
      resource: string,
      {
        id,
        first = null,
        after = null,
      }: { id: ID; first: number | null; after: number | null },
    ): Promise<{ data: LogEventConnection }> => {
      console.log('getHistory', { resource, id, first, after });
      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      return (
        apolloClient
          .query<{ history: LogEventConnection }>({
            query: QUERY_HISTORY,
            variables: {
              id: `${resourcesInfos[resource].typename}/${id}`,
              first,
              after,
            },
            fetchPolicy: 'no-cache',
          })
          // Query response to data
          .then(response => {
            const responseData = response.data;
            if (responseData) {
              return { data: responseData.history };
            }
            throw Error(
              `getHistory ${resource} #${id} did not return any value`,
            );
          })
          .then(res => {
            console.log(
              'getHistory result',
              { resource, id, first, after },
              res,
            );
            return res;
          })
      );
    }) as CustomHTProvider['getHistory'],

    // Search for resources
    getList: <RecordType extends Record = HtNode>(
      resource: string,
      params: GetListParams,
    ): Promise<GetListResult<RecordType>> => {
      console.log('getList', { resource, params });
      let query: Promise<ApolloQueryResult<QueryAllNode>>;
      switch (resource) {
        case 'htResidence':
          query = obsHtResidenceInit.then(() =>
            obsHtResidence.getCurrentResult(),
          ); // a.k.a query with fetchPolicy 'cache-only'
          break;
        case 'htRequest':
          query = obsHtRequestInit.then(() => obsHtRequest.getCurrentResult());
          break;
        // case 'htProposition':
        //   query = obsHtPropositionInit.then(() =>
        //     obsHtProposition.getCurrentResult(),
        //   );
        //   break;
        // case 'htAsset':
        //   query = obsHtAssetInit.then(() => obsHtAsset.getCurrentResult());
        //   break;
        default:
          throw InvalidResourceError(resource);
      }

      return (
        query
          // Query response to data
          .then(response => {
            const responseData = response.data;
            let data: RecordType[] = [];
            if (responseData) {
              data = enhanceQueryResult<
                RecordType,
                typeof responseData.allList
              >(responseData.allList);
            }
            return { data };
          })
          // Data to result
          .then(({ data: rawData }) => {
            const { filter, pagination, sort } = params;
            const unfilteredData = rawData;

            const unsortedData = filterNodes(unfilteredData, filter);

            const { field, order } = sort;
            const unpaginatedData = _orderBy(
              unsortedData,
              field,
              order.toLowerCase() as 'asc' | 'desc',
            );

            const { page, perPage } = pagination;
            const data = unpaginatedData.slice(
              (page - 1) * perPage,
              page * perPage,
            );
            return {
              data: data as RecordType[],
              total: unpaginatedData.length,
            };
          })
          .then(res => {
            console.log('getList result', res);
            return res;
          })
      );
    },

    // Read a single resource, by id
    getOne: <RecordType extends Record = HtNode>(
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult<RecordType>> => {
      console.log('getOne', { resource, params });

      const { id } = params as { id: ID };

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }
      const resourceInfos = resourcesInfos[resource];

      const query = resourceInfos.queryName;
      let fragmentInfo: { name: string; Fragment: DocumentNode };
      switch (resource) {
        case 'htResidence':
          fragmentInfo = {
            name: 'HtResidenceFields',
            Fragment: HtResidenceFields,
          };
          break;
        case 'htRequest':
          fragmentInfo = { name: 'HtRequestFields', Fragment: HtRequestFields };
          break;
        case 'htProposition':
          fragmentInfo = {
            name: 'HtPropositionFields',
            Fragment: HtPropositionFields,
          };
          break;
        case 'htAsset':
          fragmentInfo = { name: 'HtAssetFields', Fragment: HtAssetFields };
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.getOne is not available for the resource [${resource}]`,
            ),
          );
      }
      return (
        apolloClient
          .query<{ [k: string]: GQLHtNode }>({
            query: gql`
              query {
                ${query}(id: "${id}") {
                  ...${fragmentInfo.name}
                }
              }
              ${fragmentInfo.Fragment}
          `,
          })
          // Response to data and result
          .then(response => {
            return {
              data: enhanceQueryResult<RecordType, GQLHtNode>(
                Object.values(response.data)[0],
              ),
            };
          })
          .then(res => {
            console.log('getOne result', res);
            return res;
          })
      );
    },

    /** return: {data: Partial<Node>}: Connections are not included */
    getMany: <RecordType extends Record = HtNode>(
      resource: string,
      params: GetManyParams,
    ): Promise<GetManyResult<RecordType>> => {
      console.log('getMany', { resource, params });

      // TODO: see if we can batch instead of concat https://www.apollographql.com/docs/link/links/batch-http/

      const { ids } = params as {
        ids: ID[];
      };

      let queries: string;
      let Fragment: DocumentNode;

      switch (resource) {
        case 'htProposition':
          queries = ids.reduce(
            (acc: string, id, index) =>
              acc.concat(`
                proposition${index}: proposition(id: "${id}") {
                  ...HtPropositionFields
                }
              `),
            '',
          );
          Fragment = HtPropositionFields;
          break;
        case 'htAsset':
          queries = ids.reduce(
            (acc: string, id, index) =>
              acc.concat(`
                asset${index}: asset(id: "${id}") {
                  ...HtAssetFields
                }
              `),
            '',
          );
          Fragment = HtAssetFields;
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.getMany is not available for the resource [${resource}]`,
            ),
          );
      }

      return apolloClient
        .query<{ [k: string]: GQLHtNode }>({
          query: gql`
            query {
              ${queries}
            }
            ${Fragment}
          `,
        })
        .then(response => {
          if (!response.data) {
            return { data: [] };
          }
          return {
            data: Object.values(response.data).map(r =>
              enhanceQueryResult<RecordType, GQLHtNode>(r),
            ),
          };
        })
        .then(res => {
          console.log('getMany result', res);
          return res;
        });
    },

    getManyBase: <RecordType extends Record = HtNode>(
      resource: string,
      params: GetManyParams,
    ): Promise<GetManyResult<RecordType>> => {
      console.log('getManyBase', { resource, params });

      const { ids } = params as {
        ids: ID[];
      };
      if (ids.length === 0) {
        return Promise.resolve({
          data: [],
        });
      }

      let queries: string;
      let Fragment: DocumentNode;

      switch (resource) {
        case 'htProposition':
          queries = ids.reduce(
            (acc: string, id, index) =>
              acc.concat(`
                proposition${index}: proposition(id: "${id}") {
                  ...HtPropositionFieldsBase
                }
              `),
            '',
          );
          Fragment = HtPropositionFieldsBase;
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.getManyBase is not available for the resource [${resource}]`,
            ),
          );
      }

      return apolloClient
        .query<{ [k: string]: GQLHtNode }>({
          query: gql`
            query {
              ${queries}
            }
            ${Fragment}
          `,
        })
        .then(response => {
          if (!response.data) {
            return { data: [] };
          }
          return {
            data: Object.values(response.data).map(r =>
              enhanceQueryResult<RecordType, GQLHtNode>(r),
            ),
          };
        })
        .then(res => {
          console.log('getManyBase result', res);
          return res;
        });
    },

    // Read a list of resources related to another one
    getManyReference: <
      RecordType extends Record = Record,
    >(): // resource: string,
    // params: GetManyReferenceParams,
    Promise<GetManyReferenceResult<RecordType>> => {
      // console.log('getManyReference', { resource, params });

      return Promise.reject(
        Error('dataProvider.getManyReference is not yet implemented'),
      );
    },

    // Create a single resource
    create: <RecordType extends Record = HtNode>(
      resource: string,
      params: CreateParams,
    ): Promise<CreateResult<RecordType>> => {
      console.log('create', { resource, params });
      const { data } = params as { data: NodeInput };

      if (!resourceIsValid(resource)) {
        return Promise.reject(Error(`Resource [${resource}] is invalid`));
      }
      const resourceInfos = resourcesInfos[resource];

      let mutation: DocumentNode;
      let variables: CreateNodeVariables;

      switch (resource) {
        case 'htResidence':
          const residenceInput: ResidenceInput = data;

          mutation = gql`
            mutation ($data: ResidenceInput!) {
              createResidence(data: $data) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          variables = {
            data: residenceInput,
          };
          break;
        case 'htRequest':
          const requestInput: RequestInput = data;

          mutation = gql`
            mutation ($data: RequestInput!, $transaction: Transaction = BEGIN) {
              createRequest(data: $data, transaction: $transaction) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          variables = {
            data: requestInput,
          };
          break;
        case 'htProposition':
          const propositionInput: PropositionInput = data;

          mutation = gql`
            mutation ($data: PropositionInput!) {
              createProposition(data: $data) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          variables = {
            data: propositionInput,
          };
          break;
        case 'htAsset':
          const assetInput: AssetInput = data;

          mutation = gql`
            mutation ($data: AssetInput!) {
              createAsset(data: $data) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          variables = {
            data: assetInput,
          };
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.create is not available for the resource [${resource}]`,
            ),
          );
      }

      if (resourceInfosHasConnections(resourceInfos)) {
        resourceInfos.connections.forEach(connection => {
          const connectionKey = connection.key;
          const inputData = variables.data as typeof variables.data & {
            [k: string]: SimplifiedConnection;
          };
          const connectionData = inputData[connectionKey] as
            | SimplifiedConnectionMany
            | SimplifiedConnectionOne
            | undefined;
          if (typeof connectionData !== 'undefined') {
            (variables.data as any)[connectionKey] = {
              connect: _castArray(connectionData),
            };
          }
        });
      }

      return apolloClient
        .mutate<MutationCreateHtNode>({
          mutation,
          variables,
          update: (store, { data: resultData }) => {
            if (resultData) {
              const nodeUpdate = Object.values(resultData)[0] as HtNodeUpdate;
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          console.log('create response', response);
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.create did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data) as HtNodeUpdate[];
          return {
            data: enhanceQueryResult<RecordType, GQLHtNode>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('create result', res);
          return res;
        });
    },

    // Update a single resource
    update: <RecordType extends Record = HtNode>(
      resource: string,
      params: UpdateParams, // Replace<UpdateParams, 'data', SimplifiedNode>,
    ): Promise<UpdateResult<RecordType>> => {
      console.log('update', { resource, params });
      const { id, data, previousData } = params as {
        id: ID;
        data: RecordType;
        previousData: HtNode;
      };

      if (!resourceIsValid(resource)) {
        return Promise.reject(Error(`Resource [${resource}] is invalid`));
      }
      const resourceInfos = resourcesInfos[resource];

      let mutation: DocumentNode;
      let variables: UpdateNodeVariables;

      const shouldClearStore = false;

      switch (resource) {
        case 'htResidence':
          if (!nodeIsResidence(data)) {
            return Promise.reject(
              Error(
                'On updating ressource "htResidence", sent data was not a "Residence"',
              ),
            );
          }

          const residenceInput = extractUpdateDiff(
            data,
            previousData as typeof data,
          );

          // Nothing to update
          if (Object.keys(residenceInput).length <= 0) {
            return Promise.resolve({ data });
          }

          variables = {
            id: `${id}`,
            data: residenceInput,
            version: +data.version,
          };
          mutation = gql`
            mutation ($id: ID!, $data: ResidenceInput!, $version: Int!) {
              updateResidence(id: $id, data: $data, version: $version) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        case 'htRequest':
          if (!nodeIsRequest(data)) {
            return Promise.reject(
              Error(
                'On updating ressource "htRequest", sent data was not a "Request"',
              ),
            );
          }

          const requestInput = extractUpdateDiff(
            data,
            previousData as typeof data,
          );

          // Nothing to update
          if (Object.keys(requestInput).length <= 0) {
            return Promise.resolve({ data });
          }

          variables = {
            id: `${id}`,
            data: requestInput,
            version: +data.version,
          };
          mutation = gql`
            mutation (
              $id: ID!
              $data: RequestInput!
              $version: Int!
              $transaction: Transaction = BEGIN
            ) {
              updateRequest(
                id: $id
                data: $data
                version: $version
                transaction: $transaction
              ) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        case 'htProposition':
          if (!nodeIsProposition(data)) {
            return Promise.reject(
              Error(
                'On updating ressource "htProposition", sent data was not a "Proposition"',
              ),
            );
          }

          const propositionInput = extractUpdateDiff(
            data,
            previousData as typeof data,
          );

          // Nothing to update
          if (Object.keys(propositionInput).length <= 0) {
            return Promise.resolve({ data });
          }

          variables = {
            id: `${id}`,
            data: propositionInput,
            version: +data.version,
          };
          mutation = gql`
            mutation ($id: ID!, $data: PropositionInput!, $version: Int!) {
              updateProposition(id: $id, data: $data, version: $version) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.update is not available for the resource [${resource}]`,
            ),
          );
      }

      if (resourceInfosHasConnections(resourceInfos)) {
        resourceInfos.connections.forEach(connection => {
          const connectionKey = connection.key;

          const inputData = variables.data as typeof variables.data & {
            [k: string]: SimplifiedConnection;
          };
          let connectionIds = inputData[connectionKey] as
            | SimplifiedConnectionMany
            | SimplifiedConnectionOne
            | undefined;
          // undefined means that connection have not changed
          if (typeof connectionIds !== 'undefined') {
            if (!nodeEntryIsSimplifiedConnectionMany(connectionIds)) {
              connectionIds = [connectionIds];
            }

            const pData = previousData as typeof previousData & {
              [k: string]: SimplifiedConnection;
            };
            let previousConnectionIds = pData[connectionKey] as
              | SimplifiedConnectionMany
              | SimplifiedConnectionOne
              | undefined;
            if (!nodeEntryIsSimplifiedConnectionMany(previousConnectionIds)) {
              previousConnectionIds =
                typeof previousConnectionIds !== 'undefined'
                  ? [previousConnectionIds]
                  : [];
            }

            let removedConnectionIds: SimplifiedConnectionMany = [];
            let addedConnectionIds = connectionIds;
            if (previousConnectionIds.length) {
              removedConnectionIds = previousConnectionIds.filter(
                // TS Bug connectionIds is already forced to be SimplifiedConnectionMany
                cId =>
                  !(connectionIds as SimplifiedConnectionMany).includes(cId),
              );
              addedConnectionIds = connectionIds.filter(
                // TS Bug previousConnectionIds is already forced to be SimplifiedConnectionMany
                cId =>
                  !(previousConnectionIds as SimplifiedConnectionMany).includes(
                    cId,
                  ),
              );
            }

            (variables.data as any)[connectionKey] = {
              connect: addedConnectionIds,
              disconnect: removedConnectionIds,
            };
          }
        });
      }

      return apolloClient
        .mutate<MutationUpdateNode>({
          mutation,
          variables,
          update: (store, { data: responseData }) => {
            if (responseData) {
              const nodeUpdate = Object.values(responseData)[0] as HtNodeUpdate;
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          if (shouldClearStore) {
            apolloClient.clearStore();
          }
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.update did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data) as HtNodeUpdate[];
          return {
            data: enhanceQueryResult<RecordType, GQLHtNode>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('update result', res);
          return res;
        });
    },

    // Update multiple resources
    updateMany: (): // resource: string,
    // params: UpdateManyParams,
    Promise<UpdateManyResult & { data?: HtNode[] }> => {
      // // updateMany (call update many times ??)
      // console.log('updateMany', { resource, params });

      return Promise.reject(
        Error(`dataProvider.updateMany is not yet implemented`),
      );
    },

    // Delete a single resource
    delete: <RecordType extends Record = HtNode>(
      resource: string,
      params: DeleteParams, // & { previousData?: SimplifiedNode },
    ): Promise<DeleteResult<RecordType>> => {
      console.log('delete', { resource, params });
      const { id } = params as { id: ID };

      let mutation: DocumentNode;

      // let shouldClearStore = false;

      const variables = { id };
      switch (resource) {
        case 'htResidence':
          mutation = gql`
            mutation ($id: ID!) {
              deleteResidence(id: $id) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        case 'htRequest':
          // FIXME - add transaction when backend is fixed !
          mutation = gql`
            mutation ($id: ID!) {
              deleteRequest(id: $id) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        case 'htProposition':
          mutation = gql`
            mutation ($id: ID!) {
              deleteProposition(id: $id) {
                ...UpdateFragments
              }
            }
            ${UpdateFragmentsBase(resource)}
          `;
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.delete is not available for the resource [${resource}]`,
            ),
          );
      }

      return apolloClient
        .mutate<MutationDeleteNode>({
          mutation,
          variables,
          update: (store, { data }) => {
            if (data) {
              const nodeUpdate = Object.values(data)[0] as HtNodeUpdate;
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.delete did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data) as HtNodeUpdate[];
          return {
            data: enhanceQueryResult<RecordType, GQLHtNode>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('delete result', res);
          return res;
        });
    },

    // Delete multiple resources
    // TODO: call delete many times ??
    deleteMany: (
      resource: string,
      params: DeleteManyParams,
    ): Promise<DeleteManyResult & { data?: ID[] }> => {
      console.log('deleteMany', { resource, params });
      const { ids } = params as {
        ids: ID[];
      };

      let mutations: string; // TODO: see if possible to use DocumentNode directly?

      switch (resource) {
        case 'htResidence':
          mutations = ids.reduce(
            (acc: string, id, index) =>
              // use unified updateFragments ?
              // we need typename for the subscription to work properly
              acc.concat(`
                deleteResidence${index}: deleteResidence(id: "${id}") {
                  __typename
                  id
                  node {
                    __typename
                  }
                  updateInfo {
                    ...NodeUpdateInfoFields
                  }
                }
              `),
            '',
          );
          break;
        case 'htRequest':
          mutations = ids.reduce(
            (acc: string, id, index) =>
              acc.concat(`
                deleteRequest${index}: deleteRequest(id: "${id}") {
                  __typename
                  id
                  node {
                    __typename
                  }
                  updateInfo {
                    ...NodeUpdateInfoFields
                  }
                }
              `),
            '',
          );
          break;
        case 'htProposition':
          mutations = ids.reduce(
            (acc: string, id, index) =>
              acc.concat(`
                deleteProposition${index}: deleteProposition(id: "${id}") {
                  __typename
                  id
                  node {
                    __typename
                  }
                  updateInfo {
                    ...NodeUpdateInfoFields
                  }
                }
              `),
            '',
          );
          break;
        default:
          return Promise.reject(
            Error(
              `dataProvider.deleteMany is not available for the resource [${resource}]`,
            ),
          );
      }

      return apolloClient
        .mutate<MutationDeleteNodesID>({
          mutation: gql`
            mutation {
              ${mutations}
            }
            ${NodeUpdateInfoFields}
          `,
          update: (store, { data }) => {
            if (data) {
              Object.values(data).forEach(nodeUpdate => {
                updateStore(nodeUpdate, apolloClient);
              });
            }
          },
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.deleteMany did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          return {
            data: Object.values(response.data).map(({ id }) => id),
          };
        })
        .then(res => {
          console.log('deleteMany result', res);
          return res;
        });
    },
  };
  const htDataProviderWithUpload = withFileUpload(htDataProvider);
  const htDataProviderWithObserver = withObserver(
    htDataProviderWithUpload,
    apolloClient,
    {
      htResidence: [obsHtResidence, obsHtResidenceInit],
      htRequest: [obsHtRequest, obsHtRequestInit],
    },
  );
  return htDataProviderWithObserver;
};
