export const resourcesKeys = [
  'htResidence',
  'htRequest',
  'htProposition',
  'htAsset',
] as const;
export type ResourceKey = (typeof resourcesKeys)[number];

export function resourceIsValid(resource: string): resource is ResourceKey {
  return (
    !!resource &&
    typeof resource === 'string' &&
    resourcesKeys.includes(resource as ResourceKey)
  );
}
