import { Translate, useTranslate } from 'ra-core';
import { useCallback } from 'react';

import _upperFirst from 'lodash/upperFirst';
import _lowerCase from 'lodash/lowerCase';
import _startCase from 'lodash/startCase';

export function translateIf(
  translate: Translate,
  key: string,
  options?: { smart_count?: number; brut?: boolean },
): string {
  const keySource = key.split('.').pop() ?? key;
  const smartCount =
    typeof options === 'number' ? options : options?.smart_count ?? 1;
  let translation = translate(key, {
    _: '',
    ...options,
    smart_count: smartCount,
  });

  if (!translation) {
    console.warn(`Missing translation for key: "${key}"`);
    translation = options?.brut
      ? keySource
      : _upperFirst(_lowerCase(_startCase(keySource)));
  }
  return translation;
}

export function useTranslateIf(): ReturnType<typeof useTranslate> {
  const translate = useTranslate();

  return useCallback(translateIf.bind(null, translate), [translate]);
}
