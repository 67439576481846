import React from 'react';
import { useDataProvider, DataProvider, useRecordContext } from 'react-admin';
import createReport from 'docx-templates';
import { format } from 'date-fns';

import { CustomMinoProvider } from '../../dataProvider/dataProvider';
import { SMinoRequest, SMinoUser } from '../../types/mino/nodes';

const docxFilename = () => {
  return `${format(new Date(), 'yyMMdd-HHmmss')}-convention.docx`;
};

const dataCleanup = (data: SMinoRequest, user: SMinoUser) => {
  return {
    ...data,
    dateBail: data.dateBail && format(new Date(data.dateBail), 'dd/MM/yyyy'),
    user: user && {
      ...user,
      bday: user.bday && format(new Date(user.bday), 'dd/MM/yyyy'),
    },
    $$todayDate: format(new Date(), 'dd/MM/yyyy'),
  };
};

export const useMakeDocx = () => {
  const request = useRecordContext<SMinoRequest>();
  const dp = useDataProvider<DataProvider, CustomMinoProvider>();

  const cb = React.useCallback<() => Promise<File | void>>(() => {
    const { user: uid } = request;
    if (!uid) return Promise.resolve();

    const action = async () => {
      const { data: user } = await dp.getOne<SMinoUser>('minoUser', {
        id: uid,
      });
      if (!user) return undefined;
      const template = await fetch('./aneth-convention.docx').then(res =>
        res.arrayBuffer(),
      );
      const report = await createReport({
        template: Buffer.from(template),
        data: dataCleanup(request, user),
        cmdDelimiter: ['{{', '}}'],
      });

      const file = new File([report], docxFilename(), {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        lastModified: Date.now(),
      });
      return file;
    };
    return action();
  }, [dp, request]);
  return cb;
};
