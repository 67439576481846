import React, { useCallback } from 'react';
import {
  ReferenceArrayField,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  Record,
} from 'react-admin';
import { useDataProvider } from 'ra-core';
import moment from 'moment';
import { useForm, useField } from 'react-final-form';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import PaymentDeleteButton from './PaymentDeleteButton';

import {
  SMinoUser,
  MinoPayment,
  SMinoPayment,
} from '../../../types/mino/nodes';
import { ClassName } from '../../../types/styles';

import config from '../../../config';
import { Rights } from '../../../authProvider/permissions';
import { ID } from '../../../types/nodes';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    numOrDateCell: {
      textAlign: 'center',
      flexDirection: 'row',
    },
  }),
);

type UserPaymentsProps = {
  record?: SMinoUser;
  permissions?: Rights[];
  className?: ClassName;
};

const UserPayments: React.FC<UserPaymentsProps> = ({
  className,
  ...raProps
}: UserPaymentsProps) => {
  const classes = useStyles();
  const { record: user, permissions } = raProps;

  const dataProvider = useDataProvider();
  const form = useForm();
  const field = useField('payments');

  const canDelete = permissions?.includes(Rights.MINO_DELETE);

  const handleDeletePayment = useCallback(
    (deletedPayment: SMinoPayment) => {
      dataProvider
        .delete('minoPayment', {
          id: deletedPayment.id,
          previousData: deletedPayment,
        })
        .then(() => {
          form.change(
            'payments',
            field.input.value.filter((id: ID) => id !== deletedPayment.id),
          );
        });
    },
    [dataProvider, form, field],
  );

  if (!user) {
    return null;
  }

  return (
    <ReferenceArrayField
      {...raProps}
      record={{ payments: field.input.value } as unknown as Record}
      reference="minoPayment"
      source="payments"
      addLabel={false}
      fullWidth
      sort={{ field: 'month', order: 'DESC' }}
    >
      <Datagrid>
        <FunctionField
          sortable={false}
          source="month"
          render={(record?: Record): string => {
            const { month } = (record as MinoPayment) || {};
            return (
              month && moment(month, config.awsDateFormat).format('MM/YYYY')
            );
          }}
        />
        <FunctionField
          sortable={false}
          source="paySlip"
          render={(record?: Record): string => {
            const { paySlip } = (record as MinoPayment) || {};
            return (
              paySlip && moment(paySlip, config.awsDateFormat).format('MM/YYYY')
            );
          }}
        />
        <NumberField
          sortable={false}
          source="rent"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <NumberField
          sortable={false}
          source="provDeduction"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <NumberField
          sortable={false}
          source="deduction"
          cellClassName={classes.numOrDateCell}
          headerClassName={classes.numOrDateCell}
        />
        <TextField sortable={false} source="provider" />
        <TextField sortable={false} source="sncfSA" />
        <TextField sortable={false} source="sncfBranche" />
        <TextField sortable={false} source="sncfDivision" />
        <TextField sortable={false} source="sncfRG" />
        <TextField sortable={false} source="sncfEtablissement" />
        {canDelete && (
          <PaymentDeleteButton onConfirmDelete={handleDeletePayment} />
        )}
      </Datagrid>
    </ReferenceArrayField>
  );
};

export default UserPayments;
