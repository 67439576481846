import React, { useMemo } from 'react';
import { useGetMany, useTranslate } from 'ra-core';

import { TextField } from '@material-ui/core';

import { GQLMinoStatus } from '../../../types/mino/schema';
import { MinoRequest, SMinoUser } from '../../../types/mino/nodes';
import FormRow from '../../structure/FormRow';

type UserLastRequestsInfosProps = {
  record?: SMinoUser;
};

const UserLastRequestsInfos: React.FC<UserLastRequestsInfosProps> = ({
  ...raProps
}: UserLastRequestsInfosProps) => {
  const { record: user } = raProps;

  const translate = useTranslate();

  const { data, loading, error } = useGetMany(
    'minoRequest',
    user?.requests ?? [],
  );
  const lastRequest = useMemo(
    () =>
      (data as MinoRequest[]).reduce<MinoRequest | undefined>(
        (acc, request) => {
          if (request && request.status !== GQLMinoStatus.PUBLISHED) return acc; // #199 Hide archived requests
          if (!acc) return request;
          if ((request.createdAt ?? 0) > (acc.createdAt ?? 0)) return request;
          return acc;
        },
        undefined,
      ),
    [data],
  );

  if (error) {
    console.error('Error loading user requests');
    return null;
  }
  if (!user || loading || !lastRequest) {
    return null;
  }

  return (
    <FormRow>
      <TextField
        label={translate(`resources.minoRequest.fields.sncfCompany`)}
        value={lastRequest.sncfCompany || ''}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoRequest.fields.sncfPostalCode`)}
        value={lastRequest.sncfPostalCode || ''}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoRequest.fields.sncfContractBeginDate`)}
        value={lastRequest.sncfContractBeginDate || ''}
        type={lastRequest.sncfContractBeginDate ? 'date' : undefined}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
      <TextField
        label={translate(`resources.minoRequest.fields.sncfEmployeeType`)}
        value={lastRequest.sncfEmployeeType || ''}
        variant="filled"
        fullWidth
        margin="dense"
        disabled
      />
    </FormRow>
  );
};

export default UserLastRequestsInfos;
