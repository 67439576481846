import gql from 'graphql-tag';

import { NodeFragment } from '../fragments';

export const MinoUserFieldsBase = gql`
  fragment MinoUserFieldsBase on User {
    bday
    createdAt
    email
    familyName
    givenName
    honorificPrefix
    id
    sncfBranche
    sncfCP
    sncfDivision
    sncfEtablissement
    sncfImportDate
    sncfRG
    sncfSA
    status
    tel
    updatedAt
    version
  }
`;

export const MinoRequestFieldsBase = gql`
  fragment MinoRequestFieldsBase on Request {
    addressLevel2
    bday
    comment
    motifRefus
    createdAt
    dateBail
    email
    familyName
    gdpr
    givenName
    honorificPrefix
    id
    postalCode
    provider
    receipts
    residenceCode
    sncfCompany
    sncfContractBeginDate
    sncfCP
    sncfEmployeeType
    sncfPostalCode
    sncfSite
    sncfStatut
    status
    streetAddress
    tel
    updatedAt
    version
    wfNext
    wfNotify
    wfStatus
  }
`;

export const MinoContractFieldsBase = gql`
  fragment MinoContractFieldsBase on Contract {
    createdAt
    id
    sncfContractBeginDate
    sncfContractEndDate
    sncfContractType
    status
    updatedAt
    version
  }
`;

export const MinoPaymentFieldsBase = gql`
  fragment MinoPaymentFieldsBase on Payment {
    createdAt
    deduction
    fullAccomodation
    fullEmployment
    id
    month
    paySlip
    provider
    provDeduction
    rent
    sncfBranche
    sncfDivision
    sncfEtablissement
    sncfRG
    sncfSA
    status
    updatedAt
    version
  }
`;

export const MinoRentFieldsBase = gql`
  fragment MinoRentFieldsBase on Rent {
    createdAt
    endDate
    fullAccomodation
    id
    month
    provDeduction
    rent
    startDate
    status
    updatedAt
    version
  }
`;

export const MinoAssetFieldsBase = gql`
  fragment MinoAssetFieldsBase on Asset {
    filename
    createdAt
    id
    key
    size
    status
    type
    updatedAt
    version
  }
`;

/** Only `on Request` */
export const TransactionField = gql`
  fragment TransactionField on Request {
    transaction {
      state
      begin
      commit
      rollback
      message
    }
  }
`;

export const MinoRequestFieldsBaseWithRents = gql`
  fragment MinoRequestFieldsBaseWithRents on Request {
    ...NodeFields
    ...MinoRequestFieldsBase
    ...TransactionField
    rents @connection(key: "rents") {
      edges {
        node {
          ...NodeFields
          ...MinoRentFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoRequestFieldsBase}
  ${TransactionField}
  ${MinoRentFieldsBase}
`;

export const MinoUserFields = gql`
  fragment MinoUserFields on User {
    ...NodeFields
    ...MinoUserFieldsBase
    contracts @connection(key: "contracts") {
      edges {
        node {
          ...NodeFields
          ...MinoContractFieldsBase
        }
      }
    }
    payments @connection(key: "payments") {
      edges {
        node {
          ...NodeFields
          ...MinoPaymentFieldsBase
        }
      }
    }
    requests @connection(key: "requests") {
      edges {
        node {
          ...NodeFields
          ...MinoRequestFieldsBaseWithRents
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoUserFieldsBase}
  ${MinoContractFieldsBase}
  ${MinoPaymentFieldsBase}
  ${MinoRequestFieldsBaseWithRents}
`;

export const MinoRequestFields = gql`
  fragment MinoRequestFields on Request {
    ...NodeFields
    ...MinoRequestFieldsBase
    ...TransactionField
    rents @connection(key: "rents") {
      edges {
        node {
          ...NodeFields
          ...MinoRentFieldsBase
        }
      }
    }
    attachments @connection(key: "attachments") {
      edges {
        node {
          ...NodeFields
          ...MinoAssetFieldsBase
        }
      }
    }
    user @connection(key: "user") {
      edges {
        node {
          ...NodeFields
          ...MinoUserFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoRequestFieldsBase}
  ${TransactionField}
  ${MinoRentFieldsBase}
  ${MinoAssetFieldsBase}
  ${MinoUserFieldsBase}
`;

export const MinoContractFields = gql`
  fragment MinoContractFields on Contract {
    ...NodeFields
    ...MinoContractFieldsBase
    user @connection(key: "user") {
      edges {
        node {
          ...NodeFields
          ...MinoUserFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoContractFieldsBase}
  ${MinoUserFieldsBase}
`;

export const MinoPaymentFields = gql`
  fragment MinoPaymentFields on Payment {
    ...NodeFields
    ...MinoPaymentFieldsBase
    user @connection(key: "user") {
      edges {
        node {
          ...NodeFields
          ...MinoUserFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoPaymentFieldsBase}
  ${MinoUserFieldsBase}
`;

export const MinoRentFields = gql`
  fragment MinoRentFields on Rent {
    ...NodeFields
    ...MinoRentFieldsBase
    request @connection(key: "request") {
      edges {
        node {
          ...NodeFields
          ...MinoRequestFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoRentFieldsBase}
  ${MinoRequestFieldsBase}
`;

export const MinoAssetFields = gql`
  fragment MinoAssetFields on Asset {
    ...NodeFields
    ...MinoAssetFieldsBase
    request @connection(key: "request") {
      edges {
        node {
          ...NodeFields
          ...MinoRequestFieldsBase
        }
      }
    }
  }
  ${NodeFragment}
  ${MinoAssetFieldsBase}
  ${MinoRequestFieldsBase}
`;

export const MinoHistoryFields = gql`
  fragment MinoHistoryFields on History {
    action
    connId
    connType
    details
    identity
    wfStatus
  }
`;

export const MinoLogEventFields = gql`
  fragment MinoLogEventFields on LogEvent {
    id
    ingestionTime
    timestamp
    message {
      ...MinoHistoryFields
    }
  }
  ${MinoHistoryFields}
`;
