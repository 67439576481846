import {
  DataProvider,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  Record,
} from 'ra-core';
import _partition from 'lodash/partition';

import { uploadS3 } from '../../aws/s3-utils';
import resourcesInfos, { resourceInfosHasConnections } from './resourcesInfos';
import { resourceIsValid } from './resourcesKeys';
import {
  FormattedFile,
  FileInputFormatted,
  AssetsData,
  assetsDataIsAssetIds,
  assetDataIsFile,
} from '../../components/CustomInputs/AssetInput';
import { HtAsset, HtNode } from '../../types/ht/nodes';
import { ID } from '../../types/ht/base';
import { CustomHTProvider } from '../dataProvider';

/** Upload file to S3 and get it's ID */
function uploadFile(
  file: File,
  dataProvider: Pick<DataProvider, 'create'>,
): Promise<CreateResult & { data: HtAsset }> {
  return uploadS3(file)
    .then(({ key, upload }) =>
      upload.then(() => ({
        filename: file.name,
        size: file.size,
        type: file.type,
        key,
      })),
    )
    .then(assetInput => {
      return dataProvider.create('htAsset', { data: assetInput }) as Promise<
        CreateResult & { data: HtAsset }
      >;
    });
}

/**
 * For resources that can have `asset`s,
 * on `create` and `update`,
 * 1) upload new files to S3 and get their affected ID
 * 2) replace param[assetKey] with the new IDs list
 */
const withFileUpload = <T extends DataProvider>(dataProvider: T): T => ({
  ...dataProvider,
  create: async <RecordType extends Record = Record>(
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<RecordType>> => {
    const { data } = params as {
      id: ID;
      data: HtNode & { [k: string]: AssetsData };
    };
    if (!resourceIsValid(resource)) {
      return dataProvider.create(resource, params);
    }
    const resourceInfos = resourcesInfos[resource];
    if (!resourceInfosHasConnections(resourceInfos)) {
      return dataProvider.create(resource, params);
    }
    const assetConectionInfos = resourceInfos.connections.find(
      cInfos => cInfos.resource === 'htAsset',
    );
    if (!assetConectionInfos) {
      return dataProvider.create(resource, params);
    }
    const assetKey = assetConectionInfos.key;
    let assetsData = data[assetKey] as AssetsData;
    if (!assetsData) {
      return dataProvider.create(resource, params);
    }

    if (!Array.isArray(assetsData)) {
      assetsData = [assetsData];
    }

    let newFiles: File[] = [];
    let reusedAssetsIds: ID[] = []; // Duplication
    // On duplication, if no file modification
    if (assetsDataIsAssetIds(assetsData)) {
      reusedAssetsIds = assetsData;
    } else {
      const [newFormattedFiles, reusedFormattedAssets] = _partition<
        FileInputFormatted,
        FormattedFile
      >(assetsData, assetDataIsFile);
      newFiles = newFormattedFiles.map(({ rawFile }) => rawFile);
      reusedAssetsIds = reusedFormattedAssets.map(({ assetId }) => assetId);
    }
    const createdAssetsResults = await Promise.all(
      newFiles.map(file => uploadFile(file, dataProvider)),
    );
    const createdAssetsIDs = createdAssetsResults.map(({ data: { id } }) => id);

    return dataProvider.create(resource, {
      ...params,
      data: {
        ...data,
        [assetKey]: [...reusedAssetsIds, ...createdAssetsIDs],
      },
    });
  },
  update: async <RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<RecordType>> => {
    const { data } = params as {
      id: ID;
      data: HtNode & { [k: string]: AssetsData };
    };
    if (!resourceIsValid(resource)) {
      return dataProvider.update(resource, params);
    }
    const resourceInfos = resourcesInfos[resource];
    if (!resourceInfosHasConnections(resourceInfos)) {
      return dataProvider.update(resource, params);
    }
    const assetConectionInfos = resourceInfos.connections.find(
      cInfos => cInfos.resource === 'htAsset',
    );
    if (!assetConectionInfos) {
      return dataProvider.update(resource, params);
    }
    const assetKey = assetConectionInfos.key;
    let assetsData = data[assetKey] as AssetsData;
    if (!assetsData) {
      return dataProvider.update(resource, params);
    }

    if (!Array.isArray(assetsData)) {
      assetsData = [assetsData];
    }

    let newFiles: File[] = [];
    let formerAssetsIds: ID[] = [];
    if (assetsDataIsAssetIds(assetsData)) {
      formerAssetsIds = assetsData;
    } else {
      const [newFormattedFiles, formerFormattedAssets] = _partition<
        FileInputFormatted,
        FormattedFile
      >(assetsData, assetDataIsFile);
      newFiles = newFormattedFiles.map(({ rawFile }) => rawFile);
      formerAssetsIds = formerFormattedAssets.map(({ assetId }) => assetId);
    }

    return Promise.all(
      newFiles.map(file => uploadFile(file, dataProvider)),
    ).then(createdAssetsResults => {
      const createdAssetsIDs = createdAssetsResults.map(
        ({ data: { id } }) => id,
      );

      return dataProvider.update(resource, {
        ...params,
        data: {
          ...data,
          [assetKey]: [...formerAssetsIds, ...createdAssetsIDs],
        },
      });
    });
  },
});

export default withFileUpload;
