import React from 'react';

import ErrorBoundary from '../ErrorBoundary';

import MinoManagement from './MinoManagement';

const MinoManagementWrapper: React.FC = () => {
  return (
    <ErrorBoundary>
      <MinoManagement />
    </ErrorBoundary>
  );
};

export default MinoManagementWrapper;
