import { sleep } from './utils';

function allProgress(
  promises: Promise<any>[],
  progressCb: (p: number) => void,
): Promise<any[]> {
  let d: Parameters<typeof progressCb>[0] = 0;
  progressCb(d);
  promises.forEach(p => {
    p.then(() => {
      d += 1;
      progressCb(d / promises.length);
    });
  });
  return Promise.all(promises);
}

function progressPromise(
  promises: Promise<any>[],
  tickCallback: (p: number) => void,
): Promise<any[]> {
  var len = promises.length;
  var progress = 0;
  tickCallback(0);

  function tick(promise: Promise<any>): Promise<any> {
    promise.then(function tickPromise() {
      progress += 1;
      tickCallback(progress / len);
    });
    return promise;
  }

  return Promise.all(promises.map(tick));
}

async function progressPreparedPromisesBatch(
  preparedPromises: Array<[(...args: any) => Promise<any>, any[]]>,
  tickCallback: (p: number) => void,
  batchMax = 5,
): Promise<any[]> {
  const nbPromises = preparedPromises.length;
  const batchedPromises: Promise<any>[] = [];

  for (let index = 0; index < nbPromises; index += 1) {
    const [func, args] = preparedPromises[index];
    batchedPromises.push(func(...args));
    if (batchedPromises.length % batchMax === 0) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(batchedPromises);
      // eslint-disable-next-line no-await-in-loop
      await sleep(5000);
      tickCallback(batchedPromises.length / nbPromises);
    }
  }
  return Promise.all(batchedPromises);
}

// eslint-disable-next-line import/prefer-default-export
export { allProgress, progressPromise, progressPreparedPromisesBatch };
