import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

import { NodeFragment, NodeUpdateInfoFields } from '../fragments';
import { TransactionField } from './fragments';
import { ResourceKey } from '../../dataProvider/mino/resourcesKeys';
import resourcesInfos from '../../dataProvider/mino/resourcesInfos';

export const AllMinoFragmentsBase = gql`
  ${Object.values(resourcesInfos).reduce(
    (acc: DocumentNode, resourceInfo) => gql`
      ${acc}
      ${resourceInfo.fragment.base}
    `,
    '' as unknown as DocumentNode,
  )}
`;

export const UpdateMinoFragmentsBase = (
  resource: 'all' | ResourceKey = 'all',
): DocumentNode => {
  let fragments: DocumentNode = AllMinoFragmentsBase;
  let fragmentFields = '';
  if (resource !== 'all') {
    const resourceInfo = resourcesInfos[resource];
    fragments = resourceInfo.fragment.base;
    fragmentFields = `...${resourceInfo.fragment.name}Base`;
  } else {
    Object.values(resourcesInfos).forEach(resourceInfo => {
      fragmentFields = `${fragmentFields}
        ...${resourceInfo.fragment.name}Base
      `;
    });
  }
  return gql`
    fragment UpdateFragmentsBase on NodeUpdate {
      __typename
      id
      node {
        ...NodeFields
        ...TransactionField
        ${fragmentFields}
      }
      updateInfo {
        ...NodeUpdateInfoFields
      }
    }
    ${NodeFragment}
    ${fragments}
    ${NodeUpdateInfoFields}
    ${TransactionField}
  `;
};
