import { Translate } from 'ra-core';
import { downloadCSV } from 'react-admin';
import * as jsonExport from 'jsonexport/dist';
import _forEach from 'lodash/forEach';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import moment from 'moment';
import { CloudWatchLogs } from 'aws-sdk';

import config from '../../config';

import { enumsKeys } from '../../i18n/frenchMessages';
import {
  GQLMinoMinoStatus,
  GQLMinoPaymentEdge,
  GQLMinoRequestEdge,
  GQLMinoStatus,
} from '../../types/mino/schema';
import { Columns, getHeaders, getResourceFields } from '../utils';
import { translateIf } from '../../hooks/use-translate-if';
import { CustomMinoProvider } from '../../dataProvider/dataProvider';

const columns: Columns = [
  { resource: 'minoUser', field: 'id' },
  { resource: 'minoUser', field: 'createdAt', formatDate: true },
  { resource: 'minoUser', field: 'updatedAt', formatDate: true },
  { resource: 'minoUser', field: 'email', anonymiseArchive: true },
  { resource: 'minoUser', field: 'honorificPrefix' },
  { resource: 'minoUser', field: 'familyName', anonymiseArchive: true },
  { resource: 'minoUser', field: 'givenName', anonymiseArchive: true },
  { resource: 'minoUser', field: 'sncfCP', anonymiseArchive: true },
  { resource: 'minoUser', field: 'bday' },
  { resource: 'minoUser', field: 'tel', anonymiseArchive: true },
  { resource: 'minoUser', field: 'nbPayments' },
  { resource: 'minoUser', field: 'minoEndDate' },
  { resource: 'minoUser', field: 'lastPaymentAmount' },
  { resource: 'minoUser', field: 'totalPaymentAmount' },
  { resource: 'minoUser', field: 'nbRequests' },

  // Request 1
  { resource: 'minoRequest', field: 'id' },
  { resource: 'minoRequest', field: 'createdAt', formatDate: true },
  { resource: 'minoRequest', field: 'updatedAt', formatDate: true },
  { resource: 'minoRequest', field: 'wfStatus' },
  { resource: 'minoRequest', field: 'motifRefus' },
  { resource: 'minoRequest', field: 'sncfCompany' },
  { resource: 'minoRequest', field: 'sncfPostalCode' },
  { resource: 'minoRequest', field: 'sncfContractBeginDate' },
  { resource: 'minoRequest', field: 'sncfEmployeeType' },
  { resource: 'minoRequest', field: 'provider' },
  { resource: 'minoRequest', field: 'residenceCode' },
  { resource: 'minoRequest', field: 'dateBail' },
  { resource: 'minoRequest', field: 'receipts[0]' },
  { resource: 'minoRequest', field: 'receipts[1]' },
  { resource: 'minoRequest', field: 'receipts[2]' },
  { resource: 'minoRequest', field: 'addressLevel2' },
  { resource: 'minoRequest', field: 'postalCode' },
  // Statuses
  ...Array.from(Object.values(GQLMinoMinoStatus)).map(value => ({
    enumName: 'wfStatus',
    value,
  })),
  // Request 2
  { resource: 'minoRequest', field: 'id' },
  { resource: 'minoRequest', field: 'createdAt', formatDate: true },
  { resource: 'minoRequest', field: 'updatedAt', formatDate: true },
  { resource: 'minoRequest', field: 'wfStatus' },
  { resource: 'minoRequest', field: 'motifRefus' },
  { resource: 'minoRequest', field: 'sncfCompany' },
  { resource: 'minoRequest', field: 'sncfPostalCode' },
  { resource: 'minoRequest', field: 'sncfContractBeginDate' },
  { resource: 'minoRequest', field: 'sncfEmployeeType' },
  { resource: 'minoRequest', field: 'provider' },
  { resource: 'minoRequest', field: 'residenceCode' },
  { resource: 'minoRequest', field: 'dateBail' },
  { resource: 'minoRequest', field: 'receipts[0]' },
  { resource: 'minoRequest', field: 'receipts[1]' },
  { resource: 'minoRequest', field: 'receipts[2]' },
  { resource: 'minoRequest', field: 'addressLevel2' },
  { resource: 'minoRequest', field: 'postalCode' },
  // Statuses
  ...Array.from(Object.values(GQLMinoMinoStatus)).map(value => ({
    enumName: 'wfStatus',
    value,
  })),
  // Request 3
  { resource: 'minoRequest', field: 'id' },
  { resource: 'minoRequest', field: 'createdAt', formatDate: true },
  { resource: 'minoRequest', field: 'updatedAt', formatDate: true },
  { resource: 'minoRequest', field: 'wfStatus' },
  { resource: 'minoRequest', field: 'motifRefus' },
  { resource: 'minoRequest', field: 'sncfCompany' },
  { resource: 'minoRequest', field: 'sncfPostalCode' },
  { resource: 'minoRequest', field: 'sncfContractBeginDate' },
  { resource: 'minoRequest', field: 'sncfEmployeeType' },
  { resource: 'minoRequest', field: 'provider' },
  { resource: 'minoRequest', field: 'residenceCode' },
  { resource: 'minoRequest', field: 'dateBail' },
  { resource: 'minoRequest', field: 'receipts[0]' },
  { resource: 'minoRequest', field: 'receipts[1]' },
  { resource: 'minoRequest', field: 'receipts[2]' },
  { resource: 'minoRequest', field: 'addressLevel2' },
  { resource: 'minoRequest', field: 'postalCode' },
  // Statuses
  ...Array.from(Object.values(GQLMinoMinoStatus)).map(value => ({
    enumName: 'wfStatus',
    value,
  })),
  // Request 4
  { resource: 'minoRequest', field: 'id' },
  { resource: 'minoRequest', field: 'createdAt', formatDate: true },
  { resource: 'minoRequest', field: 'updatedAt', formatDate: true },
  { resource: 'minoRequest', field: 'wfStatus' },
  { resource: 'minoRequest', field: 'motifRefus' },
  { resource: 'minoRequest', field: 'sncfCompany' },
  { resource: 'minoRequest', field: 'sncfPostalCode' },
  { resource: 'minoRequest', field: 'sncfContractBeginDate' },
  { resource: 'minoRequest', field: 'sncfEmployeeType' },
  { resource: 'minoRequest', field: 'provider' },
  { resource: 'minoRequest', field: 'residenceCode' },
  { resource: 'minoRequest', field: 'dateBail' },
  { resource: 'minoRequest', field: 'receipts[0]' },
  { resource: 'minoRequest', field: 'receipts[1]' },
  { resource: 'minoRequest', field: 'receipts[2]' },
  { resource: 'minoRequest', field: 'addressLevel2' },
  { resource: 'minoRequest', field: 'postalCode' },
  // Statuses
  ...Array.from(Object.values(GQLMinoMinoStatus)).map(value => ({
    enumName: 'wfStatus',
    value,
  })),
  // Request 5
  { resource: 'minoRequest', field: 'id' },
  { resource: 'minoRequest', field: 'createdAt', formatDate: true },
  { resource: 'minoRequest', field: 'updatedAt', formatDate: true },
  { resource: 'minoRequest', field: 'wfStatus' },
  { resource: 'minoRequest', field: 'motifRefus' },
  { resource: 'minoRequest', field: 'sncfCompany' },
  { resource: 'minoRequest', field: 'sncfPostalCode' },
  { resource: 'minoRequest', field: 'sncfContractBeginDate' },
  { resource: 'minoRequest', field: 'sncfEmployeeType' },
  { resource: 'minoRequest', field: 'provider' },
  { resource: 'minoRequest', field: 'residenceCode' },
  { resource: 'minoRequest', field: 'dateBail' },
  { resource: 'minoRequest', field: 'receipts[0]' },
  { resource: 'minoRequest', field: 'receipts[1]' },
  { resource: 'minoRequest', field: 'receipts[2]' },
  { resource: 'minoRequest', field: 'addressLevel2' },
  { resource: 'minoRequest', field: 'postalCode' },
  // Statuses
  ...Array.from(Object.values(GQLMinoMinoStatus)).map(value => ({
    enumName: 'wfStatus',
    value,
  })),

  // Transaction 1
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 2
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 3
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 4
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 5
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 6
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 7
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 8
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 9
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 10
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 11
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
  // Transaction 12
  { resource: 'minoPayment', field: 'deduction' },
  { resource: 'minoPayment', field: 'month' },
];

type DataForExport = { [k: string]: any };

const usersExporter = async (
  translate: Translate,
  dataProvider: CustomMinoProvider,
  tickCallback?: (p: number) => void,
  { withArchived }: { withArchived?: boolean } = {},
): Promise<void> => {
  const headers = getHeaders(columns, translate);
  const minoUserEntries = getResourceFields(columns, 'minoUser');
  const minoRequestEntries = getResourceFields(columns, 'minoRequest');
  const minoPaymentEntries = getResourceFields(columns, 'minoPayment');

  const [{ data: users, flattenedData: flattenedUsers }, { data: historyAll }] =
    await Promise.all([
      dataProvider.getListAll('minoUser', { withArchived }),
      dataProvider.getHistoryAll('minoUser', { tickCallback }) as {
        data: CloudWatchLogs.FilteredLogEvents;
      },
    ]);

  const groupedHistoryAll = _groupBy(historyAll, 'logStreamName');

  const promisesUsersDataForExport = users.map((user, userIndex) => {
    const flattenedUser = flattenedUsers[userIndex];
    const isArchived =
      flattenedUser.status === 'ARCHIVED' ||
      user.requests?.edges.filter(
        ({ node: request }) => request.status === GQLMinoStatus.PUBLISHED,
      ).length === 0; // Users with no (published) requests - or with only archived requests - need to be anonymised and are considered archived

    const userDataForExport: DataForExport = {};

    // User
    // const userSelectedFields = _pick(flattenedUser, Array.from(minoUserEntries));
    _forEach(flattenedUser, (fieldValue, fieldName) => {
      const entry = minoUserEntries.get(fieldName);
      if (entry) {
        let translatedValue = fieldValue;
        if (isArchived && entry.anonymiseArchive) {
          translatedValue = '- archivé -';
        } else if (enumsKeys.includes(fieldName) && fieldValue) {
          translatedValue = translateIf(
            translate,
            `enums.${fieldName}.${fieldValue}`,
          );
        } else if (entry.formatDate) {
          translatedValue = moment(`${fieldValue}`).format('DD/MM/YYYY');
        }
        userDataForExport[translate(`resources.minoUser.fields.${fieldName}`)] =
          translatedValue;
      }
    });

    // Transactions (payments)
    (user.payments?.edges as GQLMinoPaymentEdge[]).forEach(
      ({ node: payment }, paymentIndex) => {
        _forEach(payment, (fieldValue, fieldName) => {
          const entry = minoPaymentEntries.get(fieldName);
          if (entry) {
            let translatedValue = fieldValue;
            if (enumsKeys.includes(fieldName) && fieldValue) {
              translatedValue = translateIf(
                translate,
                `enums.${fieldName}.${fieldValue}`,
              );
            } else if (entry.formatDate) {
              translatedValue = moment(`${fieldValue}`).format('DD/MM/YYYY');
            }
            userDataForExport[
              `${translate(`resources.minoPayment.fields.${fieldName}`)} ${
                paymentIndex + 1
              }`
            ] = translatedValue;
          }
        });
      },
    );

    const requestDataForExport: DataForExport = {};

    (user.requests?.edges as GQLMinoRequestEdge[])
      .filter((p, index) => index < config.mino.requests.max)
      .forEach(({ node: request }, requestIndex) => {
        // #199 Hide archived requests
        if (request.status === GQLMinoStatus.PUBLISHED || withArchived) {
          minoRequestEntries.forEach((entry, fieldName) => {
            const fieldValue = _get(request, fieldName); // FIXME use _get on other types too ?
            if (fieldValue !== undefined) {
              // FIXME always true
              let translatedValue = fieldValue;
              if (enumsKeys.includes(fieldName) && fieldValue) {
                translatedValue = translateIf(
                  translate,
                  `enums.${fieldName}.${fieldValue}`,
                );
              } else if (entry.formatDate) {
                translatedValue = moment(`${fieldValue}`).format('DD/MM/YYYY');
              } else if (typeof fieldValue === 'boolean') {
                translatedValue = fieldValue ? 'OUI' : 'NON';
              }
              requestDataForExport[
                `${translate(`resources.minoRequest.fields.${fieldName}`)} ${
                  requestIndex + 1
                }`
              ] = translatedValue;
            }

            const userHistory = groupedHistoryAll[`User/${user.id}`];
            if (userHistory) {
              userHistory.forEach(userHistoryEntry => {
                const { timestamp, message } = userHistoryEntry;
                if (message) {
                  const { wfStatus, connId } = JSON.parse(message);
                  if (connId === request.id && wfStatus) {
                    const tStatus = translate(`enums.wfStatus.${wfStatus}`);
                    requestDataForExport[`${tStatus} ${requestIndex + 1}`] =
                      moment(timestamp).format('DD/MM/YYYY');
                  }
                }
              });
            }

            // Add request current status with the creation date if it's not in the history
            if (request.wfStatus && request.createdAt) {
              const tStatus = translate(`enums.wfStatus.${request.wfStatus}`);
              if (!requestDataForExport[`${tStatus} ${requestIndex + 1}`]) {
                requestDataForExport[`${tStatus} ${requestIndex + 1}`] = moment(
                  request.createdAt,
                ).format('DD/MM/YYYY');
              }
            }
          });
        }
      });
    return { ...userDataForExport, ...requestDataForExport };
  });

  Promise.all(promisesUsersDataForExport).then(usersDataForExport => {
    jsonExport(
      usersDataForExport,
      {
        ...config.exportedCSVconfig,
        headers, // ordered headers
      },
      (err: any, csv: any) => {
        if (err) console.error(err);
        downloadCSV(
          `\ufeff${csv}`,
          `PADH_MINO_USER_${moment().format('YYYY-MM-DD')}`,
        );
      },
    );
    if (withArchived) {
      // Reload the page to delete archived in cache
      window.location.reload();
    }
  });
};

export async function usersExporterWithArchived(
  translate: Translate,
  dataProvider: CustomMinoProvider,
  tickCallback?: (p: number) => void,
): Promise<void> {
  return usersExporter(translate, dataProvider, tickCallback, {
    withArchived: true,
  });
}

export default usersExporter;
