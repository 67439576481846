import React from 'react';

import { NodeBase } from '../../types/nodes';

type ContainerProps = {
  record?: NodeBase; // Given by RA
  resource?: string; // Given by RA
};

function withInputContainer<T extends ContainerProps>(
  Component: React.ComponentType<T>,
) {
  return (props: T): JSX.Element => {
    return <Component {...props} />;
  };
}

export default withInputContainer;
