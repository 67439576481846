import React from 'react';
import { Title } from 'react-admin';
import { useAuthenticated, useTranslate } from 'ra-core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

import LegalIcon from '@material-ui/icons/Gavel';

export const key = 'cgu';
export const Icon = LegalIcon;

const CGU: React.FC = () => {
  useAuthenticated();
  const translate = useTranslate();
  return (
    <Card>
      <Title title={translate(`${key}.title`)} />
      <CardContent>
        <Typography variant="h2">PRÉAMBULE</Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          Les présentes Conditions Générales d&apos;Utilisation (ci-après les «
          CGU ») ont pour objet de déterminer les conditions d&apos;accès et
          règles d&apos;utilisation de l&apos;Application « PADH » accessible
          par le lien suivant :{' '}
          <a href="https://padh.sncf.fr">https://padh.sncf.fr/</a>.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          L&apos;accès à l&apos;Application est subordonné au respect des
          présentes CGU et est strictement réservé à l&apos;Utilisateur tel que
          défini ci-après.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          La Société se réserve le droit de modifier ou de mettre à jour à tout
          moment, unilatéralement, les présentes CGU au gré des changements ou
          additions effectués, afin notamment de se conformer à toutes
          évolutions légales, jurisprudentielles, éditoriales et/ou techniques
          ainsi qu&apos;aux éventuelles modifications des conditions générales
          d&apos;utilisation des services de l&apos;Hébergeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 10px 20px' }}
        >
          L&apos;Utilisateur doit consulter régulièrement la dernière version
          des CGU disponible en permanence sur l&apos;Application.
        </Typography>

        <Typography variant="h2">ARTICLE 1 – DÉFINITIONS</Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          Au sein des présentes CGU, les mots ou expressions commençant avec une
          majuscule, au singulier ou au pluriel, auront la signification
          suivante :
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Application</strong> : désigne PADH, application de{' '}
          <strong>
            Pilotage des Aides au logement et Demandes d&apos;Hébergement
          </strong>{' '}
          accessible par le biais d&apos;un navigateur internet, développée pour
          être utilisable depuis des ordinateurs.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Cookie(s)</strong> : désigne des fichiers informatiques,
          stockés sur le disque dur de l&apos;ordinateur ou de tout appareil
          mobile tel que smartphone ou tablette de l&apos;Utilisateur.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Information(s)</strong> : désigne l&apos;ensemble des
          informations et publications accessibles sur l&apos;Application.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Contribution(s)</strong> : désigne l&apos;ensemble des
          commentaires et/ou contenus que les Utilisateurs ont la possibilité de
          publier sur PADH.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Société</strong> : désigne la société SNCF
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Utilisateur(s)</strong> : désigne les agents ayant accès au a
          PADH quel que soit le lieu où il se trouve et les modalités de
          connexion.
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          <strong>Editeur</strong> : désigne SNCF.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 10px 20px' }}
        >
          <strong>Hébergeur</strong> : désigne la société AMAZON
        </Typography>
        <Typography variant="h2">
          ARTICLE 2 – OBJET DE L&apos;APPLICATION
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          L&apos;Application a pour objet le{' '}
          <strong>
            Pilotage des Aides au logement et Demandes d&apos;Hébergement.
          </strong>
        </Typography>
        <Typography style={{ textAlign: 'justify', padding: '0 20px 0 20px' }}>
          L&apos;Application collecte et exploite des données vous concernant
          uniquement aux fins détaillées dans les présentes CGU.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 10px 20px' }}
        >
          Toute utilisation de l&apos;Application se fera dans le respect des
          présentes CGU.
        </Typography>
        <Typography variant="h2">
          ARTICLE 3 – ACCÈS ET UTILISATION DE L&apos;APPLICATION
        </Typography>

        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '10px 20px 0px 20px' }}
        >
          3.1 Accès à l&apos;Application
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;accès à l&apos;Application est possible à partir d&apos;un
          ordinateur connecté à un réseau de télécommunication selon les
          protocoles de communication utilisés sur le réseau Internet.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;Editeur accorde à l&apos;Utilisateur un droit de consultation,
          d&apos;utilisation et d&apos;accès aux Informations du
          l&apos;Application.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0 20px 0px 20px' }}
        >
          3.2 Coût d&apos;accès
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;accès à l&apos;Application est gratuit et ne fait pas
          l&apos;objet d&apos;un abonnement. Tous les logiciels et matériels
          nécessaires à l&apos;utilisation ou au fonctionnement de
          l&apos;Application, l&apos;accès à l&apos;Internet ou les frais de
          communication sont à la charge exclusive de l&apos;Utilisateur en
          dehors de son lieu de travail.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          Chaque Utilisateur est entièrement responsable de ses données de
          connexion le concernant. Sauf preuve contraire, toute connexion à
          l&apos;Application, ou transmission de données effectuées à partir des
          informations de connexion de l&apos;Utilisateur, sera réputée avoir
          été effectuée par ce dernier.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0 20px 0px 20px' }}
        >
          3.3 Durée d&apos;accessibilité
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;accès de l&apos;Utilisateur à l&apos;Application est effectué
          pour une durée illimitée, sans préjudice de la faculté pour
          l&apos;Utilisateur ou l&apos;Editeur de résilier unilatéralement, à
          tout moment, sans préavis, ni motif, ou indemnité.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;Utilisateur reconnaît, qu&apos;en cas de violation des
          dispositions législatives et/ou des présentes CGU, l&apos;Editeur peut
          bloquer et/ou résilier l&apos;accès au à l&apos;Application, sans
          notification préalable, avec effet immédiat.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0 20px 0px 20px' }}
        >
          3.4 Utilisation du Site et/ou de l&apos;Application
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;Utilisateur s&apos;interdit notamment, sans que cette liste
          soit limitative :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0 20px 0px 60px' }}>
          <li>
            <Typography>
              d&apos;utiliser l&apos;Application à des fins de commerce et
              d&apos;une manière générale de proposer des produits et des
              services le rémunérant de manière directe ou indirecte ;
            </Typography>
          </li>
          <li>
            <Typography>
              de « revendre » ou de mettre à disposition d&apos;un tiers et/ou
              des autres Utilisateurs le contenu du l&apos;Application, à titre
              onéreux et/ou gratuit ;
            </Typography>
          </li>
          <li>
            <Typography>
              d&apos;utiliser l&apos;Application en vue d&apos;adresser, sous
              quelque forme que ce soit, de la publicité ou des éléments à
              caractère promotionnel non sollicités ;
            </Typography>
          </li>
          <li>
            <Typography>
              de diriger les Utilisateurs indirectement ou directement,
              notamment par des liens hypertextes, vers des sites Internet qui
              seraient susceptibles de ne pas respecter la législation
              applicable ou les présentes CGU.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0 20px 0px 20px' }}
        >
          3.5 Informations et fonctionnalités proposées
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0 20px 0px 40px' }}
        >
          L&apos;Application offre la possibilité à l&apos;Utilisateur de :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0 20px 0px 60px' }}>
          <li>
            <Typography>
              Gérer les demandes de logement (créer, modifier, supprimer des
              données)
            </Typography>
          </li>

          <li>
            <Typography>
              Importer et exporter des éléments de données.
            </Typography>
          </li>
        </ul>
        <Typography variant="h2">
          ARTICLE 4 – DISPONIBILITE DE L&apos;APPLICATION
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Application est accessible 24 heures sur 24, 7 jours sur 7,
          sauf cas de force majeure ou d&apos;évènement hors du contrôle de
          l&apos;Editeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Editeur se réserve le droit de suspendre, d&apos;interrompre ou
          de limiter, sans avis préalable, l&apos;accès à tout ou partie du
          l&apos;Application, notamment pour des opérations de maintenance et de
          mises à jour nécessaires au bon fonctionnement de l&apos;Application
          et des matériels afférents, ou pour toute autre raison, notamment
          technique.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Editeur n&apos;est tenu à aucune obligation de résultat
          concernant la disponibilité du l&apos;Application. L&apos;Editeur
          n&apos;est en aucun cas responsable des interruptions et des
          conséquences qui peuvent en découler pour l&apos;Utilisateur notamment
          lorsque les interruptions proviennent de celles de l&apos;Hébergeur
          pour ses besoins de maintenance ou autres.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          L&apos;Utilisateur est informé que l&apos;Editeur peut mettre fin ou
          modifier les caractéristiques du l&apos;Application, à tout moment,
          sans préavis et sans que l&apos;Utilisateur dispose d&apos;un recours
          à l&apos;encontre de l&apos;Editeur.
        </Typography>

        <Typography variant="h2">
          ARTICLE 5 – Hébergement DE L&apos;APPLICATION
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Les Utilisateurs sont expressément informés que les contenus publiés
          sur l&apos;application sont hébergés et stockés en France sur les
          serveurs de la société AMAZON.
        </Typography>
        <Typography variant="h2">ARTICLE 6 – COMPTE D&apos;ACCÈS</Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '10px 20px 0px 20px' }}
        >
          6.1 Données fournies pour la création d&apos;un compte par
          l&apos;Editeur
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Pour accéder au Site, l&apos;Utilisateur doit disposer des droits
          d&apos;accès.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Les droits sont attribués par les administrateurs du Site.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Dans le cas des Utilisateurs Internes, les comptes sont ceux du
          système de fédérations des identités de Groupe Public Ferroviaire.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;Utilisateur est seul responsable de tout usage qui pourrait
          être fait de ses mots de passe et identifiants (adresse électronique
          ou autres), et, est seul garant de leur confidentialité, ainsi que de
          toute utilisation de son Compte.
        </Typography>

        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '10px 20px 0px 20px' }}
        >
          6.2 Sécurité
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à informer immédiatement
          l&apos;Editeur de toute utilisation non autorisée de son Compte, et de
          toute atteinte à la confidentialité et à la sécurité de ses moyens
          d&apos;identification en envoyant un message à l&apos;administrateur
          du site.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Si l&apos;Editeur a des motifs légitimes de penser que la sécurité du
          Site est menacée ou que celui-ci est mal utilisé du fait d&apos;une
          utilisation non autorisée des moyens d&apos;identification de
          l&apos;Utilisateur, il pourra procéder à la suspension temporaire du
          Compte, afin notamment de préserver l&apos;intégrité du Site, et, si
          cela apparaît approprié, exiger la modification de ses moyens
          d&apos;identification.
        </Typography>

        <Typography variant="h2">ARTICLE 7 – CONTRIBUTIONS</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Editeur se réserve la possibilité de supprimer toute
          Contribution qui violerait les présentes CGU ou qui serait susceptible
          d&apos;engager en tout ou partie la responsabilité de l&apos;Editeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur est responsable de toute Contribution qu&apos;il
          affiche via l&apos;Application ainsi que de leurs conséquences. La
          Contribution que l&apos;Utilisateur soumet, publie ou affiche pourra
          être consultée par d&apos;autres Utilisateurs l&apos;Application.
          L&apos;Utilisateur ne devra fournir que des Contributions qu&apos;il
          souhaite partager avec d&apos;autres dans le respect des CGU.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à soumettre à l&apos;Editeur des
          informations et des contenus véridiques, faisant apparaître la source
          et l&apos;auteur de manière distincte dans le cas d&apos;emprunt à une
          œuvre préexistante.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Par ailleurs, les Contributions ne doivent pas :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0 20px 0px 60px' }}>
          <li>
            <Typography>
              porter atteinte aux droits de propriété intellectuelle ;
            </Typography>
          </li>
          <li>
            <Typography>
              porter atteinte aux obligations professionnelles de
              confidentialité de l&apos;Utilisateur ;
            </Typography>
          </li>
          <li>
            <Typography>
              porter atteinte aux droits des données personnelles de Tiers ou
              d&apos;autres Utilisateurs non impliqués dans le processus de
              gestion des demandes de logement concerné ;
            </Typography>
          </li>
          <li>
            <Typography>
              Comporter la mention de données sensibles au sens du RGPD ;
            </Typography>
          </li>
          <li>
            <Typography>
              Être de nature discriminatoire ou de nature à inciter à la
              violence ou à la haine, pour des raisons d&apos;origine ethnique,
              politiques, d&apos;orientation sexuelle etc. ;
            </Typography>
          </li>
          <li>
            <Typography>
              comporter des contenus obscènes, pornographiques, etc. ;
            </Typography>
          </li>
          <li>
            <Typography>
              porter atteinte à l&apos;intégrité et/ou la sécurité
              l&apos;Application et notamment comporter des virus, cheval de
              Trois, Worms, bombe à retardement, cancel bots ou tout autre
              programme informatique destiné à endommager, à intercepter
              clandestinement tout système informatique, données ou informations
              nominatives ;
            </Typography>
          </li>
          <li>
            <Typography>
              être susceptibles d&apos;engager la responsabilité de
              l&apos;Editeur en proposant des liens hypertextes renvoyant vers
              des sites ou des contenus illicites, contrefaisants, ou plus
              largement, vers des contenus susceptibles d&apos;enfreindre les
              lois ou les règlements en vigueur ou vers des sites avec
              restriction d&apos;accès sans autorisation des titulaires de droit
              sur le contenu.
            </Typography>
          </li>
        </ul>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;interdit de saisir, de publier ou
          d&apos;afficher dans ses Contributions des données à caractère
          personnel relatives à des Utilisateurs ou à des Tiers non impliqués
          dans le processus de gestion des demandes de logement concerné.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur déclare être seul et unique titulaire de tout
          éventuel droit d&apos;auteur sur les Contributions et garantit à
          l&apos;Editeur la jouissance libre et entière de toutes servitudes des
          droits faisant l&apos;objet des présentes CGU, contre tous troubles,
          revendications et évictions quelconques.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          L&apos;Utilisateur garantit notamment qu&apos;aucun litige ou procès
          n&apos;est en cours ou sur le point d&apos;être intenté quant aux
          Contributions mettant en cause les droits d&apos;auteur.
        </Typography>

        <Typography variant="h2">
          ARTICLE 8 – PROPRIÉTÉ INTELLECTUELLE
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          De manière générale, l&apos;Utilisateur s&apos;interdit de porter
          atteinte aux droits de propriété intellectuelle (droit d&apos;auteur,
          droits voisins, droit sui generis du producteur de bases de données,
          droit des marques, noms de domaine…) de l&apos;Editeur, et/ou de tout
          tiers.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Editeur est titulaire de tous les droits de propriété
          intellectuelle tant sur la structure que sur le contenu du Site
          (textes, logos, images, éléments sonores, logiciels, icônes, mise en
          page, base de données…) ou a acquis régulièrement les droits
          permettant l&apos;exploitation de la structure et du contenu du Site,
          sans aucune limitation.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Le non-respect de ces interdictions peut notamment constituer un acte
          de contrefaçon et/ou de concurrence déloyale et parasitaire engageant
          la responsabilité civile et/ou pénale de l&apos;Utilisateur.
        </Typography>

        <Typography variant="h2">ARTICLE 9 – DONNÉES PERSONNELLES</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur est informé que des données à caractère personnel
          (numéro CP, nom, prénom, traces de connexion) sont collectées par SNCF
          SA dans le cadre de l&apos;Application PADH accessible à
          l&apos;adresse :{' '}
          <a href="https://padh.sncf.fr">https://padh.sncf.fr/</a>.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Le traitement des données à caractère personnel a lieu sous la
          responsabilité de la Direction Ressources Humaines-Logement en vue de
          gérer les habilitations des utilisateurs de la plateforme PADH et plus
          largement en vue de permettre la gestion des demandes
          d&apos;hébergement temporaire en résidence meublées et des aides
          (minorations associées art. 4.2 du GRH 00333).
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Sa base juridique est l&apos;intérêt légitime de SNCF (sécurisation de
          l&apos;application et gestion des habilitations)
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La Société SNCF a désigné un délégué à la protection des données :{' '}
          <a href="mailto:dpo-sncf@sncf.fr">dpo-sncf@sncf.fr</a>
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les données ne seront conservées que pour une durée nécessaire à la
          réalisation des finalités énumérées ci-dessus, soit :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0px 20px 0px 60px' }}>
          <li>
            <Typography>
              pendant une durée de 30 mois dans le cadre de la gestion des
              demandes d’hébergement temporaire, à partir de
              l&apos;enregistrement des données relatives aux utilisateurs
            </Typography>
          </li>
          <li>
            <Typography>
              pendant une durée de 60 mois dans le cadre de la gestion des
              demandes de minoration, à partir de l&apos;enregistrement des
              données relatives aux utilisateurs
            </Typography>
          </li>
        </ul>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Elles seront transmises à la Direction Ressources Humaines Logement et
          à aux équipes support de la société PHILEOG.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Conformément à la loi n°78-17 du 6 janvier 1978 et au règlement
          2016/679 du Parlement Européen et du Conseil du 27 avril 2016,
          l&apos;Utilisateur dispose du droit de demander à SNCF l&apos;accès à
          ses données, la rectification ou l&apos;effacement de celles-ci ou une
          limitation du traitement, ainsi que du droit de s&apos;opposer au
          traitement et du droit de définir les conditions d&apos;utilisation,
          de conservation et de communication de ses données personnelles en cas
          de décès.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          A ce titre, toute personne justifiant de son identité pourra exercer
          ces droits en adressant sa demande par courriel :{' '}
          <a href="mailto:donneespersonnelleslogement@sncf.fr">
            donneespersonnelleslogement@sncf.fr
          </a>
          .
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur a également la possibilité de saisir la Commission
          Nationale Informatique et Libertés (CNIL) pour introduire une
          réclamation.
        </Typography>

        <Typography variant="h2">
          ARTICLE 10 – GARANTIES/RESPONSABILITE
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage, lors de l&apos;utilisation
          qu&apos;il fait de l&apos;Application, à ne pas contrevenir aux
          dispositions législatives et réglementaires en vigueur, aux bons
          usages de l&apos;Intranet et de la netiquette, et aux présentes CGU.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur est informé que toute utilisation du
          l&apos;Application en violation des dispositions des CGU est
          susceptible d&apos;engager sa responsabilité et d&apos;entraîner des
          poursuites judiciaires et des sanctions à son encontre.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur garantit l&apos;Editeur, ainsi que ses sociétés
          mères, sœurs ou affiliées, ses représentants, ses salariés, ses
          partenaires, contre toute demande, réclamation, revendication et/ou
          recours de toute sorte, résultant de toute violation desdites
          dispositions.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Dans les mêmes conditions, l&apos;Utilisateur s&apos;engage également
          à respecter les règles et usages internes relatifs à
          l&apos;utilisation des systèmes d&apos;information et réseaux de
          l&apos;Editeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à agir avec diligence pour répondre à
          toute réclamation.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Editeur s&apos;engage à prendre toutes les mesures techniques
          et d&apos;organisation nécessaires afin de protéger les Informations
          circulant sur l&apos;Application et de garantir la sécurité des
          données personnelles qu&apos;il a collectées et éviter leur
          divulgation à des tiers non autorisés.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Il appartient toutefois à l&apos;Utilisateur de mettre en œuvre tous
          moyens utiles aux fins de préserver la confidentialité des
          Informations transmises et notamment de les assigner à des espaces
          privés.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Chaque Utilisateur de l&apos;Application s&apos;engage expressément :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0px 20px 0px 60px' }}>
          <li>
            <Typography>
              à ne pas utiliser de logiciels ou de procédés destinés à copier
              les Informations du l&apos;Application sans l&apos;autorisation
              expresse et préalable de l&apos;Editeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à renoncer expressément à utiliser des logiciels ou dispositifs
              susceptibles de perturber le bon fonctionnement du
              l&apos;Application, ni à engager d&apos;action de nature à imposer
              une charge disproportionnée pour les infrastructures de
              l&apos;Editeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas altérer ou modifier voire créer des œuvres dérivées à
              partir des Informations du l&apos;Application sans le consentement
              exprès et préalable de l&apos;Editeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas extraire ou réutiliser, y compris à des fins privées,
              sans autorisation écrite et préalable de l&apos;Editeur, une
              partie substantielle ou non du contenu des bases de données et
              archives constituées par le l&apos;Application ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas collecter des informations sur des tiers, y compris les
              adresses électroniques, afin de les utiliser pour l&apos;envoi de
              sollicitations commerciales ou équivalentes, ou de les intégrer au
              sein d&apos;un service de référencement ou équivalent, gratuit ou
              payant, ou encore afin d&apos;effectuer une veille concurrentielle
              ;
            </Typography>
          </li>
          <li>
            <Typography>
              à mettre en place des systèmes susceptibles ou de nature à pirater
              le l&apos;Application en tout ou partie, ou de nature à violer les
              présentes CGU ;
            </Typography>
          </li>
          <li>
            <Typography>
              à informer l&apos;Editeur dès la connaissance d&apos;une atteinte
              quelle qu&apos;elle soit (notamment aux droits de propriété
              intellectuelle de l&apos;Editeur) en particulier de toute
              utilisation illicite ou non contractuelle des Informations du
              l&apos;Application et ce, quel que soit le mode de diffusion
              utilisé.
            </Typography>
          </li>
        </ul>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur qui publie une Contribution sur l&apos;Application
          reconnaît être personnellement responsable, tant vis-à-vis des tiers
          que de l&apos;Editeur, en cas de violation des dispositions
          législatives ou règlementaires applicables et garantit en conséquence
          l&apos;Editeur contre tout trouble, revendication et éviction
          quelconque.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Il appartient à l&apos;Utilisateur de préserver la confidentialité des
          Contributions qu&apos;il publie sur l&apos;Application.
        </Typography>

        <Typography variant="h2">ARTICLE 11 – CONTACT</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Pour toute question relative au fonctionnement du l&apos;Application
          et aux CGU, vous pouvez vous adresser à :{' '}
          <a href="mailto:SIRH_Logement@sncf.fr">SIRH_Logement@sncf.fr</a>
        </Typography>

        <Typography variant="h2">
          ARTICLE 12 – NULLITE D&apos;UNE CLAUSE
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La nullité en tout ou partie d&apos;une ou de plusieurs dispositions
          des présentes CGU, aux termes d&apos;une disposition légale ou
          réglementaire ou d&apos;une décision de justice devenue définitive,
          n&apos;entraîne pas la nullité des autres dispositions ou de la partie
          de la disposition non entachées de nullité.
        </Typography>

        <Typography variant="h2">
          ARTICLE 13 – DROIT APPLICABLE – RÈGLEMENT DES LITIGES
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les présentes CGU sont soumises au droit français.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          En cas de différends relatifs à l&apos;interprétation, la validité ou
          l&apos;exécution des présentes CGU, l&apos;Utilisateur et
          l&apos;Editeur conviennent de déployer leurs meilleurs efforts afin de
          régler à l&apos;amiable le litige.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Dans le cas où un accord amiable ne serait pas trouvé, tout éventuel
          litige se rapportant à l&apos;interprétation ou l&apos;exécution des
          présentes CGU sera soumis à la compétence des tribunaux
          territorialement compétents.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CGU;
