/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import {
  DataProvider,
  GetListResult,
  GetListParams,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  UpdateManyResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  Record,
  GetManyReferenceParams,
} from 'ra-core';
import { ApolloQueryResult } from '@apollo/client';
import _orderBy from 'lodash/orderBy';
import _castArray from 'lodash/castArray';
import _once from 'lodash/once';
import gql from 'graphql-tag';
import { CloudWatchLogs } from 'aws-sdk';

import apolloClient from '../../graphql/mino/client';
import { getCloudWatchLogs } from '../../lib/cloudWatch';

import getObservableQuery from '../observable';
import withObserver, { Observables, ObservableTuple } from '../withObserver';

import preFilterResult from './preFilters';
import { enhanceQueryResult, flattenQueryConnectionResult } from './flattener';
import filterNodes from './filters';
import { extractUpdateDiff } from '../utils';

import { UpdateMinoFragmentsBase } from '../../graphql/mino/updateFragments';
import {
  QueryAll,
  QUERY_ALL_MINO_USER,
  QUERY_ALL_MINO_USER_WITH_ARCHIVED,
  QUERY_MINO_HISTORY,
} from '../../graphql/mino/queries';

import { updateStore } from '../../graphql/mino/cacheUtils';

import { resourceIsValid, ResourceKey } from './resourcesKeys';
import resourcesInfos from './resourcesInfos';
import {
  resourceInfosHasConnections,
  getAssetResourceKeyConnections,
  getAssetResource,
} from '../resourcesInfos';

import { ID, NodeInput } from '../../types/nodes';
import {
  SimplifiedConnection,
  SimplifiedConnectionMany,
  SimplifiedConnectionOne,
  nodeEntryIsSimplifiedConnectionMany,
} from '../../types/connections';
import {
  CreateNodeVariables,
  UpdateNodeVariables,
  MutationCreateNode,
  MutationUpdateNode,
} from '../../types/mutations';
import {
  SMinoNode,
  MinoNode,
  MinoNodeFromResource,
} from '../../types/mino/nodes';
import withFileUpload from '../withFileUpload';
import { GQLMinoLogConnection } from '../../types/mino/schema';
import { CustomMinoProvider } from '../dataProvider';

function InvalidResourceError(resource: string): Error {
  return Error(`Resource "${resource}" is not a valid resource`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (): DataProvider => {
  // init all observable queries
  const [obsMinoUser, obsMinoUserInit, obsMinoUserFull] = getObservableQuery(
    QUERY_ALL_MINO_USER,
    apolloClient,
  );

  var getObsMinoUserWithArchived = _once(() =>
    getObservableQuery(QUERY_ALL_MINO_USER_WITH_ARCHIVED, apolloClient),
  );

  const observables: Observables = {
    minoUser: [obsMinoUser, obsMinoUserInit, obsMinoUserFull],
  };

  const minoDataProvider: DataProvider &
    Pick<CustomMinoProvider, 'getListAll' | 'getHistoryAll' | 'getHistory'> = {
    // Get node history
    getHistory: (resource, { id, first = null, after = null }) => {
      console.log('getHistory', { resource, id, first, after });
      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      return (
        apolloClient
          .query<{ history: GQLMinoLogConnection }>({
            query: QUERY_MINO_HISTORY,
            variables: {
              id: `${resourcesInfos[resource].typename}/${id}`,
              first,
              after,
            },
            fetchPolicy: 'no-cache',
          })
          // Query response to data
          .then(response => {
            const responseData = response.data;
            if (responseData) {
              return { data: responseData.history };
            }
            throw Error(
              `getHistory ${resource} #${id} did not return any value`,
            );
          })
          .then(res => {
            console.log('getHistory result', res);
            return res;
          })
      );
    },

    getHistoryAll: (
      resource: string,
      { tickCallback }: { tickCallback?: (p: number) => void } = {},
    ): Promise<{ data: CloudWatchLogs.FilteredLogEvents }> => {
      console.log('getHistoryAll', { resource });
      let cloudWatchPromise = Promise.resolve<CloudWatchLogs.FilteredLogEvents>(
        [],
      );

      if (resource === 'minoUser') {
        cloudWatchPromise = getCloudWatchLogs(
          {
            logGroupName: '/PADH/Mino',
            logStreamNamePrefix: 'User/',
          },
          tickCallback,
        );
      }

      return cloudWatchPromise
        .then(historyAll => {
          return { data: historyAll };
        })
        .then(res => {
          console.log('getHistoryAll result', { resource }, res);
          return res;
        });
    },

    // Search for full (+flattened) resources
    getListAll: <T extends ResourceKey>(
      resource: T,
      options?: { withArchived?: boolean },
    ): Promise<{
      data: MinoNodeFromResource<T>[];
      flattenedData: MinoNodeFromResource<T>[];
    }> => {
      const { withArchived } = options || {};
      console.log('getListAll', { resource, withArchived });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const {
        query: { allList: queryAllList },
      } = resourcesInfos[resource];

      let obsTuple: ObservableTuple | undefined;
      if (withArchived) {
        if (resource !== 'minoUser') {
          throw InvalidResourceError(
            `${resource} is not available withArchived`,
          );
        }
        obsTuple = getObsMinoUserWithArchived();
      } else {
        obsTuple = observables[resource];
      }

      const query = ((): Promise<ApolloQueryResult<QueryAll>> => {
        if (!obsTuple) {
          return apolloClient.query<QueryAll>({ query: queryAllList });
        }
        const [observable, , full] = obsTuple;
        return full.then(() => observable.getCurrentResult());
      })();

      return (
        query
          // Query response to data
          .then(response => {
            const responseData = response.data;
            let data: MinoNodeFromResource<typeof resource>[] = [];
            let flattenedData: MinoNodeFromResource<typeof resource>[] = [];
            if (responseData) {
              const preFilteredList = withArchived
                ? responseData.allList
                : preFilterResult(responseData.allList);
              data = flattenQueryConnectionResult(preFilteredList);
              flattenedData = enhanceQueryResult(preFilteredList);
            }
            return {
              data,
              flattenedData,
            };
          })
          .then(res => {
            console.log(`getListAll result`, { resource, withArchived }, res);
            return res;
          })
      );
    },

    // Search for resources
    getList: <RecordType extends Record = SMinoNode>(
      resource: string,
      params: GetListParams,
    ): Promise<GetListResult<RecordType>> => {
      console.log('getList', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }
      const {
        query: { allList: queryAllList },
      } = resourcesInfos[resource];

      const obsTuple = observables[resource];

      const query = ((): Promise<ApolloQueryResult<QueryAll>> => {
        if (!obsTuple) {
          return apolloClient.query<QueryAll>({ query: queryAllList });
        }
        const [observable, init] = obsTuple;
        return init.then(() => observable.getCurrentResult());
      })();

      return (
        query
          // Query response to data
          .then(response => {
            const responseData = response.data;
            let data: RecordType[] = [];
            if (responseData) {
              const preFilteredList = preFilterResult(responseData.allList);
              data = enhanceQueryResult<typeof preFilteredList, RecordType>(
                preFilteredList,
              );
            }
            return { data };
          })
          // Data to result
          .then(({ data: rawData }) => {
            const { filter, pagination, sort } = params;
            const unfilteredData = rawData;

            const unsortedData = filterNodes(unfilteredData, filter);

            const { field, order } = sort;
            const unpaginatedData = _orderBy(
              unsortedData,
              field,
              order.toLowerCase() as 'asc' | 'desc',
            );

            const { page, perPage } = pagination;
            const data = unpaginatedData.slice(
              (page - 1) * perPage,
              page * perPage,
            );
            return {
              data: data as RecordType[],
              total: unpaginatedData.length,
            };
          })
          .then(res => {
            console.log('getList result', res);
            return res;
          })
      );
    },

    getOneFull: (
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult & { data: MinoNode }> => {
      console.log('getOneFull', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { id } = params as { id: ID };
      const resourceInfos = resourcesInfos[resource];
      const {
        query: { name: queryName },
        fragment: { name: fragmentName, full: fragmentFull },
      } = resourceInfos;

      return (
        apolloClient
          .query<{ [k: string]: MinoNode }>({
            query: gql`
              query {
                ${queryName}(id: "${id}") {
                  ...${fragmentName}
                }
              }
              ${fragmentFull}
          `,
          })
          // Response to data and result
          .then(response => {
            return {
              data: Object.values(response.data)[0],
            };
          })
          .then(res => {
            console.log('getOneFull result', res);
            return res;
          })
      );
    },

    // Read a single resource, by id
    getOne: <RecordType extends Record = MinoNode>(
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult<RecordType>> => {
      console.log('getOne', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { id } = params as { id: ID };
      const resourceInfos = resourcesInfos[resource];
      const {
        query: { name: queryName },
        fragment: { name: fragmentName, full: fragmentFull },
      } = resourceInfos;

      return (
        apolloClient
          .query<{ [k: string]: MinoNode }>({
            query: gql`
              query {
                ${queryName}(id: "${id}") {
                  ...${fragmentName}
                }
              }
              ${fragmentFull}
          `,
          })
          // Response to data and result
          .then(response => {
            return {
              data: enhanceQueryResult<MinoNode, RecordType>(
                Object.values(response.data)[0],
              ),
            };
          })
          .then(res => {
            console.log('getOne result', res);
            return res;
          })
      );
    },

    /** return: {data: Partial<Node>}: Connections are not included */
    getMany: <RecordType extends Record = MinoNode>(
      resource: string,
      params: GetManyParams,
    ): Promise<GetManyResult<RecordType>> => {
      console.log('getMany', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { ids } = params as {
        ids: ID[];
      };
      const resourceInfos = resourcesInfos[resource];
      const {
        query: { name: queryName },
        fragment: { name: fragmentName, full: fragmentFull },
      } = resourceInfos;

      const queries = ids.reduce(
        (acc: string, id, index) =>
          acc.concat(`
            ${queryName}${index}: ${queryName}(id: "${id}") {
              ...${fragmentName}
            }
          `),
        '',
      );

      return apolloClient
        .query<{ [k: string]: MinoNode }>({
          query: gql`
            query {
              ${queries}
            }
            ${fragmentFull}
          `,
        })
        .then(response => {
          if (!response.data) {
            return { data: [] };
          }
          return {
            data: Object.values(response.data).map(d =>
              enhanceQueryResult<typeof d, RecordType>(d),
            ),
          };
        })
        .then(res => {
          console.log('getMany result', res);
          return res;
        });
    },
    // getManyBase: (
    //   resource: string,
    //   params: GetManyParams,
    // ): Promise<GetManyResult /* & { data: Partial<HtNode>[] } */> => {
    //   console.log('getManyBase', { resource, params });

    //   return Promise.reject(
    //     Error('dataProvider.getManyBase is not yet implemented'),
    //   );
    // },

    // Read a list of resources related to another one
    getManyReference: <RecordType extends Record = MinoNode>(
      resource: string,
      params: GetManyReferenceParams,
    ): Promise<GetManyReferenceResult<RecordType>> => {
      // console.log('getManyReference', { resource, params });

      return Promise.reject(
        Error('dataProvider.getManyReference is not yet implemented'),
      );
    },

    // Create a single resource
    create: <RecordType extends Record = SMinoNode>(
      resource: string,
      params: CreateParams,
    ): Promise<CreateResult<RecordType>> => {
      console.log('create', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { data } = params as { data: NodeInput };
      const resourceInfos = resourcesInfos[resource];

      const {
        mutation: {
          dataInputGQLType: mType,
          transactionnal: mTrans,
          createName: mCreateName,
        },
      } = resourceInfos;

      const mTransParam = mTrans ? ', transaction: BEGIN' : '';
      const mutation = gql`
        mutation($data: ${mType}!) {
          ${mCreateName}(data: $data${mTransParam}) {
            ...UpdateFragmentsBase
          }
        }
        ${UpdateMinoFragmentsBase(resource)}
      `;

      const variables: CreateNodeVariables = {
        data,
      };

      if (resourceInfosHasConnections(resourceInfos)) {
        resourceInfos.connections.forEach(connection => {
          const connectionKey = connection.key;
          const inputData = variables.data;
          const connectionData = inputData[connectionKey];
          if (typeof connectionData !== 'undefined') {
            variables.data[connectionKey] = {
              connect: _castArray(connectionData),
            };
          }
        });
      }

      return apolloClient
        .mutate<MutationCreateNode<MinoNode>>({
          mutation,
          variables,
          update: (store, { data: resultData }) => {
            if (resultData) {
              const nodeUpdate = Object.values(resultData)[0];
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          console.log('create response', response);
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.create did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data);
          return {
            data: enhanceQueryResult<MinoNode, RecordType>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('create result', res);
          return res;
        });
    },

    // Update a single resource
    update: <RecordType extends Record = MinoNode>(
      resource: string,
      params: UpdateParams, // Replace<UpdateParams, 'data', SimplifiedNode>,
    ): Promise<UpdateResult<RecordType>> => {
      console.log('update', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const {
        id,
        data: { ifMutation, ...rest },
        previousData,
      } = params as {
        id: ID;
        data: RecordType & { ifMutation: NodeInput };
        previousData: RecordType;
      };
      const resourceInfos = resourcesInfos[resource];

      const shouldClearStore = false;

      const data = rest as unknown as RecordType; // FIXME TS
      const dataInput = extractUpdateDiff(data, previousData);

      const nbData = Object.keys(dataInput).length;
      // Nothing to update OR only field is wfNotify and its value is `false`
      if (nbData <= 0 || (nbData === 1 && dataInput.wfNotify === false)) {
        return Promise.resolve({ data });
      }

      const {
        mutation: {
          dataInputGQLType: mType,
          transactionnal: mTrans,
          updateName: mUpdateName,
        },
      } = resourceInfos;

      const mTransParam = mTrans ? ', transaction: BEGIN' : '';
      const mutation = gql`
        mutation($id: ID!, $data: ${mType}!, $version: Int!) {
          ${mUpdateName}(id: $id, data: $data, version: $version${mTransParam}) {
            ...UpdateFragmentsBase
          }
        }
        ${UpdateMinoFragmentsBase(resource)}
      `;

      const variables: UpdateNodeVariables = {
        id: `${id}`,
        data: { ...dataInput, ...data.ifMutation },
      };
      if (data.version) {
        variables.version = +data.version;
        // note: $version can be null so it is not required (no need to filter it)
      }

      if (resourceInfosHasConnections(resourceInfos)) {
        resourceInfos.connections.forEach(connection => {
          const connectionKey = connection.key;

          const inputData = variables.data;
          let connectionIds = inputData[connectionKey] as
            | SimplifiedConnectionMany
            | SimplifiedConnectionOne
            | undefined;
          // undefined means that connection have not changed
          if (typeof connectionIds !== 'undefined') {
            if (!nodeEntryIsSimplifiedConnectionMany(connectionIds)) {
              connectionIds = [connectionIds];
            }

            const pData = previousData as typeof previousData & {
              [k: string]: SimplifiedConnection;
            };
            let previousConnectionIds = pData[connectionKey] as
              | SimplifiedConnectionMany
              | SimplifiedConnectionOne
              | undefined;
            if (!nodeEntryIsSimplifiedConnectionMany(previousConnectionIds)) {
              previousConnectionIds =
                typeof previousConnectionIds !== 'undefined'
                  ? [previousConnectionIds]
                  : [];
            }

            let removedConnectionIds: SimplifiedConnectionMany = [];
            let addedConnectionIds = connectionIds;
            if (previousConnectionIds.length) {
              removedConnectionIds = previousConnectionIds.filter(
                // TS Bug connectionIds is already forced to be SimplifiedConnectionMany
                cId =>
                  !(connectionIds as SimplifiedConnectionMany).includes(cId),
              );
              addedConnectionIds = connectionIds.filter(
                // TS Bug previousConnectionIds is already forced to be SimplifiedConnectionMany
                cId =>
                  !(previousConnectionIds as SimplifiedConnectionMany).includes(
                    cId,
                  ),
              );
            }

            variables.data[connectionKey] = {
              connect: addedConnectionIds,
              disconnect: removedConnectionIds,
            };
          }
        });
      }

      return apolloClient
        .mutate<MutationUpdateNode<MinoNode>>({
          mutation,
          variables,
          update: (store, { data: responseData }) => {
            if (responseData) {
              const nodeUpdate = Object.values(responseData)[0];
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          if (shouldClearStore) {
            apolloClient.clearStore();
          }
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.update did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data);
          return {
            data: enhanceQueryResult<MinoNode, RecordType>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('update result', res);
          return res;
        });
    },

    // Update multiple resources
    updateMany: (): // resource: string,
    // params: UpdateManyParams,
    Promise<UpdateManyResult /* & { data?: HtNode[] } */> => {
      // // updateMany (call update many times ??)
      // console.log('updateMany', { resource, params });

      return Promise.reject(
        Error(`dataProvider.updateMany is not yet implemented`),
      );
    },

    // Delete a single resource
    delete: <RecordType extends Record = MinoNode>(
      resource: string,
      params: DeleteParams,
    ): Promise<DeleteResult<RecordType>> => {
      console.log('delete', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { id } = params as {
        id: ID;
      };

      const resourceInfos = resourcesInfos[resource];

      const {
        mutation: { deleteName: mDeleteName },
      } = resourceInfos;

      if (!mDeleteName) {
        return Promise.reject(
          Error(
            `dataProvider.delete is not available for the resource [${resource}]`,
          ),
        );
      }

      const mutation = gql`
        mutation {
          ${mDeleteName}(id: "${id}") {
            ...UpdateFragmentsBase
          }
        }
        ${UpdateMinoFragmentsBase(resource)}
      `;

      return apolloClient
        .mutate<MutationUpdateNode<MinoNode>>({
          mutation,
          update: (store, { data: responseData }) => {
            if (responseData) {
              const nodeUpdate = Object.values(responseData)[0];
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.delete did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          const responseData = Object.values(response.data);
          return {
            data: enhanceQueryResult<MinoNode, RecordType>(
              responseData[0].node,
            ),
          };
        })
        .then(res => {
          console.log('delete result', res);
          return res;
        });
    },

    // Delete multiple resources
    // TODO: call delete many times ??
    deleteMany: (
      resource: string,
      params: DeleteManyParams,
    ): Promise<DeleteManyResult & { data?: ID[] }> => {
      console.log('deleteMany', { resource, params });

      if (!resourceIsValid(resource)) {
        throw InvalidResourceError(resource);
      }

      const { ids } = params as {
        ids: ID[];
      };

      const resourceInfos = resourcesInfos[resource];

      const {
        mutation: { deleteName: mDeleteName },
      } = resourceInfos;

      if (!mDeleteName) {
        return Promise.reject(
          Error(
            `dataProvider.deleteMany is not available for the resource [${resource}]`,
          ),
        );
      }

      const mutations = ids.reduce(
        (acc: string, id, index) =>
          // use unified updateFragments ?
          // we need typename for the subscription to work properly
          acc.concat(`
            ${mDeleteName}${index}: ${mDeleteName}(id: "${id}") {
              ...UpdateFragmentsBase
            }
          `),
        '',
      );

      return apolloClient
        .mutate<MutationUpdateNode<MinoNode>>({
          mutation: gql`
            mutation {
              ${mutations}
            }
            ${UpdateMinoFragmentsBase(resource)}
          `,
          update: (store, { data: responseData }) => {
            if (responseData) {
              const nodeUpdate = Object.values(responseData)[0];
              updateStore(nodeUpdate, apolloClient);
            }
          },
        })
        .then(response => {
          if (!response.data) {
            return Promise.reject(
              Error(
                `dataProvider.deleteMany did not return any data | Errors: ${JSON.stringify(
                  response.errors,
                )}`,
              ),
            );
          }
          return {
            data: Object.values(response.data).map(({ id }) => id),
          };
        })
        .then(res => {
          console.log('deleteMany result', res);
          return res;
        });
    },
  };

  const assetResource = getAssetResource(resourcesInfos);
  const assetResourceKeyConnections =
    getAssetResourceKeyConnections(resourcesInfos);
  const minoDataProviderWithUpload = assetResource
    ? withFileUpload(
        minoDataProvider,
        assetResource,
        assetResourceKeyConnections,
      )
    : minoDataProvider;
  const minoDataProviderWithObserver = withObserver(
    minoDataProviderWithUpload,
    apolloClient,
    observables,
    UpdateMinoFragmentsBase,
    updateStore,
  );
  return minoDataProviderWithObserver;
};
