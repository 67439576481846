import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import querystring from 'querystring';

import {
  getCredentials,
  isCreds,
  OAUTH_URL,
} from '../../authProvider/phileog-login';
import { ENV_LEVEL } from '../../aws/aws-exports';

type OAuthPageProps = {
  theme: any;
};

// Queue calls because react-admin instantiates us twice...
let getCredsQueue = Promise.resolve();
const codeCache: { [k: string]: boolean } = {};

const OAuthPage: React.FC<OAuthPageProps> = () => {
  // const location = useLocation(); // use this if search afer hash
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clientId = params.get('client_id');
    const code = params.get('code');
    if (clientId === 'PADH' && code) {
      // retrieve code, save to local storage
      getCredsQueue = getCredsQueue.then(() => {
        if (codeCache[code]) return Promise.resolve(); // we are done here
        codeCache[code] = true;
        return getCredentials(code).then(
          creds => {
            // console.log(creds);
            if (isCreds(creds)) {
              localStorage.setItem('padhCredentials', JSON.stringify(creds)); // can we do better than that ? window.parent.postMessage ...
              window.close();
            } else {
              // TODO: logout ?
              console.error(creds);
            }
          },
          e => {
            // TODO: logout ?
            console.error(e);
          },
        );
      });
    } else {
      // init login sequence
      const { protocol, host, pathname } = window.location;
      const thisUrl = `${protocol}//${host}${pathname}`; // TODO: pass hash too?
      const location = new URL(OAUTH_URL);
      location.search = querystring.stringify({
        env: ENV_LEVEL.toLowerCase(),
        redirect_uri: thisUrl,
      });
      window.location.href = location.toString();
    }
  }, []);
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Connexion en cours...
    </div>
  );
};

export default OAuthPage;
