import {
  CrudGetListAction,
  CrudGetManyAction,
  CrudGetOneAction,
  DataProvider,
  GetListResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'ra-core';

import { EventChannel } from 'redux-saga';
import {
  resourcesKeys as htResourcesKeys,
  ResourceKey as HTResourceKey,
} from './ht/resourcesKeys';
import {
  resourcesKeys as minoResourcesKeys,
  ResourceKey as MinoResourceKey,
} from './mino/resourcesKeys';

import htProvider from './ht/dataProvider';
import minoProvider from './mino/dataProvider';
import { MinoNode, MinoNodeFromResource, MinoUser } from '../types/mino/nodes';
import { GQLMinoLogConnection, GQLMinoNode } from '../types/mino/schema';
import { LogEventConnection } from '../types/ht/history';
import { ID } from '../types/nodes';
import { HtNode, GQLHtResidence } from '../types/ht/nodes';

export type CustomProvider = {
  getHistory: LogEventConnection;
};

type GetMinoListAll = {
  <T extends MinoResourceKey>(
    resource: T,
    options?: { withArchived?: boolean },
  ): Promise<{
    data: MinoNodeFromResource<T>[];
    flattenedData: MinoNodeFromResource<T>[];
  }>;
};

type GetMinoOneFull = {
  (resource: string, params: GetOneParams): Promise<
    GetOneResult & { data: MinoNode }
  >;
  (resource: 'minoUser', params: GetOneParams): Promise<
    GetOneResult & { data: MinoUser }
  >;
};

export type GetHTListAll = {
  <T = HtNode>(
    resource: string,
    options?: { withArchived?: boolean },
  ): Promise<{
    data: T[];
  }>;
  (resource: 'htResidence', options?: { withArchived?: boolean }): Promise<{
    data: GQLHtResidence[];
  }>;
};

export type CustomMinoProvider = DataProvider & {
  getOneFull: GetMinoOneFull;
  getListAll: GetMinoListAll;
  getHistory: (
    resource: string,
    options: {
      id: ID;
      first?: number | null;
      after?: string | null | undefined;
    },
  ) => Promise<{ data: GQLMinoLogConnection }>;
};

export type CustomHTProvider = DataProvider & {
  getListAll: GetHTListAll;
  getHistory: (
    resource: string,
    options: {
      id: ID;
      first?: number | null;
      after?: string | null | undefined;
    },
  ) => Promise<{ data: LogEventConnection }>;
  getObserver: (
    resource: string,
    params: CrudGetListAction | CrudGetOneAction | CrudGetManyAction,
  ) => EventChannel<GetListResult | GetOneResult | GetManyResult>;
};

const dataProviders: { [k: string]: DataProvider } = {
  ht: htProvider(),
  mino: minoProvider() as CustomMinoProvider,
};

const dataProvidersInfos = [
  {
    resources: htResourcesKeys,
    path: 'ht',
  },
  {
    resources: minoResourcesKeys,
    path: 'mino',
  },
];

export default new Proxy<DataProvider>(
  {} as DataProvider /* placeholder for dataProviders */,
  {
    get:
      (target: DataProvider, name: string | symbol) =>
      async (resource: string, params: unknown): Promise<any> => {
        if (typeof name === 'symbol') {
          return Promise.resolve();
        }
        const dataProviderInfo = dataProvidersInfos.find(({ resources }) =>
          (resources as any).includes(resource),
        );
        if (!dataProviderInfo) {
          throw Error(
            `Resource ${resource} is not defined in any dataProvider's resourceKeys`,
          );
        }
        const dataProvider = dataProviders[dataProviderInfo.path];
        return dataProvider[name](resource, params);
      },
  },
);
