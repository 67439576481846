import React, { useState, useCallback, useRef } from 'react';
import {
  useAuthenticated,
  useDataProvider,
  UpdateResult,
  CreateResult,
  UpdateParams,
  DataProvider,
} from 'ra-core';

import csv from 'papaparse';
import moment from 'moment';

import { progressPreparedPromisesBatch } from '../../../lib/promises';

import {
  SMinoPayment,
  MinoPayment,
  MinoRequest,
} from '../../../types/mino/nodes';

import GenericImport, {
  GenericFieldsInfos,
  GenericCsvData,
  GenericDataToImport,
  GenericErrorData,
  checkAndUpdateFieldsData,
} from './genericImport';
import { getUsersCP, getUsersRequests } from '../../../utils/mino/management';
import { enhanceQueryResult } from '../../../dataProvider/mino/flattener';
import { GQLMinoMinoStatus } from '../../../types/mino/schema';

import config from '../../../config';
import { CustomMinoProvider } from '../../../dataProvider/dataProvider';
import {
  NO_MINORATION,
  UserMonthData,
  getUserMonthData,
} from '../../../utils/mino/utils';

export const key = 'minoManagementImportPayments';

const importFields = [
  'month',
  'sncfCP',
  // 'fullName',
  'paySlip',
  // 'evsCode',
  // 'evsLabel',
  'deduction',
] as const;

const fields = [
  ...importFields,

  // virtual columns
  'rent',
  'provDeduction',
  'provider',
  'sncfSA',
  'sncfBranche',
  'sncfDivision',
  'sncfRG',
  'sncfEtablissement',
] as const;

type ImportField = (typeof importFields)[number];
type Field = (typeof fields)[number];

const importFieldsInfos: GenericFieldsInfos<ImportField> = {
  paySlip: {
    csvHeader: 'PERPAI',
    isMandatory: true,
    isDate: true,
    dateCsvFormat: 'YYYYMM',
    isMonth: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfCP: {
    csvHeader: 'IMMAT',
    isRowKey: true,
    isMandatory: true,
    translateFieldPath: 'resources.minoUser.fields',
  },
  // fullName: {
  //   csvHeader: 'NOM_PRENOM',
  //   isRowKey: true,
  //   isMandatory: false,
  //   translateFieldPath: 'resources.minoUser.fields',
  //   isDbData: false,
  // },
  month: {
    csvHeader: 'PERORIG',
    isRowKey: true,
    isMandatory: true,
    isDate: true,
    dateCsvFormat: '[MT]YYYYMM',
    isMonth: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  // /** Code rubrique */
  // evsCode: {
  //   csvHeader: 'COD_RUB',
  //   isRowKey: true,
  //   isMandatory: false,
  //   translateFieldPath: 'resources.minoUser.fields',
  //   isDbData: false,
  // },
  // /** Libellé de la rubrique */
  // evsLabel: {
  //   csvHeader: 'LIBELLE_RUB',
  //   isRowKey: true,
  //   isMandatory: false,
  //   translateFieldPath: 'resources.minoUser.fields',
  //   isDbData: false,
  // },
  deduction: {
    csvHeader: 'MONTANT',
    isMandatory: true,
    isNumber: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
};

const fieldsInfos: GenericFieldsInfos<Field> = {
  ...importFieldsInfos,
  rent: {
    csvHeader: 'VIRTUAL',
    isMandatory: false,
    isNumber: true,
    emptyIsZero: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  provDeduction: {
    csvHeader: 'VIRTUAL',
    isMandatory: false,
    isNumber: true,
    emptyIsZero: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  provider: {
    csvHeader: 'VIRTUAL',
    isRowKey: false,
    isMandatory: false,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfSA: {
    csvHeader: 'VIRTUAL',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfBranche: {
    csvHeader: 'VIRTUAL',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfDivision: {
    csvHeader: 'VIRTUAL',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfRG: {
    csvHeader: 'VIRTUAL',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
  sncfEtablissement: {
    csvHeader: 'VIRTUAL',
    isMandatory: true,
    isDbData: true,
    translateFieldPath: 'resources.minoPayment.fields',
  },
};

type CsvPayment = GenericCsvData<ImportField>;

type PaymentToImport = GenericDataToImport<Field> & {
  user: string;
  dbPayment?: SMinoPayment;
  userNbPayments?: number;
};

type ErrorPayment = GenericErrorData<ImportField>;

type DbPayments = {
  [sncfCP in string]?: SMinoPayment[]; // All payments (up to 12)
};

type MonthData = Map<string, UserMonthData>;

type DbRequests = Map<string, MinoRequest[]>;

const ImportUsersOrganisation: React.FC = () => {
  useAuthenticated();
  const dataProvider = useDataProvider<DataProvider, CustomMinoProvider>();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<null | number>(null);
  /** The famous export 2B (2A?), of user ids indexed by sncfCP */
  const usersCP = useRef<ReturnType<typeof getUsersCP>>(new Map());
  /** The other famous export 4 / 5 (EVS), indexed by user ID */
  const usersMonthData = useRef<MonthData>(new Map());
  const csvValidUsersSet = useRef(new Set<string>());
  const [paymentsToImport, setPaymentsToImport] = useState<PaymentToImport[]>();
  const [errorPayments, setErrorPayments] = useState<ErrorPayment[]>();

  const usersValidDbRequests = useRef<DbRequests>();

  const retrievePaymentsAndMonthData = useCallback(() => {
    return dataProvider.getListAll('minoUser').then(({ data: users }) => {
      const dbPayments: DbPayments = {};

      usersValidDbRequests.current = getUsersRequests(users);

      users.forEach(user => {
        const { id, sncfCP, payments } = user;
        if (sncfCP) {
          payments?.edges.forEach(({ node: payment }) => {
            const sPayment = enhanceQueryResult<MinoPayment, SMinoPayment>(
              payment as MinoPayment,
            );
            sPayment.user = id; // For connection not to be considered an updated data
            const sncfCpDbPayment = dbPayments[sncfCP];
            if (sncfCpDbPayment) {
              sncfCpDbPayment.push(sPayment);
            } else {
              dbPayments[sncfCP] = [sPayment];
            }
          });
          const md = getUserMonthData(user);
          // check for NO_MINORATION = export EVS
          if (md && md.mino !== NO_MINORATION)
            usersMonthData.current.set(id, md);
        }
      });
      usersCP.current = getUsersCP(users);
      return { dbPayments, monthData: usersMonthData.current };
    });
  }, [dataProvider]);

  // Match db and import data
  const checkPayments = useCallback(
    ({
      dbPayments,
      monthData,
      csvPayments,
    }: {
      dbPayments: DbPayments;
      monthData: MonthData;
      csvPayments: CsvPayment[];
    }) => {
      const importPaymentsTemp: PaymentToImport[] = [];
      const errorPaymentsTemp: ErrorPayment[] = [];

      csvPayments.forEach(csvPayment => {
        const updatedFields: ImportField[] = [];
        const processedFieldsError: ImportField[] = [];
        let userID: string | undefined;
        let dbPayment: SMinoPayment | undefined;
        let isNew = true;

        const { csvDataToImport: csvPaymentToImport, errors } =
          // header based so we're good if we have too many columns
          checkAndUpdateFieldsData<ImportField>(importFieldsInfos, csvPayment);

        const csvMonthDate = moment(`${csvPaymentToImport.month}`);
        const csvPaySlipDate = moment(`${csvPaymentToImport.paySlip}`);
        // Special knownledge: month must be equal to paySlip-1
        if (csvPaymentToImport.month && csvPaymentToImport.paySlip) {
          if (
            !csvMonthDate.isSame(
              moment(csvPaySlipDate).subtract(1, 'month'),
              'month',
            )
          ) {
            errors.push({
              field: 'month',
              message: `Le mois ne correspond pas au BS M-1`,
            });
            errors.push({
              field: 'paySlip',
              message: `Le BS ne correspond pas au mois M+1`,
            });
          } else {
            csvPaymentToImport.month = csvMonthDate.format(
              config.awsDateFormat,
            );
            csvPaymentToImport.paySlip = csvPaySlipDate.format(
              config.awsDateFormat,
            );
          }
        }

        // Special knownledge: month SHOULD be equal to currentMonth-1
        if (
          !csvPaymentToImport.month ||
          !csvMonthDate.isSame(moment(config.minoManagementMonthIso), 'month')
        ) {
          processedFieldsError.push('month');
        }

        if (csvPaymentToImport.sncfCP) {
          if (!errors.some(({ field }) => field === 'sncfCP')) {
            csvPaymentToImport.sncfCP = `${csvPaymentToImport.sncfCP}`;
            // Special knownledge: sncfCP must be in export 2b
            if (!usersCP.current.has(csvPaymentToImport.sncfCP)) {
              errors.push({
                field: 'sncfCP',
                message: "Ne correspond pas à un agent de l'export 2b",
              });
            } else {
              userID = usersCP.current.get(csvPaymentToImport.sncfCP);
            }

            // This CSV line's payment
            const dbPaymentMatch = dbPayments[csvPaymentToImport.sncfCP]?.find(
              dbP => {
                return (
                  // dbP.provider === csvPaymentToImport.provider &&
                  dbP.month === csvPaymentToImport.month
                );
              },
            );
            dbPayment = dbPaymentMatch;
            if (dbPaymentMatch) {
              // Do not (re)import this line ... (no CREATE)
              isNew = false;
              (Object.keys(importFieldsInfos) as ImportField[])
                .filter(
                  field =>
                    fieldsInfos[field].isDbData && !fieldsInfos[field].isRowKey,
                )
                .forEach(field => {
                  const paymentField = field as keyof typeof dbPaymentMatch;
                  if (
                    dbPaymentMatch[paymentField] !== csvPaymentToImport[field]
                  ) {
                    // ... except for it's updated values (some UPDATEs only)
                    updatedFields.push(field);
                  }
                });
            }
          }
        }

        const userMonthPayments = new Set();
        if (csvPaymentToImport.sncfCP) {
          const userPayments = dbPayments[`${csvPaymentToImport.sncfCP}`];
          if (userPayments) {
            userPayments.forEach(userPayment => {
              userMonthPayments.add(userPayment.month);
            });
          }
        }
        const userNbPayments = userMonthPayments.size;

        const userData = userID && monthData.get(userID);
        if (!userData)
          errors.push({
            field: 'sncfCP',
            message: "Ne correspond pas à un agent de l'export 5 (EVS)",
          });
        if (userID && errors.length === 0 && userData) {
          csvValidUsersSet.current.add(`${csvPaymentToImport.sncfCP}`);
          const {
            rent = 0,
            provDeduction = 0,
            provider = 'N/A',
            sncfSA,
            sncfBranche,
            sncfDivision,
            sncfRG,
            sncfEtablissement,
          } = monthData.get(userID) || {};
          importPaymentsTemp.push({
            ...csvPaymentToImport,
            user: userID,
            dbPayment,
            userNbPayments,
            updatedFields,
            processedFieldsError,
            isNew,
            rent,
            provDeduction,
            provider,
            sncfSA,
            sncfBranche,
            sncfDivision,
            sncfRG,
            sncfEtablissement,
          });
        } else {
          errorPaymentsTemp.push({
            ...csvPaymentToImport,
            updatedFields,
            errors,
            processedFieldsError,
          });
        }
      });

      setPaymentsToImport(importPaymentsTemp);
      setErrorPayments(errorPaymentsTemp);
    },
    [],
  );

  const onCompleteCSVImport = useCallback(
    (csvResult: csv.ParseResult<CsvPayment>): Promise<void> => {
      usersCP.current.clear();
      csvValidUsersSet.current.clear();
      return retrievePaymentsAndMonthData().then(
        ({ dbPayments, monthData }) => {
          checkPayments({
            dbPayments,
            monthData,
            csvPayments: csvResult.data,
          });
        },
      );
    },
    [retrievePaymentsAndMonthData, checkPayments],
  );

  const importPaymentsToDB = useCallback(
    (withNotification = false) => {
      if (paymentsToImport) {
        setLoading(true);

        const preparedPromises = paymentsToImport.reduce<
          Array<[(...args: any) => Promise<UpdateResult | CreateResult>, any[]]>
        >((preparedPromisesAcc, userToImport) => {
          const { user, dbPayment, userNbPayments, ...userRest } = userToImport;
          const { sncfCP } = userRest;
          const clearedPayment: SMinoPayment = (
            Object.keys(userRest) as Field[]
          ).reduce<any>((acc, field) => {
            if (fieldsInfos[field] && fieldsInfos[field].isDbData) {
              acc[field] = userRest[field];
            }
            return acc;
          }, {});
          clearedPayment.user = user;
          clearedPayment.wfNotify = withNotification;
          if (dbPayment) {
            clearedPayment.version = dbPayment.version;
            preparedPromisesAcc.push([
              dataProvider.update,
              [
                'minoPayment',
                {
                  id: dbPayment.id,
                  data: clearedPayment,
                  previousData: dbPayment,
                },
              ],
            ]);
            return preparedPromisesAcc;
          }

          // On 1st payment, set the user's request to "EN_COURS"
          // On 12th payment, set the user's request to "FIN_DROITS"
          if (sncfCP && usersValidDbRequests.current) {
            const requests = usersValidDbRequests.current.get(`${sncfCP}`);
            if (requests && requests.length > 0) {
              if (userNbPayments === 0) {
                // We will create the 1st one
                requests.forEach(request => {
                  preparedPromisesAcc.push([
                    dataProvider.update,
                    [
                      'minoRequest',
                      {
                        id: request.id,
                        data: {
                          wfStatus: GQLMinoMinoStatus.EN_COURS,
                          version: request.version,
                        },
                        previousData: request, // FIXME - Should really pass previousData
                      },
                    ] as [string, UpdateParams],
                  ]);
                });
              } else if (userNbPayments === 11) {
                // We will create the 12th one
                requests.forEach(request => {
                  preparedPromisesAcc.push([
                    dataProvider.update,
                    [
                      'minoRequest',
                      {
                        id: request.id,
                        data: {
                          wfStatus: GQLMinoMinoStatus.FIN_DROITS,
                          version: request.version,
                        },
                        previousData: request,
                      },
                    ] as [string, UpdateParams],
                  ]);
                });
              }
            }
          }

          preparedPromisesAcc.push([
            dataProvider.create,
            [
              'minoPayment',
              {
                data: clearedPayment,
              },
            ],
          ]);
          return preparedPromisesAcc;
        }, []);

        setProgress(0);
        return progressPreparedPromisesBatch(preparedPromises, p => {
          setProgress(p);
        }).then(() => {
          setLoading(false);
          setProgress(null);
        });
      }
      return Promise.resolve();
    },
    [paymentsToImport, dataProvider, usersValidDbRequests],
  );

  const importPaymentsToDBWithNotification = useCallback(
    () => importPaymentsToDB(true),
    [importPaymentsToDB],
  );

  return (
    <GenericImport
      pageKey={key}
      s3FileName="RetourPaie-EVS-PADH-\d{8}.csv"
      s3Source={`npf-evs-padh/RetourPaie-EVS-PADH-\\d{8}\\.ZIP$`}
      fieldsInfos={fieldsInfos}
      importFieldsInfos={importFieldsInfos}
      loading={loading}
      setLoading={setLoading}
      progress={progress}
      onCompleteCSVImport={onCompleteCSVImport}
      users={[
        Array.from(usersMonthData.current.values()).map(umd => umd.sncfCP),
        csvValidUsersSet.current,
      ]}
      dataToImport={paymentsToImport}
      errorsData={errorPayments}
      importDataToDB={importPaymentsToDB}
      secondaryImportDataToDB={importPaymentsToDBWithNotification}
    />
  );
};

export default ImportUsersOrganisation;
