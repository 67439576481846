import { WFNext, WFStatus } from '../../types/ht/enums';

const {
  A_VALIDER,
  EN_INSTRUCTION,
  STANDBY,
  VALIDEE_PARME,
  REFUSEE_PARME,
  VALIDEE,
  REFUSEE,
  TRANSMIS_PARTENAIRE,
  TRANSMIS_ALS,
  PROPOSITION_EN_COURS,
  PROPOSITION_ALS,
  PROPOSITION_ACCEPTEE,
  ATTENTE_ORIENTATION,
  BAIL_SIGNE,
  RENONCEE,
  ANNULEE,
} = WFStatus;

const { VALIDATED, UPDATED } = WFNext;
export enum CREATE_ENUM {
  CREATE = 'create',
}
const { CREATE } = CREATE_ENUM;
type NextState = WFNext | CREATE_ENUM;

export enum StatusState {
  VALID,
  OK,
  INVALID,
}
const { VALID, OK, INVALID } = StatusState;

type HasBailState = { hasBail: StatusState };
type HasNotBailState = { hasNotBail: StatusState };
type StatusSubState = HasBailState | HasNotBailState;
export function substatusHasBail(state: StatusSubState): state is HasBailState {
  return typeof (state as HasBailState).hasBail !== 'undefined';
}
export function substatusHasNotBail(
  state: StatusSubState,
): state is HasNotBailState {
  return typeof (state as HasNotBailState).hasNotBail !== 'undefined';
}

export type StatusPossibleState = StatusState | StatusSubState;
export function statusHasSubStates(
  state: StatusPossibleState,
): state is StatusSubState {
  return typeof state === 'object';
}
export type StatusStates = {
  [K in NextState]?: StatusPossibleState;
};

type StatusWorkflowStates = { [K in WFStatus]: StatusStates };
export const statusWorkflowStates: StatusWorkflowStates = {
  [A_VALIDER]: {
    [CREATE]: VALID,
  },
  [EN_INSTRUCTION]: {
    [VALIDATED]: OK,
  },
  [STANDBY]: {
    [VALIDATED]: VALID,
    [UPDATED]: { hasNotBail: INVALID },
  },
  [VALIDEE_PARME]: {
    [VALIDATED]: VALID,
    [UPDATED]: { hasNotBail: INVALID },
  },
  [REFUSEE_PARME]: {
    [VALIDATED]: VALID,
    [UPDATED]: { hasNotBail: INVALID },
  },
  [VALIDEE]: { [VALIDATED]: VALID },
  [ATTENTE_ORIENTATION]: {
    [VALIDATED]: OK,
    [UPDATED]: { hasNotBail: OK },
  },
  [REFUSEE]: {
    [VALIDATED]: VALID,
  },
  [TRANSMIS_PARTENAIRE]: {
    [VALIDATED]: OK,
    [UPDATED]: { hasNotBail: OK },
  },
  [TRANSMIS_ALS]: {
    [VALIDATED]: OK,
    [UPDATED]: { hasNotBail: OK },
  },
  [PROPOSITION_EN_COURS]: {
    [UPDATED]: { hasNotBail: OK },
  },
  [PROPOSITION_ALS]: {
    [UPDATED]: { hasNotBail: OK },
  },
  [PROPOSITION_ACCEPTEE]: {
    [UPDATED]: { hasNotBail: OK },
  },
  [BAIL_SIGNE]: {
    [UPDATED]: { hasBail: VALID },
  },
  [RENONCEE]: {
    [VALIDATED]: VALID,
    [UPDATED]: { hasNotBail: VALID },
  },
  [ANNULEE]: {
    [VALIDATED]: VALID,
    [UPDATED]: { hasNotBail: VALID },
  },
};
