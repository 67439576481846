export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
// Generated on 2023-02-15T15:51:03+01:00

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any;
};

export type GQLMinoQuery = {
  __typename: 'Query';
  allAsset: GQLMinoAssetConnection;
  asset?: Maybe<GQLMinoAsset>;
  allUser: GQLMinoUserConnection;
  user?: Maybe<GQLMinoUser>;
  allRequest: GQLMinoRequestConnection;
  request?: Maybe<GQLMinoRequest>;
  allContract: GQLMinoContractConnection;
  contract?: Maybe<GQLMinoContract>;
  allRent: GQLMinoRentConnection;
  rent?: Maybe<GQLMinoRent>;
  allPayment: GQLMinoPaymentConnection;
  payment?: Maybe<GQLMinoPayment>;
  node?: Maybe<GQLMinoNode>;
  /**   Returns optimistic data for nodes in transition */
  nodeNext?: Maybe<GQLMinoNodeUpdate>;
  /**   Introspection config */
  _schemaConfig?: Maybe<GQLMinoSchemaConfig>;
  history?: Maybe<GQLMinoLogConnection>;
};

export type GQLMinoQueryAllAssetArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryAssetArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryAllUserArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryUserArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryAllRequestArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryRequestArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryAllContractArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryContractArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryAllRentArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryRentArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryAllPaymentArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoQueryPaymentArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryNodeArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryNodeNextArgs = {
  id: Scalars['ID'];
};

export type GQLMinoQueryHistoryArgs = {
  id: Scalars['String'];
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoLogWhereInput>;
};

export type GQLMinoAssetConnection = GQLMinoConnection & {
  __typename: 'AssetConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoAssetEdge>>;
};

export type GQLMinoPageInfo = {
  __typename: 'PageInfo';
  /**   When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /**   When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /**   When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /**   When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type GQLMinoAssetEdge = GQLMinoEdge & {
  __typename: 'AssetEdge';
  node: GQLMinoAsset;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type GQLMinoAsset = GQLMinoNode & {
  __typename: 'Asset';
  /**   File name */
  filename: Maybe<Scalars['String']>;
  /**   File size */
  size?: Maybe<Scalars['Int']>;
  /**   Mime type */
  type?: Maybe<Scalars['String']>;
  /**   S3 Key (suffix only) */
  key?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  /**   the list of Requests of this attachment */
  request: GQLMinoRequestConnection;
};

export type GQLMinoAssetRequestArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export enum GQLMinoStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export type GQLMinoRequestConnection = GQLMinoConnection & {
  __typename: 'RequestConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoRequestEdge>>;
};

export type GQLMinoRequestEdge = GQLMinoEdge & {
  __typename: 'RequestEdge';
  node: GQLMinoRequest;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

/**   Demande Mino */
export type GQLMinoRequest = GQLMinoNode & {
  __typename: 'Request';
  /**   Consentement RGPD */
  gdpr?: Maybe<Scalars['AWSDateTime']>;
  /**   Adresse de la résidence */
  streetAddress?: Maybe<Scalars['String']>;
  /**   Code postal de la résidence */
  postalCode?: Maybe<Scalars['String']>;
  /**   Ville (deprecated)  */
  addressLevel2?: Maybe<Scalars['String']>;
  /**   Date début bail */
  dateBail?: Maybe<Scalars['AWSDate']>;
  /**   Gestionnaire */
  provider?: Maybe<Scalars['String']>;
  /**
   *   Référence du logement loué
   * @deprecated Field no longer supported
   */
  residenceCode?: Maybe<Scalars['String']>;
  /**
   *   Montant de la redevance (€)
   * @deprecated Field no longer supported
   */
  rent?: Maybe<Scalars['Float']>;
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Email */
  email?: Maybe<Scalars['String']>;
  /**   Civilité */
  honorificPrefix?: Maybe<GQLMinoHonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom */
  familyName?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   SA */
  sncfCompany?: Maybe<Scalars['String']>;
  /**   LPA (Site) */
  sncfSite?: Maybe<Scalars['String']>;
  /**   LPA (Code postal) */
  sncfPostalCode?: Maybe<Scalars['String']>;
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  sncfStatut?: Maybe<GQLMinoSncfStatut>;
  sncfEmployeeType?: Maybe<GQLMinoSncfEmployeeType>;
  comment?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  /**   Statut (workflow condition) */
  wfStatus?: Maybe<GQLMinoMinoStatus>;
  wfNotify?: Maybe<Scalars['Boolean']>;
  wfNext?: Maybe<Scalars['String']>;
  /**   Motif refus */
  motifRefus?: Maybe<Scalars['String']>;
  /**   Loyers */
  rents: GQLMinoRentConnection;
  /**   Pièces jointes: Avis imposition + CSV export + ??? */
  attachments: GQLMinoAssetConnection;
  /**   the list of Users of this request */
  user: GQLMinoUserConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransactionState>;
};

/**   Demande Mino */
export type GQLMinoRequestRentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

/**   Demande Mino */
export type GQLMinoRequestAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

/**   Demande Mino */
export type GQLMinoRequestUserArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export enum GQLMinoHonorificPrefix {
  MR = 'MR',
  MME = 'MME',
}

export enum GQLMinoSncfStatut {
  AG = 'AG',
  CD = 'CD',
  CP = 'CP',
  CS = 'CS',
  SG = 'SG',
  CC = 'CC',
  CM = 'CM',
  MD = 'MD',
}

export enum GQLMinoSncfEmployeeType {
  CDD = 'CDD',
  CDI = 'CDI',
  EXT = 'EXT',
  INT = 'INT',
}

export enum GQLMinoMinoStatus {
  NOUVELLE = 'NOUVELLE',
  EN_COURS_INSTRUCTION = 'EN_COURS_INSTRUCTION',
  ENVOI_CONVENTION = 'ENVOI_CONVENTION',
  VALIDEE = 'VALIDEE',
  EN_COURS = 'EN_COURS',
  REFUSEE = 'REFUSEE',
  FIN_DROITS = 'FIN_DROITS',
  FIN_DROITS_INTERRUPTION = 'FIN_DROITS_INTERRUPTION',
  SUSPENDUE = 'SUSPENDUE',
  TRANSFEREE = 'TRANSFEREE',
}

export type GQLMinoRentConnection = GQLMinoConnection & {
  __typename: 'RentConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoRentEdge>>;
};

export type GQLMinoRentEdge = GQLMinoEdge & {
  __typename: 'RentEdge';
  node: GQLMinoRent;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

/**   Loyer (rapport Gestionnaire) */
export type GQLMinoRent = GQLMinoNode & {
  __typename: 'Rent';
  /**   Mois considéré */
  month?: Maybe<Scalars['AWSDate']>;
  rent?: Maybe<Scalars['Float']>;
  /**   APL ou AL gestionnaire */
  provDeduction?: Maybe<Scalars['Float']>;
  /**   Logé mois entier */
  fullAccomodation?: Maybe<Scalars['Boolean']>;
  /**   Date d'entrée */
  startDate?: Maybe<Scalars['AWSDate']>;
  /**   Date de sortie */
  endDate?: Maybe<Scalars['AWSDate']>;
  /**   the list of Requests of this rent */
  request: GQLMinoRequestConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransactionState>;
};

/**   Loyer (rapport Gestionnaire) */
export type GQLMinoRentRequestArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

/**   Optional input filter */
export type GQLMinoWhereInput = {
  /**   Filter by status */
  status?: Maybe<GQLMinoStatus>;
  /**   Find known connection with other id */
  id?: Maybe<Scalars['ID']>;
  /**   Find by ddbindex 0 (begins_with) */
  alt0?: Maybe<Scalars['String']>;
  /**   Find by ddbindex 1 (begins_with) */
  alt1?: Maybe<Scalars['String']>;
};

export type GQLMinoTransactionState = {
  __typename: 'TransactionState';
  state: GQLMinoTransaction;
  begin?: Maybe<Scalars['AWSDateTime']>;
  commit?: Maybe<Scalars['AWSDateTime']>;
  rollback?: Maybe<Scalars['AWSDateTime']>;
  message?: Maybe<Scalars['String']>;
};

export enum GQLMinoTransaction {
  BEGIN = 'BEGIN',
  COMMIT = 'COMMIT',
  ROLLBACK = 'ROLLBACK',
}

/**   Any uniquely identifiable object by ID */
export type GQLMinoNode = {
  __typename: string;
  /**   The ID of the object */
  id: Scalars['ID'];
  createdAt: Maybe<Scalars['AWSDateTime']>;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};

export type GQLMinoEdge = {
  node: GQLMinoNode;
  cursor?: Maybe<Scalars['ID']>;
};

export type GQLMinoConnection = {
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoEdge>>;
};

export type GQLMinoUserConnection = GQLMinoConnection & {
  __typename: 'UserConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoUserEdge>>;
};

export type GQLMinoUserEdge = GQLMinoEdge & {
  __typename: 'UserEdge';
  node: GQLMinoUser;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type GQLMinoUser = GQLMinoNode & {
  __typename: 'User';
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  /**   Civilité */
  honorificPrefix?: Maybe<GQLMinoHonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom */
  familyName?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   SA */
  sncfSA?: Maybe<Scalars['String']>;
  /**   Branche */
  sncfBranche?: Maybe<Scalars['String']>;
  /**   Division */
  sncfDivision?: Maybe<Scalars['String']>;
  /**   RG */
  sncfRG?: Maybe<Scalars['String']>;
  /**   Établissement */
  sncfEtablissement?: Maybe<Scalars['String']>;
  /**   Date d'import */
  sncfImportDate?: Maybe<Scalars['AWSDate']>;
  /**   Demandes */
  requests: GQLMinoRequestConnection;
  /**   Contrats */
  contracts: GQLMinoContractConnection;
  /**   Transactions */
  payments: GQLMinoPaymentConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransactionState>;
};

export type GQLMinoUserRequestsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoUserContractsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoUserPaymentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoContractConnection = GQLMinoConnection & {
  __typename: 'ContractConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoContractEdge>>;
};

export type GQLMinoContractEdge = GQLMinoEdge & {
  __typename: 'ContractEdge';
  node: GQLMinoContract;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

/**   Contract */
export type GQLMinoContract = GQLMinoNode & {
  __typename: 'Contract';
  sncfContractType?: Maybe<GQLMinoSncfContractType>;
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  /**   Date fin de contrat */
  sncfContractEndDate?: Maybe<Scalars['AWSDate']>;
  /**   the list of Users of this contract */
  user: GQLMinoUserConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransactionState>;
};

/**   Contract */
export type GQLMinoContractUserArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export enum GQLMinoSncfContractType {
  DD = 'DD',
  DI = 'DI',
  ST = 'ST',
}

export type GQLMinoPaymentConnection = GQLMinoConnection & {
  __typename: 'PaymentConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoPaymentEdge>>;
};

export type GQLMinoPaymentEdge = GQLMinoEdge & {
  __typename: 'PaymentEdge';
  node: GQLMinoPayment;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

/**   Transaction (rapport Paye) */
export type GQLMinoPayment = GQLMinoNode & {
  __typename: 'Payment';
  /**   Mois considéré */
  month?: Maybe<Scalars['AWSDate']>;
  /**   Gestionnaires du mois */
  provider?: Maybe<Scalars['String']>;
  /**   APL ou AL gestionnaire (total mois) */
  provDeduction?: Maybe<Scalars['Float']>;
  /**   Loyer (total mois) */
  rent?: Maybe<Scalars['Float']>;
  /**
   *   Logé mois entier (deprecated)
   * @deprecated Field no longer supported
   */
  fullAccomodation?: Maybe<Scalars['Boolean']>;
  /**   Bulletin de salaire */
  paySlip?: Maybe<Scalars['AWSDate']>;
  /**   Minoration */
  deduction?: Maybe<Scalars['Float']>;
  /**
   *   Agent SNCF mois entier (deprecated)
   * @deprecated Field no longer supported
   */
  fullEmployment?: Maybe<Scalars['Boolean']>;
  /**   SA */
  sncfSA?: Maybe<Scalars['String']>;
  /**   Branche */
  sncfBranche?: Maybe<Scalars['String']>;
  /**   Division */
  sncfDivision?: Maybe<Scalars['String']>;
  /**   RG */
  sncfRG?: Maybe<Scalars['String']>;
  /**   Établissement */
  sncfEtablissement?: Maybe<Scalars['String']>;
  /**   Send notification on transaction */
  wfNotify?: Maybe<Scalars['Boolean']>;
  /**   the list of Users of this payment */
  user: GQLMinoUserConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransactionState>;
};

/**   Transaction (rapport Paye) */
export type GQLMinoPaymentUserArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<GQLMinoWhereInput>;
};

export type GQLMinoNodeUpdate = {
  __typename: 'NodeUpdate';
  id: Scalars['ID'];
  node: GQLMinoNode;
  updateInfo: GQLMinoNodeUpdateInfo;
};

export type GQLMinoNodeUpdateInfo = {
  __typename: 'NodeUpdateInfo';
  mutation: GQLMinoUpdateType;
  connect: Array<Maybe<GQLMinoConnectionUpdate>>;
  disconnect: Array<Maybe<GQLMinoConnectionUpdate>>;
};

export enum GQLMinoUpdateType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type GQLMinoConnectionUpdate = {
  __typename: 'ConnectionUpdate';
  field: Scalars['String'];
  id: Scalars['ID'];
};

export type GQLMinoSchemaConfig = {
  __typename: 'SchemaConfig';
  connections?: Maybe<
    Array<Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>>
  >;
  refs?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>>>;
};

export type GQLMinoLogConnection = {
  __typename: 'LogConnection';
  pageInfo: GQLMinoPageInfo;
  edges: Array<Maybe<GQLMinoLogEdge>>;
};

export type GQLMinoLogEdge = {
  __typename: 'LogEdge';
  node: GQLMinoLogEvent;
  cursor?: Maybe<Scalars['ID']>;
};

export type GQLMinoLogEvent = {
  __typename: 'LogEvent';
  id: Scalars['ID'];
  ingestionTime?: Maybe<Scalars['AWSDateTime']>;
  timestamp?: Maybe<Scalars['AWSDateTime']>;
  message?: Maybe<GQLMinoHistory>;
};

export type GQLMinoHistory = {
  __typename: 'History';
  /**   Action author */
  identity?: Maybe<Scalars['ID']>;
  /**   Workflow status */
  wfStatus?: Maybe<GQLMinoMinoStatus>;
  /**   Action type:verb, eg commit:update / commit:create */
  action?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  /**   Connection node type */
  connType?: Maybe<Scalars['String']>;
  /**   Connection node ID */
  connId?: Maybe<Scalars['ID']>;
};

export type GQLMinoLogWhereInput = {
  startTime?: Maybe<Scalars['AWSDateTime']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
};

export type GQLMinoMutation = {
  __typename: 'Mutation';
  createAsset?: Maybe<GQLMinoNodeUpdate>;
  updateAsset?: Maybe<GQLMinoNodeUpdate>;
  deleteAsset?: Maybe<GQLMinoNodeUpdate>;
  createUser?: Maybe<GQLMinoNodeUpdate>;
  updateUser?: Maybe<GQLMinoNodeUpdate>;
  deleteUser?: Maybe<GQLMinoNodeUpdate>;
  createRequest?: Maybe<GQLMinoNodeUpdate>;
  updateRequest?: Maybe<GQLMinoNodeUpdate>;
  deleteRequest?: Maybe<GQLMinoNodeUpdate>;
  createContract?: Maybe<GQLMinoNodeUpdate>;
  updateContract?: Maybe<GQLMinoNodeUpdate>;
  deleteContract?: Maybe<GQLMinoNodeUpdate>;
  createRent?: Maybe<GQLMinoNodeUpdate>;
  updateRent?: Maybe<GQLMinoNodeUpdate>;
  deleteRent?: Maybe<GQLMinoNodeUpdate>;
  createPayment?: Maybe<GQLMinoNodeUpdate>;
  updatePayment?: Maybe<GQLMinoNodeUpdate>;
  deletePayment?: Maybe<GQLMinoNodeUpdate>;
};

export type GQLMinoMutationCreateAssetArgs = {
  data: GQLMinoAssetInput;
};

export type GQLMinoMutationUpdateAssetArgs = {
  data: GQLMinoAssetInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};

export type GQLMinoMutationDeleteAssetArgs = {
  id: Scalars['ID'];
};

export type GQLMinoMutationCreateUserArgs = {
  data: GQLMinoUserInput;
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationUpdateUserArgs = {
  data: GQLMinoUserInput;
  id: Scalars['ID'];
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransaction>;
  message?: Maybe<Scalars['String']>;
};

export type GQLMinoMutationDeleteUserArgs = {
  id: Scalars['ID'];
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationCreateRequestArgs = {
  data: GQLMinoRequestInput;
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationUpdateRequestArgs = {
  data: GQLMinoRequestInput;
  id: Scalars['ID'];
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransaction>;
  message?: Maybe<Scalars['String']>;
};

export type GQLMinoMutationDeleteRequestArgs = {
  id: Scalars['ID'];
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationCreateContractArgs = {
  data: GQLMinoContractInput;
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationUpdateContractArgs = {
  data: GQLMinoContractInput;
  id: Scalars['ID'];
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransaction>;
  message?: Maybe<Scalars['String']>;
};

export type GQLMinoMutationDeleteContractArgs = {
  id: Scalars['ID'];
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationCreateRentArgs = {
  data: GQLMinoRentInput;
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationUpdateRentArgs = {
  data: GQLMinoRentInput;
  id: Scalars['ID'];
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransaction>;
  message?: Maybe<Scalars['String']>;
};

export type GQLMinoMutationDeleteRentArgs = {
  id: Scalars['ID'];
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationCreatePaymentArgs = {
  data: GQLMinoPaymentInput;
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoMutationUpdatePaymentArgs = {
  data: GQLMinoPaymentInput;
  id: Scalars['ID'];
  version: Scalars['Int'];
  transaction?: Maybe<GQLMinoTransaction>;
  message?: Maybe<Scalars['String']>;
};

export type GQLMinoMutationDeletePaymentArgs = {
  id: Scalars['ID'];
  transaction?: Maybe<GQLMinoTransaction>;
};

export type GQLMinoAssetInput = {
  /**   File name */
  filename: Maybe<Scalars['String']>;
  /**   File size */
  size?: Maybe<Scalars['Int']>;
  /**   Mime type */
  type?: Maybe<Scalars['String']>;
  /**   S3 Key (suffix only) */
  key?: Maybe<Scalars['String']>;
  status?: Maybe<GQLMinoStatus>;
  /**   Request connections */
  request?: Maybe<GQLMinoConnectionInput>;
};

/**   keep connect + disconnect count <= 25 for single ddb call */
export type GQLMinoConnectionInput = {
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>;
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GQLMinoUserInput = {
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  /**   Civilité */
  honorificPrefix?: Maybe<GQLMinoHonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom */
  familyName?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   SA */
  sncfSA?: Maybe<Scalars['String']>;
  /**   Branche */
  sncfBranche?: Maybe<Scalars['String']>;
  /**   Division */
  sncfDivision?: Maybe<Scalars['String']>;
  /**   RG */
  sncfRG?: Maybe<Scalars['String']>;
  /**   Établissement */
  sncfEtablissement?: Maybe<Scalars['String']>;
  /**   Date d'import */
  sncfImportDate?: Maybe<Scalars['AWSDate']>;
  /**   Request connections */
  requests?: Maybe<GQLMinoConnectionInput>;
  /**   Contract connections */
  contracts?: Maybe<GQLMinoConnectionInput>;
  /**   Payment connections */
  payments?: Maybe<GQLMinoConnectionInput>;
  status?: Maybe<GQLMinoStatus>;
};

export type GQLMinoRequestInput = {
  /**   Consentement RGPD */
  gdpr?: Maybe<Scalars['AWSDateTime']>;
  /**   Adresse de la résidence */
  streetAddress?: Maybe<Scalars['String']>;
  /**   Code postal de la résidence */
  postalCode?: Maybe<Scalars['String']>;
  /**   Ville */
  addressLevel2?: Maybe<Scalars['String']>;
  /**   Date début bail */
  dateBail?: Maybe<Scalars['AWSDate']>;
  /**   Gestionnaire */
  provider?: Maybe<Scalars['String']>;
  /**   Type du logement loué */
  residenceCode?: Maybe<Scalars['String']>;
  /**   Montant de la redevance (€) */
  rent?: Maybe<Scalars['Float']>;
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Email */
  email?: Maybe<Scalars['String']>;
  /**   Civilité */
  honorificPrefix?: Maybe<GQLMinoHonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom */
  familyName?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   SA */
  sncfCompany?: Maybe<Scalars['String']>;
  /**   LPA (Site) */
  sncfSite?: Maybe<Scalars['String']>;
  /**   LPA (Code postal) */
  sncfPostalCode?: Maybe<Scalars['String']>;
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  sncfStatut?: Maybe<GQLMinoSncfStatut>;
  sncfEmployeeType?: Maybe<GQLMinoSncfEmployeeType>;
  comment?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  /**   Statut (workflow condition) */
  wfStatus?: Maybe<GQLMinoMinoStatus>;
  wfNotify?: Maybe<Scalars['Boolean']>;
  wfNext?: Maybe<Scalars['String']>;
  /**   Motif refus */
  motifRefus?: Maybe<Scalars['String']>;
  /**   Rent connections */
  rents?: Maybe<GQLMinoConnectionInput>;
  /**   Asset connections (should be unique) */
  attachments?: Maybe<GQLMinoConnectionInput>;
  /**   User connections */
  user?: Maybe<GQLMinoConnectionInput>;
  status?: Maybe<GQLMinoStatus>;
};

export type GQLMinoContractInput = {
  sncfContractType?: Maybe<GQLMinoSncfContractType>;
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  /**   Date fin de contrat */
  sncfContractEndDate?: Maybe<Scalars['AWSDate']>;
  /**   User connections */
  user?: Maybe<GQLMinoConnectionInput>;
  status?: Maybe<GQLMinoStatus>;
};

export type GQLMinoRentInput = {
  /**   Mois considéré */
  month?: Maybe<Scalars['AWSDate']>;
  rent?: Maybe<Scalars['Float']>;
  /**   APL ou AL gestionnaire */
  provDeduction?: Maybe<Scalars['Float']>;
  /**   Logé mois entier */
  fullAccomodation?: Maybe<Scalars['Boolean']>;
  /**   Date d'entrée */
  startDate?: Maybe<Scalars['AWSDate']>;
  /**   Date de sortie */
  endDate?: Maybe<Scalars['AWSDate']>;
  /**   Request connections */
  request?: Maybe<GQLMinoConnectionInput>;
  status?: Maybe<GQLMinoStatus>;
};

export type GQLMinoPaymentInput = {
  /**   Mois considéré */
  month?: Maybe<Scalars['AWSDate']>;
  /**   Gestionnaires du mois */
  provider?: Maybe<Scalars['String']>;
  /**   APL ou AL gestionnaire (total mois) */
  provDeduction?: Maybe<Scalars['Float']>;
  /**   Loyer (total mois) */
  rent?: Maybe<Scalars['Float']>;
  /**   Logé mois entier (deprecated) */
  fullAccomodation?: Maybe<Scalars['Boolean']>;
  /**   Bulletin de salaire */
  paySlip?: Maybe<Scalars['AWSDate']>;
  /**   Minoration */
  deduction?: Maybe<Scalars['Float']>;
  /**   Agent SNCF mois entier (deprecated) */
  fullEmployment?: Maybe<Scalars['Boolean']>;
  /**   SA */
  sncfSA?: Maybe<Scalars['String']>;
  /**   Branche */
  sncfBranche?: Maybe<Scalars['String']>;
  /**   Division */
  sncfDivision?: Maybe<Scalars['String']>;
  /**   RG */
  sncfRG?: Maybe<Scalars['String']>;
  /**   Établissement */
  sncfEtablissement?: Maybe<Scalars['String']>;
  /**   Send notification on transaction */
  wfNotify?: Maybe<Scalars['Boolean']>;
  /**   User connections */
  user?: Maybe<GQLMinoConnectionInput>;
  status?: Maybe<GQLMinoStatus>;
};

export type GQLMinoSubscription = {
  __typename: 'Subscription';
  subscribeToNode?: Maybe<GQLMinoNodeUpdate>;
  subscribeToAsset?: Maybe<GQLMinoNodeUpdate>;
  subscribeToUser?: Maybe<GQLMinoNodeUpdate>;
  subscribeToRequest?: Maybe<GQLMinoNodeUpdate>;
  subscribeToContract?: Maybe<GQLMinoNodeUpdate>;
  subscribeToRent?: Maybe<GQLMinoNodeUpdate>;
  subscribeToPayment?: Maybe<GQLMinoNodeUpdate>;
};

export type GQLMinoSubscriptionSubscribeToNodeArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToAssetArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToUserArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToRequestArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToContractArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToRentArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type GQLMinoSubscriptionSubscribeToPaymentArgs = {
  id?: Maybe<Scalars['ID']>;
};
