import React from 'react';
import { Create } from 'react-admin';
import { useTranslate } from 'ra-core';

import ResidenceForm from './ResidenceForm';

const ResidenceTitle: React.FC = () => {
  const translate = useTranslate();
  return <span>{translate('resources.htResidence.create')}</span>;
};

type ResidenceCreateProps = {};

const ResidenceCreate: React.FC<ResidenceCreateProps> = (
  props: ResidenceCreateProps,
) => {
  return (
    <Create {...props} title={<ResidenceTitle />}>
      <ResidenceForm create />
    </Create>
  );
};

export default ResidenceCreate;
