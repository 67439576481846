import { Roles } from './permissions';
import { ENV_LEVEL } from '../aws/aws-exports';

export const OAUTH_URL = 'https://login.phileog.com/oauth/padh';

export type PADHCredentials = {
  sub: string;
  credentials: {
    IdentityPoolId: string;
    IdentityId: string;
    Logins: {
      'cognito-identity.amazonaws.com': string;
    };
  };
  roles: Roles[];
  refresh_token: string;
  status: number;
};

export type PADHError = {
  reason: string;
  status: number;
};

export const isCreds = (result: any): result is PADHCredentials => result?.sub;

export const getCredentials = async (
  code: string,
): Promise<PADHCredentials | PADHError> => {
  const body = {
    grant_type: 'authorization_code',
    code,
    env: ENV_LEVEL.toLowerCase(),
  };
  const params: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  };
  const result: PADHCredentials | PADHError = await fetch(
    OAUTH_URL,
    params,
  ).then(async r => ({ ...(await r.json()), status: r.status }));
  return result;
};

export const refreshCredentials = async (
  refreshToken?: string,
): Promise<PADHCredentials | PADHError> => {
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken, // if undefined will try cookie
    env: ENV_LEVEL.toLowerCase(),
  };
  const params: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  };
  const result: PADHCredentials | PADHError = await fetch(
    OAUTH_URL,
    params,
  ).then(async r => ({ ...(await r.json()), status: r.status }));
  return result;
};

export const logout = async (): Promise<PADHCredentials | PADHError> => {
  const body = {
    grant_type: 'logout',
  };
  const params: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  };
  const result: PADHCredentials | PADHError = await fetch(
    OAUTH_URL,
    params,
  ).then(async r => ({ ...(await r.json()), status: r.status }));
  return result;
};
