import React, { useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useTranslate, ReduxState } from 'ra-core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import TextInput from '../../CustomInputs/TextInput';

import FormSection from '../../structure/FormSection';
import FormRow from '../../structure/FormRow';
import ContainerForward from '../../structure/ContainerForward';

import History from './History/History';

import UserLastRequestsInfos from './UserLastRequestsInfos';
import UserProcessInfos from './UserProcessInfos';

import UserRequests from './UserRequests';
import UserPayments from './UserPayments';

import { SMinoUser } from '../../../types/mino/nodes';

import { Rights } from '../../../authProvider/permissions';
import { honorificPrefixChoices } from '../../../utils/mino/enums';
import ErrorBoundary from '../../ErrorBoundary';

type UserTitleProps = {
  record?: SMinoUser;
  resource?: string;
  locked?: boolean;
};

const UserTitle: React.FC<UserTitleProps> = ({
  record: user,
  resource,
  locked = false,
}: UserTitleProps) => {
  const translate = useTranslate();
  return (
    <>
      <span>
        {translate(`resources.${resource}.name`, { smart_count: 1 })}{' '}
        {user ? `${user.givenName} ${user.familyName}` : ''}
      </span>
      {locked && (
        <>
          &nbsp;<span>{translate('transaction.locked')}</span>
        </>
      )}
    </>
  );
};

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
    },
  }),
);

type UserEditToolbarProps = {
  record?: SMinoUser;
  resource?: string;
  submitting?: boolean;
  readOnly?: boolean;
  basePath?: string;
};
const UserEditToolbar: React.FC<UserEditToolbarProps> = ({
  submitting = false,
  readOnly = false,
  ...props
}: UserEditToolbarProps) => {
  const { basePath } = props;
  const classes = useToolbarStyles();
  const translate = useTranslate();

  return (
    <Toolbar {...props} className={classes.root}>
      <ContainerForward>
        <SaveButton
          label="ra.action.save"
          redirect="list"
          submitOnEnter={false}
          disabled={readOnly || submitting}
        />
        <>
          {!!basePath && (
            <Button
              component={RouterLink}
              to={basePath ?? ''}
              className={classes.cancelButton}
            >
              {translate('ra.action.cancel')}
            </Button>
          )}
        </>
      </ContainerForward>
    </Toolbar>
  );
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    editMain: {
      position: 'relative',
    },
    formSection: {
      display: 'flex',
      flexFlow: 'row wrap',

      '& $field': {
        flexGrow: 1,
        flexBasis: '21%', // More that 1/5
      },
    },
    field: {},
  }),
);

type UserEditProps = {
  resource?: string;
  permissions?: Rights[];
};

const UserEdit: React.FC<UserEditProps> = (props: UserEditProps) => {
  const { resource, permissions } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );

  const permEdit = permissions?.includes(Rights.MINO_WRITE);
  const permAdminFields = permissions?.includes(Rights.EDIT_RESTRICTED);

  const getFieldProps = useCallback(
    (name: string): { [k: string]: any } => {
      const fieldProps: { [k: string]: any } = {
        className: classes.field,
      };

      switch (name) {
        case 'sncfCP':
          fieldProps.disabled = !permAdminFields;
          break;
        default:
      }

      return fieldProps;
    },
    [classes, permAdminFields],
  );

  return (
    <Edit
      {...props}
      title={<UserTitle resource={resource} />}
      undoable={false}
      aside={<History />}
      classes={{ main: classes.editMain }}
    >
      <SimpleForm
        saving={submitting}
        toolbar={
          permEdit ? (
            <UserEditToolbar submitting={submitting} />
          ) : (
            <></> // No buttons
          )
        }
        submitOnEnter={false}
      >
        <FormSection
          title={translate(`resources.${resource}.sections.personnalInfo`)}
          className={classes.formSection}
        >
          <FormRow>
            <TextInput source="sncfCP" {...getFieldProps('sncfCP')} />
            <SelectInput
              source="honorificPrefix"
              choices={honorificPrefixChoices}
              {...getFieldProps('honorificPrefix')}
            />
            <TextInput source="givenName" {...getFieldProps('givenName')} />
            <TextInput source="familyName" {...getFieldProps('familyName')} />
          </FormRow>
          <FormRow>
            <DateInput source="bday" {...getFieldProps('bday')} />
            <TextInput source="tel" {...getFieldProps('tel')} />
            <TextInput source="email" {...getFieldProps('email')} />
          </FormRow>
        </FormSection>
        <FormSection
          title={translate(`resources.${resource}.sections.professionalInfo`)}
          className={classes.formSection}
        >
          <UserLastRequestsInfos />
        </FormSection>
        <FormSection
          title={translate(`resources.${resource}.sections.process`)}
          className={classes.formSection}
        >
          <ErrorBoundary>
            <UserProcessInfos />
          </ErrorBoundary>
        </FormSection>
        <FormSection
          title={translate(`resources.${resource}.sections.requests`)}
          className={classes.formSection}
        >
          <UserRequests permissions={permissions} />
        </FormSection>
        <FormSection
          title={translate(`resources.${resource}.sections.payments`)}
          className={classes.formSection}
        >
          <UserPayments permissions={permissions} />
        </FormSection>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
