import React, { useCallback, useState } from 'react';
import {
  SimpleForm,
  SaveButton,
  DateInput,
  DateTimeInput,
  SelectInput,
  Toolbar,
} from 'react-admin';
import { useTranslate, useDataProvider, ReduxState, useNotify } from 'ra-core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import TextInput from '../../../CustomInputs/TextInput';

import { HtProposition } from '../../../../types/ht/nodes';
import { decisionChoices } from '../../../../utils/ht/enums';
import { Provider } from '../../../../types/ht/enums';

const useToolbarStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

type PropositionCreateButtonToolbarProps = {
  submitting?: boolean;
  onCancel: () => void;
};
const PropositionCreateDialogToolbar: React.FC<
  PropositionCreateButtonToolbarProps
> = ({
  submitting,
  onCancel,
  ...props
}: PropositionCreateButtonToolbarProps) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();

  return (
    <Toolbar {...props} className={classes.root}>
      <SaveButton />
      <>
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
      </>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2, 1),
    },
    dialogContent: {
      padding: 0,
    },
  }),
);

type PropositionCreateButtonProps = {
  onCreate: (id: string) => void;
};

const PropositionCreateButton: React.FC<PropositionCreateButtonProps> = ({
  onCreate,
}: PropositionCreateButtonProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );

  const [showDialog, setShowDialog] = useState(false);
  const handleOpen = useCallback(
    event => {
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog],
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSave = useCallback(
    values => {
      dataProvider
        .create('htProposition', {
          data: values,
        })
        .then(({ data: proposition }) => {
          onCreate((proposition as HtProposition).id);
          handleClose();
          notify('ra.notification.created', 'info');
        });
    },
    [dataProvider, onCreate, notify, handleClose],
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="secondary"
        className={classes.button}
      >
        {translate('resources.htProposition.create')}
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleClose}>
        <DialogTitle>{translate('resources.htProposition.create')}</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <SimpleForm
            save={handleSave}
            saving={submitting}
            redirect={false}
            toolbar={
              <PropositionCreateDialogToolbar
                onCancel={handleClose}
                submitting={submitting}
              />
            }
            resource="htProposition"
            record={{}} // override record context
            submitOnEnter={false}
          >
            <DateInput
              source="provDate"
              initialValue={moment(new Date()).format('YYYY-MM-DD')}
              fullWidth
            />
            <TextInput source="addressLevel2" fullWidth />
            <DateTimeInput source="dateDecision" fullWidth />
            <SelectInput
              source="decision"
              choices={decisionChoices}
              fullWidth
            />
            <TextInput
              source="motifRefus"
              multiline
              minRows={3}
              maxRows={10}
              fullWidth
            />
            <DateInput source="dateBail" fullWidth />
            <TextInput
              source="provider"
              initialValue={Provider.PARME}
              fullWidth
            />
            <TextInput source="residenceCode" fullWidth />
            <TextInput source="residenceName" fullWidth />
            <TextInput source="provId" fullWidth />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PropositionCreateButton;
