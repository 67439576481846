import { Coordinates } from '../types/ht/base';

// eslint-disable-next-line import/prefer-default-export
export const districtsPostalCodeGeoPoints: { [k: string]: Coordinates } = {
  13001: [5.38227869795, 43.2999009436],
  13002: [5.36364983265, 43.3126964178],
  13003: [5.38010981423, 43.3121200046],
  13004: [5.40087488752, 43.306733355],
  13005: [5.3975770959, 43.2928061953],
  13006: [5.38104785394, 43.2870989546],
  13007: [5.36323440435, 43.28251778],
  13008: [5.37464436293, 43.2417271832],
  13009: [5.45249683067, 43.2340834927],
  13010: [5.42681101081, 43.2755155435],
  13011: [5.48381466229, 43.2884471881],
  13012: [5.43991567678, 43.3077683678],
  13013: [5.43372238738, 43.3492780005],
  13014: [5.39408915769, 43.3455018067],
  13015: [5.363407897, 43.358794754],
  13016: [5.31337828595, 43.3649054121],
  69001: [4.82922464978, 45.7699284397],
  69002: [4.82617032549, 45.7492560394],
  69003: [4.86918522013, 45.7533552486],
  69004: [4.82396112689, 45.7786867581],
  69005: [4.8022536949, 45.7558317849],
  69006: [4.85207911437, 45.7728507239],
  69007: [4.83758495703, 45.7334098415],
  69008: [4.86935722305, 45.7342034691],
  69009: [4.80818327822, 45.7817805887],
  75001: [2.33629344655, 48.8626304852],
  75002: [2.34410716666, 48.8679033789],
  75003: [2.35936105897, 48.8630541318],
  75004: [2.35736193814, 48.854228282],
  75005: [2.34985938556, 48.8445086596],
  75006: [2.33267089859, 48.8489680919],
  75007: [2.31243868773, 48.8560825982],
  75008: [2.31258256042, 48.8725272666],
  75009: [2.33746024139, 48.8768961624],
  75010: [2.36111290456, 48.8760285569],
  75011: [2.37874106024, 48.8594154976],
  75012: [2.3892, 48.843928],
  75013: [2.36246822852, 48.8287176845],
  75014: [2.32710088326, 48.8289932116],
  75015: [2.29355937244, 48.8401554186],
  75016: [2.280195, 48.863694],
  75017: [2.30748555949, 48.8873371665],
  75018: [2.34871193387, 48.8927350746],
  75019: [2.370947, 48.897106],
  75020: [2.40081982673, 48.8631867774],
  94200: [2.380194, 48.813439],
  78500: [2.287592, 48.862725],
};
