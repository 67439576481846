import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Level } from 'phicomas-client';

import {
  UserMenu as DefaultUserMenu,
  HideOnScroll,
  AppBarProps,
} from 'react-admin';
import { toggleSidebar, useTranslate } from 'ra-core';

import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { ENV_LEVEL } from '../aws/aws-exports';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    toolbar: {
      paddingRight: 24,
      background: `repeating-linear-gradient(
        130deg,
        transparent -10px,
        transparent 40px,
        rgba(0, 0, 0, 0.2) 60px,
        rgba(0, 0, 0, 0.2) 160px,
        transparent 180px,
        transparent 230px
      )`,
    },
    toolbarEnvironmentDev: {
      backgroundColor: theme.palette.success.main,
    },
    toolbarEnvironmentStage: {
      backgroundColor: theme.palette.warning.main,
    },
    toolbarEnvironmentProd: {
      backgroundColor: theme.palette.error.main,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);

type CustomAppBarProps = AppBarProps & {
  className?: string;
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'transparent';
  logo?: any; // FIXME icon ?
};

const CustomAppBar: React.FC<CustomAppBarProps> = (
  props: CustomAppBarProps,
) => {
  const padhCredentials = localStorage.getItem('padhCredentials');

  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logo,
    logout,
    open,
    title,
    userMenu = (
      <DefaultUserMenu
        label={
          (padhCredentials && JSON.parse(padhCredentials).sub) || undefined
        }
      />
    ),
    ...rest
  } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={clsx(classes.toolbar, {
            [classes.toolbarEnvironmentDev]: ENV_LEVEL === Level.DEV,
            [classes.toolbarEnvironmentStage]: ENV_LEVEL === Level.STAGE,
            [classes.toolbarEnvironmentProd]: ENV_LEVEL === Level.PROD,
          })}
        >
          <Tooltip
            title={translate(
              open ? 'ra.action.close_menu' : 'ra.action.open_menu',
              {
                _: 'Open/Close menu',
              },
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={(): void => {
                dispatch(toggleSidebar());
              }}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          {React.Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          {userMenu && React.cloneElement(userMenu as JSX.Element, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

CustomAppBar.defaultProps = {
  className: undefined,
  color: 'secondary',
  logo: undefined,
};

export default CustomAppBar;
