import {
  GQLHtProposition,
  GQLHtAsset,
  GQLHtRequest,
  GQLHtResidence,
  GQLHtNode,
} from '../../types/ht/nodes';
import { ID } from '../../types/ht/base';

/* ********************
 * Mutation returned data
 */

export enum UpdateType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

type ConnectionUpdate = {
  field: string;
  id: ID;
};

export type NodeUpdateInfo = {
  mutation: UpdateType;
  connect: [ConnectionUpdate];
  disconnect: [ConnectionUpdate];
};

export type HtNodeUpdate<Node extends Partial<GQLHtNode> = GQLHtNode> = {
  id: ID;
  node: Node;
  updateInfo: NodeUpdateInfo;
};

/* ********************
 * Mutations
 */
export type MUTATION_CREATE_HT_RESIDENCE = {
  createResidence: HtNodeUpdate<GQLHtResidence>;
};
export type MUTATION_CREATE_HT_REQUEST = {
  createRequest: HtNodeUpdate<GQLHtRequest>;
};
export type MUTATION_CREATE_HT_PROPOSITION = {
  createProposition: HtNodeUpdate<GQLHtProposition>;
};
export type MUTATION_CREATE_HT_ASSET = {
  createAsset: HtNodeUpdate<GQLHtAsset>;
};
export type MutationCreateHtNode =
  | MUTATION_CREATE_HT_RESIDENCE
  | MUTATION_CREATE_HT_REQUEST
  | MUTATION_CREATE_HT_PROPOSITION
  | MUTATION_CREATE_HT_ASSET;

export type MUTATION_UPDATE_HT_RESIDENCE = {
  updateResidence: HtNodeUpdate<GQLHtResidence>;
};
export type MUTATION_UPDATE_HT_REQUEST = {
  updateRequest: HtNodeUpdate<GQLHtRequest>;
};
export type MUTATION_UPDATE_HT_PROPOSITION = {
  updateProposition: HtNodeUpdate<GQLHtProposition>;
};
export type MUTATION_UPDATE_HT_ASSET = {
  updateAsset: HtNodeUpdate<GQLHtAsset>;
};
export type MutationUpdateNode =
  | MUTATION_UPDATE_HT_RESIDENCE
  | MUTATION_UPDATE_HT_REQUEST
  | MUTATION_UPDATE_HT_PROPOSITION
  | MUTATION_UPDATE_HT_ASSET;

export type MUTATION_DELETE_HT_RESIDENCE = {
  deleteResidence: HtNodeUpdate<GQLHtResidence>;
};
export type MUTATION_DELETE_HT_REQUEST = {
  deleteRequest: HtNodeUpdate<GQLHtRequest>;
};
export type MUTATION_DELETE_HT_PROPOSITION = {
  deleteProposition: HtNodeUpdate<GQLHtProposition>;
};
export type MUTATION_DELETE_HT_ASSET = {
  deleteAsset: HtNodeUpdate<GQLHtAsset>;
};
export type MutationDeleteNode =
  | MUTATION_DELETE_HT_RESIDENCE
  | MUTATION_DELETE_HT_REQUEST
  | MUTATION_DELETE_HT_PROPOSITION
  | MUTATION_DELETE_HT_ASSET;
export type MutationDeleteNodesID = {
  [k: string]: HtNodeUpdate;
};
