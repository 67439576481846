import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { InputHelperText, FieldTitle, InputProps } from 'react-admin';
import { useInput } from 'ra-core';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _throttle from 'lodash/throttle';
import _isEqual from 'lodash/isEqual';

import { districtsPostalCodeGeoPoints } from '../../utils/geo';
import { Geolocation } from '../../types/ht/base';

type GeoGouvFeatureType = {
  type: 'Feature';
  properties: {
    code: string;
    nom: string;
    codesPostaux: [string];
  };
  geometry?: {
    type: 'Point';
    coordinates: [number, number];
  };
};

type GeoGouvResponseType = {
  type: 'FeatureCollection';
  features: GeoGouvFeatureType[];
};

function fetchGeolocations(rawQuery: string, callback: any): void {
  // https://geo.api.gouv.fr/decoupage-administratif/communes
  const endpoint = new URL(
    `https://geo.api.gouv.fr/communes?fields=nom,codesPostaux&format=geojson&geometry=centre`,
  );

  let postalCodeStart: string;
  // Starts with numbers (max 5) followed by space => there is a department code
  if (rawQuery.match(/^\d{2,5}(\s|$)/)) {
    [, postalCodeStart] = rawQuery.split(/^(\d{0,5})/);
    endpoint.searchParams.set('codeDepartement', rawQuery.substring(0, 2));
  }
  // Starts with 5 numbers followed by space => there is a postal code
  if (rawQuery.match(/^\d{5}(\s|$)/)) {
    endpoint.searchParams.set('codePostal', rawQuery.substring(0, 5));
  }
  // If not only numbers and or spaces
  if (!rawQuery.match(/^(\d|\s)+$/)) {
    // Name is what's after numbers and spaces
    const [, , name] = /(\d+\s+)?(.*)/g.exec(rawQuery) ?? [];
    if (name) {
      endpoint.searchParams.set('nom', name);
    }
  }

  fetch(endpoint.toString())
    .then(response => response.json())
    .then((response: GeoGouvResponseType): Geolocation[] => {
      return response.features.reduce(
        (acc: Geolocation[], { geometry, properties }) => {
          if (geometry?.coordinates) {
            properties.codesPostaux.forEach(postalCode => {
              if (!postalCodeStart || postalCode.startsWith(postalCodeStart)) {
                acc.push({
                  address: `${postalCode} ${properties.nom}`,
                  coordinates:
                    districtsPostalCodeGeoPoints[postalCode] ||
                    geometry.coordinates,
                });
              }
            });
          }

          return acc;
        },
        [],
      );
    })
    .then(callback);
}

type GeolocAutocompleteInputProps = InputProps<TextFieldProps>;

const GeolocAutocompleteInput: React.FC<GeolocAutocompleteInputProps> = ({
  label,
  fullWidth,
  helperText,
  disabled,
  resource,
  source,
  ...propsRest
}: GeolocAutocompleteInputProps) => {
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, checked, ...inputProps },
    isRequired,
    meta: { error, touched = false },
  } = useInput({
    resource,
    source,
    ...propsRest,
  });
  useEffect(() => {
    // useInput defaultValue doesn't work ans sets value to "" if none
    // value cannot be a string because it's a multiple autocomplete
    // (=> Autocomplete will run array functions on value such as .some())
    if (value === '') {
      finalFormOnChange([]);
    } else {
      const newValue = value.filter((v: any) => v);
      if (newValue.length !== value.length) {
        finalFormOnChange(newValue);
      }
    }
  }, [value, finalFormOnChange]);

  const [inputValue, setInputValue] = useState('');
  const handleChange = useCallback(
    (event, newValue) => {
      finalFormOnChange(newValue);
    },
    [finalFormOnChange],
  );
  const handleInputChange = useCallback((event, newValue) => {
    setInputValue(newValue);
  }, []);

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Geolocation[]>([]);

  const getOptions = useMemo(
    () =>
      _throttle(
        (request: string, callback: (results?: Geolocation[]) => void) => {
          fetchGeolocations(request, callback);
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;
    const cleanUp = (): void => {
      active = false;
    };

    // Always keep current value as an option (So MUIAutocomplete doesn't complain about value not being an option)
    let newOptions: Geolocation[] = (value || []) as Geolocation[];

    if (inputValue === '' || inputValue.length < 2) {
      setOptions(newOptions);
      return cleanUp;
    }

    setLoading(true);
    setOptions([]);
    getOptions(inputValue, (results?: Geolocation[]) => {
      // Prevent setState on unmounted component
      if (active) {
        if (results) {
          newOptions = newOptions.concat(results);
        }
        setOptions(newOptions);
        setLoading(false);
      }
    });

    return cleanUp;
  }, [value, inputValue, getOptions]);

  return (
    <div>
      <Autocomplete
        multiple
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        id={id}
        options={options}
        getOptionLabel={(option: Geolocation): string =>
          option && option.address
        }
        getOptionSelected={(
          option: Geolocation,
          checkedValue: Geolocation,
        ): boolean => _isEqual(option, checkedValue)}
        filterSelectedOptions // Hide values from options
        filterOptions={(allOptions: Geolocation[]): Geolocation[] =>
          allOptions.filter(option => option)
        }
        includeInputInList // Keyboard <UP> goes up to text input
        open={inputValue.length >= 2}
        loading={loading}
        handleHomeEndKeys={false} // Let <Home> and <End> manipulate the input
        fullWidth={fullWidth}
        disabled={disabled}
        noOptionsText="Pas d'options"
        loadingText="Chargement..."
        clearText="Effacer"
        closeText="Fermer"
        openText="Ouvrir"
        popupIcon={null}
        renderInput={(params): React.ReactNode => (
          <TextField
            {...params}
            {...(inputProps as TextFieldProps)}
            variant="filled"
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            error={touched && error}
            helperText={
              <InputHelperText
                touched={touched}
                error={error}
                helperText={helperText}
              />
            }
          />
        )}
      />
    </div>
  );
};

export default GeolocAutocompleteInput;
