import { DataProvider, Translate } from 'ra-core';
import { downloadCSV } from 'react-admin';
import * as jsonExport from 'jsonexport/dist';
import moment from 'moment';

import config from '../../config';

import { MinoUser, SMinoUser, MinoRequest } from '../../types/mino/nodes';
import {
  GQLMinoMinoStatus,
  GQLMinoRequestEdge,
  GQLMinoEdge,
  GQLMinoPaymentEdge,
} from '../../types/mino/schema';
import { uploadToS3 } from '../../aws/s3-utils-management';
import { getUserMonthData, NO_MINORATION } from './utils';

const validRequestStatuses = [
  GQLMinoMinoStatus.EN_COURS,
  GQLMinoMinoStatus.SUSPENDUE,
  GQLMinoMinoStatus.VALIDEE,
];

/** Returns a map of users id indexed by sncfCP */
export function getUsersCP(users: MinoUser[]): Map<string, string> {
  return users.reduce<Map<string, string>>((acc, { id, sncfCP, requests }) => {
    if (sncfCP) {
      const userMatch = (requests?.edges || []).some(
        ({ node }: GQLMinoEdge & GQLMinoRequestEdge) => {
          return node.wfStatus && validRequestStatuses.includes(node.wfStatus);
        },
      );
      if (userMatch) {
        acc.set(sncfCP, id);
      }
    }
    return acc;
  }, new Map());
}

export function getUsersRequests(
  users: MinoUser[],
): Map<string, MinoRequest[]> {
  return users.reduce<Map<string, MinoRequest[]>>(
    (acc, { sncfCP, requests }) => {
      if (sncfCP) {
        (requests?.edges as GQLMinoRequestEdge[]).forEach(
          ({ node: request }) => {
            if (
              request.wfStatus &&
              validRequestStatuses.includes(request.wfStatus)
            ) {
              acc.set(sncfCP, [...(acc.get(sncfCP) || []), request]);
            }
          },
        );
      }
      return acc;
    },
    new Map(),
  );
}

export function exportUsersCP(
  dataProvider: DataProvider,
  translate: Translate,
  options: { local?: boolean } = {},
): Promise<void> {
  const translatedSncfCP = translate(`resources.minoUser.exportFields.sncfCP`);
  return dataProvider
    .getListAll('minoUser')
    .then(
      ({ data: users }: { data: MinoUser[]; flattenedData: SMinoUser[] }) => {
        return Array.from(getUsersCP(users).keys()).map(sncfCP => ({
          [translatedSncfCP]: sncfCP,
        }));
      },
    )
    .then((dataForExport: string[]) => {
      jsonExport(
        dataForExport,
        {
          ...config.exportedCSVconfig,
          headers: [translatedSncfCP],
        },
        (err: any, csv: any) => {
          if (err) console.error(err);
          if (options?.local) {
            downloadCSV(`\ufeff${csv}`, 'Agents');
          } else {
            const blob = new Blob(['\ufeff', csv]);
            const file = new File([blob], 'Agents.csv', { type: 'text/csv' });
            const datedFile = new File(
              [blob],
              `Agents_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`,
              { type: 'text/csv' },
            );
            uploadToS3(file);
            uploadToS3(datedFile);
          }
        },
      );
    });
}

export function exportData(
  dataProvider: DataProvider,
  translate: Translate,
): Promise<void> {
  const headers = [
    'sncfCP',
    'month',
    'paySlip',
    'rent',
    'provDeduction',
    'mino',
    'provider',
    'fullMonthContract',
    'sncfSA',
    'sncfBranche',
    'sncfDivision',
    'sncfRG',
    'sncfEtablissement',
    'requestStatus',
    'nbPayments',
    'familyName',
    'givenName',
    'bday',
    'requestId',
    'anomalies',
  ] as const;
  const translatedHeaders: string[] = [];
  const translatedHeadersMap = headers.reduce(
    (acc, header) => {
      const tHeader = translate(`resources.minoUser.exportFields.${header}`);
      translatedHeaders.push(tHeader);
      acc[header] = tHeader;
      return acc;
    },
    {} as {
      [k in (typeof headers)[number]]: string;
    },
  );
  return dataProvider
    .getListAll('minoUser')
    .then(
      ({ data: users }: { data: MinoUser[]; flattenedData: SMinoUser[] }) => {
        const data: {
          [k: string]: string | number | boolean | undefined;
        }[] = [];

        users.forEach(user => {
          const userMonthData = getUserMonthData(user);
          if (userMonthData) {
            data.push({
              [translatedHeadersMap.sncfCP]: userMonthData.sncfCP,
              [translatedHeadersMap.month]: moment(userMonthData.month).format(
                'DD/MM/YYYY',
              ),
              [translatedHeadersMap.paySlip]: moment(
                userMonthData.paySlip,
              ).format('DD/MM/YYYY'),
              [translatedHeadersMap.rent]: userMonthData.rent,
              [translatedHeadersMap.provDeduction]: userMonthData.provDeduction,
              [translatedHeadersMap.mino]: userMonthData.mino,
              [translatedHeadersMap.provider]: userMonthData.provider,
              [translatedHeadersMap.fullMonthContract]:
                userMonthData.fullMonthContract,
              [translatedHeadersMap.sncfSA]: userMonthData.sncfSA,
              [translatedHeadersMap.sncfBranche]: userMonthData.sncfBranche,
              [translatedHeadersMap.sncfDivision]: userMonthData.sncfDivision,
              [translatedHeadersMap.sncfRG]: userMonthData.sncfRG,
              [translatedHeadersMap.sncfEtablissement]:
                userMonthData.sncfEtablissement,
              [translatedHeadersMap.requestStatus]: userMonthData.requestStatus,
              [translatedHeadersMap.nbPayments]: userMonthData.nbPayments,
              [translatedHeadersMap.familyName]: userMonthData.familyName,
              [translatedHeadersMap.givenName]: userMonthData.givenName,
              [translatedHeadersMap.bday]: userMonthData.bday,
              [translatedHeadersMap.requestId]: userMonthData.requestId,
              [translatedHeadersMap.anomalies]: Array.from(
                userMonthData.anomalies ?? [],
              )
                .map(anomalyField =>
                  translate(`resources.minoUser.exportFields.${anomalyField}`),
                )
                .join(','),
            });
          }
        });
        return data;
      },
    )
    .then((dataForExport: any[]) => {
      jsonExport(
        dataForExport,
        {
          ...config.exportedCSVconfig,
          headers: translatedHeaders,
        },
        (err: any, csv: any) => {
          if (err) console.error(err);
          downloadCSV(
            `\ufeff${csv}`,
            `PADH_MINO_DATA_${moment().format('YYYY-MM-DD')}`,
          );
        },
      );
    });
}

export function exportEvs(
  dataProvider: DataProvider,
  translate: Translate,
): Promise<void> {
  const headers = [
    'sncfCP',
    'originalPeriod',
    'rubric',
    'sign',
    'centsAmount',
  ] as const;
  const translatedHeaders: string[] = [];
  const translatedHeadersMap = headers.reduce(
    (acc, header) => {
      const tHeader = translate(`resources.minoUser.exportFields.${header}`);
      translatedHeaders.push(tHeader);
      acc[header] = tHeader;
      return acc;
    },
    {} as {
      [k in (typeof headers)[number]]: string;
    },
  );
  return dataProvider
    .getListAll('minoUser')
    .then(
      ({ data: users }: { data: MinoUser[]; flattenedData: SMinoUser[] }) => {
        const data: {
          [k: string]: string | number | boolean | undefined;
        }[] = [];

        users.forEach(user => {
          const userMonthData = getUserMonthData(user);
          if (userMonthData && userMonthData.mino !== NO_MINORATION) {
            data.push({
              [translatedHeadersMap.sncfCP]: userMonthData.sncfCP,
              [translatedHeadersMap.originalPeriod]: moment()
                .subtract(1, 'month')
                .format('MMYYYY'),
              [translatedHeadersMap.rubric]: 'LHT',
              [translatedHeadersMap.sign]: '+',
              [translatedHeadersMap.centsAmount]: `${
                +(userMonthData.mino ?? 0) * 100
              }`.padStart(7, '0'),
            });
          }
        });
        return data;
      },
    )
    .then((dataForExport: any[]) => {
      jsonExport(
        dataForExport,
        {
          ...config.exportedCSVconfig,
          headers: translatedHeaders,
        },
        (err: any, csv: any) => {
          if (err) console.error(err);
          downloadCSV(
            `\ufeff${csv}`,
            `EVS-PADH-${moment().format('DDMMYYYY')}`,
          );
        },
      );
    });
}

export function exportPayments(
  dataProvider: DataProvider,
  translate: Translate,
): Promise<void> {
  const headers = [
    'minoUser.fields.sncfCP',
    'minoPayment.fields.month',
    'minoPayment.fields.paySlip',
    'minoPayment.fields.rent',
    'minoPayment.fields.provDeduction',
    'minoPayment.fields.deduction',
    'minoPayment.fields.provider',
    'minoPayment.fields.sncfSA',
    'minoPayment.fields.sncfBranche',
    'minoPayment.fields.sncfDivision',
    'minoPayment.fields.sncfRG',
    'minoPayment.fields.sncfEtablissement',
  ] as const;
  const translatedHeaders: string[] = [];
  headers.forEach(header => {
    const tHeader = translate(`resources.${header}`);
    translatedHeaders.push(tHeader);
  });
  return dataProvider
    .getListAll('minoUser')
    .then(
      ({ data: users }: { data: MinoUser[]; flattenedData: SMinoUser[] }) => {
        const data: { [k: string]: any }[] = [];
        users.forEach(user => {
          if (user.sncfCP) {
            user.payments?.edges.forEach(
              ({ node: payment }: GQLMinoPaymentEdge) => {
                data.push({
                  [translatedHeaders[0]]: user.sncfCP,
                  [translatedHeaders[1]]: moment(payment.month).format(
                    'MM/YYYY',
                  ),
                  [translatedHeaders[2]]: moment(payment.paySlip).format(
                    'MM/YYYY',
                  ),
                  [translatedHeaders[3]]: payment.rent,
                  [translatedHeaders[4]]: payment.provDeduction,
                  [translatedHeaders[5]]: payment.deduction,
                  [translatedHeaders[6]]: payment.provider,
                  [translatedHeaders[7]]: payment.sncfSA,
                  [translatedHeaders[8]]: payment.sncfBranche,
                  [translatedHeaders[9]]: payment.sncfDivision,
                  [translatedHeaders[10]]: payment.sncfRG,
                  [translatedHeaders[11]]: payment.sncfEtablissement,
                });
              },
            );
          }
        });
        return data;
      },
    )
    .then((dataForExport: any[]) => {
      jsonExport(
        dataForExport,
        {
          ...config.exportedCSVconfig,
          headers: translatedHeaders,
        },
        (err: any, csv: any) => {
          if (err) console.error(err);
          downloadCSV(`\ufeff${csv}`, '6c-Export des transactions');
        },
      );
    });
}
