// extracted from gapi client
export const winFeatures = (w = 599, h = 550): string => {
  const win = window.self;
  const x = win.outerWidth / 2 + win.screenX - w / 2;
  const y = win.outerHeight / 2 + win.screenY - h / 2;
  return [
    'toolbar=no',
    'location=yes',
    'directories=no',
    'status=no',
    'menubar=no',
    'scrollbars=yes',
    'resizable=yes',
    'copyhistory=no',
    `width=${w}`,
    `height=${h}`,
    `left=${x}`,
    `top=${y}`,
  ].join(',');
};
export const WINDOW_FEATURES =
  'toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=599,height=600,top=420,left=980.5';
// inspired by gapi
export const winModal = (...args: any[]): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const win = window.open(...args);
    if (!win) {
      reject();
      return;
    }
    if (win.focus) win.focus();
    const timer = setInterval(() => {
      if (win.closed || !win) {
        clearInterval(timer);
        resolve();
      }
    }, 300);
  });
