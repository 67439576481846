import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

import { QueryAllNode } from '../graphql/ht/queries';

import { ObservableTuple } from './withObserver';

const getObservableQuery = (
  query: DocumentNode,
  client: ApolloClient<NormalizedCacheObject>,
): ObservableTuple => {
  const list = client.watchQuery<QueryAllNode>({
    query,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      last: 100, // List size cannot exceed 1000
    },
  });
  const init = list.result();
  // subscribe now to actually start the query
  list.subscribe(() => {});

  const full: typeof init = new Promise((resolve, reject) => {
    let pageLoop: (p: ApolloQueryResult<QueryAllNode>) => void;

    const getMore = (cursor: string): void => {
      list
        .fetchMore({
          variables: {
            before: cursor,
          },
          updateQuery: (prev, { fetchMoreResult: next }) => {
            return {
              ...prev,
              allList: {
                ...prev.allList,
                edges: [...prev.allList.edges, ...(next?.allList.edges ?? [])],
                pageInfo: next?.allList.pageInfo ?? prev.allList.pageInfo,
              },
            } as QueryAllNode;
          },
        })
        .then(pageLoop)
        .catch(reject);
    };

    pageLoop = (res: ApolloQueryResult<QueryAllNode>): void => {
      const { data } = res;
      if (data.allList.pageInfo.hasNextPage) {
        requestAnimationFrame(() => {
          if (!data.allList.pageInfo.endCursor) {
            resolve(res);
          } else {
            getMore(data.allList.pageInfo.endCursor);
          }
        });
      } else {
        resolve(res);
      }
    };

    init.then(pageLoop);
  });

  return [list, init, full];
};

export default getObservableQuery;
